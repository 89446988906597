interface TypedValue {
  __type__: string;
  __value__: any;
}

export class CustomTypeDeserializer {
  /**
   * 직렬화된 문자열을 원래의 타입으로 복원합니다.
   */
  static deserialize(jsonString: string): any {
    const parsed = JSON.parse(jsonString);
    return this.deserializeValue(parsed);
  }

  /**
   * 값을 적절한 타입으로 변환합니다.
   */
  private static deserializeValue(value: any): any {
    // undefined 체크
    if (value === undefined) {
      return undefined;
    }

    // 배열인 경우
    if (Array.isArray(value)) {
      return value.map(item => this.deserializeValue(item));
    }

    // TypedValue 인터페이스를 만족하는지 확인
    if (this.isTypedValue(value)) {
      return this.convertTypedValue(value);
    }

    // 객체인 경우 (중첩된 객체)
    if (typeof value === 'object' && value !== null) {
      const result: { [key: string]: any } = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          result[key] = this.deserializeValue(value[key]);
        }
      }
      return result;
    }

    return value;
  }

  /**
   * 객체가 TypedValue 인터페이스를 만족하는지 확인합니다.
   */
  private static isTypedValue(value: any): value is TypedValue {
    return value &&
      (typeof value === 'object') &&
      ('__type__' in value) &&
      (value['__type__'] === "null" || '__value__' in value);
  }

  /**
   * TypedValue를 실제 타입으로 변환합니다.
   */
  private static convertTypedValue(typedValue: TypedValue): any {
    const { __type__, __value__ } = typedValue;

    switch (__type__) {
      case 'null':
        return null;

      case 'number':
        return Number(__value__);

      case 'string':
        return String(__value__);

      case 'boolean':
        return Boolean(__value__);

      case 'base64bytes':
        return this.base64ToUint8Array(__value__);

      default:
        console.warn(`Unknown type: ${__type__}`);
        return __value__;
    }
  }

  /**
   * Base64 문자열을 Uint8Array로 변환합니다.
   */
  private static base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }
}

// // 테스트 코드
// function testDeserialization() {
//   // null 값 테스트
//   const nullJson = '{"__type__":"null","__value__":null}';
//   const nullValue = CustomTypeDeserializer.deserialize(nullJson);
//   console.log('Null value:', nullValue); // null

//   // 배열에 null 포함
//   const arrayWithNullJson = '[{"__type__":"number","__value__":1},{"__type__":"null","__value__":null},{"__type__":"string","__value__":"text"}]';
//   const arrayWithNull = CustomTypeDeserializer.deserialize(arrayWithNullJson);
//   console.log('Array with null:', arrayWithNull); // [1, null, "text"]

//   // 객체에 null 포함
//   const objectWithNullJson = `{
//     "id": {"__type__":"number","__value__":1},
//     "name": {"__type__":"null","__value__":null},
//     "data": {"__type__":"string","__value__":"test"}
//   }`;
//   const objectWithNull = CustomTypeDeserializer.deserialize(objectWithNullJson);
//   console.log('Object with null:', objectWithNull);
//   // { id: 1, name: null, data: "test" }
// }

// // 테스트 실행
// testDeserialization();