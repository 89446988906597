import { makeAutoObservable } from "mobx";
import FileInfoRepository from "../repositories/FileInfoRepository";

const LogPrefix = "[FileInfoStore]";

type FileInfoStoreProps = {
	fileInfoRepository: FileInfoRepository;
};

export default class FileInfoStore {
	public fileInfoRepository: FileInfoRepository;

	constructor(props: FileInfoStoreProps) {
		this.fileInfoRepository = props.fileInfoRepository;

		makeAutoObservable(this);
	}

	create = async (file: File) => {
		try {
			const data = await this.fileInfoRepository.create(file);

			const { id, downloadUrl } = data;
			if (!id || !downloadUrl) return;

			console.log(LogPrefix, "Success create ...", data);
			return { id, downloadUrl };
		} catch (e) {
			console.log(LogPrefix, "Cannot create ...", e);
		}
	}

deleteList = async (list) => {
		try {
			await  this.fileInfoRepository.deleteList(list);

			console.log(LogPrefix, "Success deleteList ...",);
			// console.log(LogPrefix, "Success deleteList ...", response);

		} catch (e) {
			console.log(LogPrefix, "Cannot deleteList ...", e);
		}
	}
}
