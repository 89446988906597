import { IDiscoveredDevices } from "../../../../../../neolab-libs/nl-lib3-common";


export enum PenDataInquiryStatus {
	idle = 0,
	loading = 1,
	canceled = 2,
	error = 3,
	loaded = 4,
	retry = 5,
	dataInRange = 101,
	dataInBoth = 102,
	dataNotInRange = 11,
	nothing = 12,
	dataLoadedButNothing = 13,

	dataLoading = 201,
	dataLoaded = 202,


}


export type IDiscoveredDevicesExtended = IDiscoveredDevices & {
	studentInfo: PenStudentInfo,
	inquiryStatus: PenDataInquiryStatus,
	// checked: boolean,
};


export class PenStudentInfo {
	studentId: string;
	name: string;
	grade: number;
	className: string;
	number: number;
	email: string;

	schoolName: string;

	constructor(studentId: string, name: string, schoolName: string, grade: number, className: string, number: number, email: string) {
		this.studentId = studentId;
		this.name = name;
		this.schoolName = schoolName;
		this.grade = grade;
		this.className = className;
		this.number = number;
		this.email = email;
	}
	toString() {
		return `${this.grade}학년 ${this.className}반 ${this.number}번 ${this.name}`;
	}

	static compare(a: PenStudentInfo, b: PenStudentInfo) {
		if (!a && !b) return 0;
		if (!!a && !b) return -1;
		if (!a && !!b) return 1;
		if (a.schoolName !== b.schoolName) return a.schoolName.localeCompare(b.schoolName);

		if (a.grade !== b.grade) return a.grade - b.grade;
		if (a.className !== b.className) return a.className.localeCompare(b.className);
		if (a.number !== b.number) return a.number - b.number;
		return a.name.localeCompare(b.name);

	}

	static compareName(a: PenStudentInfo, b: PenStudentInfo) {
		if (!a && !b) return 0;
		if (!!a && !b) return -1;
		if (!a && !!b) return 1;
		return a.name.localeCompare(b.name);
	}

	static compareSchool(a: PenStudentInfo, b: PenStudentInfo) {
		if (!a && !b) return 0;
		if (!!a && !b) return -1;
		if (!a && !!b) return 1;
		return a.schoolName.localeCompare(b.schoolName);
	}
}
