import {
	Box,
	Button,
	IconButton,
	Popover,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as TooltipClose } from '../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../common/images/TooltipTipIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../common/images/WarningIcon.svg';
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
// import { withRouter } from "../../../../components/WithRouter";

import { ResultRow } from "./ResultRow";
import { StandardAnswerRow } from "./StandardAnswerRow";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
	params: any,
	submissionCode: string,
	submissionInfo: any,
	questionList: any,
	examOrderAndQuestionsLengthMap: any
}

function ResultReviewRightPanel(props: Props) {
	const { questionList, examOrderAndQuestionsLengthMap } = props;
	// const params = useParams();
	const i18next = useTranslation();
	const classes = useEmotionStyles(ResultReviewStyle);

	const [anchorEl, setAnchorEl] = React.useState<Element>(null);
	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;

	const handleClickTooltip = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseTooltip = () => {
		setAnchorEl(null);
	};

	const handleClickTooltipClosedBtn = () => {
		handleCloseTooltip();
	};


	const standardTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			totalScore += Number(question.score);
		})

		return totalScore;
	}, [questionList]);

	const markedTotalScore = React.useMemo(() => {
		let totalScore = 0;
		questionList.map(question => {
			if (!question.answer) return;

			totalScore += Number(question.answer.totalScore);
		});

		return totalScore;
	}, [questionList]);


	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.compareTop, classes.compareTopColor)}>
				<Box display='flex' alignItems='center' justifyContent='center' >
					<Typography className={classes.commonSubTitle}>
						{i18next.t("채점 결과")}
					</Typography>
					<IconButton className={clsx(classes.iconBtnReset, classes.examInfoIcon)} onClick={handleClickTooltip} disableRipple >
						<WarningIcon />
					</IconButton>
					<Popover
						id={id}
						open={openTool}
						anchorEl={anchorEl}
						onClose={handleCloseTooltip}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						className={classes.popoverTooltip}
					>
						<Box className={classes.tooltipTop}>
							<TooltipTipIcon />
						</Box>
						<Box display={'flex'} alignItems='center' justifyContent='space-between' style={{ marginTop: '7px', marginBottom: '12px' }}>
							<Typography className={classes.tooltipTitle}>{i18next.t("채점 결과 근거와 진단 참고")}</Typography>

							<Box display={'flex'} justifyContent={'flex-end'} className={classes.tooltipBtn}>
								<Button disableRipple onClick={handleClickTooltipClosedBtn}>
									<TooltipClose />
								</Button>
							</Box>
						</Box>
						<Typography className={classes.tooltipText}>
							{i18next.t("선생님이 작성한 ‘채점 기준표'를 기준으로 평가 항목에 대한 배점을 부여합니다.")}<br /><br />{i18next.t("상세한 채점 결과 설명과 의견은 선생님의 코멘트에 기재되어 있습니다.")}<br />{i18next.t("모범 답안을 참고하여 자신이 어떤 부분에서 부족했는지 판단하고 비교해 볼 수 있습니다.")}
						</Typography>
					</Popover>
				</Box>
			</Box>

			<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "40px" }}>
				<Typography className={classes.amountScoreTitle}>{i18next.t("총점")}</Typography>
			</Box>
			<Box className={classes.totalAmountScore}>
				<Typography><span>{markedTotalScore}</span>/{standardTotalScore}<span className={classes.amountScoreText}>{i18next.t("점")}</span></Typography>
			</Box>

			{questionList.length > 0 &&
				questionList.map((question, idx) =>
					<ResultRow
						key={idx}
						// examInfo={getExamInfoByExamCode(question.projectExamCode)}
						questionInfo={question}
						examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
					/>
				)}

			<Box className={clsx(classes.greenInnerBox, classes.redInnerBox)}>
				<Box className={clsx(classes.tagNameBox, classes.tagNameBoxRed)}>
					<Typography>{i18next.t("모범 답안")}</Typography>
				</Box>

				{questionList.length > 0 &&
					questionList.map((question, idx) =>
						<StandardAnswerRow
							key={idx}
							questionInfo={question}
							examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
						/>
					)}
			</Box>

			<Box display='flex' alignItems='center' justifyContent='center'>
				<Box className={clsx(classes.borderGuide, classes.borderGuideText)}>
					<Typography>{i18next.t("마지막입니다.")}</Typography>
				</Box>
			</Box>

			{/*<IconButton*/}
			{/*  className={classes.pageUpIcon}*/}
			{/*  onClick={handleClickPageUp}*/}
			{/*  disableRipple>*/}
			{/*    <PageUpIcon />*/}
			{/*</IconButton>*/}
		</>
	);
}

export default observer<typeof ResultReviewRightPanel>(ResultReviewRightPanel);

