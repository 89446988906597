import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const TextViewerStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	main: {
		width: '100%',
		'& .toastui-editor-contents': {
			// fontSize: 'calc(1em + 1px)',
			fontSize: '1rem',
		},
		'& .toastui-editor-contents .toastui-editor-ww-code-block:after': {
			display: 'none'
		}

	}
});
