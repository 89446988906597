import { Box, Button, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginBorderLine } from "../../../../common/images/LoginBorderLine.svg";
import { ReactComponent as LoginEyeIcon } from '../../../../common/images/LoginEyeIcon.svg';
import { ReactComponent as LoginEyeSlashIcon } from '../../../../common/images/LoginEyeSlashIcon.svg';
import { ReactComponent as LoginPasswordIcon } from '../../../../common/images/LoginPasswordIcon.svg';
import { ReactComponent as LogoWithTile } from "../../../../common/images/logo_and_title_Green_BG.svg";
import { removeSpacechar } from '../../../../common/Validation';
// import { withRouter } from '../../../../components/WithRouter';
import { SignUpStyle } from '../../styles/SignUpStyle';

import { clsx } from "clsx";
import { ReactComponent as ManageCopyN } from '../../../../common/images/ManageCopyN_NoStrokeColor.svg';
import { ReactComponent as PasswordNumberCheckedErrorIcon } from '../../../../common/images/PasswordNumberCheckedErrorIcon.svg';
import { ReactComponent as PasswordNumberCheckedIcon } from '../../../../common/images/PasswordNumberCheckedIcon.svg';
import { ReactComponent as PasswordNumberUnCheckedIcon } from '../../../../common/images/PasswordNumberUnCheckedIcon.svg';
import { useStores } from '../../../../hooks/useStores';
import { useLocation, useNavigate } from 'react-router-dom';
import useEmotionStyles from '../../styles/useEmotionStyles';
import { useNeoConfirm } from '../../../../hooks/useNeoConfirm';

type Props = {
};


const ResetPassword = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { authStore } = useStores();
	const classes = useEmotionStyles( SignUpStyle );
	const i18next = useTranslation();
	// URL 쿼리 파라미터에서 token 가져오기
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const newPasswordRef = React.useRef(null);
	const confirmPasswordRef = React.useRef(null);
	const {alert, confirm} = useNeoConfirm();

	React.useEffect(() => {
		// 컴포넌트가 마운트되면 새 비밀번호 필드에 포커스
		newPasswordRef.current.focus();
	}, []);


	// 패스워드 관련
	const [isValidPassword, setIsValidPassword] = React.useState([true, false, false, false]);
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

	const changeSignUpPassword = (e) => {
		removeSpacechar(e);
		const isValidPassword = authStore.changeSignUpPassword(e.target.value);
		setIsValidPassword(isValidPassword);
		setPassword(e.target.value);
	}



	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			await alert(i18next.t('비밀번호가 일치하지 않습니다.'));
			return;
		}
		try {
			await authStore.resetPassword(token!, password);
			await alert(i18next.t('비밀번호가 성공적으로 재설정되었습니다.'));
			navigate('/login');
		} catch (error) {
			if (error.response?.data?.reason === "Invalid or expired password reset token") {
				await alert(i18next.t('유효하지 않거나 이미 만료된 비밀번호 변경 토큰입니다.'));
			}
			else {
				await alert(i18next.t('비밀번호 재설정에 실패했습니다. 다시 시도해주세요.'));

			}
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword(prevState => !prevState);
	}

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(prevState => !prevState);
	}

	return (
		<Box className={classes.body} >

			<Box>
				<LogoWithTile className={classes.logo} />
			</Box>
			<Box className={classes.borderLine}>
				<LoginBorderLine />
			</Box>

			<div className={classes.root}>

				<Box className={classes.loginBox}>
					<Typography variant="h5" component="h1" gutterBottom>
						{i18next.t('새 비밀번호 설정')}
					</Typography>
					<form onSubmit={handleSubmit}>
						<OutlinedInput
							fullWidth
							inputProps={{ 'aria-label': 'password input box', maxLength: 20, }}
							id="outlined-password-signup"
							autoComplete="off"
							type={showPassword ? 'text' : 'password'}
							startAdornment={<InputAdornment position="start"> <LoginPasswordIcon /> </InputAdornment>}
							endAdornment={
								<InputAdornment position="end">
									<IconButton className={classes.iconButton} onClick={handleClickShowPassword} disableRipple tabIndex={4}>
										{showPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
									</IconButton>
								</InputAdornment>
							}
							placeholder={i18next.t('새 비밀번호')}
							onChange={changeSignUpPassword}
							onPaste={e => { e.preventDefault(); return false; }}
							required

							sx={{ marginTop: '15px' }}
							inputRef={newPasswordRef}
							tabIndex={1}
						/>


						<OutlinedInput
							fullWidth
							inputProps={{ 'aria-label': 'password input box', maxLength: 20, }}
							id="outlined-password-signup"
							autoComplete="off"
							type={showConfirmPassword ? 'text' : 'password'}
							startAdornment={<InputAdornment position="start">
								<ManageCopyN style={{ stroke: "#c8c8c8" }} />
							</InputAdornment>}
							endAdornment={
								<InputAdornment position="end">
									<IconButton className={classes.iconButton} onClick={handleClickShowConfirmPassword} disableRipple tabIndex={5}>
										{showConfirmPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
									</IconButton>
								</InputAdornment>
							}
							placeholder={i18next.t('비밀번호 확인')}
							onChange={(e) => setConfirmPassword(e.target.value)}
							onPaste={e => { e.preventDefault(); return false; }}
							required

							sx={{ marginTop: '15px' }}
							inputRef={confirmPasswordRef}
							tabIndex={2}
						/>


						{isValidPassword[2] === true ? (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberCheckedIcon />
								<Typography className={clsx(classes.checkedText, classes.checkedOnText)}>{i18next.t('영문, 숫자, 특수문자 중 2가지 이상 조합')}</Typography>
							</Box>
						) : isValidPassword[0] === false ? (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberCheckedErrorIcon />
								<Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>
									{i18next.t('영문, 숫자, 특수문자 중 2가지 이상 조합')}
								</Typography>
							</Box>
						) : (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberUnCheckedIcon />
								<Typography className={classes.checkedText}>{i18next.t('영문, 숫자, 특수문자 중 2가지 이상 조합')}</Typography>
							</Box>
						)}

						{isValidPassword[3] === true ? (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberCheckedIcon />
								<Typography className={clsx(classes.checkedText, classes.checkedOnText)}>{i18next.t('10자 ~ 20자')}</Typography>
							</Box>
						) : isValidPassword[0] === false ? (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberCheckedErrorIcon />
								<Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>{i18next.t('10자 ~ 20자')}</Typography>
							</Box>
						) : (
							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberUnCheckedIcon />
								<Typography className={classes.checkedText}>{i18next.t('10자 ~ 20자')}</Typography>
							</Box>
						)}

						{
							(password.length > 0 && (password !== confirmPassword)) &&

							<Box display="flex" alignItems="center" className={classes.checkedMargin}>
								<PasswordNumberCheckedErrorIcon />
								<Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>{i18next.t('일치하지 않는 비밀번호')}</Typography>
							</Box>

						}

						<Button
							sx={{ marginTop: '15px' }}
							className={classes.buttonStyle}
							fullWidth
							disableRipple
							disabled={
								!isValidPassword[1]
								|| password.length < 1
								|| (password !== confirmPassword)
							}
							type="submit"
							tabIndex={3}
						>
							{i18next.t('비밀번호 재설정')}
						</Button>



						{/* <Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submitButton}
						>
							{i18next.t('비밀번호 재설정')}
						</Button> */}
					</form>
				</Box>
			</div>
		</Box>
	);
};


export default observer<typeof ResetPassword>(ResetPassword);