import {
	Box,
	Button,
	Checkbox,
	Dialog,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";

import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import clsx from "clsx";
import { ReactComponent as SmartPenClose } from "../../../../../../common/images/SmartPenClose.svg";

import { ReactComponent as TableListOrder } from '../../../../../../common/images/TableListOrder.svg';

import { useTranslation } from "react-i18next";
import { NativeMethods } from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods";
import { IProjectSubmissionTransfer } from "../../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { BatchUploadStlye } from "./BatchUploadStlye";
import { IDiscoveredDevices, NeoStroke, sleep } from "../../../../../../neolab-libs/nl-lib3-common";
import { BatchUploadTableRow } from "./BatchUploadTableRow";
import { DownloadNativePopup } from "./DownloadNativePopup";
import { INcodeAllocationResponse } from "../../../../../../repositories/model/transfer/INcodeAllocationResponse";
import { getSortedData, rowSortingHits, getSortingHitOrderBy } from "./BatchUploadDlgSortingFunc";
import { IDiscoveredDevicesExtended, PenDataInquiryStatus, PenStudentInfo } from "./PenStudentInfo";
import CompareNeoPenData from "../CompareNeoPenData/CompareNeoPenData";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { useStores } from "../../../../../../hooks/useStores";
import { useNeoConfirm } from "../../../../../../hooks/useNeoConfirm";

type Props = {
	projectCode: string,
	templateProjectCode: string,
	open: boolean,
	ncodeAllocation: INcodeAllocationResponse,
	handleClose: () => void;
	projectSubmissionList: IProjectSubmissionTransfer[],

	refreshProjectSumissionList: () => void;

}


// 정렬 가능한 헤더 component
const SortingHeaderCell = (props: { sortRows: (val: string) => void, sortingHintName: string, title: string, sortKey: string }) => {
	const classes = useEmotionStyles(BatchUploadStlye);
	const { title, sortKey, sortRows, sortingHintName } = props;

	return (
		<Box className={classes.sortButton} style={{ cursor: 'default' }}>
			<Typography style={{ fontWeight: 800 }}>
				{title}
			</Typography>
			<IconButton
				onClick={() => { sortRows(sortKey); }}
				className={classes.iconBtnReset}
				style={{
					transform: sortingHintName !== sortKey ? 'rotate(90deg)' : (!getSortingHitOrderBy(sortKey) ? 'rotate(180deg)' : 'none'),
					transition: 'transform 0.3s ease'
				}}
				disableRipple
			>
				<TableListOrder />
			</IconButton>
		</Box>
	)
}

export function BatchUploadDialog(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles(BatchUploadStlye);
	const { projectSubmissionList, ncodeAllocation, projectCode, templateProjectCode, refreshProjectSumissionList } = props;

	const [samplingInterval, setSamplingInterval] = React.useState<{ success: boolean, min: number, max: number }>(null);
	// const [checkedDevices, setCheckedDevices] = React.useState<Record<string, boolean>>({});
	const [studentInfos, setStudentInfos] = React.useState<PenStudentInfo[]>([]);
	const [showShcoolName, setShowSchoolName] = React.useState(true);

	const [, forceUpdate] = React.useReducer(x => x + 1, 0);
	const [checkedDevices, setCheckedDevices] = React.useState<Record<string, boolean>>({});
	const [compareNeoPenOpen, setCompareNeoPenOpen] = React.useState(false);
	const [selectedSubmissionTransfer, setSelectedSubmissionTransfer] = React.useState<IProjectSubmissionTransfer & { submissionIndex: number }>(null);

	const sortingHintName = React.useRef<string>("penName");
	const sortedDevices = React.useRef<IDiscoveredDevicesExtended[]>([]);
	const discoveredDevices = React.useRef<IDiscoveredDevicesExtended[]>([]);

	const {alert, confirm} = useNeoConfirm();

	const toggleOmitSchoolName = (checked: boolean) => {
		setShowSchoolName(checked);
		forceUpdate();
	};

	const handleClickCloseDialog = () => {
		const handleClose = async () => {

			try {
				await NativeMethods.StopScan();
			}
			catch (e) {
				console.log("StopScan failed", e);
			}

			props.handleClose();

		}
		handleClose();
	}


	// 행 표시 정렬 관련
	const reverseSortingHitOrderBy = (name: string) => {
		const row = rowSortingHits.find(row => row.name === name);
		row.isAsending = !row.isAsending;
		forceUpdate();
	}

	const sortRows = async (hintName: string, pageNum?: number) => {
		if (sortingHintName.current === hintName) {
			reverseSortingHitOrderBy(hintName);
		}
		const sortedData = getSortedData(hintName, sortedDevices.current);
		sortedDevices.current = sortedData;

		sortingHintName.current = hintName;
		forceUpdate();
	}

	// const checkDevice = (deviceId: string, checked: boolean) => {
	// 	const device = sortedDevices.current.find((d) => d.id === deviceId);
	// 	if (device) {
	// 		device.checked = checked;
	// 	}
	// 	forceUpdate();
	// }

	const handleStudentInfoSelected = React.useCallback(
		(device: IDiscoveredDevicesExtended, studentInfo: PenStudentInfo) => {
			const updatedDevices = sortedDevices.current.map((d) => {
				// "학생 선택"으로 돌아온 경우
				if (!studentInfo) {
					if (d.id === device.id) {
						checkedDevices[d.id] = false;
						return { ...d, studentInfo: null };
					}
					return d;
				}

				// 다른 device에서 해당 학생이 이미 선택된 경우 해제
				if (d.studentInfo?.studentId === studentInfo.studentId && d.id !== device.id) {
					checkedDevices[d.id] = false;
					return { ...d, studentInfo: null };
				}

				// 현재 device에 학생 할당
				if (d.id === device.id) {
					checkedDevices[d.id] = true;
					return { ...d, studentInfo };
				}
				return d;
			});

			// 정렬된 데이터를 다시 적용
			const sorted = getSortedData(sortingHintName.current, updatedDevices);
			sortedDevices.current = sorted;

			forceUpdate();
		}, []);


	const handleCheckboxChange = (device: IDiscoveredDevicesExtended, checked: boolean) => {
		const { studentInfo, id: deviceId, inquiryStatus } = device;

		if (studentInfo) {
			// checked = checked && !(
			// 	inquiryStatus === PenDataInquiryStatus.nothing
			// 	|| inquiryStatus === PenDataInquiryStatus.dataNotInRange
			// );

			setCheckedDevices((prev) => ({ ...prev, [deviceId]: checked }));
		}
	};

	const handleSelectAllChange = (checked: boolean) => {
		const newChecked = sortedDevices.current.reduce((acc, device) => {
			if (checked) {
				if (device.studentInfo) {
					acc[device.id] = true;
				}
				return acc;
			}
			else {
				acc[device.id] = checked;

			}
			return acc;
		}, {} as Record<string, boolean>);
		setCheckedDevices(newChecked);
	};


	// 디바이스 리스트가 새로 들어왔다.
	// 문제는 현재 데이터를 받고 있는 디바이스는 scanned list에서 빠진다는 점이다.

	const registerDiscoveredDevices = React.useCallback((args: { devices: IDiscoveredDevices[] }) => {
		const { devices } = args;

		// 등록된 펜 중에서 이전에 등록되어 있다가 사라진 펜 중, operation 중인 것을 추가
		sortedDevices.current.forEach((d) => {
			if (d.inquiryStatus !== PenDataInquiryStatus.idle) {
				const found = devices.find((device) => device.mac === d.mac);
				if (!found) {
					devices.push(d);
				}
			}
		});

		// 등록된 학생 정보를 채워 넣는다.
		const devicesAndStudent = devices.map((device, index) => {
			const prevFound = sortedDevices.current.find((d) => d.mac === device.mac);
			// console.log(`device data ${index} ${device.name} ${device.mac} ${prevFound?.inquiryStatus} ${prevFound?.checked}`);
			const ret: IDiscoveredDevicesExtended = {
				...device,
				studentInfo: prevFound?.studentInfo,
				inquiryStatus: prevFound?.inquiryStatus || PenDataInquiryStatus.idle,
				// checked: prevFound?.checked || false,
			};
			return ret;
		});

		// 찾은 디바이스를 저장
		discoveredDevices.current = devicesAndStudent;

		const sortedData = getSortedData(sortingHintName.current, devicesAndStudent);
		sortedDevices.current = sortedData;
		forceUpdate();
	}, [sortingHintName]);


	React.useEffect(() => {
		// scan의 결과 값의 callback
		const callBackFunc = (args: { devices: IDiscoveredDevices[] }) => {
			registerDiscoveredDevices(args);
		}


		// scan 시작
		const scanPens = async () => {
			try {
				await NativeMethods.StopScan();
				await NativeMethods.DisconnectAllPens();
				await sleep(5000);

				const ret = await NativeMethods.StartScan({ callBackFunc });
				if (ret) {
					console.log("StartScan success");
					setSamplingInterval({ success: ret.success, min: ret.minSamplingInterval, max: ret.maxSamplingInterval });
				} else {
					console.log("StartScan failed");
				}
			}
			catch (e) {
				console.log("StartScan failed", e);
			}
		}
		scanPens();

		return () => {
			try {
				NativeMethods.StopScan();
			}
			catch (e) {
				console.log("StopScan failed", e);
			}
		}
	}, []);




	const onNativeAvailibilityChanged = React.useCallback((isAvailable: boolean) => {
		console.log("onNativeAvailibilityChanged", isAvailable);
		// if (strokes && isAvailable) {
		// 	putOfflineStrokesToRecognize([...strokes]);
		// }
	}, []);

	const SortingHeaderCellStudentInfo = (props: {
		title: string,
		sortKey: string,
		omitSchoolName: boolean,
		toggleOmitSchoolName: (checked: boolean) => void
	}) => {
		const { title, sortKey, omitSchoolName: omit, toggleOmitSchoolName: toggle } = props;

		return (
			<Box className={classes.sortButton}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between", // 양쪽 끝 정렬
					width: "100%",
				}}>

				{/* 왼쪽: 학생 정보와 정렬 버튼 */}
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<Typography style={{ fontWeight: 800 }}>
						{title}
					</Typography>

					<IconButton
						onClick={() => { sortRows(sortKey); }}
						className={classes.iconBtnReset}
						style={{
							transform: sortingHintName.current !== sortKey ? 'rotate(90deg)' : (!getSortingHitOrderBy(sortKey) ? 'rotate(180deg)' : 'none'),
							transition: 'transform 0.3s ease'
						}}
						disableRipple
					>
						<TableListOrder />
					</IconButton>
				</Box>

				{/* 오른쪽: 체크박스와 텍스트 */}
				<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
					<Checkbox
						checked={omit}
						onChange={(e) => toggle(e.target.checked)}
						sx={{ m: 0, p: 0 }}
					/>
					<Typography variant="caption">학교명 표시</Typography>
				</Box>
			</Box>
		)
	}



	React.useEffect(() => {
		const s = projectSubmissionList.map((submission) => {
			return new PenStudentInfo(
				submission.userCode,
				submission.userProfile.name,
				submission.userProfile.school,
				submission.userProfile.grade,
				submission.userProfile.className,
				submission.userProfile.number,
				""
			);
		});

		s.sort((a, b) => {
			if (a.grade < b.grade) return -1;
			if (a.grade > b.grade) return 1;
			if (a.className < b.className) return -1;
			if (a.className > b.className) return 1;
			if (a.number < b.number) return -1;
			if (a.number > b.number) return 1;

			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;

			return 0;
		});

		setStudentInfos(s);

	}, [projectSubmissionList])


	const isAllChecked =
		Object.values(checkedDevices).length > 0 &&
		Object.values(checkedDevices).every((value) => value);


	const handleDeviceStateChanged = (device: IDiscoveredDevicesExtended, state: PenDataInquiryStatus) => {
		const updatedDevices = sortedDevices.current.map((d, index) => {
			// let checked = d.checked;

			if (d.id === device.id) {
				//  비활성화(데이터 없는 펜은 체크 해제)
				if (false) {
					// 데이터 없는 펜은 체크 해제
					if (state === PenDataInquiryStatus.nothing || state === PenDataInquiryStatus.dataNotInRange) {
						console.log(`State changed: ${index} ${d.name} ${d.inquiryStatus}, FALSE`);
						checkedDevices[d.id] = false;
						// checked = false;
					}
				}

				d = { ...d, inquiryStatus: state };
			}
			return d;
		});

		sortedDevices.current = updatedDevices;
		forceUpdate();
	}

	const handleClickClose = () => {
		refreshProjectSumissionList();
		setCompareNeoPenOpen(false);
	}

	const [givenData, setGivenData] = React.useState<{ numPages: number, numStrokes: number, strokes: NeoStroke[] }>(null);

	const handleDataCheck = async (device: IDiscoveredDevicesExtended, studentInfo: PenStudentInfo, offlineData: { numPages: number; numStrokes: number; strokes: NeoStroke[]; }) => {
		const { studentId } = studentInfo || {}
		if (!studentId) {
			const confirmed = await confirm(i18next.t("학생이 선택되지 않았습니다. 필기 내용은 확인 가능합니다만, 제출물로 처리할 수 없습니다. 계속 진행하시겠습니까?"));
			if (!confirmed) return;
		}

		setGivenData(offlineData);
		setCompareNeoPenOpen(true);

		if (studentId) {
			const submit = projectSubmissionList.find((submission) => submission.userCode === studentId);
			setSelectedSubmissionTransfer({ ...submit, submissionIndex: null });
		} else {
			setSelectedSubmissionTransfer(null);
		}
	}

	return (
		<>
			<Dialog
				open={true}
				maxWidth={false} // 기본 maxWidth를 해제합니다.
				PaperProps={{
					sx: {
						width: "95vw", // 원하는 너비
						height: "95vh", // 원하는 높이
						overflowX: "hidden", // 불필요한 스크롤 제거
						overflowY: "auto", // 불필요한 스크롤 제거
					},
				}}>
				<Box
					sx={{
						// height: "80vh",
						width: "100%", // 원하는 너비
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '25px',
							'&:before': { content: "''", width: '24px', height: '24px' },
						}}
					>

						<DialogTitle sx={{ m: 0, padding: 0 }} id="customized-dialog-title" className={classes.titleDialog}>
							{i18next.t("스마트 펜에서 데이터 업로드")}
						</DialogTitle>

						<IconButton className={clsx(classes.iconBtnReset)} onClick={handleClickCloseDialog} disableRipple>
							<SmartPenClose />
						</IconButton>
					</Box>

					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '0px',
							paddingLeft: '30px',
							paddingRight: '30px',
							margin: "0px",
							'&:before': { content: "''", width: '24px', height: '24px' },
						}}
					>
						<Typography variant="caption">
							{samplingInterval ? i18next.t("펜의 연결 탐색은 최대 {{min}}초에서 {{max}}초까지 소요될 수 있습니다.", { min: samplingInterval.min / 1000, max: samplingInterval.max / 1000 }) : ""}
						</Typography>
					</Box>
{/*
					<Box sx={{ pl: "30px", pr: "30px" }}>
						<Button className={classes.greenBtn} onClick={handleClickCloseDialog} disableRipple>
							<Typography>{i18next.t("선택한 펜에서 답안 가져오기")} <span>({Object.values(checkedDevices).filter(value => value).length})</span></Typography>
						</Button>
					</Box>
 */}
					<Box sx={{ pl: "30px", pr: "30px" }}>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width="5%">
											<Checkbox
												checked={isAllChecked}
												indeterminate={
													Object.values(checkedDevices).some((value) => value) && !isAllChecked
												}
												onChange={(e) => handleSelectAllChange(e.target.checked)}
											/>
										</TableCell>

										<TableCell width='5%' align="center">
											{i18next.t("번호")}
										</TableCell>

										<TableCell width='15%' >
											<SortingHeaderCell title={i18next.t("펜 이름")} sortKey="penName" sortRows={sortRows} sortingHintName={sortingHintName.current} />
										</TableCell>

										<TableCell width='15%' >
											<SortingHeaderCell title={i18next.t("MAC 주소")} sortKey="mac" sortRows={sortRows} sortingHintName={sortingHintName.current} />
										</TableCell>
{/*
										<TableCell width='10%' >
											{i18next.t("TemporaryID")}
										</TableCell>
 */}
										<TableCell width='10%'>
											<SortingHeaderCell title={i18next.t("학교")} sortKey="schoolName" sortRows={sortRows} sortingHintName={sortingHintName.current} />
										</TableCell>


										<TableCell width='10%'>
											<SortingHeaderCell title={i18next.t("학생이름")} sortKey="studentName" sortRows={sortRows} sortingHintName={sortingHintName.current} />
										</TableCell>

										<TableCell width='15%'>
											{i18next.t("제출상태")}
										</TableCell>

										<TableCell width='30%'>
											<SortingHeaderCellStudentInfo title={i18next.t("학생 정보")} sortKey="studentInfo" omitSchoolName={showShcoolName} toggleOmitSchoolName={toggleOmitSchoolName} />
										</TableCell>


										<TableCell width='15%' align="center" style={{ justifyItems: "center" }}>
											{i18next.t("데이터 상태")}
										</TableCell>

										<TableCell width='8%' align="center" style={{ justifyItems: "center" }}>
											<SortingHeaderCell title={i18next.t("신호")} sortKey="rssi" sortRows={sortRows} sortingHintName={sortingHintName.current} />
										</TableCell>

										<TableCell width='8%' align="center" style={{ justifyItems: "center" }}>
											{i18next.t("필기")}
										</TableCell>


									</TableRow>
								</TableHead>
								<TableBody>
									{sortedDevices.current.map((device, index) => (
										<BatchUploadTableRow
											key={device.id}
											device={device} // 최신 상태의 device 객체 전달
											open={props.open}
											index={index}
											studentInfos={studentInfos}
											isChecked={!!checkedDevices[device.id]}
											omitSchoolName={!showShcoolName} // 학교명 생략 상태 전달
											ncodeAllocation={ncodeAllocation}
											projectSubmissionList={projectSubmissionList}

											handleCheckboxChange={handleCheckboxChange}
											handleStudentInfoSelected={handleStudentInfoSelected}
											handleDeviceStateChanged={handleDeviceStateChanged}
											handleDataCheck={handleDataCheck}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Dialog>

			{/* 한명씩 필기 데이터를 대조하고 펜에서 데이터를 지우기 위해 */}
			<Dialog className={classes.fullDialog} fullScreen open={compareNeoPenOpen} >
				<CompareNeoPenData
					projectCode={projectCode}
					templateProjectCode={templateProjectCode}
					handleClickClose={handleClickClose}
					submissionTransfer={selectedSubmissionTransfer}
					// submissionCode={selectedSubmissionCode}
					noShowPenDialog={true}
					givenData={givenData}
				/>
			</Dialog>

			{/* 펜 일괄 데이터 업로드를 위해 */}
			<DownloadNativePopup onClose={handleClickCloseDialog} onNativeAvailibilityChanged={onNativeAvailibilityChanged} />
		</>
	);
}

