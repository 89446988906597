import {
	Box, Button,
	FormControl,
	FormHelperText, IconButton,
	InputAdornment, OutlinedInput,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { SignUpStyle } from "../../styles/SignUpStyle";

import { ReactComponent as LoginBorderLine } from "../../../../common/images/LoginBorderLine.svg";
import { ReactComponent as LoginEyeIcon } from '../../../../common/images/LoginEyeIcon.svg';
import { ReactComponent as LoginEyeSlashIcon } from '../../../../common/images/LoginEyeSlashIcon.svg';
import { ReactComponent as LoginPasswordIcon } from '../../../../common/images/LoginPasswordIcon.svg';
import { ReactComponent as LoginUserIcon } from '../../../../common/images/LoginUserIcon.svg';
import { ReactComponent as LogoWithTile } from "../../../../common/images/logo_and_title_Green_BG.svg";
import { ReactComponent as PasswordNumberCheckedErrorIcon } from '../../../../common/images/PasswordNumberCheckedErrorIcon.svg';
import { ReactComponent as PasswordNumberCheckedIcon } from '../../../../common/images/PasswordNumberCheckedIcon.svg';
import { ReactComponent as PasswordNumberUnCheckedIcon } from '../../../../common/images/PasswordNumberUnCheckedIcon.svg';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { clsx } from "clsx";
import i18next from "i18next";
import { observer } from "mobx-react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useNavigate } from "react-router-dom";
import { removeSpacechar } from '../../../../common/Validation';
import { useStores } from "../../../../hooks/useStores";
import { NeoCheckBoxList } from "./CheckBoxList";
import { YourReCaptchaComponent } from "./YourReCaptchaComponent";


//v3
const AIGLE_SITE_KEY_AIGLE_CLOUD = '6LeXLN4pAAAAAKTr_NM_vt3ZHS93cppVaLxU2IhC';
const AIGLE_SITE_KEY_NEOLAB_NET = '6LecOjEqAAAAAI7k1IojB-zQHPF3NRu4SzvkOopi';
//v2
// const AIGLE_SITE_KEY = '6Lf1vd8pAAAAAK0iohc-uxVKrnHGc0DUsGqSKb6S';

type Props = {
}


function UnableToSignUp(props: Props) {
	const navigate = useNavigate();
	const { authStore, messageStore, navigateStore, userStore } = useStores();
	const classes = useEmotionStyles(SignUpStyle);

	// email 관련 부분
	const [isNullEmail, setIsNullEmail] = React.useState(true);
	const [isValidEmail, setIsValidEmail] = React.useState(false);
	const dupEmailCheckerTimer = React.useRef<number>(0);

	const changeSignUpEmailModule = (email: string) => {
		if (authStore.isExistEmail !== undefined) {
			authStore.initExistEmailState();
		}
		// setEmailValidation(authStore.changeSignUpEmail(e.target.value))
		const isValidEmail = authStore.changeSignUpEmail(email);
		if (email === '') {
			setIsNullEmail(true);
			setIsValidEmail(false);
			console.log(`isEmailNull: ${isNullEmail}, isValidEmail: ${isValidEmail}`);
		} else {
			setIsNullEmail(false);
			setIsValidEmail(isValidEmail);
			console.log(`isEmailNull: ${isNullEmail}, isValidEmail: ${isValidEmail}`);
		}


		if (dupEmailCheckerTimer.current) {
			window.clearTimeout(dupEmailCheckerTimer.current);
			dupEmailCheckerTimer.current = 0;
		}

		dupEmailCheckerTimer.current = window.setTimeout(() => {
			checkExistEmail();
		}, 300);
	}

	const changeSignUpEmail = (e) => {
		removeSpacechar(e);

		const email = e.target.value;
		changeSignUpEmailModule(email);
	};

	// 패스워드 관련
	const [isValidPassword, setIsValidPassword] = React.useState([true, false, false, false]);
	const [showPassword, setShowPassword] = React.useState(false);
	const changeSignUpPassword = (e) => {
		removeSpacechar(e);
		const isValidPassword = authStore.changeSignUpPassword(e.target.value);
		setIsValidPassword(isValidPassword);
	}

	// 이름 관련
	const [isValidName, setIsValidName] = React.useState(false);
	const [isNullName, setIsNullName] = React.useState(true);


	const changeSignUpNameModule = (name: string) => {
		const isValidName = authStore.changeSignUpName(name);
		if (name === '') {
			setIsNullName(true);
			setIsValidName(false);
		} else {
			setIsNullName(false);
			setIsValidName(isValidName);
		}
		console.log(`name: ${name}, isNullName: ${isNullName}, isValidName: ${isValidName}`);
	};



	const changeSignUpName = (e) => {
		const name = e.target.value;
		changeSignUpNameModule(name);
	};


	// 약관 동의 관련
	const [checkList, setCheckList] = React.useState([
		{
			name: 'TERMS_OF_SERVICE',
			value: i18next.t('서비스 이용약관'),
			checked: false,
			type: 'required',
		},
		{
			name: 'PRIVACY_POLICY',
			value: i18next.t('개인정보 수집 및 처리방침'),
			checked: false,
			type: 'required',
		},]);

	const [didAgreed, setDidAgreed] = React.useState(false);
	const [didAgreedAll, setDidAgreedAll] = React.useState(false);

	React.useEffect(() => {
		const checkRequireAgreements = checkList.filter(check => check.type === 'required');
		const checkAgreements = didAgreedAll || checkRequireAgreements.filter(check => check.checked).length === checkRequireAgreements.length;
		setDidAgreed(checkAgreements);
	}, [checkList, didAgreedAll])


	const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);


	// 가입 가능한 상태인가?
	const [canSignUp, setCanSignUp] = React.useState(false);

	React.useEffect(() => {
		const { signUp } = authStore;
		const canSignUp =
			signUp.id !== '' &&
			isValidEmail &&
			signUp.password !== '' &&
			isValidPassword[1] &&
			signUp.name !== '' &&
			isValidName;
		setCanSignUp(canSignUp);
	}, [isNullEmail, isValidEmail, isValidPassword, isValidName])



	const { isExistEmail } = authStore;

	let timeOut = undefined;
	const recaptchaRef = React.createRef();

	React.useEffect(() => {
		return () => {
			authStore.initCaptcha();
		}
	}, [])



	const checkExistEmail = () => {
		if (isValidEmail && authStore.signUp.email) {
			const email = authStore.signUp.email;
			userStore.checkDuplicatedEmail(email);
		}

	};

	const onCheckBoxChange = (checkName, isChecked) => {
		let isAllChecked = checkName === 'all' && isChecked;
		let isAllUnChecked = checkName === 'all' && !isChecked;
		const checked = isChecked;

		const checkedList = checkList.map((help, _index) => {
			if (isAllChecked || help.value === checkName) {
				return Object.assign({}, help, {
					checked,
				});
			} else if (isAllUnChecked) {
				return Object.assign({}, help, {
					checked: false,
				});
			}

			return help;
		});

		let isAllSelected = checkedList.findIndex(item => item.checked === false) === -1 || isAllChecked;

		setCheckList(checkedList);
		setDidAgreedAll(isAllSelected);

	}

	const handleClickSignUp = async () => {
		// const isAllowedEmail = false;
		const signUpFunc = async () => {
			const result = await userStore.doSignUp();
			return result;
		}

		const result = await signUpFunc();
		if (result) {
			await messageStore.alert(i18next.t("회원가입이 완료되었습니다."))
			navigate(`/login`);
		} else {
			await messageStore.alert(i18next.t("회원가입에 실패했습니다."))
		}
	};


	const handleClickLogin = () => {
		navigate(`/login`);
	}
	const handleClickShowPassword = () => {
		setShowPassword(prevState => !prevState);
	}

	const handleRecaptchaExpired = () => {
		authStore.changeCaptchaState(false);
	}



	console.log(`canSignUp: ${canSignUp}, agreements: ${didAgreed}, nameValidattion: ${isValidName}, isEmailVlid: ${isValidEmail}, isExistEmail: ${isExistEmail}, captcha: ${authStore.captcha}, `);


	const recaptchaSignKey = window.location.origin.includes('neolab.net') ? AIGLE_SITE_KEY_NEOLAB_NET : AIGLE_SITE_KEY_AIGLE_CLOUD;



	const [name, _setName] = React.useState('');
	const [furigana, _setFurigana] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastFurigana, setLastFurigana] = React.useState('');
	const [firstFurigana, setFirstFurigana] = React.useState('');

	const setName = (name: string) => {
		_setName(name);
		changeSignUpNameModule(name);
	}

	const setFurigana = (furigana: string) => {
		authStore.changeSignUpFurigana(furigana);
		_setFurigana(furigana);
	}


	const handleChangeFirstName = (event) => {
		const firstNameInput = event.target.value;

		authStore.changeSignUpFirstName(firstNameInput);
		setFirstName(firstNameInput);

		switch (navigateStore.language) {
			case "ja":
			case "ko":
				setName(`${lastName} ${firstNameInput}`);
				break;
			default:
				setName(`${firstNameInput} ${lastName}`);
				break;
		}
	}

	const handleChangeLastName = (event) => {
		const lastNameInput = event.target.value;

		authStore.changeSignUpLastName(lastNameInput);
		setLastName(lastNameInput);

		switch (navigateStore.language) {
			case "ja":
			case "ko":
				setName(`${lastNameInput} ${firstName}`);
				break;
			default:
				setName(`${firstName} ${lastNameInput}`);
				break;
		}
	}


	const handleChangeFirstFurigana = (event) => {
		const firstNameInput = event.target.value;

		authStore.changeSignUpFirstFurigana(firstNameInput);
		setFirstFurigana(firstNameInput);

		switch (navigateStore.language) {
			case "ja":
			case "ko":
				setFurigana(`${lastFurigana} ${firstNameInput}`);
				break;
			default:
				setFurigana(`${firstNameInput} ${lastFurigana}`);
				break;
		}
	}

	const handleChangeLastFurigana = (event) => {
		const lastNameInput = event.target.value;

		authStore.changeSignUpLastFurigana(lastNameInput);
		setLastFurigana(lastNameInput);

		switch (navigateStore.language) {
			case "ja":
			case "ko":
				setFurigana(`${lastNameInput} ${firstFurigana}`);
				break;
			default:
				setFurigana(`${firstFurigana} ${lastNameInput}`);
				break;
		}
	}



	const handleChangeFurigana = (event) => {
		setFurigana(event.target.value);
	}

	return (
		<Box className={classes.body} >

			<Box>
				<LogoWithTile className={classes.logo} />
			</Box>
			<Box className={classes.borderLine}>
				<LoginBorderLine />
			</Box>

			<GoogleReCaptchaProvider
				reCaptchaKey={recaptchaSignKey}
			>

				<div className={classes.root}>
					<Box className={classes.loginBox}>
						<Typography className={classes.loginText}>{i18next.t('안내')}</Typography>

						<Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>
							{i18next.t('현재는 인터넷을 통한 신규 회원 가입은 중지되었습니다.')}
						</Typography>

						<Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>
							{i18next.t('신규 가입이 필요한 경우에는 관리자에게 문의해주세요.')}
						</Typography>

						<Box display="flex">
							<Typography className={classes.textButton}>{i18next.t('이미 회원이신가요?')}</Typography>
							<Button className={clsx(classes.iconButton, classes.textButton, classes.underline)} disableRipple onClick={handleClickLogin}>
								{i18next.t('로그인')}
							</Button>
						</Box>
					</Box>
				</div>
			</GoogleReCaptchaProvider>
		</Box>
	);
}

export default observer<typeof UnableToSignUp>(UnableToSignUp);
