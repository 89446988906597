
import { Repository } from "./Repository";
import { ISubmissionTransferResponse } from "./model/transfer/ISubmissionTransfer";

type Props = {
	serverContextPath: string;
};

export default class ProjectSubmissionRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	// getSubmissionSubmission = (projectCode) => {
	//     return new Promise((resolve, reject) => {
	//         this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/users/submission`)
	//             .then((data) => {
	//                 resolve(data);
	//             })
	//             .catch((error) => {
	//                 reject(error);
	//             });
	//     });
	// };


	public async getSubmissionTransfers(projectCode, params: { state?: any, keyword?: string, page?: any, rowsPerPage?: number }, data) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/submissions/table`, params, data);
		return result;
	}
	public async getSubmissionTransfer(projectCode, submissionCode) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/submissions/${submissionCode}/submission`);
		return result;
	}

	public async getStudentProjectSubmissionTransfer(params, data) {
		const result = await this.getRequestPromise<ISubmissionTransferResponse>("post", `${this.requestPrefix}/submissions/table`, params, data);
		return result;
	}

	public async createAndUploadFileForSubmission(projectCode: string, userCode: string, params: { [key: string]: any }, data: FormData) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/users/${userCode}/submissions/files`, params, data, "multipart/form-data");
		return result;
	}

	// 이미 만들어진 submission에 file을 추가
	public async addFileForSubmission(projectCode: string, userCode: string, submissionCode: string, params: { [key: string]: any }, data: FormData) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/users/${userCode}/submissions/files/${submissionCode}`, params, data, "multipart/form-data");
		return result;
	}


	public async createOnlineSubmission(projectCode: string, userCode: string, params) {
		const result = await this.getRequestPromise<string>("post", `${this.requestPrefix}/${projectCode}/users/${userCode}/submissions`, params);
		return result;
	}

	public async deleteSubmission(submissionCode) {
		const result = await this.getRequestPromise("delete", `${this.requestPrefix}/submissions/${submissionCode}`, {});
		return result;
	}

	public async requestRetryOCR(projectCode, userCode) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/users/${userCode}/ocr/retry`);
		return result;
	}
}