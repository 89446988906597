import {
	Box,
	Button,
	Dialog,
	LinearProgress,
	Typography
} from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';

import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddSmartPen } from '../../../../../../common/images/AddSmartPen.svg';
import { ReactComponent as CheckSmartPen } from '../../../../../../common/images/CheckSmartPen.svg';
import { ReactComponent as SmartPenClose } from '../../../../../../common/images/SmartPenClose.svg';
import { useStores } from "../../../../../../hooks/useStores";
import { IPageSOBP, NeoStroke } from "../../../../../../neolab-libs/nl-lib3-common";
import { deviceSelectDlg, DeviceSelectDlgResult } from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-pensdk/DeviceSelectDlg";
import { ProjectExamType } from "../../../../../../repositories/model/support/ProjectExamType";
import { IProjectSubmissionTransfer } from "../../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { OfflineDataUploadDialogStlye } from "./OfflineDataUploadDialogStlye";
import { useNeoConfirm } from "../../../../../../hooks/useNeoConfirm";

export enum RequestOfflineDataState {
	disconnected = "disconnected",
	selecting = "selecting",
	connecting = "connecting",
	connected = "connected",
	requested = "requested",
	received = "received",
}

type Props = {
	submissionTransfer: IProjectSubmissionTransfer & { submissionIndex: number },
	numPages: number,
	projectCode: string,
	templateProjectCode: string,
	// setPdfBlobUrl: (val: string) => any,
	setOfflineStrokes: (val: NeoStroke[]) => any,
	handleClickBaseDialogClose,
	type: ProjectExamType,
	openUploadDlg: boolean,
	setOpenUploadDlg: (val: boolean) => any,
	setRequestOfflineDataState: (val: RequestOfflineDataState) => any,
	requestOfflineDataState: RequestOfflineDataState,
	ncodeRange: { start: IPageSOBP, end: IPageSOBP }
}

function OfflineDataUploadDialog(props: Props) {
	const i18next = useTranslation();
	const { neoPenStore, ncodeAllocationStore } = useStores();
	const classes = useEmotionStyles(OfflineDataUploadDialogStlye);

	const buttonRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { submissionTransfer, projectCode, templateProjectCode } = props;

	const { userProfile } = submissionTransfer || {};
	const { name = "", grade = 0, className = "", number = 0 } = userProfile || {};

	const { setRequestOfflineDataState, requestOfflineDataState } = props;

	const handleClickCloseDialog = () => {
		if (requestOfflineDataState !== RequestOfflineDataState.received) {
			props.handleClickBaseDialogClose();

			return;
		}

		props.setOpenUploadDlg(false);
	}

	React.useEffect(() => {
		return () => {
			// neoPenStore.disconnectPen();
		}
	}, []);

	React.useEffect(() => {
		if (props.openUploadDlg) {
			// neoPenStore.disconnectPen();
		}
		return () => {
			// neoPenStore.disconnectPen();
		}
	}, [neoPenStore, props.openUploadDlg]);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | React.KeyboardEvent<HTMLDivElement>,
		reason?: 'backdropClick' | 'escapeKeyDown'
	) => {
		if (reason && reason === "backdropClick")
			return;

		props.setOpenUploadDlg(false);
	};

	const getNcodeRangeFromProject = async (projectCode: string, templateProjectCode: string) => {
		const alloc = await ncodeAllocationStore.getProjectLastAllocation(projectCode, templateProjectCode);
		const range: { start: IPageSOBP, end: IPageSOBP } = {
			start: {
				section: alloc.section,
				owner: alloc.startOwner,
				book: alloc.startBook,
				page: alloc.startPage
			},
			end: {
				section: alloc.section,
				owner: alloc.endOwner,
				book: alloc.endBook,
				page: alloc.endPage
			}
		};
		return range;
	}

	const {alert, confirm} = useNeoConfirm();

	const handleRequestOfflineData = async (event) => {
		if (requestOfflineDataState !== RequestOfflineDataState.disconnected)
			return;

		if (!neoPenStore.isSamrtPenPopupClosed)
			setAnchorEl(event.currentTarget);

		setRequestOfflineDataState(RequestOfflineDataState.selecting);
		const deviceSelectResult = await deviceSelectDlg();

		setRequestOfflineDataState(RequestOfflineDataState.connecting);
		const result = await neoPenStore.connectPen(deviceSelectResult);
		if (result.success === DeviceSelectDlgResult.Success) {
			const pen = result.pen;

			if (!pen) {
				setRequestOfflineDataState(RequestOfflineDataState.disconnected);
				return;
			}

			setRequestOfflineDataState(RequestOfflineDataState.requested);

			const range = await getNcodeRangeFromProject(projectCode, templateProjectCode);
			const offlineData = await neoPenStore.getOfflineData({ range });

			if (offlineData?.success === "success") {
				const { numOfflineStrokes, strokes } = offlineData;

				if (numOfflineStrokes === 0) {
					// 데이터가 없다. 이 경우에는 펜 연결을 끊는다.
					pen?.disconnect();
					const confirmed = await confirm(i18next.t("펜에 제시 과제에 해당하는 데이터가 저장되어 있지 않습니다. 그래도 등록하시겠습니까?"));
					if (confirmed) {
						props.setOfflineStrokes(strokes || []);
						props.setOpenUploadDlg(false);
					}
					setRequestOfflineDataState(RequestOfflineDataState.disconnected);
				}
				else {
					props.setOfflineStrokes(strokes || []);
					setRequestOfflineDataState(RequestOfflineDataState.received);
					props.setOpenUploadDlg(false);
				}
			}
			else if (offlineData?.success === "error") {
				// 페이터 전송 과정에서 문제가 있었습니다. 다시 시도해 주십시오.
				pen?.disconnect();
				await alert(i18next.t("페이터 전송 과정에서 문제가 있었습니다. 다시 시도해 주십시오."));
				setRequestOfflineDataState(RequestOfflineDataState.disconnected);
			}
			else if (offlineData?.success === "pen_error") {
				// 펜을 선택하지 않았습니다. 다시 시도해 주십시오.
				// pen?.disconnect();
				// await alert(i18next.t("펜을 선택하지 않았습니다. 다시 시도해 주십시오."));
				setRequestOfflineDataState(RequestOfflineDataState.disconnected);
			}
			else {
				// 알 수 없는 이유로 데이터 전송에 실패했습니다. 다시 시도해 주십시오.
				pen?.disconnect();
				await alert(i18next.t("알 수 없는 이유로 데이터 전송에 실패했습니다. 다시 시도해 주십시오."));
				setRequestOfflineDataState(RequestOfflineDataState.disconnected);
			}
		}
		else {
			setRequestOfflineDataState(RequestOfflineDataState.disconnected);
			if (result.success === DeviceSelectDlgResult.BluetoothError) {
				alert("블루투스 연결에 실패했습니다. 블루투스를 켜주세요.");
			}
		}
	}

	let state = "펜 연결";
	let icon = <AddSmartPen style={{ margin: "10px", color: "white" }} />;

	if (requestOfflineDataState === RequestOfflineDataState.selecting) {
		state = "펜 선택 대기 중";
		icon = <AddSmartPen style={{ margin: "10px", color: "white" }} />;
	} else if (requestOfflineDataState === RequestOfflineDataState.connecting) {
		state = "펜 연결 중";
		icon = <CircularProgress sx={{ margin: "10px", color: "white" }} size={17} />;
	} else if (requestOfflineDataState === RequestOfflineDataState.requested) {
		state = "데이터 전송 중";
		icon = <CircularProgress sx={{ margin: "10px", color: "white" }} size={17} />;
	} else if (requestOfflineDataState === RequestOfflineDataState.received) {
		state = "데이터 전송 완료";
		icon = <CheckSmartPen style={{ margin: "10px", color: "white" }} />;
	}

	React.useEffect(() => {
		setAnchorEl(buttonRef.current);
	}, []);

	return (
		<React.Fragment>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.openUploadDlg}
				className={classes.dialogBox}
			>
				{props.numPages === null &&
					<Box width={350} style={{ margin: '0 auto', }}>
						<LinearProgress />
					</Box>
				}
				<Box
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '25px', '&:before': { content: "''", width: '24px', height: '24px' } }}

				>

					<DialogTitle sx={{ m: 0, padding: 0 }} id="customized-dialog-title" className={classes.titleDialog}>
						{i18next.t("스마트 펜에서 데이터 업로드")}
					</DialogTitle>
					<IconButton className={clsx(classes.iconBtnReset)} onClick={handleClose} disableRipple>
						<SmartPenClose />
					</IconButton>
				</Box>


				<Typography className={classes.studentInfo}>
					[ <span>{grade} </span> {i18next.t("학년")} <span>{className} </span> {i18next.t("반")} <span>{number} </span> {i18next.t("번")}<span> {name}</span> {i18next.t("학생")} ]
				</Typography>


				<Button
					ref={buttonRef}
					onClick={handleRequestOfflineData}
					className={classes.dialogBtn}
					// disabled={props.numPages === null}
					disableRipple
				>
					{icon}
					<Typography>{state}</Typography>
				</Button>
				{/* <Popover
					id={id}
					open={openTool}
					anchorEl={anchorEl}
					// onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					className={classes.popoverTooltip}
				>
					<Box className={classes.tooltipTop}>
						<TooltipTipIcon />
					</Box>
					<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={classes.tooltipBtn}>
						<Typography className={classes.tooltipTitle}>펜 연결 및 데이터 출력</Typography>
						<Button disableRipple onClick={handleClickTooltipClosedBtn}>
							<TooltipClose />
						</Button>
					</Box>
					<Typography className={classes.tooltipText}>
						펜 연결 후 데이터 불러오기 성공하면 필기 기록을 자동으로 가져와 출력합니다.<br />
						펜 연결 버튼을 눌러 진행하세요.
					</Typography>
				</Popover> */}

				{/*펜 연결 중 취소및 다른학생 선택*/}
				<Button className={classes.otherStudentBtn} onClick={() => handleClickCloseDialog()} disableRipple>
					{requestOfflineDataState === RequestOfflineDataState.received ?
						<Typography>닫기</Typography>
						:
						<Typography>취소(다른 학생 선택)</Typography>
					}
				</Button>

				{/*데이터 연결 성공*/}
				{requestOfflineDataState === RequestOfflineDataState.received &&
					<Box className={classes.noticeBox}>
						<Typography className={classes.noticeTitle}>
							※ 펜 연결을 유지해 주세요!
						</Typography>
						<Typography className={classes.noticeText}>
							대조 및 편집 작업 이후 ‘대조 작업 완료' 버튼을 누르기 전까지 스마트펜 전원을 끄지 마세요.
						</Typography>
					</Box>
				}
			</Dialog>
		</React.Fragment>
	);
}

export default OfflineDataUploadDialog;