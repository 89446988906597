export function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      } else {
        reject(new Error("Failed to convert Blob to Uint8Array"));
      }
    };
    reader.onerror = () => reject(new Error("Failed to read the Blob as ArrayBuffer"));
    reader.readAsArrayBuffer(blob);
  });
}