import { ByteConverter } from "./ByteConverter";

export class ByteUtil {
  public readonly DEF_LIMIT = 1000;

  public readonly DEF_GROWTH = 1000;

  private mPosWrite = 0;

  private mPosRead = 0;

  private mBuffer!: Uint8Array;    // Uint8Array

  private packetSize: number = 0;

  // public delegate byte[] EscapeDelegate(byte input);

  private mEscDele: ((ch: number) => number[]) | undefined;

  get Size() {
    return this.mBuffer != null ? this.mBuffer.length : 0;
  }

  public get Max() {
    return this.mBuffer != null ? this.mBuffer.length - 1 : 0;
  }

  constructor(args: {
    length?: number,
    escfunc?: (ch: number) => number[],
    data?: Uint8Array,
    packetSize?: number,
  }) {
    const { length, escfunc, data, packetSize } = args;

    if (length) {
      this.mBuffer = new Uint8Array(Array.from({ length }, () => 0));
    } else if (data) {
      this.mBuffer = new Uint8Array(Array.from(data));
    }

    this.mEscDele = escfunc;

    this.packetSize = packetSize;
  }



  public Clear() {
    this.mPosWrite = 0;
    this.mPosRead = 0;
    this.mBuffer = this.mBuffer.map(() => 0);
  }

  public Put(args: {
    input?: number,
    escapeIfExist?: boolean,
    inputs?: Uint8Array,
    length?: number,
  }) {
    const { input, escapeIfExist, inputs, length } = args;
    if (input) {
      if (this.mEscDele != null && escapeIfExist) {
        const escDatas: number[] = this.mEscDele(input);
        escDatas.forEach((item) => { this.PutByte(item) });
      } else {
        this.PutByte(input);
      }
    } else if (length && inputs) {
      const alength = inputs.length < length ? inputs.length : length;
      const arr = inputs.slice(0, alength - 1);

      arr.forEach((item) => this.Put({ input: item }));
    } else if (inputs) {
      inputs.forEach((item) => this.Put({ input: item }));
    }

    return this;
  }


  private PutByte(input: number) {
    if (this.mPosWrite > this.Max) {
      this.Expand(this.DEF_GROWTH);
    }

    this.mBuffer[this.mPosWrite++] = input;
    return this;
  }

  public PutNull(length: number) {
    for (let i = 0; i < length; i++) {
      this.Put({ input: 0x00 });
    }

    return this;
  }

  public PutInt(input: number) {
    // const a = ByteConverter.IntToByte(input);
    return this.Put({ inputs: ByteConverter.IntToByte(input) });
  }

  public PutLong(input: bigint) {
    return this.Put(ByteConverter.LongToByte(input));
  }

  public PutShort(input: number) {
    return this.Put(ByteConverter.ShortToByte(input));
  }

  /*
  private void AddSingleByte( byte input )
  {
      mBuffer[mPosWrite++] = input;
  }
  */

  private Expand(increase: number) {
    const newBuffer = new ArrayBuffer(this.mBuffer.length + increase);
    const a = new Uint8Array(newBuffer);
    a.set(this.mBuffer);
    this.mBuffer = a;
  }



  /* #region  Get */
  public GetBytes(size?: number) {
    if (!size) size = this.mPosWrite - this.mPosRead;
    const result = new Uint8Array(size);

    for (let i = 0; i < size; i++) {
      result[i] = this.mBuffer[this.mPosRead++];
    }
    return result;
  }

  public GetBytesByOffset(offset: number) {
    if (offset >= this.mBuffer.length) {
      return null;
    }

    const packetSize =
      offset + this.packetSize > this.mBuffer.length
        ? this.mBuffer.length - offset
        : this.packetSize;

    this.mPosRead = offset;
    return this.mBuffer.slice(offset, offset + packetSize);
  }



  public GetInt() {
    return ByteConverter.ToInt32(this.GetBytes(4), 0);
  }

  public GetShort() {
    return ByteConverter.ToInt16(this.GetBytes(2), 0);
  }

  public GetUShort() {
    return ByteConverter.ToUInt16(this.GetBytes(2), 0);
  }

  public GetLong() {
    return ByteConverter.ToInt64(this.GetBytes(8), 0);
  }


  public GetByte() {
    return this.mBuffer[this.mPosRead++];
  }

  public GetByteToInt() {
    return this.GetByte() as number;
  }

  public GetString(length: number) {
    const bytes = this.GetBytes(length);
    const str = new TextDecoder("utf-8").decode(bytes);
    return str;
  }

  public GetChecksum(length: number) {
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = this.mBuffer[this.mPosRead + i];
    }

    let CheckSum = 0;

    for (let i = 0; i < bytes.length; i++) {
      CheckSum += (bytes[i]);
    }
    return CheckSum % 256;
  }


  public ToArray() {
    const bytes = new Uint8Array(this.mPosWrite);

    for (let i = 0; i < this.mPosWrite; i++) {
      bytes[i] = this.mBuffer[i];
    }
    return bytes;
  }

  /* #endregion */
}
