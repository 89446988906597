import { Button, Checkbox, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IDiscoveredDevices, INeoSmartpen, IPageSOBP, isInNcodeRange, NeoStroke, OfflineDataInfo, SimpleLock, sleep } from "../../../../../../neolab-libs/nl-lib3-common";
import { BatchUploadStlye } from "./BatchUploadStlye";
import SignalStrength from "./SignalStrength";
import i18next from "i18next";
import { PenJobManager } from "./PenJobManager";
import { INcodeAllocationResponse } from "../../../../../../repositories/model/transfer/INcodeAllocationResponse";
import { IDiscoveredDevicesExtended, PenDataInquiryStatus, PenStudentInfo } from "./PenStudentInfo";
import React from "react";
import { UploadProgressBar } from "./UploadProgressBar";
import { IProjectSubmissionTransfer } from "../../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { getSubmitStateString } from "../../../../../../repositories/model/support/ProjectStateType";
import useEmotionStyles from "../../../../styles/useEmotionStyles";


type Props = {
	open: boolean;
	device: IDiscoveredDevicesExtended;
	index: number;
	studentInfos: PenStudentInfo[];

	projectSubmissionList: IProjectSubmissionTransfer[],

	handleStudentInfoSelected: (device: IDiscoveredDevicesExtended, studentInfo: PenStudentInfo) => void,
	handleCheckboxChange: (device: IDiscoveredDevicesExtended, checked: boolean) => void;
	handleDeviceStateChanged: (device: IDiscoveredDevicesExtended, status: PenDataInquiryStatus) => void;
	handleDataCheck: (device: IDiscoveredDevicesExtended, studentInfo: PenStudentInfo, offlineData: { numPages: number; numStrokes: number; strokes: NeoStroke[]; }) => void;

	isChecked: boolean;
	omitSchoolName?: boolean;
	ncodeAllocation: INcodeAllocationResponse,

};

export enum PenDataLoadingStep {
	none = "none",
	pageList = "pageList",

	determineToDownload = "determineToDownload",
	strokeData = "strokeData",

	done = "done",
}
interface IUploadTask {
	promise: Promise<{ result: { numPages: number; numStrokes: number; strokes: NeoStroke[]; }, pen: INeoSmartpen }>,
	JobCookie: string,
	cancel: () => void
}

interface INcodeRange { start: IPageSOBP, end: IPageSOBP }
interface IOfflineData { numPages: number; numStrokes: number; strokes: NeoStroke[]; }

export function BatchUploadTableRow(props: Props) {
	const { index, device, studentInfos, projectSubmissionList, handleStudentInfoSelected, isChecked, handleCheckboxChange, ncodeAllocation, handleDeviceStateChanged, handleDataCheck } = props;


	const [previousDevice, setPreviousDevice] = useState<IDiscoveredDevices | null>(null);
	const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);
	const classes = useEmotionStyles(BatchUploadStlye);
	const [rowChanged, setRowChanged] = useState(false);
	const [penInfoQueryStatus, _setPenInfoQueryStatus] = useState<PenDataInquiryStatus>(PenDataInquiryStatus.idle);

	const [offlinePageList, setOfflinePageList] = useState<OfflineDataInfo[] | null>(null);
	const [ncodeRange, setNcodeRange] = useState<INcodeRange | null>(null);

	const [offlineData, setOfflineData] = useState<IOfflineData | null>(null);

	const dataListTask = React.useRef<{ promise: Promise<{ result: OfflineDataInfo[], pen: INeoSmartpen }>, JobCookie: string, cancel: () => void } | null>(null);
	const uploadTask = React.useRef<IUploadTask | null>(null);

	const connectedPen = React.useRef<INeoSmartpen | null>(null);
	const [nextStep, setNextStep] = useState<PenDataLoadingStep>(PenDataLoadingStep.strokeData);

	const lock = React.useRef<SimpleLock>(new SimpleLock());

	const handleStudentChange = (event: SelectChangeEvent<string>) => {
		const studentId = event.target.value;
		const matchedStudent = studentInfos.find((info) => info.studentId === studentId);
		setSelectedStudentId(studentId);

		// 선택된 학생 정보를 부모 컴포넌트로 전달
		handleStudentInfoSelected(device, matchedStudent);
	};

	useEffect(() => {
		if (ncodeRange && nextStep === PenDataLoadingStep.determineToDownload) {
			const ch = determineOfflineDataCharacteristics(offlinePageList, ncodeRange);
			if (ch === PenDataInquiryStatus.dataInRange || ch === PenDataInquiryStatus.dataInBoth) {
				setNextStep(PenDataLoadingStep.strokeData);
			}
			else {
				connectedPen.current?.disconnect();
				connectedPen.current = null;
				setNextStep(PenDataLoadingStep.none);
			}
		}

	}, [ncodeRange, nextStep]);

	const setPenInfoQueryStatus = (status: PenDataInquiryStatus) => {
		_setPenInfoQueryStatus(status);
		handleDeviceStateChanged(device, status);
	}

	const getOfflinePageList = async (deviceId: string) => {
		await lock.current.acquire();
		setPenInfoQueryStatus(PenDataInquiryStatus.loading);
		// await NativeMethods.StopScan();

		try {
			dataListTask.current = PenJobManager.getOfflinePageList({ penId: deviceId, keepConnection: true });
			const ret = await dataListTask.current.promise;
			const sobpages = ret.result;
			connectedPen.current = ret.pen;

			setNextStep(PenDataLoadingStep.determineToDownload);
			setPenInfoQueryStatus(PenDataInquiryStatus.loaded);
			setOfflinePageList(sobpages);

			return sobpages;
		}
		catch (e) {
			setPenInfoQueryStatus(PenDataInquiryStatus.retry);
			setNextStep(PenDataLoadingStep.none);
			return null;
		}
		finally {
			dataListTask.current = null;
			lock.current.release();
		}
	}



	const [numDone, setNumDone] = useState(0);
	const [numTotal, setNumTotal] = useState(0);

	/**
	 * offline data를 받아온다
	 * @param deviceId
	 * @param range
	 * @returns
	 */
	const getOfflineData = async (
		deviceId: string,
		range: {
			start: IPageSOBP;
			end: IPageSOBP;
		}
	) => {

		if (range === null || range === undefined) { return; }
		await lock.current.acquire();

		const handleProgress = (progress: { numTotal: number, numDone: number }) => {
			setNumTotal(progress.numTotal);
			setNumDone(progress.numDone);
		}

		setPenInfoQueryStatus(PenDataInquiryStatus.dataLoading);

		try {
			uploadTask.current = PenJobManager.getOfflineData({ penId: deviceId, range, deleteOnFinished: false, handleProgress, givenPen: connectedPen.current, keepConnection: true });

			const ret = await uploadTask.current.promise;
			connectedPen.current = ret.pen;

			connectedPen.current?.disconnect();
			connectedPen.current = null;

			const offlineData = ret.result;


			setOfflineData(offlineData);
			console.log(offlineData);

			setNumTotal(numTotal || 1);
			setNumDone(numTotal || 1);

			if (!offlineData)
				setPenInfoQueryStatus(PenDataInquiryStatus.dataLoadedButNothing);
			else
				setPenInfoQueryStatus(PenDataInquiryStatus.dataLoaded);
		}
		catch (e) {
			const characteristics = determineOfflineDataCharacteristics(offlinePageList, ncodeRange);
			setPenInfoQueryStatus(characteristics);
		}
		finally {
			uploadTask.current = null;
			lock.current.release();
		}
	}

	useEffect(() => {
		return () => {
			if (dataListTask.current) {
				dataListTask.current.cancel();
				dataListTask.current = null;
			}

			if (uploadTask.current) {
				uploadTask.current.cancel();
				uploadTask.current = null;
			}
		}
	}, []);


	useEffect(() => {
		if (ncodeAllocation) {
			const { section } = ncodeAllocation;
			const start = { section, owner: ncodeAllocation.startOwner, book: ncodeAllocation.startBook, page: ncodeAllocation.startPage };
			const end = { section, owner: ncodeAllocation.endOwner, book: ncodeAllocation.endBook, page: ncodeAllocation.endPage };
			setNcodeRange({ start, end });
		}
	}, [ncodeAllocation]);

	// 오프라인 데이터 확인 후 상태 설정
	useEffect(() => {
		if (nextStep === PenDataLoadingStep.strokeData && ncodeRange) {
			getOfflineData(device.id, ncodeRange);
		}
		// if (nextStep === PenDataLoadingStep.strokeData && offlinePageList && ncodeRange) {
		// 	const characteristics = determineOfflineDataCharacteristics(offlinePageList, ncodeRange);
		// 	setPenInfoQueryStatus(characteristics);

		// 	if (characteristics === PenDataInquiryStatus.dataInRange
		// 		|| characteristics === PenDataInquiryStatus.dataInBoth
		// 	) {
		// 		getOfflineData(device.id, ncodeRange);
		// 	}
		// }
		else {
			// setPenInfoQueryStatus("error");
		}
		// console.log(offlinePageList);
	}, [nextStep, offlinePageList, ncodeRange]);



	// 디바이스 변경 시 오프라인 데이터 가져오기
	useEffect(() => {
		if (previousDevice) {
			const isChanged = Object.keys(device).some(
				(key) =>
					key !== "rssi" &&
					device[key as keyof IDiscoveredDevices] !== previousDevice[key as keyof IDiscoveredDevices]
			);
			setRowChanged(isChanged);
			setSelectedStudentId(device.studentInfo?.studentId || null);
		}
		setPreviousDevice({ ...device });

		if (previousDevice?.id !== device.id) {
			// getOfflinePageList(device.id);
		}

	}, [device, ncodeRange]);


	useEffect(() => {
		if (studentInfos && device.name) {
			const lastThreeDigits = parseInt(device.name.slice(-3), 10);
			const matchedStudent = studentInfos.find((info) => info.number === lastThreeDigits);
			if (matchedStudent) {
				setSelectedStudentId(matchedStudent.studentId);
				handleStudentInfoSelected(device, matchedStudent);
			}
		}
	}, [device.name, studentInfos, handleStudentInfoSelected]);


	const handleCheckBox = (device: IDiscoveredDevicesExtended, checked: boolean) => {
		// checked = checked && !(
		// 		penInfoQueryStatus === PenDataInquiryStatus.nothing
		// 		|| penInfoQueryStatus === PenDataInquiryStatus.dataNotInRange
		// 	);
		handleCheckboxChange(device, checked);
	}

	const submit = projectSubmissionList?.find((submission) => submission.userCode === selectedStudentId);

	return (
		<TableRow key={`rows${device.id}`} className={rowChanged ? classes.highlighted : ""}>
			<TableCell>
				<Checkbox
					checked={isChecked}
					onChange={(e) => handleCheckBox(device, e.target.checked)}
				/>
			</TableCell>

			<TableCell align="center">{index + 1}</TableCell>
			<TableCell>
				<strong>{device.name}</strong>
			</TableCell>
			<TableCell>
				<Typography variant="caption">
					{device.mac}
				</Typography>
			</TableCell>

{/*
			<TableCell>
				<Typography variant="caption">
					{device.id}
				</Typography>
			</TableCell>
 */}
			<TableCell>
				<Typography variant="caption">
					{device.studentInfo?.schoolName || "-"}
				</Typography>
			</TableCell>
			<TableCell><strong>{device.studentInfo?.name || "-"}</strong></TableCell>
			<TableCell><strong>{getSubmitStateString(submit?.state)}</strong></TableCell>

			<TableCell>
				<Select
					value={selectedStudentId || ""}
					onChange={handleStudentChange}
					displayEmpty
					fullWidth
					sx={{ height: "30px" }}
				>
					<MenuItem value="">
						<Typography variant="caption">
							{i18next.t("학생 선택")}
						</Typography>
					</MenuItem>
					{studentInfos?.map((info) => (
						<MenuItem key={info.studentId} value={info.studentId}>
							<Typography variant="caption">
								{props.omitSchoolName ?
									i18next.t("{{grade}}학년 {{className}}반 {{number}}번, {{name}}", {
										grade: info.grade,
										className: info.className,
										number: info.number,
										name: info.name,
									})
									: i18next.t("{{schoolName}} {{grade}}학년 {{className}}반 {{number}}번, {{name}}", {
										schoolName: info.schoolName,
										grade: info.grade,
										className: info.className,
										number: info.number,
										name: info.name,
									})}
							</Typography>
						</MenuItem>
					))}
				</Select>
			</TableCell>

			<TableCell align="center" style={{ justifyItems: "center" }}>
				<Button className={
					Number(penInfoQueryStatus) > 99 ? classes.dataStatusBtnBoxed : (Number(penInfoQueryStatus) < 10 ? classes.dataStatusBtn : classes.dataStatusBtnBoxedNothing)
				}

					onClick={() => getOfflinePageList(device.id)}>
					<Typography variant="caption">
						{penStatusString(penInfoQueryStatus)}
					</Typography>
				</Button>
			</TableCell>

			<TableCell align="center" style={{ justifyItems: "center" }}>
				<SignalStrength rssi={device.rssi} />
			</TableCell>

			<TableCell align="center" style={{ justifyItems: "center" }}>
				<Button className={classes.dataCheckBtn}
					onClick={() => {
						if (penInfoQueryStatus !== PenDataInquiryStatus.dataLoaded || (offlineData?.numStrokes || 0) === 0) {
							getOfflineData(device.id, ncodeRange)
						}
						else {
							handleDataCheck(device, device.studentInfo, offlineData);
						}
					}}
				>
					{/* <UploadProgressBar width={"30px"} height={"10px"} percent={numTotal ? numDone * 100 / numTotal : 0} /> */}
					{penInfoQueryStatus === PenDataInquiryStatus.dataLoaded || penInfoQueryStatus === PenDataInquiryStatus.dataLoadedButNothing
						?
						<Typography variant="caption">
							{(offlineData?.numStrokes || 0) > 0 ?
								<>{i18next.t("확인")}({offlineData?.strokes?.length || 0})</>
								:
								<>{i18next.t("재전송")}</>
							}
						</Typography>
						:
						<UploadProgressBar width={"30px"} height={"10px"} percent={numTotal ? numDone * 100 / numTotal : 0} />
					}
				</Button>
			</TableCell>


		</TableRow >
	);
}



function penStatusString(status: PenDataInquiryStatus) {
	switch (status) {
		case PenDataInquiryStatus.idle:
			return i18next.t("□ 대기");
		case PenDataInquiryStatus.loading:
			return i18next.t("▷ 연결중");
		case PenDataInquiryStatus.canceled:
			return i18next.t("× 취소됨");
		case PenDataInquiryStatus.error:
			return i18next.t("× 에러");
		case PenDataInquiryStatus.loaded:
			return i18next.t("↔ 확인중");
		case PenDataInquiryStatus.retry:
			return i18next.t("▶ 연결중");

		case PenDataInquiryStatus.dataInRange:
			return i18next.t("● 제시물 있음");
		case PenDataInquiryStatus.dataInBoth:
			return i18next.t("△ 제시물 있음");
		case PenDataInquiryStatus.dataNotInRange:
			return i18next.t("× 제시물 없음");
		case PenDataInquiryStatus.nothing:
			return i18next.t("× 데이터 없음");
		case PenDataInquiryStatus.dataLoading:
			return i18next.t("▶ 전송 중");
		case PenDataInquiryStatus.dataLoaded:
			return i18next.t("■ 전송 완료");
		case PenDataInquiryStatus.dataLoadedButNothing:
			return i18next.t("× 데이터 없음");
	}

	return i18next.t("알 수 없음");
}


function determineOfflineDataCharacteristics(offBooks: OfflineDataInfo[], range: { start: IPageSOBP, end: IPageSOBP }) {
	let inRange = false;
	let outOfRange = false;

	if (offBooks) {
		for (let i = 0, li = offBooks.length; i < li; i++) {
			const offBook = offBooks[i];
			for (let j = 0, lj = offBook.Pages.length; j < lj; j++) {
				const page = offBook.Pages[j];
				const sobp = { section: offBook.Section, owner: offBook.Owner, book: offBook.Note, page };

				if (isInNcodeRange(sobp, range.start, range.end)) {
					inRange = true;
				} else {
					outOfRange = true;
				}
			}
		}
	}

	if (inRange && outOfRange) {
		return PenDataInquiryStatus.dataInBoth;
	} else if (inRange) {
		return PenDataInquiryStatus.dataInRange;
	} else if (outOfRange) {
		return PenDataInquiryStatus.dataNotInRange;
	}

	return PenDataInquiryStatus.nothing;
}
