import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamExportStyle } from "../../styles/ExamExportStyle";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ReactComponent as AddGroup } from '../../../../common/images/AddGroup.svg';
import { ReactComponent as AddGroupActive } from '../../../../common/images/AddGroupActive.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';

import { observer } from "mobx-react";

import useNaviCustom from "../../../../hooks/useNaviCustom";


import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { ProjectSchoolType } from "../../../../stores/enums/ProjectStoreEnums";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";


type Props = {
}


function TempExamTargetAppointment(props: Props) {
	const params = useParams();
	const { projectStore, userStore, navigateStore } = useStores();
	const i18next = useTranslation();

	const classes = useEmotionStyles( ExamExportStyle );
	const { projectCode } = params;

	const [rotated, setRotated] = React.useState(false);
	const [projectPosed, setProjectPosed] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	// const [selectedGroup, setSelectedGroup] = React.useState(-1);
	const [schoolName, setSchoolName] = React.useState("");
	const [className, setClassName] = React.useState("");
	const [schoolType, setSchoolType] = React.useState(ProjectSchoolType.NOGRADE);
	const [grade, setGrade] = React.useState("");
	const [group, setGroup] = React.useState("");

	const [targetProjectName, setTargetProjectName] = React.useState("");
	const [sortingHints, setSortingHints] = React.useState([{ column: "up.name", hint: "ASC" }]);

	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(30);

	const [projectUserPage, setProjectUserPage] = React.useState(1);

	const [targetList, setTargetList] = React.useState([]);
	const [classNameList, setClassNameList] = React.useState([]);
	const [schoolNameList, setSchoolNameList] = React.useState([]);
	const [gradeList, setGradeList] = React.useState([]);
	const [groupList, setGroupList] = React.useState([]);
	const [searchedFlag, setSearchedFlag] = React.useState(false);

	const [allAddBtn, setAllAddBtn] = React.useState(true);
	const [searching, setSearching] = React.useState(false);

	const naviCustom = useNaviCustom();

	const { project, projectUsers, projectUserAddList, projectUserDelList, projectSelectedUsers } = projectStore;
	const { myUsers, mySchools } = userStore;

	const {alert, confirm} = useNeoConfirm();

	React.useEffect(() => {
		setSortingHints((prev) => {
			const newHints = [...prev];
			newHints[0].hint = rotated ? "DESC" : "ASC";
			return newHints;
		});
	}, [rotated])

	React.useEffect(() => {
		userStore.getMySchools();
		userStore.getMyUsersForEvaluation();
		projectStore.downloadProjectInfoAndStore(projectCode);
		projectStore.getProjectUserTransfers(projectCode, []);

		return () => {
			projectStore.initProject();
			navigateStore.changeIsConfirmForUpdateRequired(false);
		}
	}, [])

	React.useEffect(() => {
		const pagination = Math.ceil(projectSelectedUsers.length / rowsPerPage) === 0 ? 1 : Math.ceil(projectSelectedUsers.length / rowsPerPage);
		if (pagination < projectUserPage)
			setProjectUserPage(1);
	}, [projectSelectedUsers.length])

	React.useEffect(() => {
		let flag = false;
		targetList.forEach((user, userIndex) => {
			if (userIndex < page * rowsPerPage && userIndex >= (page - 1) * rowsPerPage) {
				if (!checkIsProjectUser(user)) {
					flag = true;
				}
			}
		}
		)
		if (targetList.length === 0)
			flag = true;
		setAllAddBtn(flag);
	}, [page, rowsPerPage, targetList])

	React.useEffect(() => {
		let tempArr = [];
		myUsers.forEach((user) => {
			if (user.schoolCode === schoolName) {
				tempArr.push(user.grade);
			}
		})
		const uniqueGrade = tempArr.filter((user, index, self) =>
			index === self.findIndex((t) => (
				t === user
			))
		);
		setGradeList(uniqueGrade);
	}, [schoolName])

	React.useEffect(() => {
		let checkPose = checkPosed(project.state);
		setProjectPosed(checkPose);
	}, [projectStore.project])

	React.useEffect(() => {
		let tempArr = [];
		myUsers.forEach((user) => {
			if (user.schoolType === schoolType && user.schoolCode === schoolName && user.grade === grade) {
				tempArr.push(user.className);
			}
		})
		const uniqueClassNames = [...Array.from(new Set(tempArr))];
		setClassNameList(uniqueClassNames);
	}, [grade])


	React.useEffect(() => {
		let tempArr = [];
		myUsers.forEach((user) => {
			if (user.schoolType === schoolType) {
				tempArr.push({ schoolName: user.schoolName, schoolCode: user.schoolCode });
			}
		})
		const uniqueSchoolNames = tempArr.filter((user, index, self) =>
			index === self.findIndex((t) => (
				t.schoolCode === user.schoolCode
			))
		);
		setSchoolNameList(uniqueSchoolNames);
	}, [schoolType, myUsers])


	React.useEffect(() => {
		const school = mySchools.find((school) => school.schoolCode === schoolName);
		if (school) setTargetProjectName(school.school);
	}, [schoolName])

	React.useEffect(() => {
		let tempArr = [];
		myUsers.forEach((user) => {
			let returnFlag = true;
			if (schoolType)
				returnFlag = returnFlag && user.schoolType === schoolType;
			if (schoolName)
				returnFlag = returnFlag && user.schoolCode === schoolName;
			if (grade)
				returnFlag = returnFlag && (user.grade === grade);
			if (className)
				returnFlag = returnFlag && (user.className === className);
			if (group) {
				if (user.myGroups && user.myGroups.length > 0) {
					returnFlag = returnFlag && (user.myGroups.findIndex((myGroup) => myGroup.code === group) !== -1)
				} else
					returnFlag = false;
			}
			if (keyword) {
				returnFlag = returnFlag && user.name.includes(keyword);
			}
			if (returnFlag && user.myGroups) {
				user.myGroups.forEach((group) => {
					tempArr.push(group);
				})
			}
		});
		const uniqueGroups = tempArr.filter((group, index, self) =>
			index === self.findIndex((t) => (
				t.code === group.code
			))
		);
		// checkGroupList(uniqueGroups, group.code);
		checkGroupList(uniqueGroups, group);
		setGroupList(uniqueGroups);
	}, [schoolType, schoolName, grade, className, myUsers])


	React.useEffect(() => {
		if (targetList.length > 0) {
			let tempArr = [...targetList];
			if (rotated) {
				tempArr.sort((a, b) => {
					if (a.name > b.name) return -1;
					if (a.name < b.name) return 1;
					return 0;
				});
			} else {
				tempArr.sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				});
			}
			setTargetList(tempArr);
		}
	}, [rotated])

	React.useEffect(() => {
		if (!projectStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectStore.isConfirmForUpdateRequired]);

	const checkPosed = (stateParam?: string) => {
		switch (projectStore.project.state) {
			case ProjectStateType.CREATED:
				return false;

			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
			default:
				return false;
		}
	}

	const modifyProjectUser = async (projectCodeParam, projectNameParam, targetProjectNameParam) => {
		await projectStore.changeProjectUser(projectCodeParam, projectNameParam, targetProjectNameParam)
			.then(() => {
				projectStore.getProjectUserTransfers(projectCodeParam, []);
				projectStore.downloadProjectInfoAndStore(projectCodeParam);
				projectStore.getProjectStates(projectCodeParam);
				processAfterStorage();
			});
	}




	const handleClickInit = async () => {
		const result = await confirm(i18next.t("필터를 초기화 하시겠습니까?"));
		if (!result) return;
		// setSelectedGroup(-1);
		setKeyword("");
		setSchoolName("");
		setClassName("");
		setGrade("");
		setGroup("");
		setSchoolType(ProjectSchoolType.NOGRADE);
	}

	const handleClickToggle = () => {
		setRotated(!rotated);
	};

	const handleClickNext = async () => {
		if (projectPosed) {
			const result = await confirm(i18next.t("다음으로 넘어가시겠습니까?"));
			if (!result) return;

			processAfterStorage();
			naviCustom.go(`/assignments/${projectCode}/create/paper`);
			// props.navigate(`/assignments/${projectCode}/create/paper`);
		} else {
			if (projectUsers.length + projectUserAddList.length - projectUserDelList.length === 0) {
				alert(i18next.t("선택된 학생이 없습니다."));
				return;
			}
			const result = await confirm(i18next.t("저장하고 다음으로 넘어가시겠습니까?"));
			if (!result) return;
			let response = await projectStore.changeProjectUser(projectCode, project.name, targetProjectName);
			console.log('response', response);
			if (response) {
				processAfterStorage();
				naviCustom.go(`/assignments/${projectCode}/create/paper`);
				// props.navigate(`/assignments/${projectCode}/create/paper`);
			} else
				alert(i18next.t("저장에 실패했습니다."));
		}
	}

	const handleClickSave = async () => {
		if (projectUsers.length + projectUserAddList.length - projectUserDelList.length === 0) {
			alert(i18next.t("선택된 학생이 없습니다."));
			return;
		}
		const result = await confirm(i18next.t("저장하시겠습니까?"));
		if (!result) return;

		await modifyProjectUser(projectCode, project.name, targetProjectName);
	}

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const handleProjectUserPageChange = (event, value) => {
		setProjectUserPage(value);
	};














	// const handleClickGroupAddBtnForNewbie = (user) =>{
	//     let projectUser = {
	//         name:user.name,
	//         className:user.className,
	//         email:user.email,
	//         grade:user.grade,
	//         school:user.schoolName,정
	//         schoolCode:user.schoolCode,
	//         state: HomeworkStateType.CREATED,
	//         projectCode:projectCode,
	//         userCode:user.code,
	//         groupCount:user.myGroups ? user.myGroups.length : 0,
	//         groupName: user.myGroups && user.myGroups[0] ? user.myGroups[0].name : ""
	//     }
	//
	//     projectStore.checkProjectUserAddList(projectUser);
	// }

	const handleClickDelUser = (user) => {
		let projectUser = {
			name: user.name,
			className: user.className,
			email: user.email,
			grade: user.grade,
			school: user.schoolName,
			schoolCode: user.schoolCode,
			state: ProjectStateType.CREATED,
			projectCode: projectCode,
			userCode: user.code,
			groupCount: user.myGroups ? user.myGroups.length : 0,
			groupName: user.myGroups && user.myGroups[0] ? user.myGroups[0].name : ""
		}

		// projectStore.checkProjectUserAddList(projectUser);
		projectStore.selectedProjectUserDel(projectUser);
		setAllAddBtn(true);
	}

	const handleClickAddUser = (user) => {
		let projectUser = {
			name: user.name,
			className: user.className,
			email: user.email,
			grade: user.grade,
			school: user.schoolName,
			schoolCode: user.schoolCode,
			state: ProjectStateType.CREATED,
			projectCode: projectCode,
			userCode: user.code,
			groupCount: user.myGroups ? user.myGroups.length : 0,
			groupName: user.myGroups && user.myGroups[0] ? user.myGroups[0].name : ""
		}

		// projectStore.checkProjectUserAddList(projectUser);
		projectStore.selectedProjectUserAdd(projectUser);
		checkIsProjectUsers();
	}

	const handleClickAddAll = () => {
		targetList.forEach((user, userIndex) => {
			if (userIndex < page * rowsPerPage && userIndex >= (page - 1) * rowsPerPage) {
				if (!checkIsProjectUser(user)) {
					handleClickAddUser(user)
				}
			}
		}
		)
		setAllAddBtn(false);
	}

	const handleClickDelAll = () => {
		targetList.forEach((user, userIndex) => {
			if (userIndex < page * rowsPerPage && userIndex >= (page - 1) * rowsPerPage) {
				if (checkIsProjectUser(user)) {
					handleClickDelUser(user)
				}
			}
		}
		)
		setAllAddBtn(true);
	}

	// const handleClickGroupAddBtnForProjectUser = (user) =>{
	//     let projectUser = {
	//         name:user.name,
	//         className:user.className,
	//         email:user.email,
	//         grade:user.grade,
	//         school:user.schoolName,
	//         schoolCode:user.schoolCode,
	//         state: HomeworkStateType.CREATED,
	//         projectCode:projectCode,
	//         userCode:user.code,
	//         groupCount:user.myGroups ? user.myGroups.length : 0,
	//         groupName: user.myGroups && user.myGroups[0] ? user.myGroups[0].name : ""
	//     }
	//     projectStore.checkProjectUserDelList(projectUser);
	// }

	const checkIsProjectUsers = () => {
		let flag = false;
		targetList.forEach((user, userIndex) => {
			if (userIndex < page * rowsPerPage && userIndex >= (page - 1) * rowsPerPage) {
				if (!checkIsProjectUser(user)) {
					flag = true;
				}
			}
		}
		)
		setAllAddBtn(flag);
	}

	const checkIsProjectUser = (user) => {
		const result = projectSelectedUsers.find(projectUser => projectUser.userCode === user.code);
		if (result === undefined || projectSelectedUsers.length === 0) {
			return false;
		} else {
			return true;
		}
	}
	// const checkIsUserAddList = (user) =>{
	//     const result = projectUserAddList.find(projectUser => projectUser.userCode === user.code);
	//     if(result === undefined || projectUserAddList.length===0){
	//         return false;
	//     } else{
	//         return true;
	//     }
	// }
	// const checkIsUserDelList = (userCode) =>{
	//     const result = projectUserDelList.find(code => code === userCode);
	//     if(result === undefined || projectUserDelList.length===0){
	//         return false;
	//     } else{
	//         return true;
	//     }
	// }

	const filteredUsers = () => {

		let tempArr = [];
		myUsers.forEach((user) => {
			let returnFlag = true;
			if (schoolType)
				returnFlag = returnFlag && user.schoolType === schoolType;
			if (schoolName)
				returnFlag = returnFlag && user.schoolCode === schoolName;
			if (grade)
				returnFlag = returnFlag && (user.grade === grade);
			if (className)
				returnFlag = returnFlag && (user.className === className);
			if (group) {
				if (user.myGroups && user.myGroups.length > 0) {
					returnFlag = returnFlag && (user.myGroups.findIndex((myGroup) => myGroup.code === group) !== -1)
				} else
					returnFlag = false;
			}
			if (keyword) {
				returnFlag = returnFlag && user.name.includes(keyword);
			}
			if (returnFlag)
				tempArr.push(user);
		})
		if (rotated) {
			tempArr.sort((a, b) => {
				if (a.name > b.name) return -1;
				if (a.name < b.name) return 1;
				return 0;
			});
		} else {
			tempArr.sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			});
		}
		setTargetList(tempArr);
		setSearching(false);
	}

	const checkGroupList = (groups, value) => {
		if (groups.length === 0)
			setGroup("");

		let i = 0;
		let selectedFlag = true;
		for (i = 0; i < groups.length; i++) {
			if (groups[i].code === value) {
				return;
			}
		}
		setGroup("");
	}

	const handleClickSelectionClear = async () => {
		await projectStore.initProjectUserList();
		projectStore.copyProjectUsersToDelList();
		setAllAddBtn(true);

		changeIsConfirmForUpdateRequired();
	}

	const handleChangeSchoolType = (e) => {
		setSchoolType(e.target.value);
		setGrade("");
		setSchoolName("");
		setClassName("");
	}

	const handleChangeGroup = (e) => {
		setGroup(e.target.value);
	}

	const handleChangeKeyword = (e) => {
		setKeyword(e.target.value);
	}

	const handleChangeGrade = (e) => {
		setGrade(e.target.value);
		setClassName("");
	}
	const handleChangeClass = (e) => {
		setClassName(e.target.value);
	}

	const handleSearch = async () => {
		// const result = await messageStore.confirm("검색 하시겠습니까?");
		// if (!result) return;
		// getMyUsers();
		setSearching(true);
		setSearchedFlag(true);
		filteredUsers();
		setPage(1);
	}

	const handleChangeSchoolName = (e) => {
		setSchoolName(e.target.value);
		setGrade("");
		setClassName("");
	}

	const changeIsConfirmForUpdateRequired = () => {
		if (navigateStore.isConfirmForUpdateRequired) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	};

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectStore.changeIsConfirmForUpdateRequired(false);
	};








	return (
		<div className={classes.root}>
			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 85px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>

					<Box className={classes.commonTopTitle}>
						<Typography className={classes.conTitleTop}>{i18next.t("2. 평가 대상")}</Typography>
						<Box>
							<Button className={classes.saveBtn}
								disabled={projectPosed}
								onClick={handleClickSave}
								disableRipple>
								<Typography>{i18next.t("저장")}</Typography>
							</Button>
							<Button className={classes.nextBtn}
								disabled={projectPosed}
								onClick={handleClickNext}
								disableRipple>
								<Typography>{i18next.t("다음")}</Typography>
							</Button>
						</Box>
					</Box>

					<Box className={classes.mainContentBoxLeftInner}>
						<Box>
							<Box className={classes.newSortBox}>
								<Box style={{ width: "100%", }}>
									
									<Box className={classes.selectList}>
										<Box className={classes.selectList2}>
											<Typography className={classes.selectListText}>{i18next.t("학교")}</Typography>
											<Box>
												<FormControl className={classes.formControl2}>
													<Select
														IconComponent={(props) => (
															<Box>
																<SelectIcon  {...props} />
															</Box>
														)}
														MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "right"
															},
															transformOrigin: {
																vertical: 'top',
																horizontal: 'right',
															},
															// getContentAnchorEl: null,
														}}
														value={schoolName}
														onChange={handleChangeSchoolName}
														displayEmpty
													>
														<MenuItem key={"school"} value={""}><Typography>{i18next.t("학교 선택")}</Typography></MenuItem>
														{schoolNameList.length > 0 ? schoolNameList.map((school, index) => {
															return (
																<MenuItem key={`${school.schoolCode}`}
																	value={school.schoolCode}><Typography>{school.schoolName}</Typography></MenuItem>
															)
														}) :
															<MenuItem key={"school none"} value="schoolNone"
																disabled><Typography>{i18next.t("학교 없음")}</Typography></MenuItem>
														}
													</Select>
												</FormControl>
											</Box>

											<Typography className={classes.selectListText2}>{i18next.t("학년/반")}</Typography>
											<Box display='flex'>
												<FormControl className={classes.formControl3}>
													<Select
														IconComponent={(props) => (
															<Box>
																<SelectIcon  {...props} />
															</Box>
														)}
														MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "right"
															},
															transformOrigin: {
																vertical: 'top',
																horizontal: 'right',
															},
															// getContentAnchorEl: null,
														}}
														value={grade}
														onChange={handleChangeGrade}
														disabled={!schoolName}
														displayEmpty>
														<MenuItem key={"grade"} value=""><Typography>{i18next.t("학년 선택")}</Typography></MenuItem>
														{gradeList.length > 0 ?
															gradeList.map((grade, index) => {
																return (
																	<MenuItem key={`grade_${index}`}
																		value={parseInt(grade)}><Typography>{`${grade}학년`}</Typography></MenuItem>
																)
															})
															:
															<MenuItem key={"grade none"} value="gradeNone"
																disabled><Typography>{i18next.t("학년 없음")}</Typography></MenuItem>
														}
													</Select>
												</FormControl>
												<FormControl className={classes.formControl3}>
													<Select
														IconComponent={(props) => (
															<Box>
																<SelectIcon  {...props} />
															</Box>
														)}
														MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "right"
															},
															transformOrigin: {
																vertical: 'top',
																horizontal: 'right',
															},
															// getContentAnchorEl: null,
														}}
														value={className}
														onChange={handleChangeClass}
														disabled={!schoolName}
														displayEmpty
													>
														<MenuItem key={"class"} value=""><Typography>{i18next.t("반 선택")}</Typography></MenuItem>
														{classNameList.length > 0 ?
															classNameList.map((className, index) => {
																return (
																	<MenuItem key={`className_${index}`}
																		value={className}><Typography>{`${className}반`}</Typography></MenuItem>
																)
															})
															:
															<MenuItem key={"class none"} value="classNone"
																disabled><Typography>{i18next.t("반 없음")}</Typography></MenuItem>
														}

													</Select>
												</FormControl>
											</Box>
										</Box>
									</Box>

									<Box className={classes.selectList3}>
										<Box className={classes.selectList2}>
											<Typography className={classes.selectListText}>{i18next.t("그룹")}</Typography>
											<Box>
												<FormControl className={classes.formControl2}>
													<Select
														IconComponent={(props) => (
															<Box>
																<SelectIcon  {...props} />
															</Box>
														)}
														MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "right"
															},
															transformOrigin: {
																vertical: 'top',
																horizontal: 'right',
															},
															// getContentAnchorEl: null
														}}
														value={group}
														onChange={handleChangeGroup}
														displayEmpty
													>
														<MenuItem key={"group"} value=""><Typography>{i18next.t("그룹 선택")}</Typography></MenuItem>
														{groupList.length > 0 ? groupList.map((group, index) => {
															return (
																<MenuItem key={`group_${index}`}
																	value={group.code}><Typography>{group.name}</Typography></MenuItem>
															)
														})
															:
															<MenuItem key={"groupNone"} value={"groupNone"} disabled>{i18next.t("그룹 없음")}</MenuItem>
														}
													</Select>
												</FormControl>
											</Box>
											<Typography className={classes.selectListText2}>{i18next.t("이름")}</Typography>
											<Box className={classes.loginInput}>
												<TextField
													id="outlined-basic-name"
													autoComplete="off"
													variant="outlined"
													value={keyword}
													onChange={handleChangeKeyword}
												/>
											</Box>
										</Box>

										<Box className={classes.btnGroup}>
											<Button className={!schoolName ? classes.btn2 : classes.btn1}
												onClick={handleSearch} disabled={searching}
												disableRipple>
												{searching ?
													<Typography>{i18next.t("검색중")}</Typography>
													:
													<Typography>{i18next.t("검색")}</Typography>}
											</Button>
											<Button className={classes.btn2} disableRipple>
												<Typography onClick={handleClickInit}>{i18next.t("초기화")}</Typography>
											</Button>
										</Box>

									</Box>
								</Box>
							</Box>
							<Typography className={classes.newGuideText}>{i18next.t("※ 검색에서 선택한 '학교급'과 '학교'는 학생에게 제시하는 과제지에 자동 출력됩니다.")}</Typography>

							<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box style={{ width: "50%", paddingRight: "5px" }}>
									<Typography className={classes.schoolNameSearch}>{i18next.t("검색 결과")}(<span>{targetList.length}</span>)</Typography>
									<Box className={classes.displayBetween}>
										<Box className={classes.allStudentTableBox}>
											<Typography className={classes.previewSubTextNew}>
												{i18next.t("검색된 학생들의 목록입니다.")}
											</Typography>
										</Box>
									</Box>
									<Box>
										<TableContainer component={Paper} className={classes.tableBox}>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell width='6%'>
															{allAddBtn ?
																<IconButton
																	className={classes.iconBtnReset}
																	onClick={e => handleClickAddAll()}
																	disabled={projectPosed}
																	disableRipple>
																	<AddGroup />
																</IconButton>
																:
																<IconButton
																	className={classes.iconBtnReset}
																	onClick={e => handleClickDelAll()}
																	disabled={projectPosed}
																	disableRipple>
																	<AddGroupActive />
																</IconButton>
															}
														</TableCell>
														<TableCell width='14%'>
															<Box className={classes.sortButton}
																style={{ cursor: 'default' }} display='flex'>
																<Typography
																	className={classes.nameStyle}>{i18next.t("이름")}</Typography>
																<IconButton className={classes.iconBtnReset}
																	onClick={handleClickToggle} style={{
																		transform: rotated ? 'none' : 'rotate(180deg)',
																		transition: 'transform 0.3s ease'
																	}} disableRipple>
																	<TableListOrder />
																</IconButton>
															</Box>
														</TableCell>
														<TableCell width='27%'>{i18next.t("이메일")}</TableCell>
														<TableCell width='20%'>{i18next.t("학교")}</TableCell>
														<TableCell width='15%'>{i18next.t("학년반")}</TableCell>
														<TableCell width='18%'>{i18next.t("그룹")}</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{targetList.length > 0 ?
														targetList.map((user, userIndex) => {
															return (
																<React.Fragment key={`targetList${userIndex}`}>
																	{userIndex < page * rowsPerPage && userIndex >= (page - 1) * rowsPerPage &&
																		<TableRow key={`target_${userIndex}`}
																			sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
																			<TableCell>
																				{checkIsProjectUser(user) ?
																					<IconButton
																						className={classes.iconBtnReset}
																						onClick={e => handleClickDelUser(user)}
																						disabled={projectPosed}
																						disableRipple>
																						<AddGroupActive />
																					</IconButton>
																					:
																					<IconButton
																						className={classes.iconBtnReset}
																						onClick={e => handleClickAddUser(user)}
																						disabled={projectPosed}
																						disableRipple>
																						<AddGroup />
																					</IconButton>
																				}
																			</TableCell>
																			<TableCell align="left">
																				<Box>
																					<Typography
																						className={classes.tableStudentName}>{user.name}</Typography>
																				</Box>
																			</TableCell>
																			<TableCell align="left">
																				{user.email}
																			</TableCell>
																			<TableCell align="left">
																				{user.schoolName}
																			</TableCell>
																			<TableCell align="left">
																				{i18next.t("{{grade}}학년 {{className}}반", { grade: user.grade, className: user.className })}
																			</TableCell>
																			<TableCell align="left">
																				{user.myGroups && user.myGroups.length > 0 ? `${user.myGroups[0].name}${user.myGroups.length > 1 ? i18next.t("외 {{groupCount}}개", { groupCount: user.myGroups.length - 1 }) : ""}` : "-"}
																			</TableCell>
																		</TableRow>
																	}</React.Fragment>)
														})
														:
														<React.Fragment key={`targetList_none`}>
															{searchedFlag ?
																<TableRow>
																	<TableCell align={"center"} colSpan={7}>
																		<Typography>{i18next.t("검색 조건 학생 없음")}</Typography>
																	</TableCell>
																</TableRow>
																: <TableRow>
																	<TableCell align={"center"} colSpan={7}>
																		<Typography>{i18next.t("학생을 검색해주세요.")}</Typography>
																	</TableCell>
																</TableRow>
															}
														</React.Fragment>
													}
												</TableBody>
											</Table>
										</TableContainer>
										<Box className={classes.pageNumberAll}>
											<Stack spacing={2}>
												<Pagination
													count={Math.ceil(targetList.length / rowsPerPage) === 0 ? 1 : Math.ceil(targetList.length / rowsPerPage)}
													page={page}
													onChange={handlePageChange}
													boundaryCount={2}
													showFirstButton
													showLastButton
													variant="outlined"
												/>
											</Stack>
										</Box>
									</Box>
								</Box>

								<Box style={{ width: '50%', paddingLeft: "5px" }}>
									<Typography className={classes.schoolNameSearch}>{i18next.t("과제에 할당된 학생")}(<span>{projectSelectedUsers.length}</span>)</Typography>

									<Box className={classes.displayBetween}>
										<Box className={classes.allStudentTableBox} style={{ width: "100%" }}>
											<Typography className={classes.previewSubTextNew}>{i18next.t("선택한 학생들의 목록입니다.")}</Typography>
											<Button className={classes.allSelectDelete}
												onClick={handleClickSelectionClear} disabled={projectPosed}
												disableRipple>
												<Typography>{i18next.t("초기화")}</Typography>
											</Button>
										</Box>

									</Box>
									<Box>
										<TableContainer component={Paper} className={classes.tableBox}>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell width='6%'>No.</TableCell>
														<TableCell width='14%'>
															<Box className={classes.sortButton}
																style={{ cursor: 'default' }} display='flex'>
																<Typography style={{ fontWeight: 800 }}
																	className={classes.nameStyle}>{i18next.t("이름")}</Typography>
															</Box>
														</TableCell>
														<TableCell width='27%'>{i18next.t("이메일")}</TableCell>
														<TableCell width='20%'>{i18next.t("학교")}</TableCell>
														<TableCell width='15%'>{i18next.t("학년반")}</TableCell>
														<TableCell width='18%'>{i18next.t("그룹")}</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{projectSelectedUsers && projectSelectedUsers.map((user, userIndex) => {
														return (
															<React.Fragment key={`projectSelectedUsers${userIndex}`}>
																{userIndex < projectUserPage * rowsPerPage && userIndex >= (projectUserPage - 1) * rowsPerPage &&
																	<TableRow key={`user_${userIndex}`}
																		sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
																		<TableCell>
																			{userIndex + 1}
																		</TableCell>
																		<TableCell align="left">
																			<Box>
																				<Typography
																					className={classes.tableStudentName}>{user.name}</Typography>
																			</Box>
																		</TableCell>
																		<TableCell align="left">
																			{user.email}
																		</TableCell>
																		<TableCell align="left">
																			{user.school}
																		</TableCell>
																		<TableCell align="left">
																			{i18next.t("{{grade}}학년 {{className}}반", { grade: user.grade, className: user.className })}
																		</TableCell>
																		<TableCell align="left">
																			{user.groupName && user.groupCount > 0 ? `${user.groupName}${user.groupCount > 1 ? i18next.t("외 {{groupCount}}개", { groupCount: user.groupCount - 1 }) : ""}` : "-"}
																		</TableCell>
																	</TableRow>
																}</React.Fragment>)
													})
													}
												</TableBody>
											</Table>
										</TableContainer>
										<Box className={classes.pageNumberAll}>
											<Stack spacing={2}>
												<Pagination
													count={Math.ceil(projectSelectedUsers.length / rowsPerPage) === 0 ? 1 : Math.ceil(projectSelectedUsers.length / rowsPerPage)}
													page={projectUserPage}
													onChange={handleProjectUserPageChange}
													boundaryCount={2}
													showFirstButton
													showLastButton
													variant="outlined"
												/>
											</Stack>
										</Box>
									</Box>
								</Box>
							</Box>

						</Box>
					</Box>
				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof TempExamTargetAppointment>(TempExamTargetAppointment);
