// AuthStore
export enum AuthState {
	Authenticated = "Authenticated",
	NotAuthenticated = "NotAuthenticated",
	Pending = "Pending",
	Failed = "Failed",
	NeedPasswordChange = "NeedPasswordChange",
	PassWordUpdated = 'PassWordUpdated',
	None = 'None'
};



/// ProjectSubmissionStore
