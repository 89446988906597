


// StoreManager
export enum StoreStatus {
	INITIAL = "INITIAL",
	PROGRESS = "PROGRESS",
	COMPLETED = "COMPLETED",
	FAILED = "FAILED"
}
