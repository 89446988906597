import { INcodeAllocationRequest } from "./model/transfer/INcodeAllocationRequest";
import { INcodeAllocationResponse } from "./model/transfer/INcodeAllocationResponse";
import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class NcodeAllocationRepository extends Repository {
	public requestPrefix: string;

	constructor(props: Props) {
		super();
		this.requestPrefix = `${props.serverContextPath}/api/v1/ncode`;
	}

	/**
	 * 새로운 N-Code 영역을 할당합니다.
	 */
	public async allocateCode(request: INcodeAllocationRequest): Promise<INcodeAllocationResponse> {
		const result = await this.getRequestPromise<INcodeAllocationResponse>(
			"post",
			`${this.requestPrefix}/allocate`,
			{},
			request
		);
		return result;
	}

	/**
	 * 할당 ID로 N-Code 할당 정보를 조회합니다.
	 */
	public async getAllocation(allocationId: string): Promise<INcodeAllocationResponse> {
		const result = await this.getRequestPromise<INcodeAllocationResponse>(
			"get",
			`${this.requestPrefix}/${allocationId}`
		);
		return result;
	}

	/**
	 * 프로젝트 코드로 모든 N-Code 할당 정보를 조회합니다.
	 */
	public async getAllocationsByProject(projectCode: string): Promise<INcodeAllocationResponse[]> {
		const result = await this.getRequestPromise<INcodeAllocationResponse[]>(
			"get",
			`${this.requestPrefix}/project/${projectCode}`
		);
		return result;
	}


	/**
	 * 프로젝트의 가장 최근의 할당 정보를 조회합니다.
	 */
	public async getProjectLastAllocation(projectCode: string, templateProjectCode: string, pageCount = 0): Promise<INcodeAllocationResponse> {
		try {
			const allocationsAssignment = await this.getAllocationsByProject(projectCode);
			allocationsAssignment.forEach((allocation) => { allocation.isAssignmentProject = true; });

			const allocationsTemplate = templateProjectCode ? await this.getAllocationsByProject(templateProjectCode) : [];
			allocationsTemplate.forEach((allocation) => { allocation.isAssignmentProject = false; });

			const allocations = [];

			allocations.push(...allocationsTemplate, ...allocationsAssignment);
			allocations.sort((a, b) => {
				// assignment project이면 나중에 나오도록
				if (a.isAssignmentProject && !b.isAssignmentProject) return 1;
				if (!a.isAssignmentProject && b.isAssignmentProject) return -1;

				// 같은 종류의 프로젝트이면 최신 것이 나중에 나오도록
				return b.createdAt > a.createdAt ? 1 : -1;
			});


			const last = allocations[0];
			if (last && pageCount > 0) {
				if (last.totalPages === pageCount) {
					return last;
				}
				else {
					return null;
				}
			}

			return last;
		} catch (e) {
			console.error("Cannot get project allocations...", e);
			return null;
		}
	}


	/**
	 * 할당된 N-Code 영역을 반환 처리합니다.
	 */
	public async returnAllocation(allocationId: string, reason: string): Promise<void> {
		await this.getRequestPromise(
			"put",
			`${this.requestPrefix}/${allocationId}/return`,
			{},
			{ reason }
		);
	}


	//FIXME: kitty, file upload는 문제 생성에서, ncode는 문제 제시에서 사용되므로, 이 부분은 문제 생성 리포지토리로 이동해야 합니다.
	/**
	 * 원본 PDF 파일을 업로드합니다.
	 */
	public async uploadOrgPdf(
		allocationId: string,
		file: File,
		onUploadProgress?: (progressEvent: any) => void
	): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);

		await this.getRequestPromise(
			"put",
			`${this.requestPrefix}/${allocationId}/org-pdf`,
			{},
			formData,
			{
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress
		);
	}

	//FIXME: kitty, file upload는 문제 생성에서, ncode는 문제 제시에서 사용되므로, 이 부분은 문제 생성 리포지토리로 이동해야 합니다.
	/**
	 * 원본 DOC 파일을 업로드합니다.
	 */
	public async uploadOrgDoc(
		allocationId: string,
		file: File,
		onUploadProgress?: (progressEvent: any) => void
	): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);

		await this.getRequestPromise(
			"put",
			`${this.requestPrefix}/${allocationId}/org-doc`,
			{},
			formData,
			{
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress
		);
	}

	/**
	 * N-Code PDF 파일을 업로드합니다.
	 */
	public async uploadNcodedPdf(
		allocationId: string,
		file: File,
		onUploadProgress?: (progressEvent: any) => void
	): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);

		await this.getRequestPromise(
			"put",
			`${this.requestPrefix}/${allocationId}/ncoded-pdf`,
			{},
			formData,
			{
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress
		);
	}

	/**
	 * 파일이 PDF 형식인지 검증합니다.
	 */
	private validatePdfFile(file: File): void {
		if (file.size === 0) {
			throw new Error("File is empty");
		}
		if (file.type !== 'application/pdf') {
			throw new Error("Invalid file type. Only PDF files are allowed");
		}
	}

	/**
	 * 파일이 DOC/DOCX 형식인지 검증합니다.
	 */
	private validateDocFile(file: File): void {
		if (file.size === 0) {
			throw new Error("File is empty");
		}
		if (file.type !== 'application/msword' &&
			file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
			throw new Error("Invalid file type. Only DOC/DOCX files are allowed");
		}
	}
}


/**
 *

// 사용 예시:
const repository = new NcodeAllocationRepository({
		serverContextPath: 'http://your-server'
});

// 코드 할당
const allocation = await repository.allocateCode({
		projectCode: 'PROJECT-001',
		pageCount: 50
});

// 파일 업로드
const file = new File([''], 'document.pdf', { type: 'application/pdf' });
await repository.uploadOrgPdf(allocation.allocationId, file, (progress) => {
		console.log(`Upload progress: ${progress.loaded}/${progress.total}`);
});

*/