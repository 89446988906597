import React from "react";
import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor/dist/i18n/ko-kr";
import { TextViewerStyle } from "./styles/TextViewerStyle";
import useEmotionStyles from "../../views/main/styles/useEmotionStyles";
// import TurndownService from "turndown";

type Props = {
	currentHTML: string,
	height?: number,
}



function TextViewer(props: Props) {
	const {
		currentHTML,
		height = 300
	} = props;
	const classes = useEmotionStyles( TextViewerStyle );

	// const turndownService = new TurndownService();
	const viewerRef = React.useRef(null);

	React.useEffect(() => {
		if (
			!viewerRef.current
			|| !currentHTML
			// || !(viewerRef.current instanceof Viewer)
		)
			return;

		// viewerRef.current.getInstance().setMarkdown(turndownService.turndown(currentHTML));
		viewerRef.current.getInstance().setMarkdown(currentHTML);
	}, [viewerRef.current, currentHTML]);

	React.useEffect(() => {
		return () => {
			viewerRef.current = null;
		}
	}, []);

	return (
		<div className={classes.main}>
			<Viewer
				ref={viewerRef}
			// height='50px'
			// minHeight={`${0}px`}
			// viewer={true}
			// initialEditType="wysiwyg"
			// toolbarItems={[
			// 	// ["heading", "bold", "italic", "strike"],
			// 	// ["bold", "italic"],
			// 	// ['hr', 'quote'],
			// 	// ['ul', 'ol'],
			// 	// ["image"],
			// ]}
			// usageStatistics={false} // 통계 수집 거부
			// previewStyle="vertical" // or tab
			// hideModeSwitch={true}
			/>
		</div>
	);
}
export default TextViewer;
