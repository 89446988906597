import { PEN_SERVICE_UUID_128, PEN_SERVICE_UUID_16 } from "./pencomm/pencomm_const";

const bleFilterOption = {
	// acceptAllDevices: true,
	filters: [
		// { services: [PEN_SERVICE_UUID_16, PEN_SERVICE_UUID_128] },
		{ services: [PEN_SERVICE_UUID_128] },
		{ services: [PEN_SERVICE_UUID_16] },
		{ namePrefix: "Neosmartpen" },
		{ namePrefix: "LAMY" },
		{ namePrefix: "Smartpen" },
		{ namePrefix: "NPEN"},
		{ namePrefix: "Mbest"},
		{ namePrefix: "MBEST"},
	]
}

// export function setBLEFilters(btIds: any) {
// 	bleFilterOption.filters = bleFilterOption.filters.filter(filter => filter.services);

// 	btIds.forEach((id: string) => {
// 		bleFilterOption.filters.push({ namePrefix: id });
// 	});
// }

export enum DeviceSelectDlgResult {
	Success = "Success",
	Cancel = "User canceled the dialog",
	BluetoothError = "Bluetooth adapter is not available",
	UnknownFail = "unknown fail"
}

// npm install -D @types/web-bluetooth
export async function deviceSelectDlg(): Promise<{ success: DeviceSelectDlgResult, device: BluetoothDevice }> {
	console.log("********* Requesting any Bluetooth Device... **********");

	const options = bleFilterOption;
	// let result = await Bluetooth.requestDeviceAsync(options);

	try {
		// let mobileNavigatorObject: any = window.navigator;
		const device = await navigator.bluetooth.requestDevice(options);
		return { success: DeviceSelectDlgResult.Success, device };
	} catch (e: any) {
		console.log(e);
		if ((e.message as string).search("cancelled") > -1) {
			return { success: DeviceSelectDlgResult.Cancel, device: null };
		}
		else if ((e.message as string).search("adapter not available") > -1) {
			return { success: DeviceSelectDlgResult.BluetoothError, device: null };
		}
		// throw e;
	}

	return { success: DeviceSelectDlgResult.UnknownFail, device: null };
}
