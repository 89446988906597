
export class ByteConverter {
  static ByteToInt(vals: number[]) {
    let val = 0;
    for (let i = vals.length - 1; i >= 0; i--) {
      val *= 256;
      val += vals[i];
    }

    return val;
  }

  static ShortToByte(val: number) {
    const arr = new ArrayBuffer(2);
    const view = new DataView(arr);
    view.setInt16(0, val, true);

    return new Uint8Array(arr);
  }


  static IntToByte(val: number) {
    const arr = new ArrayBuffer(4);
    const view = new DataView(arr);
    view.setInt32(0, val, true);

    return new Uint8Array(arr);
  }

  static LongToByte(val: bigint) {
    const arr = new ArrayBuffer(16);
    const view = new DataView(arr);
    view.setBigInt64(0, val, true);
    return new Uint8Array(arr);
    // const high = Math.floor(val / (2 ** 32));
    // const low = Math.abs(val) % (2 ** 32);

    // view.setUint32(0, low, true);
    // view.setUint32(4, high, true);

    // return new Uint8Array(arr);
  }

  // static byteToUnsigned(arr: Uint8Array, length: number) {
  //   let value = 0;
  //   for (let i = length - 1; i >= 0; --i) {
  //     value *= 256;
  //     value += arr[i];
  //   }
  //   return value;
  // }

  // static byteToSigned(arr: Uint8Array, length: number) {
  //   let value = 0;
  //   value = arr[length - 1] & 0x7f;
  //   const sign = arr[length - 1] & 0x80;
  //   value *= sign;
  //   for (let i = length - 2; i >= 0; --i) {
  //     value *= 256;
  //     value += arr[i];
  //   }

  //   return value;
  // }


  static ToUInt64(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 8);
    const val = new BigUint64Array(buf.buffer)[0];
    return val;
  }

  static ToUInt32(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 4);
    const val = new Uint32Array(buf.buffer)[0];
    return val;
  }

  static ToUInt16(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 2);
    const val = new Uint16Array(buf.buffer)[0];
    return val;
  }


  static ToInt64(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 8);
    const val = new BigInt64Array(buf.buffer)[0];
    return val;
  }

  static ToInt32(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 4);
    const val = new Int32Array(buf.buffer)[0];
    return val;
  }

  static ToInt16(arr: Uint8Array, startIndex: number) {
    const buf = arr.slice(startIndex, startIndex + 2);
    const val = new Int16Array(buf.buffer)[0];
    return val;
  }
}
