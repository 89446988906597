import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertSubjectNameTypeEnToKr } from "../../../stores/ProjectStore";
import { getSemesterTypeString, getYearSemesterTitle } from "./001_Functions/getSemesterTypeString";
import { ProjectSemesterType } from "../../../repositories/model/support/ProjectSemesterType";


type Props = {
	titleBoxClassName: string,
	headerFontClassName?: string,

	projectTargetGroupName: string,
	projectYear: number,
	projectSemester: ProjectSemesterType
	projectSubjectName: string,

}

export function PrintWorksheetHeader(props: Props) {
	const i18next = useTranslation();
	const { titleBoxClassName,
		projectTargetGroupName,
		projectYear,
		projectSemester,
		projectSubjectName,
		headerFontClassName
	} = props;

	return (
		<Table aria-label="simple table">
			<TableBody>
				<TableRow>
					<TableCell align="center" className={titleBoxClassName}
					>
						<Typography className={headerFontClassName}>
							{getYearSemesterTitle(projectYear, projectSemester)}
						</Typography>
					</TableCell>

					<TableCell
						sx={{ width: "240px" }}
						align="center"
					>
						<Typography className={headerFontClassName}>
							{getSemesterTypeString(projectYear, projectSemester)}
						</Typography>
					</TableCell>

					<TableCell align="center" className={titleBoxClassName}
						sx={{ width: "80px" }}
					>
						<Typography className={headerFontClassName}
						>
							{i18next.t('학교명')}
						</Typography>
					</TableCell>

					<TableCell align="center">
						<Typography className={headerFontClassName}>
							{projectTargetGroupName && projectTargetGroupName}
						</Typography>
					</TableCell>


					<TableCell align="center" className={titleBoxClassName}
						sx={{ width: "80px" }}
					>
						<Typography className={headerFontClassName}>
							{i18next.t('과목')}
						</Typography>
					</TableCell>

					<TableCell align="center">
						<Typography className={headerFontClassName}>
							{convertSubjectNameTypeEnToKr(projectSubjectName)}
						</Typography>
					</TableCell>
				</TableRow>


				<TableRow>
					<TableCell align="center" className={titleBoxClassName}>
						<Typography className={headerFontClassName}>
							{i18next.t('학년/반/번호')}
						</Typography>
					</TableCell>

					<TableCell align="center">
						<Typography className={headerFontClassName}>
							(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;){i18next.t("학년")}
							&nbsp;&nbsp;
							(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;){i18next.t("반")}
							&nbsp;&nbsp;
							(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;){i18next.t("번")}
						</Typography>
					</TableCell>

					<TableCell align="center"
						className={titleBoxClassName}
					>
						<Typography className={headerFontClassName}>
							{i18next.t('이름')}
						</Typography>
					</TableCell>

					<TableCell colSpan={3} >
						<Typography className={headerFontClassName}>
						</Typography>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}