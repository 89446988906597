
import {
	Box,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import clsx from "clsx";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { MarkFeedbackStyle } from "../../../styles/MarkFeedbackStyle";







import { useTranslation } from 'react-i18next';

export const RubricItemRow = (props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles( MarkFeedbackStyle );
	const { itemInfo } = props;

	return (
		<TableRow sx={{
			'&:last-child .MuiTableCell-body': {
				// border: '0px !important'
			}
		}}>
			<TableCell width='8%' align="center" className={classes.rubricCell}>
				<Typography>{i18next.t(itemInfo.name)}</Typography>
			</TableCell>
			<TableCell width='24%' className={classes.rubricCell}>
				<Typography className={classes.markText}><span>{itemInfo.score}</span>{i18next.t("점")}</Typography>
			</TableCell>
			<TableCell width='50%' align="left" className={classes.borderLine}>
				<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti)}>
					<Typography className={classes.tableText}>
						{i18next.t(itemInfo.standard)}
					</Typography>
				</Box>
			</TableCell>
		</TableRow>
	);
};