import { Box, Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import { clsx } from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as SideBarActive } from "../../../common/images/SideBarActive.svg";
import { ReactComponent as StepIcon } from "../../../common/images/StepIcon.svg";
import { HtmlTooltip } from "../Tooltip";
import useEmotionStyles from "../../main/styles/useEmotionStyles";
import { SideBarStyle } from "../styles/SideBarStyle";

// 예시로 ActiveIcon을 감싸는 래핑 컴포넌트
const WrappedSideBarActive: React.FC<StepIconProps> = (props: any) => {
	return <SideBarActive {...props} />;
};

// 다른 아이콘도 동일하게 래핑
const WrappedStepIcon: React.FC<StepIconProps> = (props: any) => {
	const { completed, active, error, ...rest } = props;
	return <StepIcon
		completed={`${completed}`}
		active={`${active}`}
		error={`${error}`}
		{...rest}
	/>;
};


type Props = {
	projectCode: string;
	children: any[];
	expanded: boolean;
	onClick: (link: string) => void;
	activeStates: any;
	// navigateStore: NavigateStore;
};

function ChildMenuComponent(props: Props) {
	const classes = useEmotionStyles( SideBarStyle );
	const { pathname } = useLocation();
	const { projectCode, children, expanded, onClick, activeStates } = props;
	const [subMenu, setSubMenu] = React.useState(children);
	const disabled = !projectCode || projectCode === "undefined";




	const handleClick = (child) => {
		if (!disabled && !child.disabled) {
			props.onClick(child.link);
		}
	}

	const isActive = (child) => {

		const oldCondition = (!disabled || child.id === 'info') && activeStates[child.id];
		const viewChildIdCondition = (!disabled && child.id === 'view');

		return oldCondition || viewChildIdCondition;
	}
	const isCurrent = (child) => {
		return pathname.includes(child.urlPrefix)
	}

	React.useEffect(() => {
		let trigger = false;
		const temp = children.map(child => {

			if (child.id === "view") child = { ...child, disabled: trigger, view: activeStates.rubric };
			else child = { ...child, disabled: trigger };

			if (trigger || (!child.skip && !activeStates[child.id])) {
				trigger = true;
			}
			return child;
		});
		setSubMenu(temp);
	}, [children, activeStates]);




	return (
		<Box className={expanded ? classes.subSideMenu : clsx(classes.subSideMenu, classes.subSideMenu_fold)}>
			<Box sx={{ width: "100%", }}>
				<Stepper orientation="vertical" >
					{subMenu.filter(child => child.view)
						.map(child => (
							<Step key={`step-child-${child.label}`}
								className={isCurrent(child) ? clsx(classes.step, classes.current) : classes.step}
								active={isActive(child)}
								onClick={() => handleClick(child)}>
								<HtmlTooltip title={child.label} disableHoverListener={expanded}>
									<StepLabel style={{ whiteSpace: 'nowrap', padding: 0, cursor: 'pointer', height: 20 }}
										// StepIconComponent={isActive(child)? SideBarActive : StepIcon}>
										StepIconComponent={isActive(child) ? WrappedSideBarActive : WrappedStepIcon}>
										{expanded ? child.label : null}
									</StepLabel>
								</HtmlTooltip>
							</Step>
						))}
				</Stepper>
			</Box>
		</Box>
	);
}

export default observer<typeof ChildMenuComponent>(ChildMenuComponent);
