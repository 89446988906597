import React from "react";
import NeoPenStore from "../../stores/NeoPenStore";
import { IExamInfo } from "../../repositories/model/transfer/IExamInfo";
import { IProject } from "../../repositories/model/IProject";
import { ProjectSemesterType } from "../../repositories/model/support/ProjectSemesterType";
import NcodeAllocationStore from "../../stores/NcodeAllocationStore";
import { INcodeAllocationResponse, NcodeAllocationResponse } from "../../repositories/model/transfer/INcodeAllocationResponse";
import ProjectStore from "../../stores/ProjectStore";
import AuthStore from "../../stores/AuthStore";

let projectCodeToPdf: {
	[key: string]: {
		pdfBlobURL: string,
		pageSize: number,
		ncodeAllocation: INcodeAllocationResponse,
	}
} = {};

let isProcessedCode = new Set();

type Props = {
	project: IProject,
	projectYear: number,
	neoPenStore: NeoPenStore,
	authStore: AuthStore,
	projectSemester: ProjectSemesterType,
	projectStore: ProjectStore,
	ncodeAllocationStore: NcodeAllocationStore,
	examList: IExamInfo[],
	lang: string,
	skipPdfRendering?: boolean,
}

function useNeoPen(props: Props) {
	const ps = props;
	const { authStore, project, projectStore, projectSemester, examList, lang, neoPenStore, ncodeAllocationStore, skipPdfRendering } = ps;

	const [pdfBlobURL, setPdfBlobURL] = React.useState(null);
	const [pageCount, setPageCount] = React.useState(null);
	const [ncodeAllocation, setNcodeAllocation] = React.useState(null);

	const init = async () => {
		if (isProcessedCode.has(project.code)) {
			if (projectCodeToPdf[project.code]) {
				setPdfBlobURL(projectCodeToPdf[project.code].pdfBlobURL);
				setPageCount(projectCodeToPdf[project.code].pageSize);
				setNcodeAllocation(projectCodeToPdf[project.code].ncodeAllocation);
			}
			return;
		}

		isProcessedCode.add(project.code);

		console.log("useNeoPen start init", project, projectSemester, examList);

		neoPenStore.addEventsOnPenManager();

		let templateProject: IProject = null;
		if (project.templateCode) {
			templateProject = await projectStore.fetchProject(project.templateCode);
		}

		const teacher = authStore.loginUser;
		const allocation = await ncodeAllocationStore.getProjectLastAllocation(project.code, project.templateCode);

		let url: string = null;
		let count: number = 0;
		if (!skipPdfRendering) {
			const ret  = await ncodeAllocationStore.addTextToPDF({ project, templateProject, currentSemesterString: projectSemester, examList, lang, teacher, });
			url = ret.pdfBlobURL;
			count = ret.pageCount;

			if (allocation) {
				const totalPages = NcodeAllocationResponse.getTotalPages(allocation);
				const startSobp = NcodeAllocationResponse.getStartSobp(allocation);
				await ncodeAllocationStore.registerPdfAtMappingStorage(examList[0].type, pdfBlobURL, lang, startSobp, totalPages);
			}

			setPdfBlobURL(url);
			setPageCount(count);
		}
		setNcodeAllocation(allocation);

		projectCodeToPdf[project.code] = { pdfBlobURL: url, pageSize: count, ncodeAllocation: allocation };

		console.log("useNeoPen end init ... pageSize:", pageCount);
	}

	React.useEffect(() => {
		console.log('useNeoPen ', project, projectSemester, examList, neoPenStore);

		if (project
			&& examList?.length > 0
			&& project.code === examList[0].projectCode
			&& neoPenStore) {
			init();
		}
	}, [project, projectSemester, examList, neoPenStore, ncodeAllocationStore, projectStore]);

	return { pdfBlobURL, pageCount, ncodeAllocation };
}

export default useNeoPen;
