import {
	Box,
	IconButton,
	Paper,
	TableContainer,
	Typography
} from "@mui/material";
import { ReactComponent as ScreenMinus } from '../../../../common/images/ScreenMinus.svg';
import { ReactComponent as ScreenPlus } from '../../../../common/images/ScreenPlus.svg';
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";

import { useStores } from "../../../../hooks/useStores";
import { ResultHeaderTable } from "../../000_Common/ResultHeader";
import { ExamRow } from "./ExamRow";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
	params: any,
	submissionCode: string,
	submissionInfo: any,
	questionList: any,
	examOrderAndQuestionsLengthMap: any,
	pdfWidth: number
}

function ResultReviewLeftPanel(props: Props) {
	const { submissionInfo, questionList, examOrderAndQuestionsLengthMap } = props;
	const i18next = useTranslation();
	const classes = useEmotionStyles(ResultReviewStyle);
	const { navigateStore, messageStore } = useStores();

	useEffect(() => {
		setPdfWidth(props.pdfWidth);
	}, [props.pdfWidth]);



	const [page, setPage] = React.useState(0);
	const [pages, setPages] = React.useState([]);
	const [pdfWidth, setPdfWidth] = React.useState(0);

	const [anchorEl, setAnchorEl] = React.useState<Element>(null);
	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;
	const pdfBoxRef = React.useRef<HTMLElement>();
	const [viewScale, setViewScale] = React.useState(1);

	const naviCustom = useNaviCustom();

	const handleClickReviewComplete = async () => {
		console.log("handleClickReviewComplete")
		const result = await messageStore.confirm(i18next.t("리뷰를 완료하시고 목록으로 돌아가시겠습니까?"));
		if (!result) return;

		naviCustom.go(`/projects`);
		// props.navigate(`/projects`);
	};


	const loadErr = (e) => {
		console.log(i18next.t('Error while loading document! '), e.message);
	};

	const loadSuccess = ({ numPages }) => {
		let tempArr = [];
		for (let i = 0; i < numPages; i++) {
			tempArr.push(i + 1);
		}

		setPage(1);
		setPages(tempArr);

		setPdfWidth(pdfBoxRef.current?.offsetWidth || 0);
	};

	const handleViewPlus = () => {
		if (viewScale >= 4) return;

		setViewScale(prev => prev * 2);
	};

	const handleViewMinus = () => {
		if (viewScale <= 1 / 4) return;

		setViewScale(prev => prev / 2);
	};

	const currentViewScalePercentage = React.useMemo(() => {
		return viewScale * 100
	}, [viewScale]);

	const getExamInfoByExamCode = (examCode: string) => {
		const examInfo = submissionInfo?.exams.find(exam => exam.code === examCode);
		return examInfo;
	};


	const disabledViewPlus = React.useMemo(() => {
		return viewScale >= 4;
	}, [viewScale]);

	const disabledViewMinus = React.useMemo(() => {
		return viewScale <= 1 / 4;
	}, [viewScale]);

	const questionObjByExamOrder = React.useMemo(() => {
		const obj = {};

		questionList.map(q => {
			if (!obj.hasOwnProperty(q.parentOrder)) {
				obj[q.parentOrder] = [];
			}

			obj[q.parentOrder].push(q);
		});

		return obj;
	}, [questionList]);

	const examOrderList = React.useMemo(() => {
		return Object.keys(questionObjByExamOrder);
	}, [questionObjByExamOrder]);


	const currentCreatedDatetime = React.useMemo(() => {
		if (submissionInfo?.project?.createdDatetime) {
			return convertUTCToKST(submissionInfo?.project.createdDatetime).format(i18next.t("YYYY년 M월 D일"));
		}

		return '-';
	}, [submissionInfo]);

	const currentGradeClassName = React.useMemo(() => {
		if (!submissionInfo?.grade || !submissionInfo?.className) return '-';

		return `${submissionInfo?.grade}${i18next.t('학년')} ${submissionInfo?.className}${i18next.t('반')}`;
	}, [submissionInfo?.grade, submissionInfo?.className]);


	return (
		<>
			{submissionInfo?.project?.includedOnline
				?
				<Box>
					<Box className={classes.titleBoxStudent}>
						<Typography>{i18next.t("평가 과제")}</Typography>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<ResultHeaderTable
								schoolName={submissionInfo?.school}
								currentGradeClassName={currentGradeClassName}
								userName={submissionInfo?.userName}
								year={submissionInfo?.project?.year}
								semester={submissionInfo?.project?.semesterType} currentCreatedDatetime={currentCreatedDatetime}

								titleBoxClassName={classes.titleBox}
							/>
						</TableContainer>
					</Box>

					{examOrderList.length > 0 &&
						examOrderList.map((examOrder, idx) =>
							<ExamRow
								key={idx}
								examInfo={getExamInfoByExamCode(questionObjByExamOrder[examOrder][0].projectExamCode)}
								questionList={questionObjByExamOrder[examOrder]}
								examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
							/>
						)}
				</Box>
				:
				<Box>
					<Box className={classes.titleBoxStudent}>
						<Typography>{i18next.t("평가 과제")}</Typography>
						<Box display="flex" alignItems="center">
							<Typography className={classes.viewScale}>{currentViewScalePercentage}%</Typography>
							{!disabledViewPlus &&
								<IconButton className={classes.iconBtnReset} disableRipple onClick={handleViewPlus} disabled={disabledViewPlus}>
									<ScreenPlus />
								</IconButton>
							}
							{!disabledViewMinus &&
								<IconButton className={classes.iconBtnReset} disableRipple onClick={handleViewMinus} disabled={disabledViewMinus}>
									<ScreenMinus />
								</IconButton>
							}
						</Box>
					</Box>

					<Box className={classes.compareContent} ref={pdfBoxRef}
					// style={{alignItems:"center"}}
					>
						{/*todo 여러 파일 받을때는 files map 으로 변경*/}
						<Document
							file={submissionInfo && submissionInfo?.files && submissionInfo?.files.length > 0 ? submissionInfo?.files[0].downloadUrl : ""}
							onLoadError={loadErr}
							onLoadSuccess={loadSuccess}>
							{pages && pages.map((item) => {
								return (
									<Page key={`page${item}`} className={classes.page} pageNumber={item} renderTextLayer={false} renderAnnotationLayer={false} scale={viewScale} width={pdfWidth} />
								)
							})}
						</Document>
					</Box>
				</Box>
			}
		</>
	);
}

export default observer<typeof ResultReviewLeftPanel>(ResultReviewLeftPanel);

