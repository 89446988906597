import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'

export const SideBarStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {},
	bodyOpen: {
		position: 'relative',
		width: "241px",
		height: '100vh',
		background: '#025949',
	},
	bodyClose: {
		width: "61px",
		'& .MuiBox-root': {
			display: "flex",
			justifyContent: 'center',
		}
	},
	header: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer"
	},
	logoOpen: {
		color: "inherit",
		height: "25px",
		padding: "15px",
		marginLeft: "4px"
	},
	logoClose: {
		marginLeft: "0px"
	},
	headerTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			color: '#fff',
			cursor: 'pointer'
		}
	},
	control: {
		height: "50px",
		display: 'flex',
		justifyContent: 'right',
	},
	controlMain: {
		background: '#054030'
	},
	toggle: {
		transform: 'rotate(180deg)'
	},





	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			letterSpacing: '-0.5px'
		}
	},
	iconBtnList: {
		color: "inherit",
		height: "25px",
		padding: '15px',
	},
	iconBtnResetSideBar: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButtonMarginRight: {
		'&.MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center',
			padding: '10px 10px 10px 10px',
			margin: '0px 10px'
			// padding: '5px 16px',
		},
		'&.MuiListItemButton-root:hover': {
			// padding: '5px 16px',
			background: '#fff',
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},

	listButtonActive: {
		background: '#def8f5 !important',
		borderRadius: 3,
		'& .MuiTypography-root': {
			color: '#025949 !important'
		},
		'& svg': {
			'& path': {
				stroke: '#025949',
			}
		}
	},



	listButtonActiveKitty: {
		background: '#def8f5 !important',
		borderRadius: 3,
		'&.MuiListItemButton-root': {
			justifyContent: 'center',
			padding: '10px 10px 10px 10px',
			margin: '0px 10px'
			// padding: '5px 16px',
		},
		'&.MuiListItemButton-root:hover': {
			// padding: '5px 16px',
			background: '#fff',
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		},
		'& .MuiTypography-root': {
			color: '#025949 !important'
		},
		'& svg': {
			'& path': {
				stroke: '#025949',
			}
		}
	},

	listIconSideBar: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "240px",
			transition: (theme as Theme).transitions.create('width', {
				easing: (theme as Theme).transitions.easing.sharp,
				duration: (theme as Theme).transitions.duration.enteringScreen,
				// duration: 1000,
			}),
			height: 'calc(100vh - 0px)',
			// marginTop: "55px",
			background: '#025949',
			padding: '0px 0px 0px 0px'
		},
		'& .MuiListItemButton-root:hover': {
			background: 'rgb(222, 248, 245)',
			borderRadius: 3,
			padding: 10,
			'& .MuiTypography-root': {
				color: '#025949'
			},
			'& svg': {
				'& path': {
					stroke: '#025949',
					// fill: '#025949'
				}
			}
		},
		'& .MuiListItem-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItem-gutters': {
			paddingLeft: 0,
			paddingRight: 0,

		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.875rem',
			fontWeight: 500,
			letterSpacing: '-0.5px',
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			transition: (theme as Theme).transitions.create('width', {
				easing: (theme as Theme).transitions.easing.sharp,
				duration: (theme as Theme).transitions.duration.leavingScreen,
				// duration: 1000,
			}),
			height: 'calc(100vh - 0px)',
			// marginTop: "55px",
			background: '#025949'
		},
		'& .MuiButtonBase-root': {
			height: 42
		},
		'& .MuiListItemButton-root:hover': {
			background: '#DEF8F3',
			borderRadius: 2,
			'& svg': {
				'& path': {
					stroke: '#025949'
				}
			}
		},
		'& .MuiTypography-root': {
			display: 'none'
		}
	},

	toogleBox: {
		background: '#054030',
		display: 'flex',
		justifyContent: 'right',
		alignItems: 'center',
		minHeight: 50,
	},
	backListBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginRight: '95px',
		'& svg': {
			marginRight: "7px",
		}
	},
	topLogo: {
		color: "inherit",
		padding: "15px",
	},










	subSideMenuBoxOpen: {
		position: 'relative',
		height: '100vh',
		background: '#117C68',
		zIndex: 999,
		boxSizing: 'border-box',
		width: 241
	},
	subSideMenuBoxClose: {
		width: "61px",
		'& .MuiBox-root': {
			justifyContent: 'center',
			marginRight: "0px",
		}
	},
	subSideMenuList: {
		'& .MuiButtonBase-root': {
			borderRadius: 3,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'left',
			// width: '100%',
			height: "48px",
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 500,
				letterSpacing: '-0.5px',
				color: '#fff'
			},
			'&:hover': {
				background: '#F1F8F3',
				'& .MuiTypography-root': {
					color: '#008C73'
				},
				'& svg': {
					'& path': {
						// fill: '#025949'
						stroke: '#025949'
					}
				}
			}
		},
		'&.MuiListItem-gutters': {
			padding: '1px',
		}

	},

	/////////////////////
	padding: {
		padding: 15,
	},
	commonSubTitle: {
		marginBottom: "15px",
		textAlign: 'left',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "20px",
		}
	},
	schoolNameSubText: {
		color: '#555',
		fontSize: '1rem',
		letterSpacing: '-0.5px',
		marginBottom: "15px",
	},
	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		},
		'& .MuiInputBase-input': {
			fontSize: '0.875rem',
			color: '#787878',
			letterSpacing: '-0.7px'
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: 220
		},
	},




	greenBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: 'transparent',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem'
		}
	},
	pageNumber: {
		marginTop: "20px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentNameBtn: {
		'&.MuiButtonBase-root': {
			background: 'transparent',
			'& hover': {
				background: 'transparent',
			},
			'& .MuiTypography-root': {
				color: '#008C73',
				fontSize: '0.875rem',
				textDecoration: 'underline',
				letterSpacing: '-0.5px'
			}
		}

	},
	tableBox: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "50px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "50px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},
	iconAdd: {
		'& svg': {
			marginBottom: "2px",
			marginRight: "5px",
		}
	},

	stepOpen: {
		'&.MuiButtonBase-root': {
			border: '1px solid #111',
			width: "120px",
			boxSizing: 'border-box',
			background: '#fff',
			color: '#111',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.813rem',
				fontWeight: 'bold',
			}
		}
	},
	taskSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #E97C17',
			color: '#E97C17',
		}
	},
	taskMark: {
		'&.MuiButtonBase-root': {
			border: '1px solid #7730BA',
			color: '#7730BA',
		}
	},
	taskResult: {
		'&.MuiButtonBase-root': {
			border: '1px solid #3850D5',
			color: '#3850D5',
		}
	},



	numberBox: {
		width: "41px",
		height: "27px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 13,
		background: '#eee',
		boxSizing: 'border-box',
		marginRight: "11px",
		'& p': {
			fontSize: '0.875rem',
			color: '#777',
			fontWeight: 'bold',
			'& span': {
				color: '#38cb89',
				fontWeight: 'bold'
			}
		}
	},
	progressBox: {
		width: "80px",
		'& .MuiLinearProgress-root': {
			height: "6px",
			background: '#eee',
			borderRadius: 3
		},
		'& .MuiLinearProgress-bar': {
			background: '#38cb89'
		}
	},
	progressText: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			marginLeft: "11px",
			'@media all and (max-width: 1550px)': {
				fontSize: '0.75rem',
			},
		}
	},

	popoverBox: {
		'& .MuiPaper-root': {
			width: "120px",
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			border: '1px solid #bbb',
			borderRadius: 6,
			'& ul': {
				padding: '4px 0',
				'& li': {
					color: '#6f6f6f',
					letterSpacing: '-0.5px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiTypography-root': {
						marginLeft: "6px",
						fontSize: '0.875rem',
					},
					'&:hover': {
						background: '#eee'
					},
				},
			},
			'& *': {
				fontFamily: 'Pretendard-Regular !important',
			},
		}
	},
	deleteText: {
		'&.MuiTypography-root': {
			color: 'red'
		},
	},




	taskOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '90vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},

	dialogTop: {
		borderBottom: '1px solid #CBCBCB',
		height: "70px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			marginLeft: "20px",
		},
		'& .MuiIconButton-root': {
			marginRight: "20px",
		}
	},
	dialogContent: {
		padding: '0px 20px 30px 20px',
		overflowY: 'auto',
		marginBottom: "30px",
	},
	dialogHalf: {
		width: '50%',
	},
	dialogTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			marginBottom: "20px",
			marginTop: "30px",
			'& span': {
				fontSize: '1rem',
				fontWeight: 'normal',
				color: '#555'
			}
		}
	},
	dialogSubTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	dialogTime: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			fontWeight: 'bold',
			marginLeft: "40px",
			marginRight: 10.
		}
	},

	dialogBtnGroup: {
		marginTop: "30px",
		marginBottom: "30px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogYear: {
		position: 'relative',
		marginRight: "20px",
		'& .MuiFormControl-root': {
			width: '110px',
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},

	dateBox: {
		'& .MuiStack-root': {
			paddingTop: 0,
			// paddingBottom: 28,
			display: 'flex',
			alignItems: 'center',
			overflow: 'unset'
		},
		'& .MuiFormControl-root': {
			minWidth: '145px !important',
			maxWidth: '145px !important',
			margin: 0
		},
		'& .MuiInputBase-root': {
			paddingRight: 0,
			borderRadius: 6
		},
		'& .MuiInputBase-input': {
			height: "30px",
			boxSizing: 'border-box',
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			fontWeight: 500,
			padding: '0 11px'
		},
		'& .MuiInputAdornment-root': {
			marginLeft: "0px",
			'& .MuiButtonBase-root': {
				padding: 0,
				marginRight: "5px",
				'&:hover': {
					background: 'transparent'
				}
			},
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 'solid 1px #bbbbbb',
			borderRadius: 6
		},
	},
	calendarBox: {
		cursor: 'pointer',
		width: "23px",
		height: "23px",
		marginRight: 5
	},
	waveText: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			marginRight: "10px",
		}
	},

	subSideMenu: {
		'& .MuiStepIcon-root.MuiStepIcon-active': {
			color: '#025949',
		},
		'& .MuiStepIcon-root.MuiStepIcon-completed': {
			color: '#025949'
		},
		'& .MuiStepIcon-root': {
			color: '#DDDDDD'
		},
		'& .MuiStepConnector-vertical': {
			minHeight: '20px',
			marginLeft: '11px',
		},
		'& .MuiStepLabel-root': {
			cursor: "pointer"
		},
		'& .MuiStepper-vertical': {
			padding: '24px 18px 24px 18px'
		},
		'& .MuiStepLabel-label': {
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#fff',
		},
		'& .MuiStepLabel-labelContainer': {
			textAlign: 'left'
		},
		'& .MuiStepLabel-label.MuiStepLabel-active': {
			backgroundColor: '#DDDDDD',
			color: '#008C73',
			fontWeight: 600,
			letterSpacing: '-0.5px',
		},
		'& .MuiStepLabel-label.Mui-active': {
			color: '#fff',
		},
	},
	subSideMenu_fold: {
		'& .MuiStepper-vertical': {
			padding: '24px 18px 24px 18px'
		},
	},

	current: {
		padding: 5,
		borderRadius: 3,
		background: '#def8f5 !important',
		'& .MuiStepLabel-label.Mui-active': {
			color: '#025949'
		},
		'& .MuiStepLabel-label': {
			color: '#025949'
		},
	},

	step: {
		padding: 5,
		whiteSpace: 'nowrap',
		cursor: 'pointer',
	},



	nameSearchBtn: {
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#787878',
				marginLeft: "5px",
			}
		}
	},

	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& p': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			marginLeft: "10px",
		}
	},



	// kitty
	iconMenuToggle: {},

});












