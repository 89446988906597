import { MauiRelaySocket } from "./maui-ws-relay/maui-relay-websocket";

// export const AZURE_HWR_ENGINE_URL = "https://neotrans-premium.azurewebsites.net/api/ConverterIsolated?code=i1LXEWpDzAByn6SqpE232OcVWZn5XxLFqcHDzyQo_SZnAzFuyoM7zA==";

export const mauiDevEnv = {
  cs: undefined as unknown as MauiRelaySocket,
  developmentMode: false,
}


/**
 * App + wwwroot react = true
 * App + web socket client = false
 * web only = false
 * @returns
 */
export function isWebEnvironment() {
  // const devEnvUsingWebSocket = process.env.REACT_APP_FORCE_TO_USE_WEB_SOCKET === "true" ? true : false;

  const { DotNet, NeoDotNet } = (window as any);
  // const dotNetEnvironment = DotNet || NeoDotNet || devEnvUsingWebSocket;
  const dotNetEnvironment = DotNet || NeoDotNet;

  if (dotNetEnvironment) {
    return false
  }

  return true;
}


const forceToUseDotNet = process.env.REACT_APP_FORCE_TO_USE_DOTNET === "true" ? true : false;
const forceToUsebWebSocket = process.env.REACT_APP_FORCE_TO_USE_WEB_SOCKET === "true" ? true : false;

export async function isDotNetEnvironment() {

  const env1 = process.env.REACT_APP_FORCE_TO_USE_DOTNET;
  const env2 = process.env.REACT_APP_FORCE_TO_USE_WEB_SOCKET;


  // const forceToSustainWebSocket = process.env.REACT_APP_FORCE_TO_USE_WEB_CONFIG === "true";;
  const forceToSustainWebSocket = forceToUseDotNet;

  const { DotNet, NeoDotNet } = (window as any);
  const ret = !!DotNet || !!NeoDotNet;
  if (ret) { return true }

  if (!forceToUsebWebSocket) { return false }

  const socket = MauiRelaySocket.instance;
  const connected = await socket.isConnectedAfterWaiting();
  if (connected) { return true }

  if (forceToSustainWebSocket) {
    try {
      const connectResult = await socket.open();
      const connectedRetry = await socket.isConnectedAfterWaiting();
      if (connectedRetry) { return true }
    } catch (e) {
      console.error("WebSocket connection failed", e);
      return false;
    }

  }

  return forceToSustainWebSocket;
}

export function canUseAzure() {
  return true;
}
