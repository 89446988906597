import { IAiRubric } from "./model/IAiRubric";
import { IAiRubricTransfer } from "./model/transfer/IAiRubricTransfer";
import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class AiRubricRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	public async createAIRubric(params, language: string) {
		let locale = "ja-JP";
		switch (language) {
			case "ko":
				locale = "ko-KR";
				break;
			case "en":
				locale = "en-US";
				break;
			case "zh":
				locale = "zh-CN";
				break;
			default:
				locale = "ja-JP";
				break;
		}

		const data = await this.getRequestPromise<IAiRubric>("post", `${this.requestPrefix}/rubrics/ai?languageCode=${locale}`, params);
		return data;
	}

	public async getAIRubricByExamCodeAndQuestionNum(projectExamCode: string, projectExamQuestionNum: number) {
		const data = await this.getRequestPromise<IAiRubricTransfer[]>("get", `${this.requestPrefix}/exams/${projectExamCode}/questions/${projectExamQuestionNum}/rubrics/ai`);
		return data;
	}

	public async getAIRubricByRubricId(rubricId: string) {
		const data = await this.getRequestPromise<IAiRubricTransfer>("get", `${this.requestPrefix}/rubrics/${rubricId}/ai`);
		return data;
	}
}