import { NeoBtDeviceBase } from "../../../../nl-lib3-common/interfaces/neo-bt-device-base";
import { IDiscoveredDevices, IPenIdType, sleep } from "../../../../nl-lib3-common";
import { IMauiToJsBridge } from "./maui-to-js-interface";
import { MauiToJsEvent_onDeviceDisconnected, MauiToJsEvent_onDeviceDiscoveringUpdate, MauiToJsEvent_onDeviceNotification, MauiToJsEventName } from "./maui-to-js-types";
import PenManager from "../../nl-lib-pensdk/PenManager";
import { autoReconnectMauiPen } from "../../nl-lib-pensdk/pencomm/maui-pencomm/auto-reconnect-maui-pen";
import { autoReconnectAtRestartWebVars, initializeAutoReconnect } from "../../nl-lib-pensdk/pencomm/maui-pencomm/autoReconnectAtRestartWebVars";
import { IMauiPenCommManager } from "../../../../nl-lib3-common/interfaces/maui-pen-interface";


let mauiToJsBridge: IMauiToJsBridge;

export function setMauiToJsBridge(bridge: IMauiToJsBridge) {
  mauiToJsBridge = bridge;
}

//
// Native bluetooth proxy

let ins: MauiPenCommManager = null;

export default class MauiPenCommManager implements IMauiPenCommManager {
  private mauiPenComms: Map<IPenIdType, NeoBtDeviceBase> = new Map<IPenIdType, NeoBtDeviceBase>();

  private pendingData: Map<IPenIdType, Uint8Array[]> = new Map<IPenIdType, Uint8Array[]>();

  public getMauiPenComm(id: IPenIdType) {
    return this.mauiPenComms.get(id);
  }

  private setEventListeners() {
    // onDeviceDisconnected
    mauiToJsBridge.addEventListener(MauiToJsEventName.onDeviceDisconnected, async (event: MauiToJsEvent_onDeviceDisconnected) => {
      const { id } = event;
      this.onDeviceDisconnected(id);
    });


    // onDeviceNotification
    mauiToJsBridge.addEventListener(MauiToJsEventName.onDeviceNotification, async (event: MauiToJsEvent_onDeviceNotification) => {
      const { id, bytes, mac } = event;

      console.log(`onDeviceNotification, ${id}, ${bytes.length}`);
      this.onPacketReceived(id, bytes, mac);
    });

    // onDeviceDiscoveringUpdate
    mauiToJsBridge.addEventListener(MauiToJsEventName.onDeviceDiscoveringUpdate, async (event: MauiToJsEvent_onDeviceDiscoveringUpdate) => {
      const { devices } = event;
      this.onDeviceDiscoveringUpdate(devices);
    });


    // onReportDeviceScanningFinished
    mauiToJsBridge.addEventListener(MauiToJsEventName.onReportDeviceScanningFinished, async (event: MauiToJsEvent_onDeviceDiscoveringUpdate) => {
      const { devices } = event;
      this.onReportDeviceScanningFinished(devices);
    });
  }

  private constructor() {
    initializeAutoReconnect(this);
    this.setEventListeners();
  }


  public static get instance() {
    if (!ins) {
      ins = new MauiPenCommManager();
    }
    return ins;
  }

  public init() {
    console.log(`MauiPenCommManager inited`);
  }

  public addPenCommBase(id: IPenIdType, pencomm: NeoBtDeviceBase) {
    if (this.mauiPenComms.has(id)) {
      console.error(`MauiPenCommManager.addPenCommBase error, pen already exists for ID ${id}, ${pencomm.deviceId}`);
    }

    this.mauiPenComms.set(id, pencomm);
  }

  public onPacketReceived = async (id: IPenIdType, value: Uint8Array, mac: string) => {
    if (!this.mauiPenComms.has(id)) {
      console.log(`onPacketReceived: PenComm not found for ID ${id}, auto reconnect`);

      while (autoReconnectAtRestartWebVars.autoCreateMauiPenWorkingFlag) {
        await sleep(100);
      }

      await autoReconnectMauiPen(id, mac, value, PenManager.instance.getUserId());
      return;
    }

    const dv = new DataView(value.buffer);
    const pencomm = this.mauiPenComms.get(id);

    pencomm?.onPenPacketReceived({ target: { value: dv } });
  }

  public onDeviceDisconnected = (id: IPenIdType) => {
    if (!this.mauiPenComms.has(id)) {
      console.warn(`onDeviceDisconnected: PenComm not found for ID ${id}`);
      // throw new Error(`PenComm not found for ID ${id}`);
    }

    const pencomm = this.mauiPenComms.get(id);
    pencomm?.onDeviceDisconnected();

    // 2024-01-24 펜이 연결이 끊어지면, 관리되는 펜리스트에서 펜을 삭제한다.
    this.mauiPenComms.set(id, null);
    this.mauiPenComms.delete(id);
  }

  // eslint-disable-next-line class-methods-use-this
  public sendPacket(id: IPenIdType, bytes: ArrayBuffer) {
    console.log(id, bytes);
  }

  public onDeviceDiscoveringUpdate = (devices: IDiscoveredDevices[]) => {
    PenManager.getInstance().setDeviceDiscovered(devices);
  }


  public onReportDeviceScanningFinished = (devices: IDiscoveredDevices[]) => {
    PenManager.getInstance().onReportDeviceScanningFinished(devices);
  }
}
