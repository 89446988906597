export function convertBase64DotsToUint8Array(dots: string, dotCount: number) {
  if (!dots) return { dotBlob: new Uint8Array(0), dotPacketSize: 17, shiftDot: 1 };

  let dotBlob = new Uint8Array(0);
  const binary_string = window.atob(dots);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  dotBlob = bytes;


  // if (typeof dots === "string") {
  //   // console.log(`string type dots data`);
  //   const binary_string = window.atob(dots);
  //   const len = binary_string.length;
  //   const bytes = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   dotBlob = bytes;
  // } else {
  //   // console.log(`binary type dots data`);
  //   if (dots) { dotBlob = (dots as any).toUint8Array(); }
  // }

  let dotPacketSize = 0;
  let shiftDot = 0;

  if (dotCount > 0) {
    dotPacketSize = dotBlob.length / dotCount; // 16 or 17
    if (dotPacketSize !== 16 && dotPacketSize !== 17) {
      dotPacketSize = 0;
      console.error("invalid dot", dotBlob.length, dotCount, dotPacketSize);
    }

    if (dotPacketSize === 17) {
      shiftDot = 1;
    }
  }

  return { dotBlob, dotPacketSize, shiftDot };
}
