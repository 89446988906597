import { useMemo } from 'react';
import ProjectExamStore from '../../../../stores/ProjectExamStore';
import { IQuestionInfo } from '../../../../repositories/model/transfer/IQuestionInfo';
import { setQuestionHTMLWithOrderAndScore } from '../../../../util/setQuestionHTMLWithOrderAndScore';

export function useCurrentQuestionHTML(
	projectExamStore: ProjectExamStore,
	questionInfo: IQuestionInfo,
	currentOrder: string
): string {
	return useMemo(() => {
		return setQuestionHTMLWithOrderAndScore(
			questionInfo.question,
			questionInfo.score,
			questionInfo.reference,
			currentOrder
		);
	}, [projectExamStore, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);
}

// 사용 예시
// const currentQuestionHTML = useCurrentQuestionHTML(projectExamStore, questionInfo, currentOrder);
