import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from "@mui/material";
import { observer } from "mobx-react";
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import { ReactComponent as PageUpIcon } from '../../../../common/images/PageUpIcon.svg';
// import { withRouter } from "../../../../components/WithRouter";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ProjectPreviewStyle } from "../../styles/ProjectPreviewStyle";



import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../hooks/useStores';
import { ProjectSchoolType } from '../../../../stores/enums/ProjectStoreEnums';
import { AnswerTab } from '../../000_Common/002_Tabs/AnswerTab';
import { PlanTab } from '../../000_Common/002_Tabs/PlanTab';
import { QuestionAndReferenceTab } from '../../000_Common/002_Tabs/QuestionAndReferenceTab';
import { RubricTab } from '../../000_Common/002_Tabs/RubricTab';
import { useParams } from 'react-router-dom';

type Props = {
}

function ProjectPreviewRightPannel(props: Props) {
	const params = useParams();
	const classes = useEmotionStyles( ProjectPreviewStyle );
	const [tabIndex, setTabIndex] = React.useState(0);
	const [projectCode, setProjectCode] = React.useState("");
	const [open, setOpen] = React.useState(window.innerWidth > 1500);
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	const [isSelectingAll, setIsSelectingAll] = React.useState(false);
	const [isChangingTab, setIsChangingTab] = React.useState(false);
	const [didMount, setDidMount] = React.useState(true);

	const { navigateStore, projectStore, projectRagStore, projectExamStore, projectRubricStore } = useStores();
	const { project } = projectStore;
	const {
		projectExamQuestionsList,
		questionListLenObjPerExam,
		projectRubricTransferList,
		questionKeyAndSelectedProjectRubricIdxMap,
	} = projectRubricStore;

	const basicInfoRef = React.useRef(null);
	const questionRef = React.useRef(null);
	const goodAnswerRef = React.useRef(null);
	const rubricRef = React.useRef(null);

	let scrolloingTimeOut;

	React.useEffect(() => {
		const currentProjectCode = params?.projectCode;
		if (!currentProjectCode || currentProjectCode === projectCode) return;

		setProjectCode(currentProjectCode);
	}, [params]);

	React.useEffect(() => {
		setOpenByLocalStorage();

		return () => {
			clearTimeout(scrolloingTimeOut);
		};
	}, []);

	const setOpenByLocalStorage = () => {
		const isOpen = localStorage.getItem('previewState');
		if (isOpen === undefined || isOpen === null) {
			setOpen(window.innerWidth > 1500);
		} else {
			if (isOpen === 'true') {
				setOpen(true);
			} else if (isOpen === 'false') {
				setOpen(false);
			}
		}
	};

	const setTabIndexBySideBarStepNum = (sideBarStepNum) => {
		let tabIndex = 0;
		if (sideBarStepNum === 2 || sideBarStepNum === 3) tabIndex = 1;
		if (sideBarStepNum === 4) tabIndex = 2;
		if (sideBarStepNum === 5) tabIndex = 3;

		return tabIndex;
	};

	React.useEffect(() => {
		const sideBarStepNum = navigateStore.sideBarMakeProjectStepNum;
		if (!projectCode || sideBarStepNum === undefined) return;

		// 현재 페이지는 실시간 적용되도록 제외

		// 평가 계획
		if (sideBarStepNum !== 1) {
			projectStore.downloadProjectInfoAndStore(projectCode);
			projectStore.getProjectPlanTransfer(projectCode);
		}

		if (sideBarStepNum !== 2 && sideBarStepNum !== 4) {
			// 문항 생성 or 모범 답안
			projectExamStore.downloadProjectExamListAndStore(projectCode);
		}

		if (sideBarStepNum !== 3) {
			// 평가 참고 자료
			projectRagStore.getProjectRag(projectCode);
		}

		if (sideBarStepNum !== 5) {
			// 평가 기준표
			projectRubricStore.getProjectExamQuestions(projectCode);
		}

		setIsSelectingAll(true);

		const tabIndex = setTabIndexBySideBarStepNum(sideBarStepNum);
		setTabIndex(tabIndex);

		return () => {
			projectStore.init();
			projectExamStore.init();
			projectRagStore.init();
			projectRubricStore.init();
		}
	}, [projectCode, navigateStore.navigateDetailPage, navigateStore.sideBarMakeProjectStepNum]);

	React.useEffect(() => {
		if (!isSelectingAll) return;

		if (
			!projectStore.isSelectingProjectInfo &&
			!projectStore.isSelectingProjectPlanTransfer &&
			!projectExamStore.isSelectingProjectExamList &&
			!projectRagStore.isSelectingProjectRag &&
			!projectRubricStore.isSelectingProjectExamQuestions
		) {
			setIsSelectingAll(false);
			setIsChangingTab(true);
		}

	}, [
		isSelectingAll,
		projectStore.isSelectingProjectInfo,
		projectStore.isSelectingProjectPlanTransfer,
		projectExamStore.isSelectingProjectExamList,
		projectRagStore.isSelectingProjectRag,
		projectRubricStore.isSelectingProjectExamQuestions,
	]);

	React.useEffect(() => {
		if (!isChangingTab || !open) return;

		setIsChangingTab(false);
		setDidMount(false);

		const setTimeOutForTab = setTimeout(() => {
			handleChangeTab(null, true, tabIndex);
			clearTimeout(setTimeOutForTab);
		}, 50)
	}, [isChangingTab, open, tabIndex]);

	React.useEffect(() => {
		if (didMount || !open) return;

		const tabIndex = setTabIndexBySideBarStepNum(navigateStore.sideBarMakeProjectStepNum);

		handleChangeTab(null, true, tabIndex);
	}, [open]);

	const handleChangeTab = (event, isMounted, newIndex) => {
		let selectedTag;

		switch (newIndex) {
			case 0:
				if (basicInfoRef?.current) {
					selectedTag = basicInfoRef.current;
				}
				break;
			case 1:
				if (questionRef?.current) {
					selectedTag = questionRef.current;
				}
				break;
			case 2:
				if (goodAnswerRef?.current) {
					selectedTag = goodAnswerRef.current;
				}
				break;
			case 3:
				if (rubricRef?.current) {
					selectedTag = rubricRef.current;
				}
				break;
		}

		setTabIndex(newIndex);

		if (!selectedTag) return;

		if (!isMounted) {
			selectedTag.scrollIntoView({ behavior: 'smooth' });
			return;
		}

		scrolloingTimeOut = setTimeout(() => {
			selectedTag.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 500);
	};

	const handleClickScrollToTopBtn = () => {
		if (basicInfoRef.current) {
			basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const handleClickOpenBtn = () => {
		localStorage.setItem('previewState', `${!open}`);
		setOpen(!open);
		setTooltipOpen(false)
	};

	const currentSchoolLevelObj = React.useMemo(() => {
		return SchoolLevelSubjects.find(school => school.name === project?.schoolType);
	}, [project]);

	const currentSubjectGroupObj = React.useMemo(() => {
		if (!currentSchoolLevelObj) return;

		return currentSchoolLevelObj.subjectGroups.find(group => group.name === project?.subject);
	}, [project, currentSchoolLevelObj]);

	const currentSubjectObj = React.useMemo(() => {
		if (!currentSubjectGroupObj) return;

		return currentSubjectGroupObj.subjects.find(subject => subject.name === project?.subjectName);
	}, [project, currentSubjectGroupObj]);

	const i18next = useTranslation();

	return (
		<Box className={classes.previewRoot}>
			<Box className={open ? classes.openFoldBtnBox : classes.closedFoldBtnBox}>
				<Tooltip title={open ? i18next.t('접기') : i18next.t('펼치기')}
					open={tooltipOpen}
					classes={{ tooltip: classes.contentTooltip }}
					placement="bottom">
					<Button className={classes.foldBtn}
						onClick={handleClickOpenBtn}
						onMouseEnter={() => setTooltipOpen(true)}
						onMouseLeave={() => setTooltipOpen(false)}
						disableRipple>
						{open ?
							<ArrowForwardIosIcon /> :
							<ArrowBackIosNewIcon />
						}
					</Button>
				</Tooltip>
			</Box>
			{open &&
				<Box className={classes.mainContentBoxRight}>
					{(!didMount && !isSelectingAll) ?
						<Box>
							<Typography className={classes.previewText}>{i18next.t('미리보기')}</Typography>
							<Typography className={classes.previewSubText}>{i18next.t('과제 개요부터 입력한 내용을 한눈에 바로 확인할 수 있습니다.')}</Typography>
							<Tabs value={tabIndex} onChange={(e, newIdx) => handleChangeTab(e, false, newIdx)} className={classes.trigger}>
								<Tab value={0} label={i18next.t('과제 개요')} disableRipple />
								<Tab value={1} label={i18next.t('문항 및 참고 자료')} disableRipple />
								<Tab value={2} label={i18next.t('모범 답안')} disableRipple />
								<Tab value={3} label={i18next.t('평가 기준표')} disableRipple />
							</Tabs>

							<div className={classes.root}>
								<Box className={classes.scrollBox} style={{ paddingRight: 20 }}>

									<Box>
										<Box className={classes.commonSubTitleBasicBox} >
											<Typography className={classes.commonSubTitle} ref={basicInfoRef}>{i18next.t('과제 개요')}</Typography>
										</Box>

										<Box className={classes.listBoxTop}>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('교육과정 시기')}</Typography>
												<Typography className={classes.basicText2}>{project?.curriculumYear ? project.curriculumYear + i18next.t("년 개정") : "-"} </Typography>
											</Box>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('학교급')}</Typography>
												<Typography className={classes.basicText2}>
													{project.schoolType === ProjectSchoolType.ELEM ? i18next.t("초등학교") :
														project.schoolType === ProjectSchoolType.MIDD ? i18next.t("중학교") :
															project.schoolType === ProjectSchoolType.HIGH ? i18next.t("고등학교") : i18next.t("해당 없음")
													}
												</Typography>
											</Box>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('학년/교과/과목')}</Typography>
												<Typography className={classes.basicText2}>
													{project?.grade ? project?.grade + i18next.t("학년") : " - "} /
													{currentSubjectGroupObj?.text ? currentSubjectGroupObj?.text : " - "} /
													{currentSubjectObj?.text ? currentSubjectObj?.text : " - "}
												</Typography>
											</Box>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('단원명')}</Typography>
												<Typography className={classes.basicText2}>{`${project?.chapter || '-'}`}</Typography>
											</Box>
											<Box className={classes.displayStyle}>
												<Typography className={classes.basicTitle2}>{i18next.t('평가과제명')}</Typography>
												<Typography className={classes.basicText2}>{`${project.name || '-'}`}</Typography>
											</Box>
										</Box>
									</Box>

									<Typography className={classes.commonSubTitle}>{i18next.t('성취기준 및 평가기준')}</Typography>
									<PlanTab key={"planTab_Main"} />

									<Typography ref={questionRef} className={classes.commonSubTitle}>{i18next.t('문항 및 참고자료')}</Typography>
									<QuestionAndReferenceTab
										projectExamList={projectExamStore.projectExamList}

										// projectRagList={projectRagStore.projectRagList}
										sideBarStepNum={navigateStore.sideBarMakeProjectStepNum}
										onSetQuestionHTMLWithOrderAndScore={(question, score, reference, orderString) => { }}
										onClickScrollToTopBtn={handleClickScrollToTopBtn}
										questionRef={questionRef}
										handleClickQuestionEdit={() => { }}
										handleClickRagEdit={() => { }}
									/>

									<Typography ref={goodAnswerRef} className={classes.commonSubTitle}>{i18next.t('모범 답안')}</Typography>
									<AnswerTab projectExamList={projectExamStore.projectExamList} />
									<Typography ref={rubricRef} className={classes.commonSubTitle}>{i18next.t('평가 기준표')}</Typography>
									<RubricTab
										projectExamQuestionsList={projectExamQuestionsList}
										questionListLenObjPerExam={questionListLenObjPerExam}
										projectRubricTransferList={projectRubricTransferList}
										questionKeyAndSelectedProjectRubricIdxMap={questionKeyAndSelectedProjectRubricIdxMap}
										
									/>
								</Box>
							</div>
						</Box> :
						<Box className={classes.progressBox}>
							<CircularProgress className={classes.progress} />
						</Box>
					}

					<IconButton
						className={classes.pageUpIcon}
						onClick={handleClickScrollToTopBtn}
						disableRipple>
						<PageUpIcon />
					</IconButton>
				</Box>
			}
		</Box>
	);
}


export default observer<typeof ProjectPreviewRightPannel>(ProjectPreviewRightPannel);













