import clsx from 'clsx';
import React, { Component } from 'react';
import { Box, Typography } from "@mui/material";
import { Styles, withStyles } from "@mui/styles"

const styles: Styles<any, any> = {
	root: {},
	topBar: {
		background: '#fff',
		borderBottom: '1px solid #c0c2c3',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '30px 0',
		boxSizing: 'border-box',
	},
	contentsBox: {
		'@media all and (min-width: 1500px)': {
			width: "1440px",
		},
		width: "1200px",
		margin: '0 auto',
		paddingTop: 50,
		paddingBottom: 70,
	},
	titleText: {
		fontSize: '1.938rem',
		fontWeight: 'bold',
		marginBottom: "40px",
	},
	textStyle1: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
		marginBottom: "20px",
	},
	textStyle2: {
		fontSize: '1rem',
		marginBottom: "30px",
		textAlign: 'justify',
	},
	bold: {
		fontWeight: 'bold',
		marginBottom: "10px",
	},
	marginBottom: {
		marginBottom: "10px",
	},
	table: {
		'& table': {
			width: '100%',
			border: '1px solid #000',
			borderCollapse: 'collapse',
		},
		'& th, td': {
			border: '1px solid #000',
			padding: 10,
		},
	},
	alignCenter: {
		textAlign: 'center',
	},
};

type Props = {
	classes: any;
};

class PrivacyPolicy_ja extends Component<Props> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{/*<Box className={classes.topBar} id={'top'}>*/}
				{/*    <StudyLiveTopBarLogo />*/}
				{/*</Box>*/}
				<Box className={classes.contentsBox}>
					<Typography className={classes.titleText}>プライバシーポリシー</Typography>

					<Typography className={classes.textStyle1}>1. プライバシーポリシーとは？</Typography>
					<Typography className={classes.textStyle2}>
						アイグル（以下「サービス」）は、利用者の『同意に基づいて個人情報を収集・利用および提供』しており、『利用者の権利（個人情報
						自己決定権）を積極的に保障』します。
						<br />
						<br />
						サービスは情報通信サービス提供者が遵守しなければならない韓国の関係法令および個人情報保護規定、ガイドラインを遵守しています。
						「プライバシーポリシー」とは、利用者の貴重な個人情報を保護することによって、利用者が安心してサービスを利用できるように遵守すべき指針を
						意味します。本プライバシーポリシーは、サービスが提供するアイグルアカウントベースのサービスに適用されます。
					</Typography>

					<Typography className={classes.textStyle1}>2. 個人情報の収集</Typography>
					<Typography className={classes.textStyle2}>
						サービス提供のための必要最小限の個人情報を収集しています。
						<br />
						<br />
						会員登録時またはサービス利用過程でホームページや個別アプリケーションやプログラムなどを通じて、以下のようなサービス提供のために
						必要最小限の個人情報を収集しています。
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>
						[アイグルアカウントを外部アカウントを通じずに直接作成する場合]
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 必須：ID（メールアドレス）、パスワード、名前、メール（本人確認用）、登録日時
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 任意：生年月日、性別、国籍
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>
						[アイグルを外部アカウントを通じて登録し使用する場合]
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 必須：ID（メールアドレス）、名前、登録日時、プロフィール写真URL
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 任意：生年月日、性別、国籍
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						一部サービスでは、特化したさまざまな機能を提供するために、「アイグルアカウント」で共通に収集する情報以外に、利用者に同意を得て
						追加の個人情報を収集することがあります。
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 必須情報とは？：該当サービスの本質的な機能を遂行するための情報
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 任意情報とは？：より特化したサービスを提供するために追加収集する情報（任意情報を入力しなくてもサービス利用に制限はありません。）
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						個人情報を収集する方法は次のとおりです。
						<br />
						個人情報を収集する場合には必ず事前に利用者にその事実を知らせ、同意を得ており、以下のような方法で個人情報を収集します。
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 会員登録およびサービス利用過程で利用者が個人情報収集について同意し、直接情報を
						入力する場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 提携サービスや団体などから個人情報を提供された場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• カスタマーセンターを通じた相談過程でのウェブページ、メール、ファックス、電話など
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• オン・オフラインで行われるイベント/行事などへの参加
					</Typography>

					<Typography className={classes.textStyle2}>
						サービス利用過程で利用者から収集する個人情報は以下のとおりです。
						<br />
						<br />
						PCウェブ、モバイルウェブ/アプリ利用過程での端末情報（OS、画面サイズ、デバイスID、電話機種、端末モデル名）、IPアドレス、クッキー、訪問日時、
						不正利用記録、サービス利用記録、アカウント修正日時、ログインアプリ情報、アプリ登録日時、最も最近のログイン日時、ログイン回数、スマートペン
						デバイス情報（筆記データ、ファイル、デバイスMacアドレス、Inklink URLおよび時間、位置情報など）、使用したリソース情報（タイプ、使用量、
						数、使用日時）、データ同期ログ（データタイプ、データ固有番号、データをアップロードしたアプリ情報、同期結果、エラーログ、再試行回数、同期日時）
						などの情報が自動的に生成され収集されることがあります。
					</Typography>

					<Typography className={classes.textStyle1}>3. 個人情報の利用</Typography>

					<Typography className={classes.textStyle2}>
						会員管理、サービス提供・改善、新規サービス開発などのために利用します。会員登録時またはサービス利用過程でホームページや個別
						アプリケーションやプログラムなどを通じて以下のようにサービス提供のために必要最小限の個人情報を収集しています。
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 会員識別/登録意思確認、本人/年齢確認、不正利用防止
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 新規サービス開発、さまざまなサービス提供、問い合わせ事項や不満処理、告知事項の伝達
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• サービスの円滑な運営に支障を与える行為（アカウント盗用および不正利用行為などを含む）に対する防止および制裁
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• マーケティングに活用
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• サービス利用記録、接続頻度およびサービス利用に関する統計、プライバシー保護の観点からのサービス環境
						構築、サービス改善に活用
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• サービスアプリ（ネオスタジオ、ペンマネージャー、グリダボード、ペーパーチューブなど）の活用目的に利用されることがあります。
					</Typography>

					<Typography className={classes.textStyle1}>4. 個人情報の提供</Typography>

					<Typography className={classes.textStyle2}>
						サービスは利用者の別途の同意がある場合や法令で規定された場合を除き、利用者の個人情報を第三者に提供しません。
						第三者サービスとの連携のために、以下のように個人情報を提供しています。
						<br />
						<br />
						サービスは利用者の事前同意なしに個人情報を第三者に提供しません。ただし、利用者が外部提携先などのサービスを利用する
						ために必要な範囲内で利用者の同意を得た後に個人情報を第三者に提供しています。
					</Typography>
					<Box className={classes.table}>
						<table>
							<th>サービス名</th>
							<th>提供先</th>
							<th>提供目的</th>
							<th>提供する個人情報項目</th>
							<tr className={classes.alignCenter}>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
							</tr>
						</table>
					</Box>
					<Typography className={classes.textStyle2}>
						サービス提供のために以下のような業務を委託しています。
						<br />
						<br />
						サービス提供において必要な業務の一部を外部業者に委託し個人情報を提供しています。そして、委託された業者が
						関係法令を遵守するよう管理・監督しています。
					</Typography>

					<Box className={classes.table}>
						<table>
							<th>業者名</th>
							<th>委託業務目的</th>
							<tr className={classes.alignCenter}>
								<td>Google Inc</td>
								<td>クラウドプラットフォームサービス利用（サービス運営、データ保存・管理）</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>MyScript</td>
								<td>筆記認識エンジン利用</td>
							</tr>
						</table>
					</Box>
					<Typography className={classes.textStyle2}>個人情報処理委託中、国外で処理する委託業務は以下のとおりです。</Typography>

					<Box className={classes.table} mb={3}>
						<table>
							<th>受託業者</th>
							<th>情報管理責任者および連絡先</th>
							<th>移転目的</th>
							<th>移転される個人情報項目</th>
							<th>移転される国</th>
							<th>移転日時および方法</th>
							<th>個人情報利用期間</th>
							<tr className={classes.alignCenter}>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
								<td>N/A</td>
							</tr>
						</table>
					</Box>

					<Typography className={classes.textStyle1}>5. 個人情報の破棄</Typography>
					<Typography className={classes.textStyle2}>
						個人情報は収集および利用目的が達成され次第、遅滞なく破棄し、手続きおよび方法は以下のとおりです。
						<br />
						<br />
						電子的ファイル形式の場合、復旧および再生されないよう安全に削除し、それ以外の記録物、印刷物、書面などの場合は破砕または焼却して
						破棄します。
						<br />
						<br />このほか、法令に従い一定期間保存すべき個人情報および該当法令は以下の表のとおりです。
					</Typography>

					<Box className={classes.table} mb={3}>
						<table>
							<th>保存項目</th>
							<th>根拠法令</th>
							<th>保存期間</th>
							<tr className={classes.alignCenter}>
								<td>契約または契約解除に関する記録</td>
								<td rowSpan={4}>電子商取引などにおける消費者保護に関する法律</td>
								<td>5年</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>代金決済および物品などの供給に関する記録</td>
								<td>5年</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>消費者の苦情または紛争処理に関する記録</td>
								<td>3年</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>表示/広告に関する記録</td>
								<td>6ヶ月</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>税法が規定するすべての取引に関する帳簿および証憑書類</td>
								<td>国税基本法</td>
								<td>5年</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>電子金融取引に関する記録</td>
								<td>電子金融取引法</td>
								<td>5年</td>
							</tr>
							<tr className={classes.alignCenter}>
								<td>サービス訪問記録</td>
								<td>通信秘密保護法</td>
								<td>3ヶ月</td>
							</tr>
						</table>
					</Box>

					<Typography className={classes.textStyle1}>6. その他</Typography>
					<Typography className={classes.textStyle2}>
						サービスは皆様の権利を保護します。利用者はいつでも自身の個人情報を閲覧または修正することができ、収集・利用に対する同意
						撤回または登録解除を要請することができます。より具体的には、サービス内の設定を通じて会員情報修正機能や会員脱退機能を
						利用することができ、カスタマーセンターを通じて書面、電話またはメールで要請いただければ、遅滞なく対処いたします。個人情報の誤りに関する修正を
						要請された場合、修正を完了する前に該当個人情報を利用または提供しません。ウェブベースのサービス提供のためにクッキーを
						利用する場合があります。クッキーはより迅速かつ便利なウェブサイト使用をサポートし、カスタマイズされたサービスを提供するために使用されます。
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• クッキーとは？
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o ウェブサイトを運営する際に利用されるサーバーが利用者のブラウザに送信する非常に小さな
						テキストファイルであり、利用者のコンピュータに保存されます。
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 使用目的
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o パーソナライズされたサービスを提供するために利用者の情報を保存し、随時呼び出すために
						クッキーを使用します。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利用者がウェブサイトに訪問する場合、ウェブサイトサーバーは利用者の
						デバイスに保存されているクッキーの内容を読み取って利用者の環境設定を維持し、カスタマイズされたサービスを提供します。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;クッキーは利用者がウェブサイトを訪問する際、ウェブサイトの使用設定通りに接続し、
						便利に使用できるように支援します。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;また、利用者のウェブサイト訪問記録、利用形態を通じて
						最適化された広告などのカスタマイズ情報を提供するために活用されます。
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• クッキー収集拒否
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o クッキーには名前、電話番号など個人を識別する情報を保存せず、利用者は
						クッキー設置に対する選択権を持っています。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;したがって、利用者はウェブブラウザのオプションを設定することにより、すべての
						クッキーを許可するか、クッキーが保存されるたびに確認を経るか、すべてのクッキーの保存を拒否することができます。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ただし、クッキーの設置を拒否する場合、ウェブの使用が不便になり、ログインが
						必要な一部サービスの利用に困難が生じることがあります。設定方法の例
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Internet Explorerの場合：ウェブブラウザ上部のツールメニュー ＞ インターネットオプション ＞
						プライバシー ＞ 設定
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Chromeの場合：ウェブブラウザ右側の設定メニュー ＞ 画面下部の高度な設定表示 ＞
						プライバシーのコンテンツ設定ボタン ＞ クッキー
					</Typography>

					<Typography className={classes.textStyle2}>
						サービスは利用者の貴重な個人情報保護のために以下の努力をしています。利用者の個人情報を最も重要な価値と考え、
						個人情報を処理するにあたり、次のような努力を尽くしています。
						<br />
						<br />
						利用者の個人情報を暗号化しています。
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ハッキングやコンピュータウイルスなどにより利用者の個人情報が流出したり損傷されるのを防ぐために、
						外部からのアクセスが制御された区域にシステムを設置しています。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ハッカーなどの侵入を検知して遮断できるシステムを設置し、24時間監視しており、ウイルス対策プログラムを
						設置してシステムが最新の悪性コードやウイルスに感染しないように努めています。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;また、新しいハッキング/セキュリティ技術について継続的に研究しサービスに適用しています。
					</Typography>

					<Typography className={classes.textStyle2}>
						サービスはEU一般データ保護規則（General Data Protection Regulation）および各加盟国の法律を遵守します。
						<br />
						<br />
						EU内の利用者を対象にサービスを提供する場合、以下の内容が適用されることがあります。
					</Typography>
					<Typography className={clsx(classes.textStyle2, classes.bold)}>[個人情報処理の目的および処理根拠]</Typography>
					<Typography className={classes.textStyle2}>
						サービスは収集した個人情報を「3. 個人情報の利用」に記載された目的でのみ利用し、事前に利用者にその事実を知らせ同意を
						得ています。そして、GDPRなど適用される法律に基づき、サービスは以下のいずれかに該当する場合に利用者の個人情報を処理することができます。
					</Typography>
					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 情報主体の同意
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 情報主体との契約の締結および履行のための場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 法的義務事項遵守のための場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 情報主体の重大な利益のために処理が必要な場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• サービスの合法的な利益追求のための場合（情報主体の利益や権利または自由がその利益よりも重要な場合を除く）
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>[EU内の利用者の権利保障]</Typography>
					<Typography className={classes.textStyle2}>
						サービスは「皆様の権利を保護します」でお知らせしたとおり、利用者の個人情報を大切に保護します。GDPRなど適用される法律に
						基づき、利用者は自身の個人情報を他の管理者に移転してほしいと要求することができ、自身の情報処理拒否を要求することができます。
						そして利用者は個人情報保護権限当局に不満を申し立てる権利があります。また、サービスはイベントおよび広告などマーケティングを提供する
						ために個人情報を活用することができ、事前に利用者の同意を得ています。利用者は望まない場合いつでも同意を撤回することができます。
						これに関連する要請事項はカスタマーセンターを通じて書面、電話またはメールでご連絡いただければ、遅滞なく対処いたします。
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>個人情報保護に関してご不明な点は？</Typography>
					<Typography className={classes.textStyle2}>
						サービスを利用する中で発生するすべての個人情報保護に関する問い合わせ、不満、アドバイスやその他の事項は、個人情報保護責任者および
						担当部署にご連絡ください。サービスは皆様の声に耳を傾け、迅速かつ十分な回答を差し上げるために最善を尽くします。
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>個人情報保護責任者</Typography>

					<Typography className={classes.textStyle2} style={{ color: '#e8441e' }}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 責任者：キム・ジョンホ
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 所属：株式会社ネオラボコンバージョンス
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 電話：02-3462-2981
					</Typography>
					<Typography className={classes.textStyle2}>
						また、個人情報が侵害され、これに関する申告や相談が必要な場合には以下の機関にお問い合わせいただき、支援を受けることができます。
					</Typography>

					<Typography className={classes.textStyle2}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 個人情報侵害申告センター
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o （局番なし）118
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o https://privacy.kisa.or.kr
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 大検察庁サイバー捜査課
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o （局番なし）1301
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o cid@spo.go.kr
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 警察庁サイバー安全局
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o （局番なし）182
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o https://cyberbureau.police.go.kr
						<br />
					</Typography>

					<Typography className={clsx(classes.textStyle2, classes.bold)}>プライバシーポリシーが変更される場合</Typography>

					<Typography className={classes.textStyle2}>
						法律やサービスの変更事項を反映する目的などでプライバシーポリシーを修正することがあります。プライバシーポリシーが変更される場合、
						サービスは変更事項を掲示し、変更されたプライバシーポリシーは掲示した日から7日後に効力を発生します。
						<br />
						<br />
						サービスは利用者の皆様の情報を大切に考え、利用者がさらに安心してサービスを利用できるよう最善の努力を尽くすことを約束します。
					</Typography>
				</Box>
				{/*<Footer />*/}
			</div>
		);
	}
}

export default withStyles(styles)(PrivacyPolicy_ja);
