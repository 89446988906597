import { IPenIdType } from "../../../../../nl-lib3-common";
import { IAutoReconnectVars, IMauiPenCommManager } from "../../../../../nl-lib3-common/interfaces/maui-pen-interface";



export const autoReconnectAtRestartWebVars: IAutoReconnectVars = {
	autoCreateMauiPenWorking: new Map<IPenIdType, { promise: Promise<void>, interimBuf: Uint8Array }>(),

	autoCreateMauiPenWorkingFlag: false
}

export const mauiPenCommManagerConfig = {
	mauiPenCommManager: null as IMauiPenCommManager
}

export function initializeAutoReconnect(manager: IMauiPenCommManager) {
	mauiPenCommManagerConfig.mauiPenCommManager = manager;
}

