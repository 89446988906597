
export function getParams(func: string, data: string, isBase64Bytes: boolean) {

  if (isBase64Bytes) {
    console.log(`dotNetCommand, ${func}, BYTES`);
    const binary_string = window.atob(data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return [bytes];
  }

  const d = JSON.parse(data);
  console.log(`dotNetCommand Request, ${func}, ${d}`);

  switch (func) {
    case "Disconnect":
    case "InitDevlopmentSocket":
      return [d[0]];

    case "SetUuidConstants":
      return [...d];

    case "StartScan":
    case "StopScan":
    case "StartDiscovery":
    case "StopDiscovery":
      return [];

    case "Write":
      {
        const bytes = d[1];
        const arr = Array.from({ length: Object.keys(bytes).length }).map((v, i) => bytes[i]);
        const u8arr = new Uint8Array(arr);
        return [d[0], u8arr];
      }

    case "Connect":
      {
        const penId = d[0] as string;
				const shouldStopScanTask = d[1] as number;
        return [penId, shouldStopScanTask];
      }

    default:
      return [...d];
  }

  return d;
}

export function initWebSocketCommandProxy() {
  const w = (window as any);


}
