import { Box, Button, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginBorderLine } from "../../../../common/images/LoginBorderLine.svg";
import { ReactComponent as LogoWithTile } from "../../../../common/images/logo_and_title_Green_BG.svg";
// import { withRouter } from '../../../../components/WithRouter';
import { SignUpStyle } from '../../styles/SignUpStyle';
// import UserStore from '../../../../stores/UserStore';
import * as validation from "../../../../common/Validation";
import { removeSpacechar } from '../../../../common/Validation';
import useNaviCustom from '../../../../hooks/useNaviCustom';
import { useStores } from '../../../../hooks/useStores';
import useEmotionStyles from '../../styles/useEmotionStyles';
import { useNeoConfirm } from '../../../../hooks/useNeoConfirm';

type Props = {
};

const ForgotPassword = (props: Props) => {
	const classes = useEmotionStyles( SignUpStyle );
	const i18next = useTranslation();
	const [email, setEmail] = useState('');

	const { authStore, navigateStore } = useStores();
	const naviCustom = useNaviCustom();
    const {alert, confirm} = useNeoConfirm();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await authStore.requestPasswordReset(email, navigateStore.language);
			await alert(i18next.t('비밀번호 재설정 링크가 이메일로 전송되었습니다.'));

			naviCustom.go('/login');
		} catch (error) {
			await alert(i18next.t('비밀번호 재설정 요청에 실패했습니다. 다시 시도해주세요.'));
		}
	};

	// email 관련 부분
	const [isNullEmail, setIsNullEmail] = React.useState(true);
	const [isValidEmail, setIsValidEmail] = React.useState(false);
	const [isExistingEmail, setIsExistingEmail] = React.useState(true);
	const dupEmailCheckerTimer = React.useRef<number>(0);

	const changeSignUpEmailModule = (email: string) => {
		setEmail(email);
		const valid = validation.validateEmail(email);
		setIsValidEmail(valid);

		if (email === '') {
			setIsNullEmail(true);
			setIsValidEmail(false);
			console.log(`isEmailNull: ${isNullEmail}, isValidEmail: ${isValidEmail}`);
		} else {
			setIsNullEmail(false);
			setIsValidEmail(valid);
			console.log(`isEmailNull: ${isNullEmail}, isValidEmail: ${isValidEmail}`);
		}

		if (valid) {
			if (dupEmailCheckerTimer.current) {
				window.clearTimeout(dupEmailCheckerTimer.current);
				dupEmailCheckerTimer.current = 0;
			}
			dupEmailCheckerTimer.current = window.setTimeout(() => {
				checkExistEmail(email);
			}, 300);
		}
	}

	const changeSignUpEmail = (e) => {
		removeSpacechar(e);

		const email = e.target.value;
		changeSignUpEmailModule(email);
	};

	const checkExistEmail = async (email: string) => {
		// const exist = await userStore.checkEmailAccountExist(email);
		// setIsExistingEmail(exist);
	};

	console.log(`isNullEmail: ${isNullEmail}, isValidEmail: ${isValidEmail}, isExistingEmail: ${isExistingEmail}`);

	return (
		<Box className={classes.body} >

			<Box>
				<LogoWithTile className={classes.logo} />
			</Box>
			<Box className={classes.borderLine}>
				<LoginBorderLine />
			</Box>

			<div className={classes.root}>
				<Box className={classes.loginBox}>
					<Typography variant="h5" component="h1" gutterBottom>
						{i18next.t('비밀번호 재설정')}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{i18next.t('등록된 이메일 주소를 입력해주세요.')}
						<br />
						{i18next.t('비밀번호 재설정 링크를 보내드리겠습니다.')}
					</Typography>
					<form onSubmit={handleSubmit}>
						<TextField
							fullWidth
							margin="normal"
							label={i18next.t('이메일')}
							value={email}
							onChange={changeSignUpEmail}
							required
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.buttonStyle}

							disabled={
								isNullEmail
								|| !isValidEmail
								|| !isExistingEmail
							}
						>
							{i18next.t('재설정 링크 발송')}
						</Button>
					</form>
				</Box>

			</div>
		</Box>
	)
};

export default observer<typeof ForgotPassword>(ForgotPassword);
