import {
	Box
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TemplateProjectViewStyle } from "../../styles/TemplateProjectViewStyle";

// import { useTranslation } from 'react-i18next';
import React from "react";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";
//import { ISystemRubricConditionsToSearch } from "../../../../repositories/model/transfer/ISystemRubricConditionsToSearch";
import { ProjectRubricTable } from "./ProjectRubricTable";
import { useStores } from "../../../../hooks/useStores";
import { observer } from "mobx-react";

type Props = {
	questionInfo: IQuestionInfo,
	questionListLen: number,
	projectRubricList: IRubricInfo[],
	selectedProjectRubricIdx: number,
}

export const QuestionRow = observer((props: Props) => {
	const classes = useEmotionStyles( TemplateProjectViewStyle );
	// const i18next = useTranslation();
	const { questionInfo, questionListLen, projectRubricList, selectedProjectRubricIdx } = props;
	const { projectExamStore, projectRubricStore } = useStores();


	const currentOrder = React.useMemo(() => {
		if (questionListLen)
			return projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
	}, [questionListLen, questionInfo.parentOrder, questionInfo.order]);



	const currentProjectRubricList = React.useMemo(() => {
		return projectRubricList.filter(rubric => rubric.projectExamCode === questionInfo.projectExamCode && rubric.projectExamQuestionNum === questionInfo.projectExamQuestionNum);
	}, [projectRubricList, questionInfo.projectExamQuestionNum, questionInfo.projectExamCode]);

	const currentSelectedProjectRubricIdx = React.useMemo(() => {
		if (!selectedProjectRubricIdx) return 0;

		return selectedProjectRubricIdx;
	}, [selectedProjectRubricIdx]);

	const handleClickPublicRublicPage = (e, value) => {
		const idx = value - 1;
		projectRubricStore.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, idx);
	};

	return (
		<Box id={`question-${currentOrder}`} className={classes.rubricBox}>
			<Box className={classes.rubricTaskBox}>

				<Box className={clsx(classes.standardBox, classes.boxMarginBottom40px)}>
					<Box>
						{currentProjectRubricList.length > 0 &&
							currentProjectRubricList[currentSelectedProjectRubricIdx]?.items?.length > 0 &&
							<ProjectRubricTable
								rubricInfo={currentProjectRubricList[currentSelectedProjectRubricIdx]}
							/>
						}
						<Box className={clsx(classes.pageNumber, classes.pageNumberBorder)} display='flex' alignItems='center' justifyContent='center'>
							<Stack spacing={2}>
								<Pagination count={currentProjectRubricList.length}
									page={Number(currentSelectedProjectRubricIdx) + 1}
									boundaryCount={2}
									showFirstButton
									showLastButton
									onChange={handleClickPublicRublicPage}
									variant="outlined"
								/>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
});


