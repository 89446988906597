import {
	Box,
	Button,
	Typography
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../styles/CompareDataStyle";

import { useStores } from "../../../../../hooks/useStores";
import useNeoPen from "../../../../../ndp/hooks/useNeoPen";
import { codeToRecognizedTextPromise } from "../../../../../ndp/hooks/useStrokeRecognition";
import { ProjectExamType } from "../../../../../repositories/model/support/ProjectExamType";

import { CircularProgress } from "@mui/material";
import { makePdfWithStrokes } from "../../../../../ndp/NdpPdfPrinter";
import { IPageSOBP, NeoStroke } from "../../../../../neolab-libs/nl-lib3-common";
import { IProjectSubmissionTransfer } from "../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import NcodeIsNotAllocationDialog from "../OfflineSubmissions/PenDataUploaderDialog/NcodeIsNotAllocationDialog";
import OfflineDataUploadDialog, { RequestOfflineDataState } from "../OfflineSubmissions/PenDataUploaderDialog/OfflineDataUploadDialog";
import { useNeoConfirm } from "../../../../../hooks/useNeoConfirm";
import CompareOcrDataStudent from "./CompareOcrDataStudent";

let a = 0;
type Props = {
	handleClickClose: () => void;
	projectCode: string;
	templateProjectCode: string;
	submissionTransfer: IProjectSubmissionTransfer & { submissionIndex: number };
	noShowPenDialog: boolean,

	// batch upload에서 사용
	givenData?: { numPages: number; numStrokes: number; strokes: NeoStroke[]; }
	// submissionCode: string;
};

function CompareNeoPenData(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles(CompareDataStyle);
	const { projectCode, templateProjectCode, submissionTransfer, givenData } = props;
	const { navigateStore, projectStore, projectExamStore, neoPenStore, ncodeAllocationStore, authStore, projectSubmissionStore, projectSubmissionAnswerStore } = useStores();
	const { project } = projectStore;

	const [page, setPage] = React.useState(0);
	const [pages, setPages] = React.useState([]);
	const [pdfWidth, setPdfWidth] = React.useState(0);
	const [pdfWithStroke, setPdfWithStrokeBlobUrl] = React.useState(null);
	const [handwritingConfirmed, setHandwritingConfirmed] = React.useState(false);
	const [notAllocatedDlgState, setNotAllocatedDlgState] = React.useState<"none" | "notAllocated" | "confirmed" | "allocated">("none");
	const [requestOfflineDataState, setRequestOfflineDataState] = React.useState(RequestOfflineDataState.disconnected);
	const [openUploadDlg, setOpenUploadDlg] = React.useState(true);
	const [allocationRange, setAllocationRange] = React.useState<{ start: IPageSOBP, end: IPageSOBP }>(null);
	const [strokesDataReady, setStrokesDataReady] = React.useState(true);

	
	const [offlineStrokes, _setOfflineStrokes] = React.useState<NeoStroke[]>(null);
	const setOfflineStrokes = (val: NeoStroke[]) => {
		_setOfflineStrokes(val);

		console.log("CompareNeoPenData", projectCode, templateProjectCode, submissionTransfer);
	}

	React.useEffect(() => {
		setOfflineStrokes(givenData?.strokes || null);
	}, [givenData]);

	const pdfBoxRef = React.useRef<HTMLElement>();
	const { submissionCode, userCode } = submissionTransfer || {};

	const [submittedMaterial, setSubmittedMaterial] = React.useState<"pdf" | "stroke" | "none">("none");

	const { pdfBlobURL, pageCount, ncodeAllocation } = useNeoPen({
		project,
		projectStore,
		projectYear: project?.year,
		projectSemester: project?.semesterType,
		examList: projectExamStore.projectExamList,
		neoPenStore,
		ncodeAllocationStore,
		lang: navigateStore.language,
		authStore
	});


	const getProjectInformation = async () => {
		console.log(`getProjectInformation ${++a}`);
		const promises = [];

		if ((submissionTransfer?.files?.length || 0) > 0) {
			const strokeFiles = submissionTransfer.files.filter((item) => item.downloadUrl.endsWith(".zip") || item.contentsType.toUpperCase() === "STRK_ZIP");

			if (strokeFiles.length > 0) {
				setSubmittedMaterial("stroke");
				setStrokesDataReady(false);
				const pr5 = projectSubmissionStore.downloadStrokesFromServer(submissionTransfer)
					.then((strokes) => {
						setOfflineStrokes(strokes);
						setStrokesDataReady(true);
					})
					.catch((e) => {
						console.log(e);
						setStrokesDataReady(true);
					});
				promises.push(pr5);
			}
		}

		const pr1 = projectStore.downloadProjectInfoAndStore(projectCode); //기본정보 %필수정보
		promises.push(pr1);
		const pr2 = projectExamStore.downloadProjectExamListAndStore(projectCode); // 문항 생성 %필수 정보
		promises.push(pr2);

		if (submissionCode) {
			const pr3 = projectSubmissionStore.downloadSubmissionTransferAndStore(projectCode, submissionCode);
			promises.push(pr3);
			const pr4 = projectSubmissionAnswerStore.downloadSubmissionAnswersAndStore(submissionCode);
			promises.push(pr4);
		}

		await Promise.all(promises);
	}

	const loadErr = (e) => {
		console.log(i18next.t('문서를 로드하는 동안 오류가 발생했습니다!'), e.message);
	}

	const loadSuccess = (args: { numPages: number }) => {
		const { numPages } = args;

		let tempArr: number[] = [];
		for (let i = 0; i < numPages; i++) {
			tempArr.push(i + 1);
		}
		setPage(1);
		setPages(tempArr);
		setPdfWidth(pdfBoxRef?.current?.offsetWidth);
	}


	const handleResize = () => {
		setPdfWidth(pdfBoxRef.current?.offsetWidth);
	}

	const handleClickClose = async () => {
		neoPenStore.setSortedOfflineStrokes([]);
		Object.keys(codeToRecognizedTextPromise).forEach(key => delete codeToRecognizedTextPromise[key]);

		props.handleClickClose();
	}

	const { confirm } = useNeoConfirm();

	// 펜의 offline data를 업로드하고 펜 내의 데이터를 지우는 곳
	const confirmHandwritingData = async () => {
		const yesNo = await confirm(
			i18next.t('확인을 누르면 현재 보고 있는 데이터는 펜에서 완전히 삭제되고 다음의 처리로 진행됩니다. 계속하시겠습니까?'),
			i18next.t('필기 데이터 삭제 확인'),
		);

		if (!yesNo) return;

		const code = await projectSubmissionStore.uploadAnswerStrokes({
			projectCode,
			userCode,
			range: allocationRange,
			strokes: offlineStrokes,
			submissionCode
		});

		if (code) {
			await neoPenStore.removeOfflineData({ range: allocationRange });
			setHandwritingConfirmed(true);
		}
	}


	const handleCloseNotAllocatedDlg = () => {
		setNotAllocatedDlgState("confirmed");
		handleClickClose();

	}

	const retryUploadOfflinestroke = () => {
		setRequestOfflineDataState(RequestOfflineDataState.disconnected);
		setOpenUploadDlg(true);
	}

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
		// storeManager.changeStoreState(StoreStatus.PROGRESS);

		getProjectInformation()
			.then(() => {
				// storeManager.changeStoreState(StoreStatus.COMPLETED);
				navigateStore.changeWithoutBars(true);
			})
			.catch((error) => {
				// storeManager.changeStoreState(StoreStatus.COMPLETED);
			});


		return () => {
			window.removeEventListener('resize', handleResize);
			neoPenStore.disconnectPen();
			navigateStore.changeWithoutBars(false);
			projectSubmissionAnswerStore.initSubmissionAnswer();
		}
	}, []);



	React.useEffect(() => {
		if (pageCount === null) return;
		if (ncodeAllocation) {
			setNotAllocatedDlgState("allocated");
			const { section, startOwner, startBook, startPage, endOwner, endBook, endPage } = ncodeAllocation;
			const range: { start: IPageSOBP, end: IPageSOBP } = {
				start: { section, owner: startOwner, book: startBook, page: startPage },
				end: { section, owner: endOwner, book: endBook, page: endPage }
			};
			setAllocationRange(range);
		}
		else {
			setNotAllocatedDlgState("notAllocated");
		}
	}, [pageCount, ncodeAllocation]);


	// offline stroke가 offline data upload dialog에서 upload 되면
	React.useEffect(() => {
		const generatePdf = async (strokes: NeoStroke[], range: { start: IPageSOBP, end: IPageSOBP }) => {
			setStrokesDataReady(false);
			console.log("render", strokes, range);
			try {
				const pdfUrl = await makePdfWithStrokes({
					type: projectExamStore.projectExamList[0]?.type || ProjectExamType.DESCRIPTIVE,
					lang: navigateStore.language,
					ndpNcodeRange: {
						section: range.start.section,
						owner: range.start.owner,
						bookCode: range.start.book,
						pageStart: range.start.page,
						pageEnd: range.end.page
					},
					strokes,
					dpi: 120,
				});
				setPdfWithStrokeBlobUrl(pdfUrl);
				const blob = await fetch(pdfUrl).then(r => r.blob());
				// saveAs(new Blob([blob]), "test.pdf");
			} finally {
				setStrokesDataReady(true);
			}
		}

		if (ncodeAllocation && offlineStrokes) {
			const { section, startOwner, startBook, startPage, endOwner, endBook, endPage } = ncodeAllocation;
			const range: { start: IPageSOBP, end: IPageSOBP } = {
				start: { section, owner: startOwner, book: startBook, page: startPage },
				end: { section, owner: endOwner, book: endBook, page: endPage }
			};

			generatePdf(offlineStrokes, range);
		}

	}, [ncodeAllocation, offlineStrokes]);

	// const pdfFileUrl = submittedMaterial === "stroke" ? pdfWithStroke : pdfBlobURL || pdfWithStroke;

	const pdfFileUrl = pdfWithStroke || pdfBlobURL;

	return (
		<div className={classes.root}>
			<Box className={classes.padding} >
				<Box className={classes.mainContentBox}>
					<Box className={classes.newTabContent} display='flex' alignItems='center' justifyContent='space-between'>
						<Box className={classes.fileUploadBox} sx={{ marginRight: "10px" }}>
							<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.compareTop}>
								<Typography>{i18next.t('필기원본')}</Typography>
								<Box display='flex' alignItems='center'>
									{/* <Typography style={{ marginRight: "10px" }} className={classes.compareText}>{i18next.t('다른 학생의 필기입니까?')}</Typography> */}
									<Button className={classes.selectOtherStudentBtn} onClick={handleClickClose} disableRipple disabled={openUploadDlg || handwritingConfirmed}>
										<Typography>{i18next.t('다른 학생의 필기입니까?')}</Typography>
									</Button>
								</Box>


								<Box display='flex' alignItems='center'>
									{/* <Typography style={{ marginRight: "10px" }} className={classes.compareText}>{i18next.t('다른 학생의 필기입니까?')}</Typography> */}
									<Button
										className={classes.compareTopBtn}
										onClick={retryUploadOfflinestroke}
										disableRipple
										disabled={openUploadDlg || handwritingConfirmed}
									>
										<Typography>{i18next.t('다시 전송')}</Typography>
									</Button>
								</Box>


								<Box display='flex' alignItems='center'>
									{/* <Typography style={{ marginRight: "10px" }} className={classes.compareText}>{i18next.t('다른 학생의 필기입니까?')}</Typography> */}
									<Button className={classes.confirmHandwritingDataBtn} onClick={confirmHandwritingData} disableRipple disabled={!submissionTransfer ||
										(handwritingConfirmed ? true : false) || !strokesDataReady || !(pdfWithStroke || pdfBlobURL)}>
										<Typography>{i18next.t('필기 데이터 확인 완료 ▷▷▷')}</Typography>
									</Button>
								</Box>

							</Box>
							<Box className={classes.compareContent} ref={pdfBoxRef} style={{ alignItems: "center" }}>
								{(pdfFileUrl) &&
									<Document
										loading={<CircularProgress />}
										file={pdfFileUrl}
										onLoadError={loadErr}
										onLoadSuccess={loadSuccess}>
										{/*<Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} scale={1} loading/>*/}
										{pages && pages.map((item) => {
											return (
												<Page
													loading={<CircularProgress />}
													key={`page${item}`}
													className={classes.page}
													pageNumber={item}
													renderTextLayer={false}
													renderAnnotationLayer={false}
													scale={1}
													width={pdfWidth - 20}
												/>
											)
										})}
									</Document>
								}
							</Box>
						</Box>

						<CompareOcrDataStudent
							disabled={!handwritingConfirmed}
							handleClickClose={handleClickClose}
							projectCode={projectCode}
							templateProjectCode={templateProjectCode}
							submissionTransfer={submissionTransfer}
							pdfWithStroke={pdfWithStroke}
							ncodeRange={allocationRange}
							strokes={offlineStrokes}
						/>

					</Box>
				</Box>
			</Box>

			{
				notAllocatedDlgState === "notAllocated" &&
				<NcodeIsNotAllocationDialog
					project={project}
					submissionTransfer={submissionTransfer}
					onClose={handleCloseNotAllocatedDlg}
				/>
			}

			<OfflineDataUploadDialog
				numPages={pageCount}
				projectCode={projectCode}
				templateProjectCode={templateProjectCode}
				submissionTransfer={submissionTransfer}
				handleClickBaseDialogClose={handleClickClose}
				type={projectExamStore.projectExamList[0]?.type || ProjectExamType.DESCRIPTIVE}
				openUploadDlg={openUploadDlg && !props.noShowPenDialog}
				setOfflineStrokes={setOfflineStrokes}
				setOpenUploadDlg={setOpenUploadDlg}
				setRequestOfflineDataState={setRequestOfflineDataState}
				requestOfflineDataState={requestOfflineDataState}
				ncodeRange={allocationRange}
			/>

		</div >
	);
}


export default observer<typeof CompareNeoPenData>(CompareNeoPenData);
