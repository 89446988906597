import { Typography } from "@mui/material";
import React from "react";

type SignalStrengthProps = {
	rssi: number;
};

const SignalStrength: React.FC<SignalStrengthProps> = ({ rssi }) => {
	const getSignalLevel = (rssi: number): number => {
		if (rssi >= -50) return 5;
		if (rssi >= -70) return 4;
		if (rssi >= -76) return 3;
		if (rssi >= -80) return 2;
		return 1;
	};

	const level = getSignalLevel(rssi);

	return (
		<div >
			<div style={{ width: "30px", display: "flex", alignItems: "flex-end", height: "20px" }}>
				{[1, 2, 3, 4, 5].map((bar) => (
					<div
						key={bar}
						style={{
							width: "4px",
							height: `${bar * 4}px`, // 각 막대의 높이를 설정
							backgroundColor: bar <= level ? "#1976D2" : "#e0e0e0", // 활성화된 막대만 색상 지정
							marginRight: "2px",
						}}
					/>
				))}
			</div>
			<div style={{ width: "30px", display: "flex", alignItems: "flex-end" }}>
				<Typography sx={{ fontSize: "9px" }}>
					({rssi}dB)
				</Typography>
			</div >
		</div >
	);
};

export default SignalStrength;
