import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControlLabel,
	IconButton,
	InputBase,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as XLSX from "xlsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ResultExportListStyle } from "../../styles/ResultExportListStyle";

import clsx from "clsx";
import { ReactComponent as CheckCircleIcon } from '../../../../common/images/CheckCircleIcon.svg';
import { ReactComponent as CheckCircleIconOff } from '../../../../common/images/CheckCircleIconOff.svg';
import { ReactComponent as DownloagAll } from '../../../../common/images/DownloagAll.svg';
import { ReactComponent as SearchNameIcon } from '../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';

import { observer } from "mobx-react";
import { ReactComponent as EmptyUserBg } from '../../../../common/images/EmptyUserBg.svg';

// import 'dayjs/locale/ko';
import React from "react";

import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import i18n from "../../../../lang";

import { useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { SortingHintColumn } from "../../../../stores/support/SortingHintColumn";
import { Row } from "./Row";
import StudentResult from "./StudentResultPopUp/StudentResult";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";

type Props = {
};


function ResultExportList(props: Props) {
	const params = useParams();
	const { navigateStore, projectStore, projectSubmissionAnswerStore } = useStores();
	const classes = useEmotionStyles( ResultExportListStyle );
	const {
		projectCode,
		answerScoreList,
		answerScoreTotalList,
		answerScoreListConditionsToSearch
	} = projectSubmissionAnswerStore;

	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [sortStateMap, setSortStateMap] = React.useState({
		[SortingHintColumn.NAME]: { sort: false },
		[SortingHintColumn.EXPORTED_DATE_TIME]: { sort: false },
	});
	const [checkedOnlyExported, setCheckedOnlyExported] = React.useState(false);
	const [openResultDiolog, setOpenResultDiolog] = React.useState(false);
	const [resultSubmissionCode, setResultSubmissionCode] = React.useState("");
	const [isExportingResultTable, setIsExportingResultTable] = React.useState(false);


	const naviCustom = useNaviCustom();

	const {alert, confirm} = useNeoConfirm();


	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeSideBarStepNum(5);
		projectStore.downloadProjectInfoAndStore(params.projectCode);

		return () => {
			navigateStore.changeNavigateDetailPage(null);
			projectSubmissionAnswerStore.init();
			projectStore.initProject();
		}
	}, []);

	React.useEffect(() => {
		const projectCode = params.projectCode;
		if (!projectCode || projectCode === projectSubmissionAnswerStore.projectCode) return;

		projectSubmissionAnswerStore.setProjectCode(projectCode);
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchStateType(ProjectStateType.SCORED);
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
		projectSubmissionAnswerStore.getAnswerScoreTotalList(projectCode);
	}, [params]);

	const currentAnswerScoreListConditionsToSearch = React.useMemo(() => {
		const list = answerScoreList;
		const condition = answerScoreListConditionsToSearch;

		return list?.length > 0
			? condition?.totalCount > condition.rowsPerPage
				? Math.ceil(condition?.totalCount / condition.rowsPerPage)
				: 1
			: 0
	}, [answerScoreList, answerScoreListConditionsToSearch]);

	const incompleteExportingCount = React.useMemo(() => {
		const totalCount = answerScoreListConditionsToSearch.totalCount;
		const checkCount = answerScoreListConditionsToSearch.checkCount;
		if (checkCount > totalCount) return 0;

		return totalCount - checkCount;
	}, [answerScoreListConditionsToSearch.checkCount, answerScoreListConditionsToSearch.totalCount]);

	const rowStartNum = React.useMemo(() => {
		const page = answerScoreListConditionsToSearch.page;
		const rowsPerPage = answerScoreListConditionsToSearch.rowsPerPage;

		return page * rowsPerPage + 1;
	}, [answerScoreListConditionsToSearch.page, answerScoreListConditionsToSearch.rowsPerPage]);

	const handleCheckOnlySent = (e) => {
		const checked = e.target.checked;

		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchExcept(checked ? ProjectStateType.SCORED : "");
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchStateType(ProjectStateType.SCORED);
		projectSubmissionAnswerStore.getAnswerScoreList(projectCode);
		setCheckedOnlyExported(checked);
	};

	const doSearchKeyword = () => {
		projectSubmissionAnswerStore.getAnswerScoreList(projectSubmissionAnswerStore.projectCode);
	};

	const handleKeyDownInSearchInput = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			doSearchKeyword();
		}
	};

	const setGradeClassName = (item) => {
		if (!item.grade || !item.className) {
			return '-';
		}

		return `${item.grade}${i18n.t('학년')} ${item.className}${i18n.t('반')}`
	};

	const setCurrentExportedDatetime = (item) => {
		if (item.exportedDatetime) {
			return convertUTCToKST(item.exportedDatetime).format("YYYY.MM.DD HH:mm");
		}

		return '-';
	};

	const setScore = (item) => {
		if (!item?.scoredScore || !item?.totalScore) {
			return '-';
		}

		return `${item.scoredScore} / ${item.totalScore}`;
	};

	const setIsExported = (item) => {
		if (!item?.state) {
			return '-';
		}

		return item.state === ProjectStateType.EXPORTED ? "Y" : "N";
	};

	const exportResultList = () => {
		if (answerScoreTotalList.length === 0) return;

		try {
			setIsExportingResultTable(true);

			let dataList = [];

			answerScoreTotalList.map((item, idx) => {
				const scoreObj = {
					"No.": idx + 1,
					[i18n.t("이름")]: item?.userName || '',
					[i18n.t("학년 / 반")]: setGradeClassName(item),
					[i18n.t("채점 점수")]: setScore(item),
					[i18n.t("발송 여부")]: setIsExported(item),
					[i18n.t("발송일시")]: setCurrentExportedDatetime(item)
				};

				dataList.push(scoreObj);
			});

			const workSheet = XLSX.utils.json_to_sheet(dataList, { skipHeader: false });
			const workBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workBook, workSheet, i18n.t("결과_내보내기_목록"));

			XLSX.writeFile(workBook, i18n.t("결과_내보내기_목록.xls"), { bookType: 'xls', type: 'binary' });

			setIsExportingResultTable(false);
		} catch (e) {
			console.log("Cannot exportResultList ...", e);
			alert(i18n.t("결과 목록 다운로드를 실패했습니다."));

			setIsExportingResultTable(false);
		}
	};

	const handleClickResultListDownload = async () => {
		const result = await confirm(i18n.t("결과 목록을 다운로드하시겠습니까?"));
		if (!result) return;

		exportResultList();
	};

	const handleChangeSearchKeyword = (e) => {
		const value = e.target.value;
		console.log("value=", value);

		setSearchKeyword(value);
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchKeyword(value.trim());
	};

	const handleClickKeywordSearch = () => {
		doSearchKeyword();
	};

	const handleClickSort = (columnName) => {
		const sortState = sortStateMap[columnName];
		if (sortState) {
			sortState.sort = !sortState.sort;
			setSortStateMap({
				...sortStateMap,
				[columnName]: sortState,
			});

			projectSubmissionAnswerStore.changeSortingHint(columnName, sortState.sort);
		}
	};

	const handleClickViewProjectBtn = () => {
		naviCustom.go(`/projects/${projectCode}/create/view`);
		// props.navigate(`/projects/${projectCode}/create/view`);
	};

	const handleClickPage = (e, value) => {
		const page = value - 1;
		projectSubmissionAnswerStore.changeAnswerScoreListConditionsToSearchPage(page);
	};

	const exportedCount = React.useMemo(() => {
		if (checkedOnlyExported) {
			return answerScoreList.length;
		}

		return answerScoreListConditionsToSearch.checkCount;
	}, [checkedOnlyExported, answerScoreList, answerScoreListConditionsToSearch.checkCount]);

	const handleOpenResultDialog = (submissionCode) => {
		setResultSubmissionCode(submissionCode);
		setOpenResultDiolog(true);
	};

	const handleCloseResultDialog = () => {
		setResultSubmissionCode("");
		setOpenResultDiolog(false);
		projectSubmissionAnswerStore.getAnswerScoreList(projectSubmissionAnswerStore.projectCode);
	};

	const disableToExportResultTable = React.useMemo(() => {
		return answerScoreTotalList.length === 0;
	}, [answerScoreTotalList]);

	return (
		<div className={classes.root}>

			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Typography className={classes.commonSubTitle}>{i18n.t('결과 내보내기')}</Typography>
							<Box>
								<Button className={disableToExportResultTable ? clsx(classes.downloadAllBtn, classes.disabledDownloadAllBtn) : classes.downloadAllBtn} disableRipple
									disabled={disableToExportResultTable}
									onClick={handleClickResultListDownload}>
									<DownloagAll className={disableToExportResultTable ? classes.disabledDownloadSvg : ""} />
									<Typography>{i18n.t('결과 목록 다운로드')}</Typography>
								</Button>
							</Box>
						</Box>
						{/*<Box display='flex' alignItems='center' justifyContent='space-between'>*/}
						{/*    <Typography className={classes.commonSubTitle}>결과 내보내기</Typography>*/}
						{/*    <Box>*/}
						{/*        <Button className={classes.downloadAllBtn} disableRipple*/}
						{/*          // disabled={true}*/}
						{/*                onClick={handleClickResultBatchDownload}>*/}
						{/*            <DownloagAll />*/}
						{/*            <Typography>결과지 일괄 다운로드</Typography>*/}
						{/*        </Button>*/}
						{/*    </Box>*/}
						{/*</Box>*/}

						<Typography className={classes.commonSubmit}>{i18n.t("채점 결과")}(<span>{answerScoreListConditionsToSearch.totalCount}</span>)</Typography>
						<Typography className={classes.submitSubText}>{i18n.t("채점 완료된 학생에게 채점 결과를 발송하세요.")}</Typography>
					</Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "25px" }}>
						<Box display='flex' alignItems='center' justifyContent='space-between' >
							<Box sx={{ marginRight: "20px" }}>
								<FormControlLabel
									control={
										<Checkbox
											icon={<CheckCircleIconOff />}
											checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}
											disableRipple
										/>
									}
									onChange={handleCheckOnlySent}
									label={i18n.t("발송 완료만")}
									className={classes.checkMargin}
								/>
							</Box>

							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}
							>
								<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18n.t("이름 검색")}
									value={searchKeyword}
									onKeyDown={handleKeyDownInSearchInput}
									onChange={handleChangeSearchKeyword}
									inputProps={{ 'aria-label': i18n.t('이름 검색') }}
								/>
							</Paper>
						</Box>
						<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.submitScanText}>
							<Typography>{i18n.t("발송 완료")} <span>{answerScoreListConditionsToSearch.checkCount}</span></Typography>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='10%' align="center">{i18n.t("No.")}</TableCell>
										<TableCell width='18%'>
											<Box className={classes.sortButton} style={{ cursor: 'pointer' }} onClick={() => handleClickSort(SortingHintColumn.NAME)} >
												<Typography>{i18n.t("이름")}</Typography>
												<IconButton
													className={classes.iconBtnReset} style={{ transform: sortStateMap[SortingHintColumn.NAME].sort ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
													<TableListOrder />
												</IconButton>
											</Box>
										</TableCell>
										<TableCell width='18%'>{i18n.t("학년/반")}</TableCell>
										{/*<TableCell width='16%'>채점 결과</TableCell>*/}
										<TableCell width='18%'>{i18n.t("채점 점수")}</TableCell>
										<TableCell width='18%'>
											<Box className={classes.sortButton} style={{ cursor: 'default' }}>
												<Typography>{i18n.t("발송일시")}</Typography>
												{/*<IconButton onClick={() => handleClickSort(SortingHintColumn.EXPORTED_DATE_TIME)} className={classes.iconBtnReset} style={{ transform: sortStateMap[SortingHintColumn.EXPORTED_DATE_TIME].sort ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>*/}
												{/*    <TableListOrder/>*/}
												{/*</IconButton>*/}
											</Box>
										</TableCell>
										<TableCell width='18%' align="center">{i18n.t("리포트")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>

									{answerScoreList.length > 0 &&
										answerScoreList.map((item, idx) =>
											<Row
												key={idx}
												idx={idx}
												item={item}
												rowStartNum={rowStartNum}
												projectCode={projectCode}
												handleOpenResultDialog={handleOpenResultDialog}
											/>
										)}
								</TableBody>
							</Table>
						</TableContainer>

						{answerScoreList.length === 0 &&
							<Box className={classes.emptyTableData} display='flex' flexDirection='column' alignItems='center' justifyContent='center' >
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: '500px' }}>
									<EmptyUserBg />
									<Typography className={classes.emptyBgTitle}>{i18n.t("대상이 없습니다.")}</Typography>
									{/*<Typography className={classes.emptyBgText}>‘과제 보기' 메뉴에서 생성한 과제 확인 후 대상을 추가하여 출제해 보세요.</Typography>*/}
									{/*<Box>*/}
									{/*    <Button className={classes.emptyViewBtn} disableRipple onClick={handleClickViewProjectBtn}>*/}
									{/*        <Typography>과제 보기</Typography>*/}
									{/*    </Button>*/}
									{/*</Box>*/}
								</Box>
							</Box>
						}

					</Box>
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination
								count={currentAnswerScoreListConditionsToSearch}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>

			{openResultDiolog &&
				<Dialog className={classes.fullDialog} fullScreen open={openResultDiolog}>
					<StudentResult
						projectCode={projectSubmissionAnswerStore.projectCode}
						submissionCode={resultSubmissionCode}
						handleClickClose={handleCloseResultDialog}
					/>
				</Dialog>
			}

			{projectSubmissionAnswerStore.isSelectingAnswerScoreList &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingAnswerScoreList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18n.t('조회 중입니다...')}</Typography>
				</Backdrop>
			}

			{isExportingResultTable &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={isExportingResultTable}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18n.t('작업 중입니다...')}</Typography>
				</Backdrop>
			}
		</div>
	);
}

export default observer<typeof ResultExportList>(ResultExportList);
