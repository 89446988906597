import LoginBgImg from "../../../common/images/LoginBgImg.svg";
import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const SignUpStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	body: {
		height: '100vh',
		background: '#008C73',
		backgroundImage: `url(${LoginBgImg})`,

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	root: {
		width: "522px",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#fff',
		borderRadius: 16,
		margin: '0 auto 0',
	},
	iconButton: {
		padding: 0,
		'&:hover': {
			background: 'transparent',
		},
	},
	textButton: {
		fontSize: '0.875rem',
		color: '#333',
		marginBottom: "15px",
		marginTop: "45px",
	},
	underline: {
		fontWeight: '600',
		textDecoration: 'underline',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	// loginBox: {
	//     display: 'flex',
	//     flexDirection: 'column',
	//     border: '1px solid #e5e6e8',
	//     padding: '50px 90px 30px',
	//     boxSizing: 'border-box',
	//     borderRadius: 12,
	// },
	loginBox: {
		width: "100%",
		background: '#fff',
		borderRadius: 16,
		boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)',
		padding: '40px 42px',
		boxSizing: 'border-box',
	},
	loginText: {
		fontSize: '1.75rem',
		color: '#333',
		textAlign: 'center',
		marginBottom: "12px",
	},
	textField: {
		marginTop: "20px",
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
		{
			border: '1px solid #d9dbde',
		},
		'& .MuiOutlinedInput-root': {
			width: "340px",
			borderRadius: 7,
			'&:hover': {
				borderColor: '#d9dbde',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '10px 0px',
			color: '#333',
			fontSize: '1rem',
			'&::placeholder': {
				color: '#9d9d9d',
				opacity: 1,
			},
		},
		'& .MuiInputBase-root div:first-child::after': {
			display: 'block',
			content: "'*'",
			color: 'red',
			fontSize: 16,
			marginBottom: "8px",
		},
	},

	errorTextField: {
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
		{
			border: '1px solid #ff0000',
		},
	},
	searchIconButton: {
		padding: 6,
		backgroundColor: '#f8f8f8',
		'&:hover': {
			backgroundColor: '#f8f8f8',
		},
	},
	errorText: {
		fontSize: '0.813rem',
		color: '#ff0000',
		margin: '4px 0 0',
	},
	checkBoxMargin: {
		marginTop: "30px",
	},
	buttonStyle: {
		background: '#0097ff',
		color: '#fff',
		fontSize: '1.125rem',
		padding: '15px 0',
		borderRadius: 7,
		'&:hover': {
			background: '#0097ff',
		},
		'&.Mui-disabled': {
			background: '#c2c2c2',
			color: '#fff',
		},
	},
	lineStyle: {
		width: '100%',
		height: "1px",
		borderBottom: '1px dashed #dddddd',
		margin: '30px 0',
	},
	checkBoxIcon: {},
	checkedListBoxIn: {
		'& .MuiCheckbox-colorSecondary:hover': {
			'&:hover': {
				background: 'transparent',
			},
		},
		'& label': {
			fontSize: '0.875rem',
			color: '#333',
		},
	},
	allCheckedBox: {
		'& .MuiCheckbox-colorSecondary:hover': {
			'&:hover': {
				background: 'transparent',
			},
		},
		'& label': {
			fontSize: '0.938rem',
			fontWeight: 600,
			color: '#000',
		},
	},
	checkedViewButton: {
		fontSize: '0.875rem',
		color: '#9a9a9a',
		textDecoration: 'underline',
		padding: 0,
		'&:hover': {
			background: 'transparent',
			textDecoration: 'underline',
		},
	},
	infoBox: {
		width: '100%',
		height: "115px",
		borderRadius: 5,
		boxSizing: 'border-box',
		padding: '14px 17px',
		background: 'rgba(242, 245, 248, 0.5)',
		display: 'flex',
		marginTop: "14px",
		'& p': {
			fontSize: '0.875rem',
			color: '#333',
			letterSpacing: '-0.2px',
			lineHeight: 1.4,
			'& span': {
				fontWeight: 'bold',
			},
			'& a': {
				color: '#0097ff',
			},
			'&:last-child': {
				marginTop: "8px",
			},
		},
		'& svg': {
			marginRight: "4px",
		},
	},
	checkedMargin: {
		marginTop: "15px",
	},
	checkedText: {
		fontSize: '0.875rem',
		color: '#9d9d9d',
		marginLeft: "10px",
	},
	checkedOnText: {
		color: '#0097ff',
	},
	checkedErrorText: {
		color: '#ff0000',
	},

	logo: {
		width: "200px",
		height: "52px"
	},
	borderLine: {
		marginTop: "17px",
		marginBottom: "37px",
	},


	// kitty
	listBox: {
		borderBottom: '1px solid #cbcbcb',
		// padding: '26px 0px',
		paddingBottom: "20px",
		marginBottom: "20px",
	},
	listBoxTitle: {
		marginBottom: "0px",
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px',
			lineHeight: 'normal',
			'& span': {
				fontWeight: 400,
				color: '#555'
			}
		},
	},
	textFieldBox: {
		display: 'flex',
		alignItems: 'center',
	},

	dialogInputLastNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "0px",
			marginRight: "5px",
			width: "60px",
			textAlign: 'right'
		}
	},

	dialogInputLastName: {
		'&.MuiFormControl-root': {
			width: '120px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'

		}
	},


	dialogInputFirstNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "5px",
			width: "60px",
			textAlign: 'right'
		}
	},
	dialogInputFirstName: {
		'&.MuiFormControl-root': {
			width: '120px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'
		}
	},



	dialogInputFullNameTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			fontWeight: 400,
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			fontSize: '1rem',
			marginLeft: "5px",
			marginRight: "5px",
			width: "80px",
			textAlign: 'right'
		}
	},

	dialogInputFullName: {
		'&.MuiFormControl-root': {
			width: '260px',
		},
		'& .MuiInputBase-input': {
			textAlign: 'center'
		}
	},

	dialogInput: {
		'&.MuiFormControl-root': {
			width: "429px",
			height: "36px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'&.MuiOutlinedInput-root': {
			height: "36px",
			borderRadius: 2,
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px',
		},
		'& .MuiInputBase-input': {
			fontSize: '1rem',
			color: '#787878',
			fontWeight: 400,
			letterSpacing: '-0.7px'
		},
		'& .MuiInputBase-root': {
			borderRadius: 3,
		},
	},

	formWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},

	formContainer: {
		width: "340px",
		borderRadius: 7,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		padding: theme.spacing(1),
	},
	nameRow: {
		display: 'flex',
		gap: theme.spacing(2),
		alignItems: 'center',
		width: '100%',
	},
	lastNameField: {
		flex: 1,
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
		{
			border: '1px solid #d9dbde',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 7,
			'&:hover': {
				borderColor: '#d9dbde',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '13px 0px',
			color: '#333',
			fontSize: '1rem',
			'&::placeholder': {
				color: '#9d9d9d',
				opacity: 1,
			},
		},
		'& .MuiInputBase-root div:first-child::after': {
			display: 'block',
			content: "'*'",
			color: 'red',
			fontSize: 16,
			marginBottom: "8px",
		},

	},
	firstNameField: {
		flex: 1,
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
		{
			border: '1px solid #d9dbde',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 7,
			'&:hover': {
				borderColor: '#d9dbde',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '13px 0px',
			color: '#333',
			fontSize: '1rem',
			'&::placeholder': {
				color: '#9d9d9d',
				opacity: 1,
			},
		},
		'& .MuiInputBase-root div:first-child::after': {
			display: 'block',
			content: "'*'",
			color: 'red',
			fontSize: 16,
			marginBottom: "8px",
		},
	},
	fullNameField: {
		width: '100%',
	},
	input: {
		height: '40px',
	},

	nameLabel: {
		alignSelf: 'flex-start',
		margin: "0px",
		fontWeight: 'bold',

	},

	submitButton: {marginTop: "20px"},
});












