export enum PacketConst {
  PK_STX = 0xC0,
  PK_ETX = 0xC1,
  PK_DLE = 0x7D,

  PK_POS_CMD = 1,
  PK_POS_RESULT = 2,
  PK_POS_LENG1 = 2,
  PK_POS_LENG2 = 3,

  PK_HEADER_SIZE = 3,
}

export enum SettingType {
  Timestamp = 1,
  AutoPowerOffTime = 2,
  PenCapOff = 3,
  AutoPowerOn = 4,
  Beep = 5,
  Hover = 6,
  OfflineData = 7,
  LedColor = 8,
  Sensitivity = 9,
  UsbMode = 10,
  DownSampling = 11,
  BtLocalName = 12,
  FscSensitivity = 13,
  DataTransmissionType = 14,
  BeepAndLight = 16,
  SetPenStatus = 17,
}


export enum Cmd {
  // 아래의 내용은 어디서 나온 것이지?
  ONLINE_PEN_DATA_REQUEST = 0x12,
  ONLINE_PEN_DATA_RESPONSE = 0x92,

  ONLINE_PEN_DOT_EVENT_V100 = 0x65,
  ONLINE_PEN_DOT_EVENT_V200 = 0x66,

  ONLINE_PEN_DOWN_EVENT_V212 = 0x69,

  ONLINE_PEN_HOVER_MOVE_EVENT = 0x6f,

  ENCRYPTION_CERT_UPDATE_REQUEST = 0x06,
  ENCRYPTION_CERT_UPDATE_RESPONSE = 0x86,
  ENCRYPTION_CERT_DELETE_REQUEST = 0x07,
  ENCRYPTION_CERT_DELETE_RESPONSE = 0x87,
  ENCRYPTION_KEY_REQUEST = 0x08,
  ENCRYPTION_KEY_RESPONSE = 0x88,
  ENCRYPTION_ONLINE_PAPER_INFO_EVENT = 0x09,
  ENCRYPTION_ONLINE_PEN_DOT_EVENT = 0x0a,
  ENCRYPTION_OFFLINE_PACKET_REQUEST = 0x0b,
  ENCRYPTION_OFFLINE_PACKET_RESPONSE = 0x8b,


  // PenClientParserV2.cs 에 있는 내용
  VERSION_REQUEST = 0x01,
  VERSION_RESPONSE = 0x81,

  PASSWORD_REQUEST = 0x02,
  PASSWORD_RESPONSE = 0X82,

  PASSWORD_CHANGE_REQUEST = 0X03,
  PASSWORD_CHANGE_RESPONSE = 0X83,

  SETTING_INFO_REQUEST = 0X04,
  SETTING_INFO_RESPONSE = 0X84,

  SYSTEM_CHANGE_REQUEST = 0X06,
  SYSTEM_CHANGE_RESPONSE = 0X86,
  
  SYSTEM_INFO_REQUEST = 0X07,
  SYSTEM_INFO_RESPONSE = 0X87,

  LOW_BATTERY_EVENT = 0X61,
  SHUTDOWN_EVENT = 0X62,

  SETTING_CHANGE_REQUEST = 0X05,
  SETTING_CHANGE_RESPONSE = 0X85,

  ONLINE_DATA_REQUEST = 0X11,
  ONLINE_DATA_RESPONSE = 0X91,

  ONLINE_PEN_UPDOWN_EVENT = 0X63,
  ONLINE_PAPER_INFO_EVENT = 0X64,
  ONLINE_PEN_DOT_EVENT = 0X65,
  ONLINE_PEN_ERROR_EVENT = 0X68,

  ONLINE_NEW_PEN_DOWN_EVENT = 0X69,
  ONLINE_NEW_PEN_UP_EVENT = 0X6A,
  ONLINE_NEW_PAPER_INFO_EVENT = 0X6B,
  ONLINE_NEW_PEN_DOT_EVENT = 0X6C,
  ONLINE_NEW_PEN_ERROR_EVENT = 0X6D,

  ONLINE_ENCRYPTION_PAPER_INFO_EVENT = 0X6E,
  ONLINE_ENCRYPTION_PEN_DOT_EVENT = 0X6F,

  OFFLINE_NOTE_LIST_REQUEST = 0X21,
  OFFLINE_NOTE_LIST_RESPONSE = 0XA1,

  OFFLINE_PAGE_LIST_REQUEST = 0X22,
  OFFLINE_PAGE_LIST_RESPONSE = 0XA2,

  OFFLINE_DATA_REQUEST = 0X23,
  OFFLINE_DATA_RESPONSE = 0XA3,
  OFFLINE_PACKET_REQUEST = 0X24,
  OFFLINE_PACKET_RESPONSE = 0XA4,

  OFFLINE_DATA_DELETE_REQUEST = 0X25,
  OFFLINE_DATA_DELETE_RESPONSE = 0XA5,

  OFFLINE_PAGE_LIST_LARGE_REQUEST = 0X26,
  OFFLINE_PAGE_LIST_LARGE_RESPONSE = 0XA6,

  FIRMWARE_UPLOAD_REQUEST = 0X31,
  FIRMWARE_UPLOAD_RESPONSE = 0XB1,
  FIRMWARE_PACKET_REQUEST = 0X32,
  FIRMWARE_PACKET_RESPONSE = 0XB2,

  PEN_PROFILE_REQUEST = 0x41,
  PEN_PROFILE_RESPONSE = 0xC1,

  AES_KEY_REQUEST = 0X76,
  AES_KEY_RESPONSE = 0XF6,

  PDS_COMMAND_EVENT = 0x73
}

export enum FwUpgrade {
  FIRMWARE_RECEIVED = 0,
  FIRMWARE_SAME_VERSION = 1,
  FIRMWARE_INSUFFICIENT_DISK_SPACE = 2,
  FIRMWARE_FAILED = 3,
  FIRMWARE_COMPRESSION_NOT_SUPPORTED = 4,

  FIRMWARE_PACKET_STATUS_START = 0,
  FIRMWARE_PACKET_STATUS_CONTINUE = 1,
  FIRMWARE_PACKET_STATUS_END = 2,
  FIRMWARE_PACKET_STATUS_ERROR = 3,
}


export enum PenProfile {
  LIMIT_BYTE_LENGTH_PROFILE_NAME = 8,
  LIMIT_BYTE_LENGTH_PASSWORD = 8,
  LIMIT_BYTE_LENGTH_KEY = 16,
  /**
   * request type
   */
  PROFILE_CREATE = 0x01,
  PROFILE_DELETE = 0x02,
  PROFILE_INFO = 0x03,
  PROFILE_READ_VALUE = 0x12,
  PROFILE_WRITE_VALUE = 0x11,
  PROFILE_DELETE_VALUE = 0x13,
  /**
   * status
   */
  PROFILE_STATUS_SUCCESS = 0x00,
  PROFILE_STATUS_FAILURE = 0x01,
  PROFILE_STATUS_EXIST_PROFILE_ALREADY = 0x10,
  PROFILE_STATUS_NO_EXIST_PROFILE = 0x11,
  //    public static readonly byte PROFILE_STATUS_EXIST_KEY_ALREADY = 0x20;
  PROFILE_STATUS_NO_EXIST_KEY = 0x21,
  PROFILE_STATUS_NO_PERMISSION = 0x30,
  PROFILE_STATUS_BUFFER_SIZE_ERR = 0x40,
}
