interface TypedValue {
  __type__: string;
  __value__: any;
}

export class CustomTypeSerializer {
  /**
   * 값을 타입 정보가 포함된 JSON 문자열로 직렬화합니다.
   */
  static serialize(value: any): string {
    const serialized = this.serializeValue(value);
    return JSON.stringify(serialized);
  }

  /**
   * 값을 타입 정보가 포함된 형식으로 변환합니다.
   */
  private static serializeValue(value: any): any {
    // null 처리
    if (value === null) {
      return { __type__: 'null', __value__: null };
    }

    // undefined 처리
    if (value === undefined) {
      return { __type__: 'undefined', __value__: null };
    }

    // 단순 값 처리
    if (this.isPrimitiveOrString(value)) {
      return this.serializePrimitive(value);
    }

    // Uint8Array 처리
    if (value instanceof Uint8Array) {
      return this.serializeBytes(value);
    }

    // 배열 처리
    if (Array.isArray(value)) {
      return this.serializeArray(value);
    }

    // 객체 처리
    if (typeof value === 'object') {
      return this.serializeObject(value);
    }

    return { __type__: 'unknown', __value__: value };
  }

  /**
   * 값이 기본 타입인지 확인합니다.
   */
  private static isPrimitiveOrString(value: any): boolean {
    const type = typeof value;
    return type === 'string' ||
           type === 'number' ||
           type === 'boolean' ||
           type === 'bigint';
  }

  /**
   * 기본 타입 값을 직렬화합니다.
   */
  private static serializePrimitive(value: any): TypedValue {
    const type = typeof value;

    switch (type) {
      case 'number':
        return { __type__: 'number', __value__: value };
      case 'string':
        return { __type__: 'string', __value__: value };
      case 'boolean':
        return { __type__: 'boolean', __value__: value };
      case 'bigint':
        return { __type__: 'number', __value__: Number(value) };
      default:
        return { __type__: type, __value__: value };
    }
  }

  /**
   * Uint8Array를 base64 문자열로 직렬화합니다.
   */
  private static serializeBytes(bytes: Uint8Array): TypedValue {
    let binary = '';
    const length = bytes.length;

    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    const base64 = window.btoa(binary);
    return { __type__: 'base64bytes', __value__: base64 };
  }

  /**
   * 배열을 직렬화합니다.
   */
  private static serializeArray(array: any[]): any[] {
    return array.map(item => this.serializeValue(item));
  }

  /**
   * 객체를 직렬화합니다.
   */
  private static serializeObject(obj: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = this.serializeValue(obj[key]);
      }
    }

    return result;
  }
}

// // 테스트 코드
// function testSerialization() {
//   // 단순 값 테스트
//   console.log('Number:', CustomTypeSerializer.serialize(42));
//   console.log('String:', CustomTypeSerializer.serialize("test"));
//   console.log('Boolean:', CustomTypeSerializer.serialize(true));
//   console.log('Null:', CustomTypeSerializer.serialize(null));

//   // 바이트 배열 테스트
//   const bytes = new Uint8Array([1, 2, 3]);
//   console.log('Bytes:', CustomTypeSerializer.serialize(bytes));

//   // 배열 테스트
//   const array = [1, "text", new Uint8Array([1, 2, 3]), null];
//   console.log('Array:', CustomTypeSerializer.serialize(array));

//   // 객체 테스트
//   const obj = {
//     id: 1,
//     name: "test",
//     data: new Uint8Array([1, 2, 3]),
//     isValid: true,
//     nullValue: null
//   };
//   console.log('Object:', CustomTypeSerializer.serialize(obj));

//   // 중첩 객체 테스트
//   const nestedObj = {
//     user: {
//       id: 1,
//       settings: {
//         theme: "dark",
//         notifications: true
//       }
//     },
//     data: new Uint8Array([1, 2, 3]),
//     tags: ["a", "b", "c"]
//   };
//   console.log('Nested object:', CustomTypeSerializer.serialize(nestedObj));
// }

// testSerialization();