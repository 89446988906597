import {
	Button,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { CustomProjectAssignmentStyle } from "../../styles/CustomProjectAssignmentStyle";
import React from "react";



export const ProjectUserTableRow = (props) => {
	const classes = useEmotionStyles( CustomProjectAssignmentStyle );
	const i18next = useTranslation();

	const {
		idx,
		rowStartNum,
		user
	} = props;

	const userGroup = React.useMemo(() => {
		if (!user || !user?.groupName || !user?.groupCount || user?.groupCount === 0) return '-';

		if (user.groupCount > 1) {
			return i18next.t("{{groupName}} 외 {{count}}개", { groupName: user.groupName, count: user.groupCount - 1 });
		}

		return user.groupName;
	}, [user]);

	return (
		<TableRow key={idx}
			sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
			<TableCell className={classes.numberColumn}>
				{rowStartNum + idx}
			</TableCell>
			<TableCell align="left">
				<Button className={classes.tableStudentNameBtn} disableRipple>
					<Typography>{user?.name || '-'}</Typography>
				</Button>
			</TableCell>
			<TableCell align="left">
				{user?.email || '-'}
			</TableCell>
			<TableCell align="left">
				{user?.school || '-'}
			</TableCell>
			<TableCell align="left">
				{i18next.t("{{grade}}학년 {{className}}반", { grade: user?.grade || '-', className: user?.className || '-' })}
			</TableCell>
			<TableCell align="left">
				{userGroup}
			</TableCell>
		</TableRow>
	)
}
