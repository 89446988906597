
/**
 * pen packet에 있는 escape code를 처리
 * @param packet
 */

import { PEN_PACKET_END, PEN_PACKET_START, PK_DLE } from "./bt-base-protorol-indicators";


export function unescapePacket(packet: Uint8Array): Uint8Array {
  const len = packet.length;
  const unescapedBuf = new Uint8Array(len);

  let cnt = 0;
  for (let i = 0; i < len; i++) {
    if (packet[i] === PK_DLE) {
      // eslint-disable-next-line no-bitwise
      unescapedBuf[cnt++] = packet[i + 1] ^ 0x20;
      i++;
    } else {
      unescapedBuf[cnt++] = packet[i];
    }
  }

  return unescapedBuf.subarray(0, cnt);
}


export function escapePenPacket(input: number[]) {
  const ret: number[] = [];

  for (let i = 0, ln = input.length; i < ln; ++i) {
    const ch = input[i];
    if (ch === PEN_PACKET_START || ch === PEN_PACKET_END || ch === PK_DLE) {
      ret.push(PK_DLE);
      ret.push(ch ^ 0x20);
    } else {
      ret.push(ch);
    }
  }

  return ret;
}

