import {
	Button,
	Box,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AssignmentProjectMainStyle } from "../../styles/AssignmentProjectMainStyle";

import React from "react";
import { useTranslation } from 'react-i18next';
import { IProject } from "../../../../repositories/model/IProject";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";
import useNaviCustom from "../../../../hooks/useNaviCustom";

type Props = {
	basicInfoRef: React.MutableRefObject<HTMLElement>,
	project: IProject,
	isNotSimpleModeAssignment: boolean,
	templateProject: IProject,
}


export function AssignmentCurriculumInfo(props: Props) {
	const {
		basicInfoRef,
		project,
		templateProject
	} = props;


	const i18next = useTranslation();
	const classes = useEmotionStyles( AssignmentProjectMainStyle );

	const naviCustom = useNaviCustom();
	const currentSemesterString = getSemesterTypeString(project?.year, project?.semesterType);

	const currentAssignmentType = React.useMemo(() => {
		return project?.includedOnline ? i18next.t('학생 등록용 평가과제') : i18next.t('선생님 일괄 등록용 평가과제');
	}, [project?.includedOnline]);

	const currentSchoolLevelObj = React.useMemo(() => {
		return SchoolLevelSubjects.find(school => school.name === project?.schoolType);
	}, [project]);

	const currentSubjectGroupObj = React.useMemo(() => {
		if (!currentSchoolLevelObj) return;

		return currentSchoolLevelObj.subjectGroups.find(group => group.name === project?.subject);
	}, [project, currentSchoolLevelObj]);



	const currentSubjectObj = React.useMemo(() => {
		if (!currentSubjectGroupObj) return;

		return currentSubjectGroupObj.subjects.find(subject => subject.name === project?.subjectName);
	}, [project, currentSubjectGroupObj]);

	const handleClickTemplateProjectInfo = () => {
		if (!project.code) return;

		const code = project.templateCode ? project.templateCode : project.code;
		naviCustom.go(`/projects/${code}/create/view`);
		// props.navigate(`/projects/${projectCode}/create/view`);
	};

	return (
		<>
			<Box ref={basicInfoRef} display='flex' alignItems='center'
				justifyContent='left'
				className={classes.commonTitleBox}>
				<Box>
					<Typography className={classes.commonSubTitle3} id={"projectTotalInfo"}>{i18next.t('평가과제 운영 정보')}</Typography>
				</Box>
			</Box>
			<Box className={clsx(classes.innerBox2, classes.boxMarginBottom60px)}>
				<Box>
					<Box className={classes.displayStyle}>
						<Typography className={classes.basicTitle}>{i18next.t('평가과제 운영명')}</Typography>
						<Typography className={classes.basicText}>{project?.name || '-'}</Typography>
						{/*<Typography className={classes.basicText}>{i18next.t('{{year}}학년도/ {{semester}}학기', {year: project.year, semester: project.semesterType === ProjectSemesterType.FIRST ? '1':'2'})}</Typography>*/}
					</Box>
					<Box className={classes.displayStyle}>
						<Typography className={classes.basicTitle}>{i18next.t('제시방식')}</Typography>
						<Typography className={classes.basicText}>{currentAssignmentType}</Typography>
					</Box>

					<Box className={classes.displayStyle}>
						<Typography className={classes.basicTitle}>{i18next.t('평가과제 정보 ')}</Typography>
						<Button className={classes.basicTextBtnLeft} disableRipple onClick={handleClickTemplateProjectInfo}>
							<Typography>
								{templateProject?.name || i18next.t('평가과제 정보로 가기')}
							</Typography>
						</Button>
					</Box>


					{props.isNotSimpleModeAssignment && (
						<>
							<Box className={classes.displayStyle}>
								{/*<Typography className={classes.basicTitle}>{i18next.t('학년도 / 개정')}</Typography>*/}
								<Typography className={classes.basicTitle}>{i18next.t('학년도 / 학기')}</Typography>
								{/*<Typography className={classes.basicText}>{i18next.t(`${project?.year || '-'}학년도 ${project?.curriculumYear || '-'}년 개정`)}</Typography>*/}
								<Typography className={classes.basicText}>{project?.year || '-'}{i18next.t('학년도')} / {currentSemesterString}</Typography>
							</Box>

							<Box className={classes.displayStyle}>
								<Typography className={classes.basicTitle}>{i18next.t('과목')}</Typography>
								<Typography className={classes.basicText}>
									{currentSubjectObj?.text || '-'}
								</Typography>
							</Box>
						</>
					)}


				</Box>
			</Box>
		</>
	)
}