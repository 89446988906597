import React, { Component } from 'react';
import clsx from 'clsx';
import { Box, Theme, Typography } from "@mui/material";
import { Styles, withStyles } from "@mui/styles"


const styles: Styles<any, any> = (theme: Theme) => ({
	root: {},
	topBar: {
		background: '#fff',
		borderBottom: '1px solid #c0c2c3',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '30px 0',
		boxSizing: 'border-box',
	},
	contentsBox: {
		'@media all and (min-width: 1500px)': {
			width: "1440px",
		},
		width: "1200px",
		margin: '0 auto',
		paddingTop: 50,
		paddingBottom: 70,
	},
	titleText: {
		fontSize: '1.938rem',
		fontWeight: 'bold',
		marginBottom: "40px",
	},
	textStyle1: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
		marginBottom: "20px",
	},
	textStyle2: {
		fontSize: '1rem',
		fontWeight: 'bold',
		marginBottom: "10px",
		textAlign: 'justify',
	},
	marginBottom: {
		marginBottom: "30px",
		fontWeight: 'normal',
	},
});


type Props = {
	classes: any;
};


class TermsOfUse_ja extends Component<Props> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{/*<Box className={classes.topBar} id={'top'}>*/}
				{/*    <StudyLiveTopBarLogo />*/}
				{/*</Box>*/}
				<Box className={classes.contentsBox}>
					<Typography className={classes.titleText}>利用規約</Typography>

					<Typography className={classes.textStyle1}>第1章 ようこそ！</Typography>

					<Typography className={classes.textStyle2}>第1条（目的）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						アイグル（以下「サービス」）をご利用いただきありがとうございます。サービスは、皆様がさまざまなインターネットとモバイルを通じてより便利に利用できるようにするために、「アイグルアカウント約款」（以下「本約款」）を用意しました。本約款は、皆様がアイグルアカウントサービスを利用するために必要な権利、義務および責任事項、利用条件および手続きなど基本的な事項を規定していますので、少しだけ時間を取って注意深くお読みください。
					</Typography>

					<Typography className={classes.textStyle2}>第2条（約款の効力および変更）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. 本約款の内容は、アイグルウェブサイトに掲示するかその他の方法で通知し、本約款に同意した皆様全員にその効力が生じます。 <br /> <br />
						2. サービスは、必要な場合関連法令に違反しない範囲内で本約款を変更することができます。本約款が変更される場合、サービスは変更事項を施行日7日前から皆様にサービス告知事項で通知または通知することを原則とします。
						<br /> <br />
						3. サービスが前項に従って通知または告知しながら、通知または告知日から改正約款施行日7日後まで拒否の意思を表示しない場合、承認したものとみなす旨を明確に告知したにもかかわらず、皆様の意思表示がない場合には変更された約款を承認したものとみなします。皆様が改正約款に同意しない場合、皆様は利用契約を解除することができます。
					</Typography>

					<Typography className={classes.textStyle2}>第3条（約款外の準則）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						本約款に規定されていない事項については、関連法令またはサービスが定めた個別利用約款、運営方針および規則など（以下「詳細指針」）の規定に従います。
					</Typography>

					<Typography className={classes.textStyle2}>第4条（用語の定義）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. 本約款で使用する用語の定義は次のとおりです。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. アイグルアカウント: サービスが提供するログインアカウントとパスワードで会員認証、会員情報変更、会員登録および脱退などを管理できるようにサービスが定めたログインアカウントを指します。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 会員: アイグルアカウントが適用されたサービスまたはアイグルアカウントウェブサイトで本約款に同意し、アイグルアカウントを利用する者を指します。
					</Typography>

					<Typography className={classes.textStyle1}>第2章 アイグルアカウント利用契約</Typography>

					<Typography className={classes.textStyle2}>第5条（契約の成立）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. アイグルアカウント利用申請は、アイグルアカウントウェブサイトの会員登録画面で皆様がアイグルアカウント情報に一定の情報を入力する方法や皆様の外部アカウントの情報を送信する方法で行われます。
						<br /> <br />
						2. アイグルアカウント利用契約は、皆様が本約款の内容に同意した後、本条第1項で定めた利用申請を行い、サービスが入力された一定の情報を認証した後に登録を承諾することで成立します。
					</Typography>

					<Typography className={classes.textStyle2}>第6条（アイグルアカウント利用の制限）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. 第5条に基づく登録申請者に対して、サービスは原則としてアイグルアカウントの利用を承諾します。ただし、サービスは以下の各号の場合には、その理由が解消されるまで承諾を保留または承諾しないことがあります。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. サービスが本約款または詳細指針に基づいて皆様のアイグルアカウントを削除した場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 皆様が他人の名義やメールアドレスなど個人情報を利用してアイグルアカウントを作成しようとした場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. アイグルアカウント作成時に必要な情報を入力しないか、虚偽の情報を入力した場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 提供サービス設備容量に現実的な余裕がない場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. サービス提供のための技術的な部分に問題があると判断される場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. その他サービスが財政的、技術的に必要と認める場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. サービスから会員資格停止措置などを受けた会員が、その措置期間に利用契約を任意に解除し、再利用を申請する場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. その他関連法令に違反するか、詳細指針などサービスが定めた基準に反する場合
						<br /> <br />
						2. もし皆様が上記の条件に違反してアイグルアカウントを作成したと判明した場合、サービスは直ちに皆様のアイグルアカウント利用を停止させるか、アイグルアカウントを削除するなど適切な制限を行うことができます。
					</Typography>

					<Typography className={classes.textStyle1}>第3章 アイグルアカウント利用</Typography>

					<Typography className={classes.textStyle2}>第7条（アイグルアカウント提供）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. サービスがアイグルアカウントで提供するアカウントサービス（以下「アイグルアカウントサービス」）の内容は以下の通りです。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 統合ログイン：アイグルアカウントが適用された個別サービスで一つのアカウントとパスワードでログインできる会員認証サービスを利用できます。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. アカウント情報統合管理：個別サービス利用のためにアイグルアカウント情報を統合管理します。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 同期機能：アイグルアカウントが適用された個別サービスでデータを共有できるように同期サービスを利用できます。
						<br /> <br />
						2. より良いサービス提供のために、皆様にサービスの利用に関する各種通知、管理メッセージおよびその他の広告を含むさまざまな情報をサービス画面内に表示するか、皆様のメールに送信することがあります。広告性情報の送信の場合には、事前に受信に同意した場合にのみ送信します。
					</Typography>

					<Typography className={classes.textStyle2}>第8条（アイグルアカウントサービスの変更および終了）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. サービスは、アイグルアカウントサービスを365日、24時間休まず提供するために最善の努力を尽くします。ただし、以下の各号の場合、アイグルアカウントサービスの全部または一部を制限または中止することがあります。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. アイグルアカウントサービス用設備の維持・保守などのための定期または臨時点検の場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 停電、諸設備の障害または利用量の急増などで正常なアイグルアカウント利用に支障がある場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 関係者との契約終了、政府の命令/規制などサービスの諸事情でアイグルアカウントサービスを維持できない場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. その他天災、国家非常事態など不可抗力的な事由がある場合
						<br /> <br />
						2. 前項によるアイグルアカウントサービス中断の場合には、皆様に通知または告知いたします。ただし、サービスでも予測できないかまたは制御できない事由（サービスの過失がないディスクまたはサーバ障害、システムダウンなど）により事前通知または告知が不可能な場合には、そうではありません。このような場合でもサービスは状況を把握次第、できる限り早期に復旧するよう努力します。
						<br /> <br />
						3. 皆様に重大な影響を与えるサービスの変更事項や終了は、アイグルアカウントに登録されたメールアドレスを通じてお知らせいたします。登録されたメールアドレスがない場合、サービス内に通知メッセージを表示するなどの別の電子的手段を通じてお知らせいたします。
					</Typography>

					<Typography className={classes.textStyle2}>第9条（アイグルアカウント管理）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. アイグルアカウントは、皆様ご本人のみ利用でき、他人が皆様のアイグルアカウントを利用することを許可することはできません。また、皆様は他人が皆様のアイグルアカウントを無断で使用できないよう、直接パスワードを管理しなければなりません。サービスは、他人が皆様のアイグルアカウントを無断で使用することを防ぐために、パスワード入力および追加の本人確認手続きを取ることができます。もし無断使用が発見された場合、カスタマーセンターを通じてサービスに通知し、サービスは無断使用を防ぐ方法を皆様に案内いたします。
						<br />
						<br />
						2. 皆様は、アイグルアカウントウェブサイト内のアイグルアカウント設定画面を通じて皆様のアイグルアカウント情報を閲覧および修正できます。ただし、アイグルアカウントサービスの提供および管理に必要なアイグルアカウント、その他本人確認情報など一部の情報は修正できない場合があり、修正する場合には追加の本人確認手続きが必要になることがあります。皆様が利用申請時に知らせた内容に変更があった場合、直接修正するか、メール、カスタマーセンターを通じてサービスに通知してください。
						<br /> <br />
						3. 皆様がアイグルアカウント情報を適時に修正しなかったことにより発生する問題について、サービスは責任を負いません。
					</Typography>

					<Typography className={classes.textStyle1}>第4章 契約当事者の義務</Typography>

					<Typography className={classes.textStyle2}>第10条（会員の義務）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. 皆様がアイグルアカウントサービスを利用する際、以下の各号の行為を行ってはなりません。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 利用申請または変更時に虚偽の事実を記載するか、他の会員のアイグルアカウントおよびパスワードを不正に使用するか、他人の名義を使用する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 他人の名誉を損なうか不利益を与える行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 掲示板などにわいせつ物を掲載するか、わいせつサイトをリンクする行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. サービスまたは第三者の著作権などその他の権利を侵害する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. 公共秩序および善良な風俗に反する内容の情報、文章、図形、音声などを他人に流布する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. アイグルアカウントサービスと関連する設備の誤動作や情報などの破壊および混乱を引き起こすコンピュータウイルス感染資料を登録または流布する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. アイグルアカウントサービスの運営を故意に妨害するか安定的な運営を妨害する情報および受信者の明示的な受信拒否の意思に反して広告性情報またはスパムメール（Spam Mail）を送信する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. サービスの同意なしにソフトウェアの一部を複製、修正、配布、販売、譲渡、貸与、担保提供するか他人にその利用を許可する行為およびソフトウェアを逆設計するかソースコードの抽出を試みるなどサービスを複製、分解または模倣するかその他の変形する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ix. 他人になりすます行為および他人との関係を虚偽に明示する行為や他の会員の個人情報を収集、保存、公開する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x. 自己または他人に財産上の利益を与えるか、他人に損害を与える目的で虚偽の情報を流通させる行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xi. 売春行為をあっせんするか淫行を媒介する内容の情報を流通させる行為や羞恥心や嫌悪感または恐怖心を引き起こす言葉や音声、文章や画像または映像を継続して相手に届けて相手の日常的生活を妨害する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xii. 関連法令によってその送信または掲示が禁止される情報（コンピュータプログラム含む）の送信または掲示行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xiii. サービスまたは関係者の従業員や運営者をなりすますか詐称して他人の名義を不正使用して文を掲載するかメールを送信する行為
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xiv. コンピュータソフトウェア、ハードウェア、電気通信機器の正常な稼働を妨害、破壊する目的で設計されたソフトウェアウイルス、その他のコンピュータコード、ファイル、プログラムを含む資料を掲載するかメールなどで送信する行為やその他の違法行為
						<br />
						<br />
						2. 皆様は、サービスの利用権限、その他の利用契約上の地位を他人に譲渡・贈与することはできず、担保として提供することもできません。
						<br />
						<br />
						3. もし皆様が関連法令、サービスのすべての約款またはポリシーを遵守しない場合、サービスは皆様の違反行為などを調査することができ、皆様のサービス利用を一時的または継続して中断するか、再登録を制限することもあります。
					</Typography>

					<Typography className={classes.textStyle2}>第11条（個人情報の保護）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						皆様の個人情報の安全な処理はサービスにとって最も重要な仕事の一つです。皆様の個人情報は、サービスの円滑な提供のために皆様が同意した目的と範囲内でのみ利用されます。法令によるか、皆様が別途同意しない限り、サービスが皆様の個人情報を第三者に提供することは決してありませんので、ご安心ください。サービスが皆様の個人情報を安全に処理するために尽くす努力やその他の詳細は、アイグル個人情報処理方針をご参照ください。
					</Typography>

					<Typography className={classes.textStyle2}>第12条（会員への通知および告知）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						サービスは、皆様との意見交換を大切に考えています。皆様はいつでもカスタマーセンター（ts.kim@learningspark.io）に訪れて意見を述べることができます。サービス利用者全体への告知は、7日以上サービスの告知事項欄に掲載することで効力が生じます。皆様に重大な影響を与える通知は、アイグルアカウントに登録されたメールアドレスを通じてお知らせいたします。登録されたメールアドレスがない場合、サービス内に通知メッセージを表示するなどの別の電子的手段を通じてお知らせいたします。
					</Typography>

					<Typography className={classes.textStyle1}>第5章 利用契約の解除など</Typography>

					<Typography className={classes.textStyle2}>第13条（利用契約の解除）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. 皆様がアイグルアカウントの利用を希望しない場合、いつでもサービス内で提供されるメニューを利用して利用契約の解除申請をすることができ、サービスは法令が定めるところに従って迅速に処理します。
						<br />
						<br />
						2. サービスは、法令で定める期間内に皆様がサービスを利用するためにアイグルアカウントにログインまたは接続した記録がない場合、皆様が登録したメールアドレスまたは連絡先に連絡するなど、その他有効な手段で通知後、皆様のアイグルアカウント情報を破棄するか分離保管することができ、これによりアイグルアカウントサービス利用に必要な情報が不足する場合、利用契約が解除されることもあります。
						<br />
						<br />
						3. 利用契約が解除されると、法令および個人情報処理方針に基づいて皆様の情報を保有する場合を除き、皆様のアイグルアカウント情報およびアイグルアカウントで利用したサービスデータは削除されます。ただし、皆様がサービス内で作成した掲示物などすべてのデータの削除に関する事項は別途内部規定に従うことがあります。利用契約が解除された場合でも、皆様は再度サービスに対して利用契約の締結を申請することができます。
					</Typography>

					<Typography className={classes.textStyle2}>第14条（損害賠償）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						1. サービスは、法令で許容される限度内で本約款に明示されていないいかなる具体的な事項についても約定や保証を行いません。また、サービスは第三者が提供するか会員が作成するなどの方法でサービスに掲載された情報、資料、事実の信頼性、正確性などについては保証せず、サービスの過失なしに発生した皆様の損害については責任を負いません。
						<br />
						<br />
						2. サービスは、サービスの過失により皆様が損害を被った場合、本約款および関連法令に従って皆様の損害を賠償いたします。ただし、サービスはサービスの過失なしに発生した以下のような損害については責任を負いません。また、サービスは法律上許容される限度内で間接損害、特別損害、結果的損害、懲戒的損害および懲罰的損害について責任を負いません。
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 天災地変またはこれに準じる不可抗力の状態で発生した損害
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 皆様の責任によりサービス利用に障害が発生した場合
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. サービスへの接続または利用過程で発生する個人的な損害
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 第三者が不法にサービスのサーバに接続またはサーバを利用することで発生する損害
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. 第三者がサービスサーバへの送信またはサービスサーバからの送信を妨害することで発生する損害
						<br />글
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. 第三者が悪性プログラムを送信または拡散することで発生する損害
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. 送信されたデータの省略、欠落、破壊などで発生した損害、名誉毀損など第三者がサービスを利用する過程で発生した損害
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. その他サービスの故意または過失がない理由で発生した損害
					</Typography>

					<Typography className={classes.textStyle2}>第15条（紛争の解決）</Typography>
					<Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
						本約款またはサービスは大韓民国法令により規定され履行されます。サービス利用に関して皆様間に紛争が発生した場合、その解決のために誠実に協議いたします。それにもかかわらず解決されない場合、民事訴訟法上の管轄裁判所に訴訟を提起することができます。
					</Typography>
				</Box>
				{/*<Footer />*/}
			</div>
		);
	}
}

export default withStyles(styles)(TermsOfUse_ja);
