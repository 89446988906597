import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ResultReviewStyle } from "../../styles/ResultReviewStyle";
import useEmotionStyles from "../../styles/useEmotionStyles";

import { observer } from "mobx-react";
import React, { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import useNaviCustom from "../../../../hooks/useNaviCustom";

import { useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import ResultReviewLeftPanel from "./ResultReviewLeftPanel";
import ResultReviewRightPanel from "./ResultReviewRightPanel";
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const defaultLayout = [40, 60];
type Props = {
}

function ResultReview(props: Props) {
	const params = useParams();
	const i18next = useTranslation();
	const classes = useEmotionStyles(ResultReviewStyle);
	const { navigateStore, projectSubmissionAnswerStore } = useStores();

	const {
		submissionInfo,
		questionList,
		examOrderAndQuestionsLengthMap,
	} = projectSubmissionAnswerStore;

	const [pdfWidth, setPdfWidth] = React.useState(0);
	const pdfBoxRef = React.useRef<HTMLElement>();
	const [submissionCode, setSubmissionCode] = React.useState("");

	const naviCustom = useNaviCustom();
	const {alert, confirm} = useNeoConfirm();

	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.STUDENT);
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
		navigateStore.changeTopBarRightItem(topBarRightItem);
		window.addEventListener('resize', handleResize);

		return () => {
			navigateStore.changeNavigateDetailPage(null);
			navigateStore.changeTopBarLeftItem(null);
			navigateStore.changeTopBarRightItem(null);
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	React.useEffect(() => {
		const currentSubmissionCode = params.submissionCode;
		if (!currentSubmissionCode || currentSubmissionCode === submissionCode) return;

		setSubmissionCode(currentSubmissionCode);
		projectSubmissionAnswerStore.init();

		const callBack = () => naviCustom.go(`/projects`, true);
		// const callBack = () => props.navigate(`/projects`, {replace: true});
		projectSubmissionAnswerStore.getSubmissionInfoBySubmissionCode(currentSubmissionCode, callBack);
	}, [params]);

	React.useEffect(() => {
		navigateStore.changeTopBarLeftItem(topBarLeftItem);
	}, [submissionInfo?.projectName]);

	const handleClickReviewComplete = async () => {
		console.log("handleClickReviewComplete")
		const result = await confirm(i18next.t("리뷰를 완료하시고 목록으로 돌아가시겠습니까?"));
		if (!result) return;

		naviCustom.go(`/projects`);
		// props.navigate(`/projects`);
	};

	const topBarLeftItem = () => {
		return (
			<p>&nbsp;&nbsp;&nbsp;<strong>
				{submissionInfo?.projectName}&nbsp;
				{i18next.t("서·논술형 평가 결과")}
			</strong>
			</p>
		)
	};

	const topBarRightItem = () => {
		return (
			<Box display='flex'>
				<Box className={clsx(classes.applyMarkBtn, classes.applyMarkBtnActive)}>
					<Button disableRipple onClick={handleClickReviewComplete}>
						<Typography>{i18next.t("리뷰 완료")}</Typography>
					</Button>
				</Box>
			</Box>
		)
	};

	const handleResize = () => {
		setPdfWidth(pdfBoxRef.current?.offsetWidth || 0);
	};

	const leftPanelRef = useRef<ImperativePanelHandle>(null)
	const leftPanelPixelWidthRef = useRef(0)

	const leftBoxRef = useRef<HTMLElement>(null)

	const updateLeftPanelWidthRef = (width: number) => {
		leftPanelPixelWidthRef.current = leftPanelRef.current?.getSize() || 0;

		// setPdfWidth(leftPanelPixelWidthRef.current);
		setPdfWidth(leftBoxRef.current?.offsetWidth - 20);

	}

	return (
		<div className={classes.root}>
			{!projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<PanelGroup id="fixedPanelGroup" direction="horizontal">
					<Box className={classes.padding}>
						<Panel
							className="bg-slate-100 flex text-center p-0"
							defaultSize={defaultLayout[0]}
							style={{ color: "black" }}
							collapsible
							ref={leftPanelRef}
							onResize={updateLeftPanelWidthRef}
						>
							<Box className={classes.mainContentBoxLeft}
								ref={leftBoxRef}
							>
								<ResultReviewLeftPanel
									params={params}
									submissionCode={submissionCode}
									submissionInfo={submissionInfo}
									questionList={questionList}
									examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
									pdfWidth={pdfWidth}
								/>
							</Box>
						</Panel>

						<PanelResizeHandle style={{
							marginLeft: '0.25rem',  // mx-1: 0.25rem
							marginRight: '0.25rem', // mx-1: 0.25rem
							height: '100%',      // h-1: 0.25rem
							width: '0.25rem',
							backgroundColor: '#e2e8f0', // bg-slate-300: #e2e8f0
						}} />

						<Panel
							className="bg-slate-100 flex text-center p-0"
							defaultSize={defaultLayout[1]}
							style={{ color: "black" }}
							collapsible
						>
							<Box className={classes.mainContentBoxRight}>
								<ResultReviewRightPanel
									params={params}
									submissionCode={submissionCode}
									submissionInfo={submissionInfo}
									questionList={questionList}
									examOrderAndQuestionsLengthMap={examOrderAndQuestionsLengthMap}
								/>
							</Box>

						</Panel>
					</Box>
				</PanelGroup>
			}

			{projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectSubmissionAnswerStore.isSelectingSubmissionInfoBySubmissionCode}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}
		</div>
	);
}

export default observer<typeof ResultReview>(ResultReview);

