import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

import List from "@mui/material/List";
import clsx from "clsx";
import { ReactComponent as Logo } from "../../common/images/aigle_logo.svg";
import { ReactComponent as LogoWithTile } from "../../common/images/logo_and_title_Green_BG.svg";
import { ReactComponent as SideBarListBtn } from "../../common/images/SideBarListBtn.svg";
import { ReactComponent as SubSideToggle } from "../../common/images/SubSideToggle.svg";
import { SideBarStyle } from "./styles/SideBarStyle";

import { useLocation, useNavigate } from "react-router-dom";
import useNaviCustom from "../../hooks/useNaviCustom";
import ChildMenuComponent from "./menu/ChildMenuComponent";
import { getMenus, getMenuType, MenuType, StudentMenu } from "./menu/common/MenuItem";
import MenuItemComponent from "./menu/MenuItemComponent";
import useEmotionStyles from "../main/styles/useEmotionStyles";
import { Box, IconButton, Typography } from "@mui/material";
import { useStores } from "../../hooks/useStores";

type Props = {
	id?: string,
	menuType: MenuType;
	sideBarOpen: boolean;
	changSideBarOpen: (open: boolean) => void;

	projectCode?: string;
	projectStates?: any;
}

function SideBar(props: Props) {
	const classes = useEmotionStyles(SideBarStyle);
	const i18next = useTranslation();

	const { menuType, projectCode, projectStates, sideBarOpen, changSideBarOpen } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const { navigateStore, messageStore, authStore } = useStores();


	const naviCustom = useNaviCustom({
		navigateStore, messageStore, navigate, location
	});

	const handleDrawerOpen = () => changSideBarOpen(!sideBarOpen);
	const handleChangePage = (value) => {
		if (value) naviCustom.go(value);
		// if (value) props.navigate(value);
	}

	const menus = authStore.loginUser.type === "Admin" ? getMenus(menuType, projectCode) : StudentMenu();

	const { pathname } = useLocation();
	const isMain = MenuType.Main === getMenuType(pathname);

	const goToList = () => {
		const paths = pathname.split("/");
		const path = paths[1];
		if (path) naviCustom.go(path);
		// if (path) props.navigate(path);
	}

	return (
		<div className={classes.root}>
			{menuType === MenuType.Main ?
				<Box className={sideBarOpen ? classes.bodyOpen : clsx(classes.bodyOpen, classes.bodyClose)}>
					<Box className={classes.header}
						onClick={() => handleChangePage("/projects")}>

						{/*<Box className={sideBarOpen ? classes.logoOpen : clsx(classes.logoOpen, classes.logoClose)}>*/}
						{/*    <PageLogo />*/}
						{/*</Box>*/}
						{sideBarOpen ?
							// <Typography className={classes.headerTitle}>
							<div style={{ height: 55, display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
								<LogoWithTile />
							</div>
							// </Typography>
							:
							<Box className={sideBarOpen ? classes.logoOpen : clsx(classes.logoOpen, classes.logoClose)}>
								<Logo />
							</Box>
						}
					</Box>

					<Box className={clsx(classes.control, classes.controlMain)}>
						{isMain
							&& (
								<IconButton className={sideBarOpen ? clsx(classes.iconBtnReset, classes.iconButtonMarginRight) : classes.iconBtnReset}
									onClick={handleDrawerOpen}
									disableRipple>
									<SubSideToggle className={!sideBarOpen ? classes.toggle : undefined} />
								</IconButton>
							)
						}
					</Box>

					<List>
						{menus.map(menu => {
							return <MenuItemComponent key={`menu-item-${menu.label}`}
								menu={menu.label}
								icon={menu.icon}
								onClick={() => handleChangePage(menu.link)}
								urlPrefix={menu.urlPrefix}
								expanded={sideBarOpen}
								child={menu.children &&
									<ChildMenuComponent projectCode={props.projectCode}
										children={menu.children}
										onClick={handleChangePage}
										activeStates={projectStates}
										expanded={sideBarOpen} />
								} />
						})}
					</List>
				</Box>

				:

				<Box className={sideBarOpen ? classes.subSideMenuBoxOpen : clsx(classes.subSideMenuBoxOpen, classes.subSideMenuBoxClose)}>
					<Box className={classes.header}
						style={{ background: '#025949' }}
						onClick={goToList}>
						<Box className={clsx(classes.iconBtnReset, classes.iconToggle, classes.iconBtnList)}>
							<SideBarListBtn />
						</Box>
						{sideBarOpen &&
							<Typography className={classes.headerTitle}>
								{i18next.t("목록으로")}
							</Typography>
						}
					</Box>

					<Box className={classes.control}>
						<IconButton className={sideBarOpen ? clsx(classes.iconBtnReset, classes.iconButtonMarginRight) : classes.iconBtnReset}
							onClick={handleDrawerOpen}
							disableRipple>
							<SubSideToggle className={!sideBarOpen ? classes.toggle : undefined} />
						</IconButton>
					</Box>

					<List>
						{menus.map(menu => {
							return <MenuItemComponent key={`menu-item-${menu.label}`}
								menu={menu.label}
								icon={menu.icon}
								onClick={() => handleChangePage(menu.link)}
								urlPrefix={menu.urlPrefix}
								expanded={sideBarOpen}
								child={menu.children &&
									<ChildMenuComponent projectCode={props.projectCode}
										children={menu.children}
										onClick={handleChangePage}
										activeStates={projectStates}
										expanded={sideBarOpen} />
								} />
						})}
					</List>
				</Box>
			}
		</div>
	);
}

export default observer<typeof SideBar>(SideBar);
