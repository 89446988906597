import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const CommonDialogStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
    root:{
        width: '100%',
        textAlign: 'left',
    },
    // emptyBox:{
    //     width: '100%',
    //     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
    //     display:'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     '& p':{
    //         fontSize: '1.375rem',
    //         color: '#87888d',
    //         fontWeight:400,
    //         textAlign: 'center',
    //         lineHeight: '30.36px'
    //     }
    // },
    // boxWrap: {
    //     backgroundColor: '#fff',
    //     padding: 20,
    //     borderRadius: 8,
    //     height: 'calc(100vh - 500px)'
    // },
    dialogMain: {
        zIndex: '999999 !important',
        width: '100%',
        '& .MuiDialog-paperWidthSm': {
            maxWidth: 785,
            '@media all and (max-width: 950px)': {
                maxWidth: 444,
            },
        },

    },
    dialogTop: {
        borderBottom: '1px solid #CBCBCB',
        height: "70px",
        minWidth: 300,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiTypography-root': {
            fontSize: '1.25rem',
            fontWeight: 700,
            marginLeft: "32px",
        },
        '& .MuiIconButton-root': {
            marginRight: "20px",
        }
    },
    dialogTopNoTitle: {
        // borderBottom: '1px solid #CBCBCB',
        height: "44px",
        minWidth: 300,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // '& .MuiTypography-root': {
        //     fontSize: '1.25rem',
        //     fontWeight: 700,
        //     marginLeft: "32px",
        // },
        '& .MuiIconButton-root': {
            marginRight: "20px",
        }
    },
    dialogContent: {
        display: 'flex',
        textAlign: 'center',
        minHeight: '43px',
        alignItems: 'center',
        width: "785px",
        paddingTop: '25px',
        paddingBottom: '25px',
        '@media all and (max-width: 950px)': {
            maxWidth: 444,
        },
        // paddingLeft: '20px',
        // paddingRight: '20px',
    },
    dialogContentText: {
        width: '100%',
        fontSize: 18,
        fontWeight: 400,
        marginLeft: "20px",
        marginRight: "20px",
        wordWrap: 'normal',
        overflowWrap: 'normal',
        wordBreak: 'keep-all'
    },
    uploadBox: {
        textAlign: 'center',
        '& .MuiTypography-root': {
            fontSize: '1.125rem',
            color: '#111'
        }
    },
    dialogHalf: {
        width: '50%',
    },
    dialogTitle: {
        '&.MuiTypography-root': {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            marginBottom: "20px",
            marginTop: "30px",
            '& span': {
                fontSize: '1rem',
                fontWeight: 'normal',
                color: '#555'
            }
        }
    },
    dialogSubTitle: {
        '&.MuiTypography-root': {
            fontSize: '1rem',
            color: '#555',
            marginBottom: "20px",
        }
    },
    dialogTime: {
        '&.MuiTypography-root': {
            fontSize: '1rem',
            color: '#111',
            fontWeight: 'bold',
            marginLeft: "40px",
            marginRight: 10.
        }
    },

    dialogBtnGroup: {
        // marginTop: "30px",
        marginBottom: "20px",
        width: '100%'
    },
    dialogBtn: {
        '&.MuiButtonBase-root': {
            border: '1px solid #2F80ED',
            width: "120px",
            height: "40px",
        },
        '&.MuiButton-root:hover': {
            background: '#fff'
        },
        '& .MuiTypography-root': {
            color: '#2F80ED',
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    },
    dialogBtnSave: {
        '&.MuiButtonBase-root': {
            border: '1px solid #2F80ED',
            background: '#2F80ED',
            width: "120px",
            height: "40px",
            marginLeft: "15px",
        },
        '&.MuiButton-root:hover': {
            background: '#2F80ED',
        },
        '& .MuiTypography-root': {
            color: '#fff',
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    },
    SubText: {
        '&.MuiTypography-root': {
            color: '#555',
            fontSize: '1rem',
            marginTop: "20px",
        }
    },
    iconBtnReset:{
        '&.MuiIconButton-root': {
            padding: 10,
            '&:hover':{
                background: 'transparent'
            },
        }
    },
    iconBtnResetNoTitle:{
        '&.MuiIconButton-root': {
            padding: 10,
            marginTop: "15px",
            '&:hover':{
                background: 'transparent'
            },
        }
    },









});












