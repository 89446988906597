/* eslint-disable react/jsx-pascal-case */
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	MenuList,
	Popover,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TableHomeTaskStlye } from "../../styles/TableHomeTaskStlye";

import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { ReactComponent as AddPlusIcon } from '../../../../common/images/AddPlusIcon.svg';
import { ReactComponent as BoardTextDelete } from '../../../../common/images/BoardTextDelete.svg';
import { ReactComponent as DeleteSubMenu } from '../../../../common/images/DeleteSubMenu.svg';
import { ReactComponent as DotsMoreMenu } from '../../../../common/images/DotsMoreMenu.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
// import { withRouter } from "../../../../components/WithRouter";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { SchoolLevelSubjects } from "../../../../common/SchoolLevelSubjects";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import MessageDialog from "../../../components/MessageDialog";
import SubjectMenuItem from "./SubjectMenuItem";
import SubjectNameMenuItem from "./SubjectNameMenuItem";

import { ProjectSchoolType } from "../../../../stores/enums/ProjectStoreEnums";

import { useStores } from "../../../../hooks/useStores";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import { GradeType } from "../../../../stores/support/GradeType";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";
import { useNavigate, useParams } from "react-router-dom";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";



const minumumStandardGradeCount = 3;
const maxStandardGradeCount = 5;

type Props = {
};


function TempProjectCreateBasicInfo(props: Props) {
	const navigate = useNavigate();
	const params = useParams();

	const i18next = useTranslation();

	const maxLenChapter = 500;
	const maxLenName = 500;

	const classes = useEmotionStyles( TableHomeTaskStlye );

	const { projectStore, authStore, navigateStore } = useStores();
	const { project, projectPlanTransfer } = projectStore;
	const { loginUser } = authStore;

	const [dotsMoreMenuAnchorEl, setDotsMoreMenuAnchorEl] = React.useState(null);
	const [projectCode, setProjectCode] = React.useState('');
	const [name, setName] = React.useState('');
	const [subjectIndex, setSubjectIndex] = React.useState(-1);
	const [subjectNameIndex, setSubjectNameIndex] = React.useState(-1);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [dialogType, setDialogType] = React.useState(0);
	const [initialized, setInitialized] = React.useState(false);
	const [subjectInitialized, setSubjectInitialized] = React.useState(false);
	const [openDialogForDeletionGrade, setOpenDialogForDeletionGrade] = React.useState(false);
	const [selectedStandardIdx, setSelectedStandardIdx] = React.useState(-1);
	const [selectedGradeIdx, setSelectedGradeIdx] = React.useState(-1);
	const {alert, confirm} = useNeoConfirm();

	const currentSemesterString = getSemesterTypeString(project?.year, project?.semesterType);

	const isNotSimpleModeAssignment = (subjectIndex > -1 && (currentSemesterString.length > 1)) || (!navigateStore.simpleMode);


	const naviCustom = useNaviCustom();

	const [gradePlaceholder, setGradePlaceholder] = React.useState([
		i18next.t("상"),
		i18next.t("중"),
		i18next.t("하")
	]);

	const [dialogTitleText, setDialogTitleText] = React.useState([
		i18next.t("저장 하시겠습니까?"),
		i18next.t("저장하고 다음으로 넘어가시겠습니까?"),
		i18next.t("과제 개요가 저장 되었습니다.")
	]);

	React.useEffect(() => {
		const gradePlaceholderTemp = [
			i18next.t("상"),
			i18next.t("중"),
			i18next.t("하")
		];
		setGradePlaceholder(gradePlaceholderTemp);

		const dialogTitleTextTemp = [
			i18next.t("저장 하시겠습니까?"),
			i18next.t("저장하고 다음으로 넘어가시겠습니까?"),
			i18next.t("과제 개요가 저장 되었습니다.")
		];

		setDialogTitleText(dialogTitleTextTemp);
	}, [navigateStore.language]);

	const dialogIsConfirm = [true, true, false];

	React.useEffect(() => {
		dayjs.locale(navigateStore.language);

		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeProjectSideBarStep(0);
		navigateStore.changeSideBarStepNum(2);
		projectStore.initProjectPlanTransfer();

		return () => {
			navigateStore.changeIsConfirmForUpdateRequired(false);
			projectStore.initProject();
			projectStore.initProjectPlanTransfer();
			navigateStore.changeNavigateDetailPage(null);
		}
	}, []);

	React.useEffect(() => {
		const code = params.projectCode;
		setProjectCode(code);
	}, [params]);

	React.useEffect(() => {
		if (!projectCode) {
			projectStore.initProject();

			if (!isNotSimpleModeAssignment) {

				// kitty
				// 현재 년도를 가져와서 과제명에 추가
				const year = dayjs().format('YYYY');
				projectStore.changeCirriculumYear(year);

				//
				projectStore.changeSchoolType(ProjectSchoolType.NOGRADE);


				// 교과 역량
				projectStore.changeProjectPlanRequired('교과역량');
				// 출제 의도
				projectStore.changeProjectPlanPurpose('출제의도');

				// 성취기준 코드
				projectStore.changeProjectPlanExternalPlanCode('성취기준코드', 0);

				// 성취기준 내용
				projectStore.changeProjectPlanStandard('성취기준내용', 0);

				projectStore.changeProjectPlanStandardGrade(i18next.t("상"), 0, 0);
				projectStore.changeProjectPlanStandardGrade(i18next.t("중"), 0, 1);
				projectStore.changeProjectPlanStandardGrade(i18next.t("하"), 0, 2);


				projectStore.changeProjectPlanStandardGradeContents("상의 내용", 0, 0);
				projectStore.changeProjectPlanStandardGradeContents("중의 내용", 0, 1);
				projectStore.changeProjectPlanStandardGradeContents("하의 내용", 0, 2);

				projectStore.changeIsConfirmForUpdateRequired(false);
			}

			return;
		}

		const fetchProjectInfo = async (code: string) => {
			try {
				const data = await projectStore.downloadProjectInfoAndStore(code);
				if (!data) {
					navigate('/', { replace: true }); // 프로젝트 정보가 없으면 메인 페이지로 이동
				} else {
					await projectStore.getProjectPlanTransfer(code);
					setInitialized(true); // 프로젝트 정보가 제대로 로드된 경우 초기화 설정
				}
			} catch (error) {
				console.error("Error fetching project info:", error);
			}
		}
		fetchProjectInfo(projectCode); // 비동기 함수 호출

		// projectStore.getProjectInfo(projectCode)
		// 	.then(data => {
		// 		if (!data) {
		// 			naviCustom.go('/', true);
		// 			// props.navigate('/', {replace: true});
		// 		} else {
		// 			projectStore.getProjectPlanTransfer(projectCode);
		// 			setInitialized(true);
		// 		}
		// 	})
	}, [projectCode]);

	React.useEffect(() => {
		if (!projectStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectStore.isConfirmForUpdateRequired]);

	React.useEffect(() => {
		if (project && project.name)
			setName(project.name);
	}, [project.name])

	React.useEffect(() => {
		if (subjectIndex > -1) {
			const subjectNameIndex = SchoolLevelSubjects[subjectIndex].subjectGroups.findIndex(item => item.name === project.subject);
			setSubjectNameIndex(subjectNameIndex);
			if (initialized && subjectInitialized)
				projectStore.clearSubjectName();
		}
	}, [project.subject, subjectIndex]);

	React.useEffect(() => {
		const subjectIndex = SchoolLevelSubjects.findIndex(item => item.name === project.schoolType);
		setSubjectIndex(subjectIndex);
		if (initialized) {
			projectStore.changeProjectGrade(0);
			projectStore.clearSubject();
			projectStore.clearSubjectName();
			setSubjectInitialized(true);
		}
	}, [project.schoolType])

	const isElementarySchoolChecked = React.useMemo(() => {
		return project.schoolType === ProjectSchoolType.ELEM;
	}, [project.schoolType]);

	const remainingChapterTextLen = React.useMemo(() => {
		return project.chapter ? maxLenChapter - project.chapter.length : 0;
	}, [project.chapter]);


	const remainingNameTextLen = React.useMemo(() => {
		return name ? maxLenName - name.length : 0;
	}, [name]);

	const unableToSubmit = React.useMemo(() => {
		const { chapter, curriculumYear, schoolType, subject, subjectName, name, grade } = project;
		const { requiredSubjectAbility, purpose, standards } = projectPlanTransfer;
		let haveEmptyValue = (
			!curriculumYear
			|| !schoolType
			|| !subject
			|| !subjectName
			|| !name
			|| !chapter
			|| !requiredSubjectAbility
			|| !purpose
			|| !grade
		);
		if (!standards) return true;

		for (let i = 0; i < standards.length; i++) {
			if (standards[i].standard === "" || standards[i].standard === null || standards[i].externalPlanCode === "" || standards[i].externalPlanCode === null) {
				return true;
			}
			for (let j = 0; j < standards[i].grades.length; j++) {
				if (standards[i].grades[j].grade === "" || standards[i].grades[j].grade === null) {
					return true;
				} else if (standards[i].grades[j].contents === "" || standards[i].grades[j].contents === null) {
					return true;
				}
			}
		}
		return haveEmptyValue;
	}, [project, project.chapter, project.grade, project.curriculumYear, project.schoolType, project.year, project.semesterType, project.subject, project.subjectName, project.name, projectPlanTransfer.standards, projectPlanTransfer]);

	const judgeCreateOrModify = async (projectCode) => {
		if (projectPlanTransfer.code !== null && projectPlanTransfer.code !== "") {
			//수정
			return projectStore.modifyProjectPlan();
		} else {
			//생성
			const result = await projectStore.createProjectPlan(projectCode);
			await projectStore.getProjectPlanTransfer(projectCode);
			return result;
		}
	}

	const handleClickDotsMoreMenu = event => {
		setDotsMoreMenuAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setDotsMoreMenuAnchorEl(null);
	};

	const handleChangeCirriculumYear = (e) => {
		projectStore.changeCirriculumYear(e.target.value);
	};

	const handleChangeSchoolType = (e) => {
		projectStore.changeSchoolType(e.target.value);
		projectStore.changeProjectGrade(0);
		projectStore.changeSubject(null);
		projectStore.changeSubjectName(0);
	}
	const handleChangeSubject = (e) => {
		projectStore.changeSubject(e.target.value);
		projectStore.changeSubjectName(0);
	}



	const handleChangeName = (e) => {
		const name = e.target.value;
		if (name.trim().length > maxLenName) return;

		setName(name);
		projectStore.changeName(name);
	}

	const handleChangeChapter = (e) => {
		// const chapter = (e.target.value).trim();
		const chapter = e.target.value;

		if (chapter.length > maxLenChapter) return;

		projectStore.changeChapter(chapter);
	}

	const handleClearName = () => {
		setName('');
		projectStore.changeName('');
	}

	const handleClearChapter = () => {
		projectStore.changeChapter('');
	}

	const resetIsConfirmForUpdateRequired = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectStore.changeIsConfirmForUpdateRequired(false);
	};

	const processAfterStorage = () => {
		resetIsConfirmForUpdateRequired();
	};

	const processAfterDeletion = () => {
		resetIsConfirmForUpdateRequired();
		handleClosePopover();
	};

	const handleClickTemporalStorage = async () => {
		const projectCode = await processProject();
		if (!projectCode) return;
		const response = await judgeCreateOrModify(projectCode);
		if (!response) return;

		setOpenDialog(false);

		processAfterStorage();
		naviCustom.go(`/projects/${projectCode}/create/info`, true);
		// props.navigate(`/projects/${projectCode}/create/info`, {replace: true});
	}

	const handleClickNextBtn = () => {
		setDialogType(1);
		setOpenDialog(true);
	}

	const processProject = async () => {
		const data = await createOrUpdateProject();
		if (!data) return;

		const projectCode = data?.code;
		return projectCode;
	}

	const createOrUpdateProject = async () => {
		if (!projectCode) {
			// 새로 만드는 경우
			console.log("create project");
			const ret = await projectStore.createProjectForTemplate(loginUser.code);
			return ret;
		} else {
			// 기존 과제를 업데이트하는 경우
			console.log("update project");
			const ret = await projectStore.updateTempProject(loginUser.code);
			return ret;
		}
	}


	const handleClickDelete = async () => {
		const accepted = await confirm(i18next.t("평가과제를 삭제하시겠습니까?"));
		if (!accepted) {
			handleClosePopover();
			return;
		}

		projectStore.deleteProject();

		await alert(i18next.t("삭제되었습니다."));

		// 리스트 페이지로 돌아가기
		processAfterDeletion();
		naviCustom.go(`/projects`, true);
		// props.navigate(`/projects/list`, {replace: true});
	}

	const handleChangeGrade = (e) => {
		projectStore.changeProjectGrade(e.target.value);
	}

	const handleAddButtonClick = () => {
		if (projectStore.projectPlanTransfer.standards.length === 3) {
			alert(i18next.t("성취 기준 및 평가 기준은 최대 3개까지 적용할 수 있습니다."));
		} else {
			projectStore.addProjectPlanStandard();
		}
	}

	const handleClickClose = () => {
		handleClose();
	}

	const handleClose = () => {
		setOpenDialog(false);
		setOpenDialogForDeletionGrade(false);
		setSelectedStandardIdx(-1);
		setSelectedGradeIdx(-1);
	}

	const handleClickConfirmBtn = () => {
		setOpenDialog(false);
		processAfterStorage();
		naviCustom.go(`/projects/${projectCode}/create/info`, true);
		// props.navigate(`/projects/${projectCode}/create/info`, {replace: true});
	}

	const handleClickNext = async () => {
		const currentProjectCode = await processProject();
		if (!currentProjectCode) return;
		const response = await judgeCreateOrModify(currentProjectCode);
		if (!response) return;
		setOpenDialog(false);
		processAfterStorage();
		naviCustom.go(`/projects/${currentProjectCode}/create/question`, true);
		// props.navigate(`/projects/${currentProjectCode}/create/question`, {replace: true});
	}

	const handleClickSave = () => {
		setDialogType(0);
		setOpenDialog(true);
	}

	const handleClickDeleteStandard = async (e, standardIndex) => {
		let response = await confirm(i18next.t("성취 기준을 삭제 하시겠습니까?"));
		if (!response) return;
		projectStore.deleteProjectPlanStandard(standardIndex);
	}

	const handleClickAddGrade = (_, standardIndex) => {
		projectStore.addProjectPlanStandardGrade(standardIndex);
	};

	const handleClickDeleteGrade = (_, standardIdx, gradeIdx) => {
		setOpenDialogForDeletionGrade(true);
		setSelectedStandardIdx(standardIdx);
		setSelectedGradeIdx(gradeIdx);
	};

	const deleteStandardGrade = () => {
		if (selectedStandardIdx < 0 || selectedGradeIdx < 0) return;

		projectStore.deleteProjectPlanStandardGrade(selectedStandardIdx, selectedGradeIdx);
		handleClose();
	};






	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<Typography className={classes.mainTitle}>{i18next.t("1. 과제 개요")}</Typography>
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<Box>
								<IconButton className={clsx(classes.iconBtnReset, classes.moreMenu)} disableRipple onClick={handleClickDotsMoreMenu}>
									<DotsMoreMenu />
								</IconButton>
								<Popover
									id="simple-popper"
									open={!!dotsMoreMenuAnchorEl}
									anchorEl={dotsMoreMenuAnchorEl}
									onClose={handleClosePopover}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									className={classes.popoverBox}
								>
									<Box>
										<MenuList>
											<MenuItem
												onClick={handleClickDelete}
												disableRipple>
												<DeleteSubMenu />
												<Typography className={classes.deleteText}>{i18next.t("과제 삭제")}</Typography>
											</MenuItem>
										</MenuList>
									</Box>
								</Popover>
							</Box>
							<Box>
								<Button
									className={unableToSubmit ? classes.lineBtn : clsx(classes.lineBtn, classes.lineBtnActive)}
									disabled={unableToSubmit}
									onClick={handleClickSave}
									disableRipple>
									<Typography>{i18next.t("저장")}</Typography>
								</Button>
							</Box>

							<Box sx={{ marginLeft: "15px" }}>
								<Button
									className={unableToSubmit ? classes.greenBtn : clsx(classes.greenBtn, classes.greenBtnActive)}
									// disabled={unableToSubmit}
									onClick={handleClickNextBtn}
									disableRipple
								>
									<Typography>{i18next.t("다음")}</Typography>
								</Button>
							</Box>
						</Box>
					</Box>

					<Box className={classes.dialogContent2}>
						{isNotSimpleModeAssignment &&
							<Box className={classes.dialogHalf2} >
								<Box className={classes.basicTitleBox}>
									<Typography className={classes.dialogTitle2}>{i18next.t("교육과정 시기")}</Typography>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby="cirriculum-radio-group"
											name="cirriculum-radio-buttons-group"
											onChange={handleChangeCirriculumYear}
											value={project.curriculumYear && project.curriculumYear}
											className={classes.radioGroupBtn}
										>
											<FormControlLabel value={'2015'} control={<Radio />} label={i18next.t("2015년 개정")} />
											<FormControlLabel value={'2022'} control={<Radio />} label={i18next.t("2022년 개정")} />
										</RadioGroup>
									</FormControl>
								</Box>

								<Box className={classes.basicTitleBox}>
									<Typography className={classes.dialogTitle2}>{i18next.t("학교급")}</Typography>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby="school-type-radio-group"
											name="school-type-radio-buttons-group"
											onChange={handleChangeSchoolType}
											value={project.schoolType && project.schoolType}
											className={classes.radioGroupBtn}
										>
											<FormControlLabel value={ProjectSchoolType.ELEM} control={<Radio />} label={i18next.t("초등학교")} />
											<FormControlLabel value={ProjectSchoolType.MIDD} control={<Radio />} label={i18next.t("중학교")} />
											<FormControlLabel value={ProjectSchoolType.HIGH} control={<Radio />} label={i18next.t("고등학교")} />
										</RadioGroup>
									</FormControl>
								</Box>
							</Box>
						}
						<Box className={classes.dialogHalf2}>
							<Box className={classes.basicTitleBox}>
								<Typography className={classes.dialogTitle2}>{i18next.t("학년/교과/과목")}</Typography>
								<Box className={classes.selectBoxWidth1}>
									<FormControl className={classes.formControl}>

										{isNotSimpleModeAssignment &&

											<Select
												IconComponent={(props) => (
													<Box>
														<SelectIcon  {...props} />
													</Box>
												)}
												MenuProps={{
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "right"
													},
													transformOrigin: {
														vertical: 'top',
														horizontal: 'right',
													},
													// getContentAnchorEl: null,
													className: classes.selectPopover
												}}
												displayEmpty
												onChange={handleChangeGrade}
												value={project.grade ? project.grade : 0}
											>
												<MenuItem value={0}><Typography>{i18next.t("학년 선택")}</Typography></MenuItem>
												{isElementarySchoolChecked ?
													[<MenuItem value={GradeType.ELEMLOW}><Typography>1~2</Typography></MenuItem>,
													<MenuItem value={GradeType.ELEMMID}><Typography>3~4</Typography></MenuItem>,
													<MenuItem value={GradeType.ELEMHIGH}><Typography>5~6</Typography></MenuItem>]
													:
													<MenuItem value={GradeType.MIDDNHIGH}><Typography>1~3</Typography></MenuItem>
												}
											</Select>
										}

										{!isNotSimpleModeAssignment &&
											<Select
												IconComponent={(props) => (
													<Box>
														<SelectIcon  {...props} />
													</Box>
												)}
												MenuProps={{
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "right"
													},
													transformOrigin: {
														vertical: 'top',
														horizontal: 'right',
													},
													// getContentAnchorEl: null,
													className: classes.selectPopover
												}}
												displayEmpty
												onChange={handleChangeGrade}
												value={project.grade ? project.grade : 0}
											>
												<MenuItem value={`0`}><Typography>{i18next.t("레벨")}</Typography></MenuItem>
												[
												<MenuItem value={GradeType.G1}><Typography>1</Typography></MenuItem>,
												<MenuItem value={GradeType.G2}><Typography>2</Typography></MenuItem>,
												<MenuItem value={GradeType.G3}><Typography>3</Typography></MenuItem>,
												<MenuItem value={GradeType.G4}><Typography>4</Typography></MenuItem>,
												<MenuItem value={GradeType.G5}><Typography>5</Typography></MenuItem>,
												<MenuItem value={GradeType.G6}><Typography>6</Typography></MenuItem>,
												<MenuItem value={GradeType.G7}><Typography>7</Typography></MenuItem>,
												<MenuItem value={GradeType.G8}><Typography>8</Typography></MenuItem>,
												<MenuItem value={GradeType.G9}><Typography>9</Typography></MenuItem>,
												<MenuItem value={GradeType.G10}><Typography>10</Typography></MenuItem>,
												<MenuItem value={GradeType.G11}><Typography>11</Typography></MenuItem>,
												<MenuItem value={GradeType.G12}><Typography>12</Typography></MenuItem>,
												<MenuItem value={GradeType.G13}><Typography>13</Typography></MenuItem>,
												<MenuItem value={GradeType.G14}><Typography>14</Typography></MenuItem>,
												<MenuItem value={GradeType.G15}><Typography>15</Typography></MenuItem>,
												<MenuItem value={GradeType.G16}><Typography>16</Typography></MenuItem>,

												<MenuItem value={GradeType.ELEMLOW}><Typography>{`${i18next.t("초등학교_short")} ${GradeType.ELEMLOW}`}</Typography></MenuItem>,
												<MenuItem value={GradeType.ELEMMID}><Typography>{`${i18next.t("초등학교_short")} ${GradeType.ELEMMID}`}</Typography></MenuItem>,
												<MenuItem value={GradeType.ELEMHIGH}><Typography>{`${i18next.t("초등학교_short")} ${GradeType.ELEMHIGH}`}</Typography></MenuItem>,
												<MenuItem value={GradeType.MIDDNHIGH}><Typography>
													{
														project.schoolType === ProjectSchoolType.MIDD
															? `${i18next.t("중학교_short")} ${GradeType.MIDDNHIGH}`
															: `${i18next.t("고등학교_short")} ${GradeType.MIDDNHIGH}`
													}
												</Typography></MenuItem>,
												]
											</Select>


										}
									</FormControl>
								</Box>


								{isNotSimpleModeAssignment &&
									<Box className={classes.selectBoxWidth2}>
										<SubjectMenuItem
											subjectIndex={subjectIndex}
											handleChangeSubject={handleChangeSubject}
											subject={project.subject}
										/>
									</Box>
								}

								<Box className={classes.selectBoxWidth3}>
									<SubjectNameMenuItem
										schoolTypeId={!isNotSimpleModeAssignment ? ProjectSchoolType.NOGRADE : project.schoolType}
										subjectIndex={!isNotSimpleModeAssignment ? -1 : subjectIndex}
										subjectNameIndex={subjectNameIndex}
										subjectName={project.subjectName}
										project={project}
									/>
								</Box>
							</Box>

						</Box>
						<Box className={classes.basicTitleBox}>
							<Typography className={classes.dialogTitle2}>{i18next.t("단원명")}</Typography>
							<Box className={clsx(classes.dialogInput, classes.dialogInputMulti140N)}>
								<TextField
									id="outlined-basic-chapter"
									autoComplete="off"
									variant="outlined"
									// multiline
									minRows={1}
									value={project?.chapter}
									onChange={handleChangeChapter}
									placeholder={i18next.t("최대 {{maxLenChapter}}자", { maxLenChapter })} />
								<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} className={classes.countPosition}>
									<Typography className={classes.countNum}>{remainingChapterTextLen}</Typography>
									{project.chapter && project.chapter.length > 0 &&
										<IconButton
											className={classes.iconBtnReset}
											onClick={handleClearChapter}
											disableRipple>
											<BoardTextDelete />
										</IconButton>
									}
								</Box>
							</Box>
						</Box>
						<Box className={classes.basicTitleBox}>
							<Typography className={classes.dialogTitle2}>{i18next.t("평가 과제명")}</Typography>
							<Box className={clsx(classes.dialogInput, classes.dialogInputMulti140N)}>
								<TextField
									id="outlined-basic-project-name"
									autoComplete="off"
									variant="outlined"
									// multiline
									minRows={3}
									value={project?.name}
									onChange={handleChangeName}
									placeholder={i18next.t("최대 {{maxLenName}}자", { maxLenName })} />
								<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} className={classes.countPosition}>
									<Typography className={classes.countNum}>{remainingNameTextLen}</Typography>
									{name.length > 0 &&
										<IconButton
											className={classes.iconBtnReset}
											onClick={handleClearName}
											disableRipple>
											<BoardTextDelete />
										</IconButton>
									}
								</Box>
								{/*<Typography className={classes.warningText}><span>[항목명]</span>을 입력해 주세요.</Typography>*/}
							</Box>
						</Box>



						{
							isNotSimpleModeAssignment &&
							<>
								<Box className={classes.displayBetweenTopBox}>
									<Typography className={classes.dialogTitle}>{i18next.t("성과 기준 및 평가 기준")}</Typography>
									<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
										<Button className={classes.addBtn} onClick={handleAddButtonClick} disableRipple>
											<AddPlusIcon />
											<Typography>{i18next.t("추가")}</Typography>
										</Button>
									</Box>
								</Box>

								{projectPlanTransfer && projectPlanTransfer.standards && projectPlanTransfer.standards.map((standard, standardIndex) => {
									return (
										<div key={standardIndex}>
											<Box className={classes.bgInputBox}>
												<Box className={classes.basicTitleBox}>
													<Typography className={classes.schoolName2}>{i18next.t("성취 기준 코드")}</Typography>
													<Box className={clsx(classes.dialogInput, classes.dialogInputMulti140N, classes.margin)}>
														<TextField
															id="outlined-basic-standard-code"
															autoComplete="off"
															variant="outlined"
															// multiline
															minRows={10}
															value={standard.externalPlanCode}
															onChange={(e) => { projectStore.changeProjectPlanExternalPlanCode(e.target.value, standardIndex) }}
															placeholder={i18next.t("내용을 입력하세요. (최대 500자)")} />
													</Box>
												</Box>
												<Box className={classes.basicTitleBox} sx={{ marginTop: "10px" }}>
													<Typography className={classes.schoolName2}>{i18next.t("성취 기준 내용")}</Typography>
													<Box className={clsx(classes.dialogInput, classes.dialogInputMultiN, classes.margin)}>
														<TextField
															id="outlined-basic-standard-content"
															autoComplete="off"
															variant="outlined"
															multiline
															minRows={8}
															value={standard.standard}
															onChange={(e) => { projectStore.changeProjectPlanStandard(e.target.value, standardIndex) }}
															placeholder={i18next.t("내용을 입력하세요. (최대 10,000자)")} />
													</Box>
												</Box>
												<Box className={classes.basicTitleBox} sx={{ marginTop: "10px" }}>
													<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} className={classes.stepInputBox}>
														<Typography className={classes.schoolName2}>{i18next.t("평가 기준 입력")}</Typography>
													</Box>
													<Box className={classes.inputBox2}>
														{standard.grades.map((grade, gradeIndex) => {
															// 2024.04.03, hkkim@onthelive. 평가 기준을 기본값으로 설정 (상, 중, 하)
															// if(grade.grade === "") {
															//     projectStore.changeProjectPlanStandardGrade(gradeDefaultValues[gradeIndex],standardIndex,gradeIndex);
															// }

															return (
																<Box key={gradeIndex} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
																	className={clsx(classes.inputStep2, classes.inputStepLeft)}>
																	<Box className={classes.gradeName}>
																		<TextField
																			id="outlined-basic-grade-name"
																			autoComplete="off"
																			className={classes.gradeNameInput}
																			variant="outlined"
																			// multiline
																			inputProps={{ style: { textAlign: 'center' } }}
																			value={grade.grade}
																			onChange={(e) => { projectStore.changeProjectPlanStandardGrade(e.target.value, standardIndex, gradeIndex) }}
																			// placeholder={gradeIndex < 3 ? gradePlaceholder[gradeIndex] : "예) 상, 중, 하" }
																			placeholder={gradeIndex < 3 ? gradePlaceholder[gradeIndex] : i18next.t("입력")}
																		/>
																	</Box>
																	<Box className={classes.gradeContents}>
																		<TextField
																			id="outlined-basic-grade-contents"
																			autoComplete="off"
																			variant="outlined"
																			multiline
																			minRows={3}
																			value={grade.contents}
																			onChange={(e) => { projectStore.changeProjectPlanStandardGradeContents(e.target.value, standardIndex, gradeIndex) }}
																			placeholder={i18next.t("내용을 입력하세요. (최대 10,000자)")} />
																	</Box>
																	{standard.grades.length > minumumStandardGradeCount &&
																		<Tooltip
																			title={i18next.t("과제평가 기준표 삭제")}
																			classes={{ tooltip: classes.deleteTooltip }}
																		>
																			<Box className={classes.deleteGradeBox}
																				onClick={e => handleClickDeleteGrade(e, standardIndex, gradeIndex)}>
																				<TrashDeleteIcon />
																			</Box>
																		</Tooltip>
																	}
																</Box>
															)
														})}
														{standard.grades.length < maxStandardGradeCount &&
															<Box className={classes.addBox}>
																<Box className={classes.addGradeBox} onClick={(e) => handleClickAddGrade(e, standardIndex)}>
																	<Box className={classes.plusBtn}>
																		<AddPlusIcon />
																	</Box>
																	<Typography>{i18next.t("평가 기준 추가")}</Typography>
																</Box>
															</Box>
														}
													</Box>
												</Box>
												{standardIndex !== 0 &&
													<Box className={classes.deleteBtnBox}>
														<Button onClick={e => handleClickDeleteStandard(e, standardIndex)} disableRipple>
															<TrashDeleteIcon />
															<Typography>{i18next.t("삭제")}</Typography>
														</Button>
													</Box>
												}
											</Box>
										</div>)
								})
								}
								<Box className={classes.basicTitleBox} sx={{ marginTop: "30px" }}>
									<Typography className={classes.dialogTitle2}>{i18next.t("교과 역량")}</Typography>
									<Box className={clsx(classes.dialogInput, classes.dialogInputMultiN, classes.margin2)}>
										<TextField
											id="outlined-basic-required-subject-ability"
											autoComplete="off"
											variant="outlined"
											multiline
											minRows={8}
											value={projectPlanTransfer.requiredSubjectAbility}
											onChange={(e) => { projectStore.changeProjectPlanRequired(e.target.value) }}
											placeholder={i18next.t("내용을 입력하세요. (최대 10,000자)")} />
									</Box>
								</Box>
								<Box className={classes.basicTitleBox} sx={{ marginTop: "30px" }}>
									<Typography className={classes.dialogTitle2}>{i18next.t("출제 의도")}</Typography>
									<Box className={clsx(classes.dialogInput, classes.dialogInputMultiN, classes.margin2)}>
										<TextField
											id="outlined-basic-purpose"
											autoComplete="off"
											variant="outlined"
											multiline
											minRows={8}
											value={projectPlanTransfer.purpose}
											onChange={(e) => { projectStore.changeProjectPlanPurpose(e.target.value) }}
											placeholder={i18next.t("내용을 입력하세요. (최대 10,000자)")} />
									</Box>
								</Box>
							</>
						}


					</Box>
				</Box>
			</Box>

			<MessageDialog
				open={openDialog}
				titleText={""}
				children={dialogTitleText[dialogType]}
				isConfirm={dialogIsConfirm[dialogType]}
				onClick={dialogType === 0 ? handleClickTemporalStorage : dialogType === 1 ? handleClickNext : handleClickConfirmBtn}
				onClose={handleClickClose}
			>
			</MessageDialog>

			{
				isNotSimpleModeAssignment &&
				<MessageDialog
					open={openDialogForDeletionGrade}
					titleText={""}
					children={i18next.t('선택한 평가 기준을 삭제하시겠습니까?')}
					isConfirm={true}
					onClick={deleteStandardGrade}
					onClose={handleClickClose}
				>
				</MessageDialog>
			}
		</div>
	);

}

export default observer<typeof TempProjectCreateBasicInfo>(TempProjectCreateBasicInfo);
