
export enum ProjectCreateStatusType {
	INFO = "INFO",
	ANSWER = "ANSWER",
	EXAM = "EXAM",
	PLAN = "PLAN",
	RAG = "RAG",
	RUBRIC = "RUBRIC",
	SUBMIT = "SUBMIT"
}
