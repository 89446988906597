import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	MenuList,
	Popover,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import { ReactComponent as BoardTextDelete } from '../../../../common/images/BoardTextDelete.svg';
import { ReactComponent as CalendarIcon } from '../../../../common/images/CalendarIcon.svg';
import { ReactComponent as DeleteSubMenu } from '../../../../common/images/DeleteSubMenu.svg';
import { ReactComponent as DotsMoreMenu } from '../../../../common/images/DotsMoreMenu.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { AssignmentProjectCreateBasicInfoStlye } from "../../styles/AssignmentProjectCreateBasicInfoStlye";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { observer } from "mobx-react";
import React from "react";

import { useParams } from "react-router-dom";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectSemesterType } from "../../../../repositories/model/support/ProjectSemesterType";
import { AssignmentType } from "../../../../stores/enums/ProjectStoreEnums";
import { AmPm } from "../../../../stores/support/AmPm";
import { DateTimeUtils } from "../../../../util/dateTime-utils";
import MessageDialog from "../../../components/MessageDialog";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";

// dayjs.locale('ko');

type Props = {
}

const hourArr = Array.from({ length: 12 }, (_, index) => index + 1);
const minuteArray = Array.from({ length: 60 }, (_, index) => index);

function AssignmentProjectCreateBasicInfo(props: Props) {
	const params = useParams();
	const { projectStore, navigateStore, authStore } = useStores();
	const i18next = useTranslation();
	const classes = useEmotionStyles( AssignmentProjectCreateBasicInfoStlye );
	const { project } = projectStore;

	const [dotsMoreMenuAnchorEl, setDotsMoreMenuAnchorEl] = React.useState(null);
	const [projectCode, setProjectCode] = React.useState('');
	const [userCode, setUserCode] = React.useState('');
	const [openDialog, setOpenDialog] = React.useState(false);
	const [dialogType, setDialogType] = React.useState(0);
	const [endDateTimeAnchorEl, setEndDateTimeAnchorEl] = React.useState(null);
	const [isMainBoxHeightLessThanStandard, setIsMainBoxHeightLessThanStandard] = React.useState(false);

	const naviCustom = useNaviCustom();
	const {alert, confirm} = useNeoConfirm();

	const dialogTitleText = [
		i18next.t("저장 하시겠습니까?"),
		i18next.t("저장하고 다음으로 넘어가시겠습니까?"),
		i18next.t("평가과제운영 정보가 저장 되었습니다.")
	];
	const dialogIsConfirm = [true, true, false];

	React.useEffect(() => {
		dayjs.locale(navigateStore.language);

		projectStore.initProjectPlanTransfer();
		window.addEventListener('resize', resize);

		return () => {
			navigateStore.changeIsConfirmForUpdateRequired(false);
			projectStore.initProject();
			window.removeEventListener('resize', resize);
		}
	}, []);

	React.useEffect(() => {
		if (navigateStore.simpleMode) {
			projectStore.changeSemesterType(ProjectSemesterType.OTHER);
			projectStore.changeIsConfirmForUpdateRequired(false);
		}
	}, [navigateStore.simpleMode]);

	React.useEffect(() => {
		const code = params.projectCode;
		if (code === projectCode) return;
		setProjectCode(code);
	}, [params]);

	React.useEffect(() => {
		if (!projectCode) {
			projectStore.initProject();

			// kitty
			if (navigateStore.simpleMode) {
				projectStore.changeSemesterType(ProjectSemesterType.OTHER);
				projectStore.changeIsConfirmForUpdateRequired(false);
			}

			return;
		}

		const checkAndGo = async (code) => {
			try {
				const data = await projectStore.downloadProjectInfoAndStore(code)

				if (!data) {
					naviCustom.go('/assignments', true);
				}
			} catch (e) {
				console.error(e);
			}
		}

		checkAndGo(projectCode);
		// projectStore.getProjectInfo(projectCode)
		// 	.then(data => {
		// 		if (!data) {
		// 			naviCustom.go('/assignments', true);
		// 		}
		// 	})
	}, [projectCode]);

	React.useEffect(() => {
		const userCode = authStore.loginUser.code;
		setUserCode(userCode);
	}, [authStore.loginUser.code]);

	React.useEffect(() => {
		if (!projectStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectStore.isConfirmForUpdateRequired]);

	const unableToSubmit = React.useMemo(() => {
		const { semesterType, name, includedOnline, endDatetime } = project;

		const haveEmptyValue = (
			!semesterType
			|| !name
			|| (includedOnline && !endDatetime)
		);

		return haveEmptyValue;
	}, [project, project.semesterType, project.name, project.includedOnline, project.endDatetime]);

	const remainingNameTextLen = React.useMemo(() => {
		return 500 - project?.name.length;
	}, [project?.name]);

	const resize = () => {
		const mainContentBox = document.getElementsByClassName(classes.mainContentBox);
		if (mainContentBox.length > 0 && mainContentBox[0]?.clientHeight) {
			setIsMainBoxHeightLessThanStandard(mainContentBox[0].clientHeight < 810);
		}
	};

	const handleClickDotsMoreMenu = event => {
		setDotsMoreMenuAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setDotsMoreMenuAnchorEl(null);
	};

	const handleChangeSemesterType = (e) => {
		projectStore.changeSemesterType(e.target.value);
	};

	const handleChangeName = (e) => {
		const name = e.target.value;
		if (name.length > 500) return;

		projectStore.changeName(name);
	};

	const handleClearName = () => {
		projectStore.changeName('');
	};

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectStore.changeIsConfirmForUpdateRequired(false);
	};

	const handleClickTemporalStorage = async () => {
		const projectCode = await processProject();
		if (!projectCode) return;

		handleClickClose();
		processAfterStorage();
	};

	const handleClickNext = async () => {
		const currentProjectCode = await processProject();
		if (!currentProjectCode) return;

		handleClickClose();
		processAfterStorage();

		naviCustom.go(`/assignments/${currentProjectCode}/create/target`, true);
	};

	const handleClickNextBtn = () => {
		setDialogType(1);
		setOpenDialog(true);
	}

	const processProject = async () => {
		const data = await createOrUpdateProject();
		if (!data) return;

		const projectCode = data?.code;
		return projectCode;
	}

	const createOrUpdateProject = async () => {
		if (!projectCode) {
			return await projectStore.createProjectForCustom(userCode);
		} else {
			return await projectStore.updateProject(userCode);
		}
	}

	const handleClickDelete = async () => {
		const result = await confirm(i18next.t("평가과제운영 정보를 삭제하시겠습니까?"));
		if (!result) {
			handleClosePopover();
			return;
		}

		projectStore.deleteProject();
		await alert(i18next.t("삭제되었습니다."));
		processAfterStorage();

		naviCustom.go(`/assignments`, true);
	}

	const handleClickClose = () => {
		setOpenDialog(false);
	}

	const handleClickConfirmBtn = () => {
		handleClickClose();
		processAfterStorage();

		naviCustom.go(`/assignments`, true);
	}

	const handleClickSave = () => {
		setDialogType(0);
		setOpenDialog(true);
	};

	const handleChangeIncludedOnline = (e) => {
		let isOnlined = false;
		const type = e.target.value;
		if (type === AssignmentType.ONLINE) {
			isOnlined = true;
		}

		projectStore.changeIncludedOnline(isOnlined);
	};

	const handleClickEndCalendarBtn = (event) => {
		setEndDateTimeAnchorEl(event.currentTarget);
	};

	const handleCloseEndCalendar = () => {
		setEndDateTimeAnchorEl(null);
	};

	const setDateTimeByDate = (dateValue, currentDateTime) => {
		if (!dateValue) return;

		let newDateTime = dateValue;

		if (currentDateTime && dayjs.isDayjs(currentDateTime)) {
			newDateTime = newDateTime
				.hour(currentDateTime.hour())
				.minute(currentDateTime.minute())
		}

		return newDateTime;
	};

	const isSameOrAfterNow = (newDateTime) => {
		const selectedDate = dayjs(newDateTime);
		const today = dayjs();

		dayjs.extend(isSameOrAfter);

		const selectedDateTxt = getDateTimeText(selectedDate);
		const isSameOrAter = dayjs(selectedDate).isSameOrAfter(today);
		if (!isSameOrAter) {
			alert(i18next.t("선택하신 {{selectedDateTxt}}은(는) 현재보다 이전이므로 선택할 수 없습니다.", { selectedDateTxt }));
			return false;
		}

		return true;
	};

	const handleChangeEndDate = (dateValue) => {
		const newDateTime = setDateTimeByDate(dateValue, project.endDatetime);
		if (!isSameOrAfterNow(newDateTime)) return;

		projectStore.changeEndDatetime(newDateTime);
	};

	const setDateTimeByHour = (hourValue, currentDateTime, amPm) => {
		if (!hourValue) return;

		let newDateTime = dayjs();

		if (currentDateTime && dayjs.isDayjs(currentDateTime)) {
			newDateTime = currentDateTime;
		}

		let afterHour = hourValue;
		if (amPm === AmPm.AM && afterHour === 12) {
			afterHour -= 12;
		}
		if (amPm === AmPm.PM && afterHour !== 12) {
			afterHour += 12;
		}

		newDateTime = newDateTime.hour(afterHour);

		return newDateTime;
	};

	const handleChangeEndHour = (e) => {
		const hourValue = e.target.value;
		const newDateTime = setDateTimeByHour(hourValue, project.endDatetime, currentEndAmPm);
		if (!isSameOrAfterNow(newDateTime)) return;

		projectStore.changeEndDatetime(newDateTime);
	};

	const setDateTimeByMinute = (minuteValue, currentDateTime) => {
		if (minuteValue === undefined) return;

		let newDateTime = dayjs();

		if (currentDateTime && dayjs.isDayjs(currentDateTime)) {
			newDateTime = currentDateTime;
		}

		newDateTime = newDateTime.minute(minuteValue);

		return newDateTime;
	}

	const handleChangeEndMinute = (e) => {
		const minuteValue = e.target.value;
		const newDateTime = setDateTimeByMinute(minuteValue, project.endDatetime);
		if (!isSameOrAfterNow(newDateTime)) return;

		projectStore.changeEndDatetime(newDateTime);
	};

	const setDateTimeByAmPm = (amPmValue, currentDateTime) => {
		if (!amPmValue) return;

		let newDateTime = dayjs();

		if (currentDateTime && dayjs.isDayjs(currentDateTime)) {
			newDateTime = currentDateTime;
		}

		if (newDateTime.hour() > 11 /* now PM */ && amPmValue === AmPm.AM) {
			newDateTime = newDateTime.hour(newDateTime.hour() - 12);
		}

		if (newDateTime.hour() <= 11 /* now AM */ && amPmValue === AmPm.PM) {
			newDateTime = newDateTime.hour(newDateTime.hour() + 12);
		}

		return newDateTime;
	};

	const handleChangeEndAmPm = (e) => {
		const amPmValue = e.target.value;
		const newDateTime = setDateTimeByAmPm(amPmValue, project.endDatetime);
		if (!isSameOrAfterNow(newDateTime)) return;

		projectStore.changeEndDatetime(newDateTime);
	};

	const formatStringTwoDigits = (value) => {
		if (value === undefined) return;

		return DateTimeUtils.formatStringDigits(value, 2);
	};

	const getDateTimeText = (dateTime) => {
		if (!dateTime) return i18next.t("날짜/시간 선택");

		let currentDateTime = dateTime;
		const year = currentDateTime.year();
		const month = formatStringTwoDigits(currentDateTime.month() + 1);
		const date = formatStringTwoDigits(currentDateTime.date());
		const hour = formatStringTwoDigits(currentDateTime.hour());
		const minute = formatStringTwoDigits(currentDateTime.minute());

		return `${year}-${month}-${date} ${hour}:${minute}`;
	};

	const getDate = (dateTime) => {
		if (!dateTime || !dayjs.isDayjs(dateTime)) {
			return dayjs();
		}

		return dayjs()
			.year(dateTime.year())
			.month(dateTime.month())
			.date(dateTime.date());
	};

	const getHour = (dateTime) => {
		let hour = dayjs().hour();
		if (dateTime && dayjs.isDayjs(dateTime)) {
			hour = dateTime.hour();
		}

		if (hour > 12) {
			hour = hour - 12;
		}

		if (hour === 0) {
			hour = hour + 12;
		}

		return hour;
	};

	const getMinute = (dateTime) => {
		if (!dateTime || !dayjs.isDayjs(dateTime)) {
			return dayjs().minute();
		}
		return dateTime.minute();
	};

	const getAmPm = (dateTime) => {
		let currentDateTime = dayjs();

		if (dateTime && dayjs.isDayjs(dateTime)) {
			currentDateTime = dateTime;
		}

		if (currentDateTime.hour() > 11) {
			return AmPm.PM;
		}

		return AmPm.AM;
	};

	const currentEndDateTimeText = React.useMemo(() => {
		return getDateTimeText(project.endDatetime);
	}, [project.endDatetime]);

	const currentEndDate = React.useMemo(() => {
		return getDate(project.endDatetime);
	}, [project.endDatetime]);

	const currentEndHour = React.useMemo(() => {
		return getHour(project.endDatetime);
	}, [project.endDatetime]);

	const currentEndMinute = React.useMemo(() => {
		return getMinute(project.endDatetime);
	}, [project.endDatetime]);

	const currentEndAmPm = React.useMemo(() => {
		return getAmPm(project.endDatetime);
	}, [project.endDatetime]);

	const currentYear = React.useMemo(() => {
		return project?.year || '';
	}, [project?.year]);

	const currentSemesterType = React.useMemo(() => {
		return project?.semesterType || '-';
	}, [project?.semesterType]);

	const currentTemplateName = React.useMemo(() => {
		return project?.templateName || i18next.t("'3단계 - 평가과제 선택'에서 평가과제를 선택하면 자동 표시되는 항목입니다.");
	}, [project?.templateName]);

	const currentName = React.useMemo(() => {
		return project?.name || '';
	}, [project?.name]);

	const currentIncludedOnline = React.useMemo(() => {
		return project?.includedOnline || false;
	}, [project?.includedOnline]);

	return (
		<div className={classes.root}>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 85px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>

				<Box className={classes.mainContentBox}>
					<Box className={classes.commonTopTitle}>
						<Typography className={classes.commonMainTitle}>{i18next.t("1. 과제 정보")}</Typography>
						<Box>
							<IconButton className={clsx(classes.iconBtnReset, classes.moreMenu)} disableRipple onClick={handleClickDotsMoreMenu}>
								<DotsMoreMenu />
							</IconButton>
							<Popover
								id="simple-popper"
								open={!!dotsMoreMenuAnchorEl}
								anchorEl={dotsMoreMenuAnchorEl}
								onClose={handleClosePopover}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								className={classes.popoverBox}
							>
								<Box>
									<MenuList>
										<MenuItem
											onClick={handleClickDelete}
											disableRipple>
											<DeleteSubMenu />
											<Typography className={classes.deleteText}>{i18next.t("과제 삭제")}</Typography>
										</MenuItem>
									</MenuList>
								</Box>
							</Popover>

							<Button
								className={classes.saveBtn}
								disabled={unableToSubmit}
								onClick={handleClickSave}
								disableRipple>
								<Typography>{i18next.t("저장")}</Typography>
							</Button>

							<Button
								className={classes.nextBtn}
								disabled={unableToSubmit}
								onClick={handleClickNextBtn}
								disableRipple>
								<Typography>{i18next.t("다음")}</Typography>
							</Button>
						</Box>
					</Box>

					<Box className={classes.dialogContent}>
						{(!navigateStore.simpleMode) &&
							<Box className={classes.basicContent} >
								<Box className={classes.basicTitleBox}>
									<Typography className={classes.basicTitle}>{i18next.t("학년도 / 학기")}</Typography>
									<Box className={classes.titleBox}>
										<Typography className={classes.titleList}><span>{currentYear}</span> {i18next.t("학년도")}</Typography>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="semester-type-radio-group"
												name="semester-type-radio-buttons-group"
												className={classes.radioGroupBtn}
												value={currentSemesterType}
												onChange={handleChangeSemesterType}>

												<FormControlLabel value={ProjectSemesterType.FIRST}
													control={<Radio />}
													label={i18next.t("1학기")} />
												<FormControlLabel value={ProjectSemesterType.SECOND}
													control={<Radio />}
													label={i18next.t("2학기")} />
												<FormControlLabel value={ProjectSemesterType.OTHER}
													control={<Radio />}
													label={i18next.t("해당 없음")} />
											</RadioGroup>
										</FormControl>
									</Box>
								</Box>
							</Box>
						}

						<Box className={classes.basicContent}>
							<Box className={classes.basicTitleBoxTop2}>
								<Typography className={classes.basicTitle}>{i18next.t("평가 과제운영명")}</Typography>
								<Box className={classes.titleBox}>
									<Box className={clsx(classes.dialogInput, classes.basicInputBox2)}>
										<TextField
											id="outlined-basic-assessment-title"
											variant="outlined"
											minRows={3}
											value={currentName}
											onChange={handleChangeName}
											placeholder={i18next.t("최대 500자")} />
										<Box display='flex' alignItems='center' justifyContent='right' className={classes.countPosition}>
											<Typography className={classes.countNum}>{remainingNameTextLen}</Typography>
											{currentName.length > 0 &&
												<IconButton
													className={classes.iconBtnReset}
													onClick={handleClearName}
													disableRipple>
													<BoardTextDelete />
												</IconButton>
											}
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box className={classes.basicContent} >
							<Box className={classes.basicTitleBoxTop}>
								<Typography className={classes.basicTitle}>{i18next.t("제시방식")}</Typography>
								<Box className={classes.titleBox2}>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby="cirriculum-radio-group"
											name="cirriculum-radio-buttons-group"
											value={currentIncludedOnline ? AssignmentType.ONLINE : AssignmentType.OFFLINE}
											onChange={handleChangeIncludedOnline}
											className={classes.radioGroupBtn}
										>
											<FormControlLabel value={AssignmentType.OFFLINE} control={<Radio />} label={i18next.t("선생님 일괄 등록용 평가과제")} />
											<FormControlLabel value={AssignmentType.ONLINE} control={<Radio />} label={i18next.t("학생 등록용 평가과제")} />
										</RadioGroup>
									</FormControl>

									<Box className={classes.assignmentType}>
										{!currentIncludedOnline
											?
											<Typography>
												{i18next.t("* 평가과제지를 출력하여 학생들에게 배부합니다.")}
											</Typography>
											:
											<>
												<Typography>
													{i18next.t("* 온라인 평가과제는 마감일을 지정해야 합니다. 마감일이 지나면 학생은 제출할 수 없습니다.")}
												</Typography>

												<Box className={classes.timeCalendarBox}>
													<Typography className={classes.dialogTime}>{i18next.t("제출기한")}</Typography>
													<Box className={classes.dialogInputCalendar} display='flex' alignItems='center' justifyContent='center'>
														<Box sx={{ position: 'relative' }}>
															<TextField
																id="outlined-text-field"
																variant="outlined"
																value={currentEndDateTimeText}
																onClick={handleClickEndCalendarBtn}
																InputProps={{
																	startAdornment: (
																		<IconButton
																			// position="start"
																			sx={{ position: "start" }}
																			className={classes.iconBtnReset}
																			id="basic-button"
																			aria-controls={!!endDateTimeAnchorEl ? 'basic-menu' : undefined}
																			aria-haspopup="true"
																			aria-expanded={!!endDateTimeAnchorEl ? 'true' : undefined}
																			disableRipple>
																			<CalendarIcon />
																		</IconButton>
																	),
																}}
															/>
														</Box>
														<Menu
															id="basic-menu"
															anchorEl={endDateTimeAnchorEl}
															open={!!endDateTimeAnchorEl}
															onClose={handleCloseEndCalendar}
															MenuListProps={{
																'aria-labelledby': 'basic-button',
															}}
															PaperProps={isMainBoxHeightLessThanStandard ? {} : {
																className: classes.endCalendar
															}}
														>
															<Box display={'flex'} justifyContent={'flex-end'} className={classes.calendarTooltipBtn}>
																<Button
																	className={classes.calendarLineBtn}
																	onClick={handleCloseEndCalendar}
																	disableRipple>
																	<Typography>{i18next.t("확인")}</Typography>
																</Button>
															</Box>
															{/* <ForwardRefEndtLocalizationProvider dateAdapter={AdapterDayjs} >
																<DemoContainer components={['DateCalendar', 'DateCalendar']}>
																	<DemoItem className={classes.calendarBox}>
																		<DateCalendar
																			value={currentEndDate}
																			onChange={handleChangeEndDate} />
																	</DemoItem>
																</DemoContainer>
															</ForwardRefEndtLocalizationProvider> */}

															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DemoContainer components={['DateCalendar', 'DateCalendar']}>
																	{/* <DemoItem className={classes.calendarBox}> */}
																	<DemoItem>
																		<DateCalendar
																			value={currentEndDate}
																			onChange={handleChangeEndDate}
																		/>
																	</DemoItem>
																</DemoContainer>
															</LocalizationProvider>

															<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.timeBox}>
																<Typography>{i18next.t("시간")}</Typography>
																<Box display='flex' alignItems='center' justifyContent='space-between'>
																	<FormControl className={clsx(classes.formControl, classes.formControlWidth)}>
																		<Select
																			IconComponent={(props) => (
																				<Box>
																					<SelectIcon  {...props} />
																				</Box>
																			)}
																			MenuProps={{
																				anchorOrigin: {
																					vertical: "bottom",
																					horizontal: "right"
																				},
																				transformOrigin: {
																					vertical: 'top',
																					horizontal: 'right',
																				},
																				// getContentAnchorEl: null,
																				className: classes.selectPopover
																			}}
																			displayEmpty
																			onChange={handleChangeEndHour}
																			value={currentEndHour}
																		>
																			<MenuItem key={'end-hour-default'} disabled style={{ display: 'none' }} value="">
																				<em>{i18next.t("시간")}</em>
																			</MenuItem>
																			{hourArr.map(hour => <MenuItem key={`end-hour-${hour}`} value={hour}>{formatStringTwoDigits(hour)}</MenuItem>)}
																		</Select>
																	</FormControl>
																	<Typography>:</Typography>
																	<FormControl className={clsx(classes.formControl, classes.formControlWidth)}>
																		<Select
																			IconComponent={(props) => (
																				<Box>
																					<SelectIcon  {...props} />
																				</Box>
																			)}
																			MenuProps={{
																				anchorOrigin: {
																					vertical: "bottom",
																					horizontal: "right"
																				},
																				transformOrigin: {
																					vertical: 'top',
																					horizontal: 'right',
																				},
																				// getContentAnchorEl: null,
																				className: classes.selectPopover
																			}}
																			displayEmpty
																			onChange={handleChangeEndMinute}
																			value={currentEndMinute}
																		>
																			<MenuItem key={'end-minute-default'} disabled style={{ display: 'none' }} value="">
																				<em>{i18next.t("분")}</em>
																			</MenuItem>
																			{minuteArray.map(minute => <MenuItem key={`end-minute-${minute}`} value={minute}>{formatStringTwoDigits(minute)}</MenuItem>)}
																		</Select>
																	</FormControl>
																	<Box>
																		<ToggleButtonGroup
																			color="primary"
																			value={currentEndAmPm}
																			exclusive
																			onChange={handleChangeEndAmPm}
																			aria-label="Platform"
																			className={classes.toggleBtnBox}
																		>
																			<ToggleButton value={AmPm.AM} disableRipple>{AmPm.AM}</ToggleButton>
																			<ToggleButton value={AmPm.PM} disableRipple>{AmPm.PM}</ToggleButton>
																		</ToggleButtonGroup>
																	</Box>
																</Box>
															</Box>
														</Menu>
													</Box>
												</Box>
											</>
										}
									</Box>
								</Box>
							</Box>
						</Box>

					</Box>
				</Box>
			</Box>

			{openDialog &&
				<MessageDialog
					open={openDialog}
					children={dialogTitleText[dialogType]}
					isConfirm={dialogIsConfirm[dialogType]}
					onClick={dialogType === 0 ? handleClickTemporalStorage : dialogType === 1 ? handleClickNext : handleClickConfirmBtn}
					onClose={handleClickClose}
				>
				</MessageDialog>
			}

			{projectStore.isSelectingProjectInfo &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isSelectingProjectInfo}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{(projectStore.isCreatingProjectForCustom || projectStore.isUpdatingProject) &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isCreatingProjectForCustom || projectStore.isUpdatingProject}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("저장 중입니다...")}</Typography>
				</Backdrop>
			}

			{projectStore.isDeletingProject &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectStore.isDeletingProject}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("삭제 중입니다...")}</Typography>
				</Backdrop>
			}

		</div>
	);
}

export default observer<typeof AssignmentProjectCreateBasicInfo>(AssignmentProjectCreateBasicInfo);
