import { Box } from "@mui/material";
import React from 'react';

export function validateEmail(email) {
    const regExp = /^\w([\w-.]+)@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/;
    return regExp.test(String(email).toLowerCase());
}

export function validateName(name:string ) {
    // const regExp = /^[가-힣a-zA-Z\d][가-힣a-zA-Z\d\s]{0,50}[가-힣a-zA-Z\d]$/g;
    const regExp = /^[가-힣a-zA-Z\d一-龥ぁ-ゔァ-ヴー々〆〤\s]{1,52}$/u;
    const valid = regExp.test(name);
		console.log(`name valid: ${name} ==> ${valid}`);
		return valid;
}


export function validatePasswordCombination(password) {
    const num = password.search(/\d/g);
    const eng = password.search(/[a-z]/gi);
    const spe = password.search(/[`~!@#$%^&*|₩'";:/?]/gi);

    let result = true;

    if ((num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0)) {
        result = false;
    }
    return result;
}

export function validatePasswordLength(password) {
    const pw = password;

    let result = true;

    if (pw.length < 10 || pw.length > 20) {
        result = false;
    }
    return result;
}

export function removeSpacechar(e) {
    const space = /\s/;
    if (space.exec(e.target.value)) {
        e.target.value = e.target.value.replace(' ', '');
        return false;
    }
}

export function validateFileExtension(fileName) {
    const regExp = /.(zip|pdf|mp4|hwp|doc|docx|xls|xlsx|ppt|pptx|txt|gif|jpeg|jpg|png)/gi;
    return regExp.test(fileName);
}

export function checkFullStop(e) {
    const regExp = /:/g;
    if (regExp.exec(e.target.value)) {
        e.target.value = e.target.value.replace(':', '');
        return false;
    }
}

export function checkDot(e) {
    const regExp = /./g;
    if (regExp.exec(e.target.value)) {
        e.target.value = e.target.value.replace('.', '');
        return false;
    }
}

export function checkFileOrFolderNameLength(e) {
    const allowedLength = 50;
    if (e && e.target.value.length > allowedLength) {
        e.target.value = e.target.value.substring(0, allowedLength);
        return false;
    }
}

export function checkTextValueLength(e, allowedLength = 50) {
    const value = e.target.value;
    if (!value || typeof value !== 'string') return false;

    if (value.length > allowedLength) {
        e.target.value = value.substring(0, allowedLength);
        return false;
    }
}

export function validateFileOrFolderName(fileName) {
    const regExp1 = /^(?!\.)(?!com[1-9]$)(?!con$)(?!lpt\d$)(?!nul$)(?!prn$)(?!aux$)[^\|\*\?\\:<>/$"]*[^\.\|\*\?\\:<>/$"]+$/;
    return regExp1.test(String(fileName));
}

export function checkMention(value) {
    const regExp = /\B@([\.\w]*)$/;
    return regExp.test(String(value).toLowerCase());
}

export function checkLetterNumber(e) {
    const charRegExp = /[~!@\#$%^&*\()\-=+_'\;<>\/.\`:\"\\,\[\]?|{}]/gi;
    const koreanRegExp = /[ㄱ-ㅎㅏ-ㅣ]/gi;
    if (charRegExp.exec(e.target.value)) {
        e.target.value = e.target.value.replace(charRegExp, '');
    }
    if (koreanRegExp.exec(e.target.value)) {
        e.target.value = e.target.value.replace(koreanRegExp, '');
    }
    return false;
}

export function getNoticeContents(content) {
    const getClickableLink = link => {
        return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
    };
    const urlRegex =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    const replace = () => {
        const convertContent = content.replace(urlRegex, function (url) {
            return (
                '<a style="text-decoration: none" href="' +
                getClickableLink(url) +
                '" target="blank"  rel=”noopener” style="margin:0" >' +
                url +
                '</a> '
            );
        });
        return { __html: convertContent };
    };
    return <Box dangerouslySetInnerHTML={replace()} />;
}

export function getLowerCaseInputValue(e) {
    const {
        target: { value },
    } = e;
    e.target.value = value.toLowerCase();
}
