import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class NeoPenRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/neolab";
	}

	// getAIScore = (recognitionBody) => {
	//     return new Promise((resolve, reject) => {
	//         this.getRequestPromise("post", `${this.requestPrefix}/{answerCode}/rubrics/${rubricId}/ai`)
	//           .then((data) => {
	//               resolve(data);
	//           })
	//           .catch((error) => {
	//               reject(error);
	//           });
	//     });
	// };
}
