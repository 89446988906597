import { Theme } from '@mui/material/styles';

import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

export const LandingComponentStyle: (theme: Theme) => Record<string, CSSInterpolation> = (theme) => {
	const style: Record<string, CSSInterpolation> = {
		container: {
			// backdropFilter:'blur(2px)',
			boxSizing: 'border-box',
		},
		topbar: {
			position: 'fixed',
			left: 0,
			top: 0,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '12px 24px',
			boxSizing: 'border-box',
		},
		logo: {
			width: 121,
			height: 34,
		},
		buttonStyle: {
			backgroundColor: '#fff',
			color: '#008c73',
			padding: '8px 24px',
			fontSize: '1rem',
			fontWeight: 700,
			'& svg': {
				stroke: '#000',
			},
			'&:hover': {
				background: '#fff',
			},
		},

		langButtonStyle: {
			backgroundColor: '#fff',
			color: '#008c73',
			padding: '8px 24px',
			fontSize: '1rem',
			fontWeight: 700,
			'& svg': {
				stroke: '#000',
			},
			'&:hover': {
				background: '#fff',
			},
			// borderRadius: "5px",
		},


		lendingWrap: {
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			gap: 80,
			paddingBottom: 120,
			boxSizing: 'border-box',
			'@media all and (max-height: 1080px)': {
				gap: 60,
				paddingBottom: 60,
			}
		},
		info: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 42,
			'@media all and (max-height: 1080px)': {
				'& > img': {
					width: '70%',
					height: '70%',
				},
			}
		},
		slogan: {
			color: '#fff',
			fontSize: '3.5rem',
			fontWeight: 100,
			lineHeight: '1.35',
			textShadow: '0px 1px 4px #268070c2',
			'@media all and (max-height: 1080px)': {
				fontSize: '2.5rem',
			}
		},
		bodyStyle: {
			color: '#fff',
			fontSize: '1.125rem',
			fontWeight: 300,
			lineHeight: '134.9%',
			letterSpacing: '-0.45px',
			textShadow: '0px 1px 4px #268070',
			'@media all and (max-height: 1080px)': {
				fontSize: '1rem',
			}
		},
		boxStyle: {
			display: 'flex',
			gap: 32,
			'@media all and (max-width: 1280px)': {
				padding: '0 24px',
				boxSizing: 'border-box',
			}
		},
		card: {
			width: 300,
			display: 'flex',
			flexDirection: 'column',
			gap: 16,
			padding: 40,
			backgroundColor: '#fff',
			borderRadius: 24,
			// animation:'$blink 5s linear infinite',
			boxShadow: '0 2px 5px #b0b0b08f',
			'& .MuiTypography-root': {
				textAlign: 'left',
			},
			'& > svg': {
				width: 32,
				height: 32,
				marginLeft: 'auto',
			},
			'&:nth-of-type(2)': {
				animationDelay: '1.2s',
			},
			'&:nth-of-type(3)': {
				animationDelay: '2.4s',
			},
			'@media all and (max-width: 1280px)': {
				width: 'auto',
				padding: 32,
				flex: 1,
			},
			'@media all and (max-height: 1080px)': {
				padding: 24,
			}
		},
		subTitle: {
			color: '#5DC3F9',
			fontSize: '1.5rem',
			fontWeight: 800,
			letterSpacing: '-0.45px',
			'@media all and (max-height: 1080px)': {
				fontSize: '1rem',
			}
		},
		contentTxt: {
			color: '#6a6f71',
			fontSize: '1.125rem',
			fontWeight: 500,
			letterSpacing: '-0.45px',
			'@media all and (max-height: 1080px)': {
				fontSize: '0.875rem',
			}
		},

		'@keyframes blink': {
			'0%': {
				opacity: 0.5,
				boxShadow: 'none',
			},
			'100%': {
				opacity: 1,
				boxShadow: '0 2px 5px #b0b0b08f',
			},
		},


		// kitty
		LanguageBox: {
			'& .MuiPopover-paper': {
				// borderRadius: 15,
				borderRadius: (theme as Theme).shape.borderRadius,
				boxShadow: (theme as Theme).shadows[3],
				minWidth: 150,  // 최소 너비를 150px로 줄임
				maxWidth: 200,  // 최대 너비를 200px로 제한
			},
		},
		LanguageBoxInner: {
			padding: (theme as Theme).spacing(2),
		},
		languageTitle: {
			fontWeight: 600,
			fontSize: '1.1rem',
			marginBottom: (theme as Theme).spacing(2),
			borderBottom: `1px solid ${theme.palette.divider}`,
			paddingBottom: theme.spacing(1),
		},
		LanguageButton: {
			width: '100%',
			justifyContent: 'flex-start',
			padding: (theme as Theme).spacing(1, 2),
			margin: (theme as Theme).spacing(0.5, 0),
			borderRadius: theme.shape.borderRadius,
			transition: 'background-color 0.3s',
			textTransform: 'none',  // 추가: 대문자 변환 비활성화

			'&:hover': {
				backgroundColor: (theme as Theme).palette.action.hover,
			},
			'& .MuiTypography-root': {
				fontWeight: 500,
			},
		},
		SelectedLanguage: {
			color: (theme as Theme).palette.primary.main,
			backgroundColor: (theme as Theme).palette.action.selected,
			'&:hover': {
				backgroundColor: (theme as Theme).palette.action.selected,
			},
			'& .MuiTypography-root': {
				fontWeight: 600,
			},
		},
		iconBtnReset: {
			'&.MuiIconButton-root': {
				padding: 0,
				'&:hover': {
					background: 'transparent'
				},
			}
		},
		iconButton: {
			'&.MuiIconButton-root': {
				marginLeft: "20px",
			}
		},
	}
	return style
};
