





// ProjectSubmissionAnswerStore
export enum SortingHintColumn {
	SCORED_DATE_TIME = "scored_datetime",
	NAME = "user_name",
	EXPORTED_DATE_TIME = "exported_datetime"
};
