import { IProjectSubmissionAnswerScoreTableTransfers } from "./model/transfer/IProjectSubmissionAnswerScoreTableTransfers";

import { IProjectSubmissionAnswerScoreTableTransfer } from "./model/transfer/IProjectSubmissionAnswerScoreTableTransfer";

import { IProjectSubmissionTransfer } from "./model/transfer/IProjectSubmissionTransfer";
import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class ProjectSubmissionAnswerScoreRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	// getAnswerScoreList = (projectCode, params) => {
	//     return new Promise((resolve, reject) => {
	//         this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/submissions/answers/scores/table`, params)
	//             .then((data) => {
	//                 resolve(data);
	//             })
	//             .catch((error) => {
	//                 reject(error);
	//             });
	//     });
	// };


	public async getAnswerScoreList(projectCode: string, params, data) {
		const result = await this.getRequestPromise<IProjectSubmissionAnswerScoreTableTransfers>("post", `${this.requestPrefix}/${projectCode}/submissions/answers/scores/table`, params, data);
		return result;
	}
	public async getAnswerScoreTotalList(projectCode: string, params) {
		const result = await this.getRequestPromise<IProjectSubmissionAnswerScoreTableTransfer[]>("get", `${this.requestPrefix}/${projectCode}/submissions/answers/scores/table`, params);
		return result;
	}

	public async getSubmissionInfo(submissionCode: string) {
		const result = await this.getRequestPromise<IProjectSubmissionTransfer>("get", `${this.requestPrefix}/submissions/${submissionCode}/answers/scores`);
		return result;
	}

	public async getSubmissionInfoByOrder(projectCode: string, params) {
		const result = await this.getRequestPromise<IProjectSubmissionTransfer>("get", `${this.requestPrefix}/${projectCode}/submissions/answers/scores`, params);
		return result;
	}
}