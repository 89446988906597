import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const RubricStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	iconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	closedIconBtnReset: {
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},

			'& svg': {
				'& path': {
					stroke: '#6F6F6F',
				},
				'& rect': {
					fill: '#6F6F6F',
				}
			},
		}
	},
	standardBtnIcon: {
		'&.MuiIconButton-root': {
			padding: 0,
			marginTop: '2px',
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		padding: 15,
	},
	minaContentLeftRight: {
		width: '100%',
		maxWidth: 1482,
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	mainContentBox: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 145px)',
		overflowY: 'auto',
		background: '#fff',
		margin: '0px 15px',
	},
	mainContentBoxLeft: {
		border: '1px solid #EAECEB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		// overflowY: 'auto',
		background: '#fff',
		// width: 'calc(100vw - 770px)',
		width: '93%',
		minWidth: 600,
		maxWidth: 1024,
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '620px',
		boxSizing: 'border-box',
		marginLeft: "10px",
	},


	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	schoolNameSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
		}
	},
	taskTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	taskTitleBold: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.25rem',
			letterSpacing: '-1px',
			marginBottom: "15px",
			fontWeight: 500,
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	greenBtn: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			// background: '#008c73',
			background: '#cdcdcd',
			'&:hover': {
				// background: '#008c73',
				background: '#cdcdcd',
			},
			'& .MuiTypography-root': {
				// color: '#fff',
				color: '#888',
				fontWeight: 'bold'
			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	greenBtnActive: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		},
		'&.Mui-disabled': {
			opacity: 0.5,
			border: '1px solid rgba(0, 0, 0, 0.12)',
			'& .MuiTypography-root': {
				color: 'rgba(0, 0, 0, 0.26)',
			},
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	lineBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	greenLineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#cdcdcd',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold'
			}
		}
	},
	greenLineBtnActive: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		},
	},
	formControlTable: {
		'&.MuiFormControl-root ': {
			width: "96px",
		},
		'& .MuiSelect-select': {
			width: "96px",
			height: "22px",
			fontSize: '0.875rem',
			boxSizing: 'border-box',
		},
	},
	selectPopover: {
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#6f6f6f',
			marginLeft: "10px",
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "10px",
		paddingBottom: 10,
		borderBottom: '1px solid #CBCBCB',
		marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#555',
				color: '#fff',
				border: '1px solid #555',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #555',
			color: '#555',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},
	standardEvaluationPageNumber: {
		marginTop: "10px",
		paddingBottom: 10,
		borderBottom: '1px solid #CBCBCB',
		// marginBottom: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#555',
				color: '#fff',
				border: '1px solid #555',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #555',
			color: '#555',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},
	pageNumberBorder: {
		borderBottom: 0,
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	tableBox: {
		marginBottom: "10px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				letterSpacing: '-0.5px',
				fontWeight: 700,
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "44px",
				background: '#F3F3F3',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '0.875rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		},
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#555',
			letterSpacing: '-0.5px',
		},
	},
	bgColor: {
		height: '140px !important',
		background: '#DFDFDF',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	sortButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& p': {
			fontSize: '0.875rem',
			color: '#555',
			marginRight: "4px",
			letterSpacing: '-0.5px',
			fontWeight: 700,
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	scoreMarkInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			// padding: '9px 5px',
			padding: 0,
			height: '92px !important',
			// textAlign     : 'center',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	scoreMarkInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "120px",
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#fff',
		}
	},
	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "440px",
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 5px',
			height: "19px",
			letterSpacing: '-0.5px'
		},
		'& svg': {
			marginLeft: 10
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		// marginTop: "30px",
		background: '#E0E0E0',
		height: 'calc(100vh - 490px)',
		overflowY: 'auto',
		position: 'relative'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	emptySystemRubrics: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '40px'
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #CBCBCB',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "180px",
			height: "35px",
			border: '1px solid #878787;',
			boxShadow: 'none'
		},
		'& .MuiInputBase-root': {
			width: "150px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 480px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	greenBtnWide: {
		'&.MuiButtonBase-root': {
			width: "168px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	trigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: "440px",
		marginBottom: "34px",
		'&.MuiTabs-root': {
			minHeight: 40
		},
		'& .MuiTabs-flexContainer': {
			// width: "208px",
			border: '1px solid #F3F3F3',
			borderRadius: 8,
			overflow: 'hidden',
		},
		'& button': {
			width: "110px",
			minWidth: 110,
			minHeight: 36,
			height: "36px",
			position: 'relative',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px',
			color: '#787878',
			boxSizing: 'border-box',
			padding: 0,
			background: '#fff',
			'& .MuiTab-wrapper': {
				fontSize: '0.875rem',
				color: '#111'
			}
		},
		'& button.Mui-selected': {
			fontWeight: 'bold',
			color: '#fff',
			opacity: 1,
			boxSizing: 'border-box',
			background: '#2F80ED',
			borderRadius: 6,
			'& .MuiTab-wrapper': {
				color: '#fff'
			}
		},
		'& .MuiTabs-indicator': {
			height: "0px",
		}
	},
	matching: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			fontSize: '0.875rem',
			color: '#9F9F9F',
		},
	},
	matchingActive: {
		'&.MuiTypography-root': {
			marginLeft: "10px",
			fontSize: '0.875rem',
			color: '#008C73'
		},
	},
	manageIconList: {
		'& .MuiIconButton-root': {
			marginRight: "13px",
		}
	},
	rangeInput: {
		'& .MuiInputBase-input': {
			height: "10px",
			padding: '11px 7px',
		}
	},
	uploadWidth: {
		marginLeft: "10px",
		'& .MuiInputBase-input': {
			width: "80px",
		}
	},
	dataOpenDialog: {
		'& .MuiPaper-root': {
			width: "785px",
			height: '90vh',
			borderRadius: '15px'
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 785,
		}
	},

	dialogTop: {
		padding: '30px 30px',
		borderBottom: '1px solid #CBCBCB',
		'& .MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
		}
	},
	dialogContent: {
		padding: 30,
	},
	// uploadBox: {
	//     border: '1px dashed #111',
	//     borderRadius: 15,
	//     padding: '30px 0',
	//     background: '#F3F3F3',
	//     '& .MuiTypography-root': {
	//         textAlign: 'center',
	//         fontSize: '1.25rem',
	//         fontWeight: 500,
	//         letterSpacing: '-0.5px',
	//     },
	// },
	uploadBox: {
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: '1.125rem',
			color: '#111'
		}
	},
	SubTextBox: {
		textAlign: 'center',
	},
	SubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			marginTop: "20px",
		}
	},
	uploadBoxBtn: {
		textAlign: 'center',
		marginTop: "20px",
		'& .MuiButtonBase-root': {
			border: '1px solid #cbcbcb',
			borderRadius: 6,
			width: "74px",
			height: "37px",
			background: '#fff',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 700,
			}
		}
	},
	uploadGuideText: {
		marginTop: "11px",
		marginBottom: "30px",
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 400,
			color: '#555',
			letterSpacing: '-0.5px',
			'& span': {
				fontWeight: 600,
			}
		}
	},
	uploadListTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#555',
			letterSpacing: '-0.5px',
		}
	},
	uploadListSub: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			color: '#555',
			letterSpacing: '-0.5px',
		}
	},

	dialogBtnGroup: {
		marginTop: "30px",
		marginBottom: "30px",
	},
	dialogBtn: {
		'&.MuiButtonBase-root': {
			border: '1px solid #CBCBCB',
			width: "120px",
			height: "40px",
		},
		'&.MuiButton-root:hover': {
			background: '#fff'
		},
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	dialogBtnSave: {
		'&.MuiButtonBase-root': {
			border: '1px solid #2F80ED',
			background: '#2F80ED',
			width: "120px",
			height: "40px",
			marginLeft: "15px",
		},
		'&.MuiButton-root:hover': {
			background: '#2F80ED'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	},
	uploadListTable: {
		borderTop: '1px solid #CBCBCB',
		borderBottom: '1px solid #CBCBCB',
		padding: '12px 11px',
	},
	uploadFileDetail: {
		background: '#F3F3F3',
		padding: '24px 20px',
		'& .MuiTypography-root': {
			color: '#111',
			fontWeight: 700,
		},
		'& .MuiButtonBase-root': {
			background: 'transparent',
			'& .MuiTypography-root': {
				color: '#3552F2'
			}
		}
	},
	uploadText: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '0.813rem',
			color: '#666',
			letterSpacing: '-0.65px',
		}
	},
	uploadTextRed: {
		'&.MuiTypography-root': {
			fontSize: '0.813rem',
			color: '#F00',
			letterSpacing: '-0.65px',
		}
	},
	fileTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			marginLeft: "10px",
		}
	},
	fileSize: {
		'&.MuiTypography-root': {
			color: '#555',
			marginLeft: "25px",
			position: 'relative',
			'&:before': {
				content: "''",
				width: "4px",
				height: "4px",
				background: '#cbcbcb',
				borderRadius: '50%',
				display: 'inline-block',
				position: 'absolute',
				left: '-13px',
				top: 10,
			},
			'&:after': {
				content: "''",
				width: "4px",
				height: "4px",
				background: '#cbcbcb',
				borderRadius: '50%',
				display: 'inline-block',
				position: 'absolute',
				right: '-13px',
				top: 10,
			},
		},

	},
	filePreView: {
		'&.MuiTypography-root': {
			color: '#3552F2',
			fontWeight: 600,
			marginLeft: "25px",
		}
	},
	uploadScrollCont: {
		height: 'calc(100vh - 670px)',
		overflowY: 'auto'
	},
	previewText: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 'bold',
			fontSize: '1.25rem',
			marginBottom: "10px",
		}
	},
	zoomPageNum: {
		marginLeft: "25px",
		marginRight: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
		'&:after': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			right: '-13px',
			top: 10,
		},
	},
	dataFileName: {
		marginBottom: "15px",
	},
	dataFileText: {
		'& span': {
			fontSize: '0.875rem',
			color: '#555'
		}
	},
	tableInfoText: {
		marginRight: 5
	},
	popoverTooltip: {
		background: '#2078E8',
		color: '#fff',
		minWidth: 280,
		minHeight: 60,
		maxWidth: 280,
		padding: '10px 5px 10px 15px',
		overflow: 'visible',
		// marginTop: "15px",
		marginLeft: "15px",
		zIndex: 10,
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 280,
			minHeight: 60,
			maxWidth: 280,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	aiRubricTooltip: {
		'&.MuiTooltip-tooltip': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 280,
			minHeight: 60,
			maxWidth: 280,
			padding: '10px 15px 10px 15px',
			overflow: 'visible',
			// marginTop: "15px",
			marginLeft: "5px",
			// zIndex: 10,
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		},
		// padding: '10px 15px 10px 15px',
		// marginLeft: "5px",
		// top: '15px !important'
	},
	tooltipTop: {
		position: 'absolute',
		top: 60,
		left: '-12px',
		color: '#000',
		transform: 'rotate(-90deg)'
	},
	aiRubricTooltipTop: {
		position: 'absolute',
		top: '-14px',
		left: '47%',
		color: '#000',
	},
	popoverTooltipInfo: {
		background: '#2078E8',
		color: '#fff',
		minWidth: 300,
		minHeight: 60,
		maxWidth: 600,
		padding: '10px 10px 10px 15px',
		overflow: 'visible',
		marginTop: "15px",
		zIndex: 99990,
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 280,
			minHeight: 60,
			maxWidth: 280,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	tooltipTopInfo: {
		position: 'absolute',
		top: '-14px',
		left: '49%',
		color: '#000',
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	supportTooltipBtn: {
		padding: '10px 10px 10px 0'
	},
	aiRubricSupportTooltipBtn: {
		padding: '10px 3px 10px 0'
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			marginBottom: "5px",
			textAlign: 'left',
		}
	},
	scoreTooltipTitle: {
		'&.MuiTypography-root': {
			marginBottom: "0px",
		}
	},
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
			whiteSpace: 'pre-line',
			textAlign: 'left',
			'& span': {
				position: 'relative',
				marginLeft: "10px",
				width: "250px",
				display: 'block',
				'&:before': {
					content: "''",
					width: "4px",
					height: "4px",
					background: '#fff',
					borderRadius: '50%',
					display: 'inline-block',
					position: 'absolute',
					left: '-10px',
					top: 9,
				},
			}
		}
	},
	notifyBox: {
		border: '1px solid #E97C17',
		background: 'rgba(233, 124, 23, 0.04)',
		borderRadius: 10,
		padding: '16px 20px',
		marginBottom: "38px",
	},
	notifyBoxTitle: {
		marginBottom: "13px",
		'& .MuiTypography-root': {
			color: '#E97C17',
			fontWeight: 800,
			marginLeft: "5px",
		}
	},
	notifyBoxText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#787878',
			marginTop: "5px",
			'& span': {
				color: '#E97C17',
				fontWeight: 700,
			}
		}
	},
	rubricBox: {
		// borderTop: '1px solid rgba(53, 82, 242, 0.15)',
		// borderBottom: '1px solid rgba(53, 82, 242, 0.15)',
		border: '4px solid #75c2F2',
		// borderLeft: '4px solid #3552F2',
		marginBottom: "10px"
	},
	rubricBoxOff: {
		border: '4px solid #cbcbcb',
		'& .MuiBox-root': {
			'& .MuiTypography-root': {
				color: '#6F6F6F !important'
			},
			// '& svg': {
			//     '& path': {
			//         stroke: '#6F6F6F',
			//     }
			// },
			// '& .MuiButtonBase-root': {
			//     '& svg': {
			//         '& rect': {
			//             fill: '#6F6F6F',
			//         }
			//     },
			// }
		}

	},
	borderRight: {
		borderRight: '1px solid red'
	},
	rubricTaskBox: {
		background: 'rgba(53, 82, 242, 0.04)',
		borderBottom: '1px solid rgba(53, 82, 242, 0.15)',
		padding: '14px 20px',
	},
	rubricTaskBoxOff: {
		background: '#FAFAFA',
		// borderBottom: '0px solid rgba(53, 82, 242, 0.15)',
		padding: '14px 20px',
		border: ' 1px solid #EAECEB'

	},
	taskBox: {
		border: '2px solid #111',
		borderRadius: 3,
		padding: 14,
		marginTop: "10px",
		background: '#fff',
	},
	rubricInfoText: {
		marginTop: "5px",
		'& .MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
			marginLeft: "5px",
		}
	},
	rubricQuestionViewer: {
		marginBottom: "10px",
		// cursor:'pointer',
		'& .toastui-editor-contents': {
			fontSize: '1rem',
			fontWeight: '500',
			color: '#3552F2',
			'& > div': {
				'& > p': {
					margin: '0px 0px 5px 0px'
				}
			},
		}
	},
	btnBox: {
		display: 'flex',
		marginLeft: '10px'
	},
	rubricMarginBottom: {
		marginBottom: "0px"
	},
	rubricMarginTop: {
		// marginTop: '-10px'
	},
	rubricTaskTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			color: '#111',
			letterSpacing: '-0.4px'
		}
	},
	standardBtn: {
		// width: '160px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#fff',
		height: "45px",
		borderTop: '1px solid rgba(53, 82, 242, 0.15)',
		borderBottom: '1px solid rgba(53, 82, 242, 0.15)',
		textAlign: 'center',
		boxSizing: 'border-box',
		'& .MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 400,
			letterSpacing: '-0.7px',
		}
	},
	standardTextBox: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center'
	},
	standardBox: {
		marginTop: "15px",
	},
	aiStandCreateTextBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	standardTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			textAlign: 'center',
			lineHeight: 'normal',
			letterSpacing: '-0.5px',
			marginBottom: "14px",
			marginTop: "22px",
		}
	},
	searchBox: {
		borderTop: '1px solid #CBCBCB',
		padding: '5px 20px',
	},
	rubricTextfield: {
		'& .MuiInput-underline': {
			marginLeft: "10px",
		},
		'& .MuiInput-underline:before': {
			borderBottom: '0px',
		}
	},
	doubleDownPage: {
		marginBottom: "35px",
		'& .MuiTypography-root': {
			marginRight: "5px",
			fontSize: '0.875rem',
			color: '#6f6f6f',
			letterSpacing: '-0.5px'
		}
	},
	dialogBox: {
		position: 'relative',
		marginRight: "10px",
		'& .MuiFormControl-root': {
			width: '64px',
			height: "32px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			background: '#fff',
			borderRadius: 5,
		}
	},
	scoreBox: {
		position: 'relative',
		'& .MuiFormControl-root': {
			width: '64px',
			height: "32px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 64
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			background: '#fff',
			borderRadius: 5,
		}
	},
	yearSelectIcon: {
		position: 'absolute',
		right: 0,
		top: 3,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	iconYearSelect: {
		'&.MuiIconButton-root': {
			margin: '4px 5px',
		}
	},
	scoreText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			marginTop: "15px",
			color: '#787878'
		}
	},
	taskTableTitle: {
		marginRight: '10px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			fontWeight: 700,
		}
	},
	btnListBox: {
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'center',
		justifyContent: 'space-evenly',
		marginBottom: "29px",
	},
	addTableBtnBox: {
		// width: "375px",
		width: '48%',
		minWidth: 280,
	},
	addTableBtn: {
		'&.MuiButtonBase-root': {
			border: '1px dashed #000',
			width: '100%',
			// width: "342px",
			// minWidth: 280,
			boxSpacing: 'border-box',
			height: "36px",
			'&:hover': {
				background: '#fff'
			},
			'& .MuiTypography-root': {
				fontSize: '1rem',
				marginLeft: "5px",
				'& span': {
					fontWeight: 700,
				}
			}
		}
	},
	aiStandCreateBtn: {
		'&.MuiButtonBase-root': {
			background: '#7730BA',
			borderRadius: 3,
			width: '100%',
			// width: "342px",
			// minWidth: 280,
			boxSpacing: 'border-box',
			height: "36px",
			// marginLeft: "15px",
			// zIndex: 9999,
			'&:hover': {
				background: '#7730BA'
			},
			'& .MuiTypography-root': {
				fontSize: '1rem',
				marginRight: "5px",
				color: '#fff',
				fontWeight: 700,
			}
		}
	},
	pageUpIcon: {
		position: 'absolute',
		bottom: 23,
		right: 10,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			background: '#fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
			width: "40px",
			height: "40px",
			opacity: 0.3,
			'&:hover': {
				transition: 'all 0.5s',
				opacity: 1,
				background: '#fff'
			}
		},
	},
	tableText: {
		height: "120px",
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			padding: '0 5px',
			// overflow:'hidden',
			// textOverflow:'ellipsis',
			whiteSpace: 'break-spaces',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	},
	scoreMark: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			'& span': {
				fontSize: '0.813rem',
				fontWeight: 400,
				marginLeft: 5
			}
		}
	},
	countTopPage: {
		'&.MuiButtonBase-root': {
			fontSize: '1.25rem !important',
			minWidth: 32,
			height: "32px",
			background: '#fff',
			borderRadius: 0,
			boxSizing: 'border-box',
			// border: '1px solid #787878',
			marginRight: "5px",
			marginLeft: "0px",
			padding: '3px 0px',
			border: '2px solid #fff',
			'& .MuiTypography-root': {
				color: '#787878',
				fontSize: '1.125rem',
				letterSpacing: '-0.5px',
			},
			'&:hover': {
				color: '#3552F2',
				border: '2px solid #3552F2',
				borderRadius: 2,
				// background: 'transparent'
				'& .MuiTypography-root': {
					color: '#3552F2',
					fontWeight: 600,
				}
			}
		}
	},
	countTopPageActive: {
		'&.MuiButtonBase-root': {
			minWidth: 32,
			height: "32px",
			borderRadius: 2,

			color: '#3552F2',
			border: '2px solid #3552F2',
			// background: '#3552F2',
			// borderBottom: '3px solid #3552F2',
			'& .MuiTypography-root': {
				color: '#3552F2',
				fontWeight: 600,
			}

		}
	},
	scrollBox: {
		height: 'calc(100vh - 235px)',
		overflowY: 'auto',
		// borderTop: '1px solid rgba(53, 81, 239, 0.15)',
		// borderBottom: '1px solid rgba(53, 81, 239, 0.15)',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	visible: {
		overflow: 'visible !important',
	},

	'@keyframes waveAnimation': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '100% 0',
		},
	},

	skeletonShortUI: {
		width: '30%',
		height: '20px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		backgroundSize: '400% 100%',
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},

	skeletonMiddleUI: {
		width: '50%',
		height: '20px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},

	skeletonLongUI: {
		width: '80%',
		height: '20px',
		background: `linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(150, 150, 150, 1) 50%, rgba(245, 245, 245, 1) 100%)`,
		backgroundSize: '400% 100%',
		animation: '$waveAnimation 1s linear infinite',
		animationName: '$waveAnimation'
	},
	moreInfoPopover: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& svg': {
			marginBottom: "10px",
			marginLeft: "6px",
		}
	},

	tableCellRubricInfoName: {
		backgroundColor: 'rgb(250, 250, 250)',
		padding: '0px !important',
	},

	boxRubricInfoName: {
		width: '100%',
	},

	textFieldRubricInfoName: {
		width: '95%',
		'& .MuiOutlinedInput-notchedOutline': {
			// border: 'none',
			border: '2px solid rgb(250, 250, 250)',
		},

		'& .MuiOutlinedInput-multiline': {
			padding: '0px',

			'&:hover': {
				// border: '2px solid rgba(119, 119, 119, 0.7)',
				backgroundColor: '#fff',
			},
			'&.Mui-focused': {
				backgroundColor: '#fff',
			}
		},

		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				border: '1px solid #eeeeee',
			},
		},
	},

	textFieldInputRubricInfoName: {
		textAlign: 'center',
		fontWeight: 500,
		width: '100%',
		overflowY: 'overlay' as 'auto',  // 타입 강제 변환
		padding: '20px 10px',
		'&::-webkit-scrollbar': {
			width: '10px',
			// height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'rgba(0, 0, 0, 0.2)',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},

	tableCellRubricItemName: {
		borderTop: '1px solid rgba(119, 119, 119, 0.7)',
		borderLeft: '1px solid #cbcbcb',
		borderRight: '1px solid #cbcbcb',
		borderBottom: '1px solid #cbcbcb',
	},
	addStandardCell: {
		borderTop: '1px solid rgba(119, 119, 119, 0.7)',
		borderLeft: '1px solid #cbcbcb',
		// borderRight: '1px solid #cbcbcb',
		borderBottom: '1px solid #cbcbcb',
	},
	boxRubricItemName: {
		'& .MuiFormControl-root': {
			// width: '100%',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: '1.12rem',
			fontWeight: '500',
			color: '#01261C',
		},
	},

	textFieldRubricItemName: {
		fontSize: '1.5rem',
		fontWeight: 700,
	},

	tableCellRubricPoint: {
		borderRight: '1px solid #cbcbcb',
	},

	boxRubricPoint: {
	},

	fontRubricPointText: {
		fontSize: '0.875rem !important',
		fontWeight: '500 !important',
		color: '#111 !important',
		marginTop: '6px',
	},

	tableCellRubricStandard: {
		borderTop: '1px solid rgba(119, 119, 119, 0.7)',
	},

	boxRubricStandard: {

	},

	textFieldRubricStandard: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},

		'& .MuiOutlinedInput-multiline': {
			padding: '0px',

			'&.Mui-focused': {
				backgroundColor: '#fff',
			}
		},

		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				// border: '1px solid #eeeeee',
			},
		},
		paddingTop: '0px',  // Adjust padding inside the input area if needed
	    paddingBottom: '0px',
	},

	textFieldScoreNumber: {
		textAlign: 'right',
		marginRight: '18px'
	},
	rubricShownBox: {
		display: 'flex',
		// alignItems:'center',
		justifyContent: 'space-between',
		// cursor: 'pointer'
	},

	'@keyframes spin': {
		// from: {
		//     transform: 'rotate(0deg)'
		// },
		'100%': {
			transform: 'rotate(360deg)'
		}
	},

	aiSpinner: {
		// borderRadius: '50%',
		width: '24px',
		height: '24px',
		animation: '$spin 2s linear infinite'
	},
	addBox: {
		display: 'flex',
		justifyContent: 'center'
	},
	addStandardBox: {
		cursor: "pointer",
		width: '100%',
		padding: '5px 0px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px dotted #111',
		borderRadius: '4px',
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#111'
		},
		'&:hover': {
			border: '1px solid #111',
		},
	},
	plusBtn: {
		marginRight: '5px',
		marginTop: '2px'
	},
	deleteGradeBox: {
		marginLeft: '5px',
		cursor: "pointer",
	},
	standardTextFieldBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	deleteBoxWrap: {
		padding: '10px',
	},
	deleteBox: {
		width: '25px',
		height: '25px',
		cursor: "pointer",
	},
	deleteIcon: {
		cursor: "pointer",
	},
	deleteTooltip: {
		'&.MuiTooltip-tooltip': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 70,
			minHeight: 15,
			maxWidth: 280,
			padding: '10px 15px 10px 15px',
			overflow: 'visible',
			// marginTop: "15px",
			marginLeft: "5px",
			// zIndex: 10,
			fontSize: '0.875rem'
		},
	},

	// kitty
	borderLine: {},
	titleTrashIcon: {},
});












