import {
	Box,
	Button,
	Paper,
	TableContainer,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { CompareDataStyle } from "../../../../styles/CompareDataStyle";

import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";
import { observer } from "mobx-react";
import { Trans, useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';


import { useStores } from "../../../../../../hooks/useStores";
import useNeoPen from "../../../../../../ndp/hooks/useNeoPen";
import { makePdfWithStrokes } from "../../../../../../ndp/NdpPdfPrinter";
import { IPageSOBP, NeoStroke } from "../../../../../../neolab-libs/nl-lib3-common";
import { ProjectExamType } from "../../../../../../repositories/model/support/ProjectExamType";
import { ProjectStateType } from "../../../../../../repositories/model/support/ProjectStateType";
import { ProjectSubmitType } from "../../../../../../repositories/model/support/ProjectSubmitType";
import { WorksheetResultHeader } from "../../../../000_Common/WorksheetResultHeader";
import { QuestionAndReferenceTab } from "./QuestionAndReferenceTab";
import { useNeoConfirm } from "../../../../../../hooks/useNeoConfirm";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// const DrawerHeader = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	alignItems: "center",
// 	justifyContent: "flex-end",
// 	padding: theme.spacing(0, 1),
// 	// necessary for content to be below app bar
// 	...(theme as Theme).mixins.toolbar,
// }));

type Props = {
	projectCode: string;
	submissionCode: string;
	handleClickClose: () => void;
};

function CompareData(props: Props) {
	const { projectExamStore, projectSubmissionAnswerStore, projectStore, projectSubmissionStore, navigateStore, neoPenStore, ncodeAllocationStore, authStore } = useStores();;
	const classes = useEmotionStyles(CompareDataStyle);
	const i18next = useTranslation();

	const { projectCode, submissionCode } = props;
	// const [rotated, setRotated] = React.useState(false);
	const [page, setPage] = React.useState(0);
	// const [scale, setScale] = React.useState(1);
	const [pages, setPages] = React.useState([]);
	const [pdfWidth, setPdfWidth] = React.useState(0);
	const [answerList, setAnswerList] = React.useState([]);
	const [editMode, setEditMode] = React.useState(false);

	const { projectSubmission } = projectSubmissionStore;
	const { project } = projectStore;
	const pdfBoxRef = React.useRef<HTMLDivElement>();
	const {alert, confirm} = useNeoConfirm();

	console.log("answerList", { ...answerList });

	React.useEffect(() => {
		// storeManager.changeStoreState(StoreStatus.PROGRESS);
		getProjectInformation()
			.then(() => {
				// storeManager.changeStoreState(StoreStatus.COMPLETED);
			})
			.catch((error) => {
				// storeManager.changeStoreState(StoreStatus.COMPLETED);
			});;
		window.addEventListener('resize', handleResize);

		return () => {
			navigateStore.changeWithoutBars(false);
			projectSubmissionAnswerStore.initSubmissionAnswer();
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	React.useEffect(() => {
		let tempArr = cloneDeep(projectSubmissionAnswerStore.submissionAnswers);
		setAnswerList(tempArr);
	}, [projectSubmissionAnswerStore.submissionAnswers])

	const [offlineStrokes, setOfflineStrokes] = React.useState<NeoStroke[]>([]);


	const getProjectInformation = async () => {
		const promises = [];

		const pr1 = projectStore.downloadProjectInfoAndStore(projectCode); //기본정보 %필수정보
		promises.push(pr1);
		const pr2 = projectExamStore.downloadProjectExamListAndStore(projectCode); // 문항 생성 %필수 정보
		promises.push(pr2);

		if (submissionCode) {
			const pr3 = projectSubmissionStore.downloadSubmissionTransferAndStore(projectCode, submissionCode);
			promises.push(pr3);
			const pr4 = projectSubmissionAnswerStore.downloadSubmissionAnswersAndStore(submissionCode);
			promises.push(pr4);
		}
		await Promise.all(promises);

		const { projectSubmission: submissionTransfer } = projectSubmissionStore;

		if ((submissionTransfer?.files?.length || 0) > 0) {
			const strokeFiles = submissionTransfer.files.filter((item) => item.downloadUrl.endsWith(".zip") || item.contentsType.toUpperCase() === "STRK_ZIP");

			const pdfFiles = submissionTransfer.files.filter((item) => item.downloadUrl.endsWith(".pdf") || item.contentsType.toUpperCase() === "PDF");

			if (strokeFiles.length === 0) {
				// PDF만 있는 상태이면
				if (pdfFiles.length > 0) {
					const sorted = pdfFiles.sort((a, b) => b.order - a.order);
					setScannedPdfFile(sorted[0].downloadUrl);
					setSubmittedMaterial("pdf");
				}
			}
			else {
				// Stroke가 있는 상태라면
				setSubmittedMaterial("stroke");
				try {
					const strokes = await projectSubmissionStore.downloadStrokesFromServer(submissionTransfer);

					setOfflineStrokes(strokes);
				}
				catch (e) {
					console.log(e);
				}
			}
		}

		return true;
	}

	const { ncodeAllocation } = useNeoPen({
		project,
		projectStore,
		projectYear: project?.year,
		projectSemester: project?.semesterType,
		examList: projectExamStore.projectExamList,
		neoPenStore,
		ncodeAllocationStore,
		lang: navigateStore.language,
		authStore,
		skipPdfRendering: true
	});

	// offline stroke가 offline data upload dialog에서 upload 되면
	React.useEffect(() => {
		const generatePdf = async (strokes: NeoStroke[], range: { start: IPageSOBP, end: IPageSOBP }) => {
			console.log("render", strokes, range);
			try {
				const pdfUrl = await makePdfWithStrokes({
					type: projectExamStore.projectExamList[0]?.type || ProjectExamType.DESCRIPTIVE,
					lang: navigateStore.language,
					ndpNcodeRange: {
						section: range.start.section,
						owner: range.start.owner,
						bookCode: range.start.book,
						pageStart: range.start.page,
						pageEnd: range.end.page
					},
					strokes,
					dpi: 300,
				});
				setScannedPdfFile(pdfUrl);
			} finally {
			}
		}

		if (ncodeAllocation && submittedMaterial === "stroke") {
			const { section, startOwner, startBook, startPage, endOwner, endBook, endPage } = ncodeAllocation;
			const range: { start: IPageSOBP, end: IPageSOBP } = {
				start: { section, owner: startOwner, book: startBook, page: startPage },
				end: { section, owner: endOwner, book: endBook, page: endPage }
			};

			generatePdf(offlineStrokes, range);
		}

	}, [ncodeAllocation, offlineStrokes]);



	const [pdfLoadError, setPdfLoadError] = React.useState(false);
	const loadErr = (e) => {
		console.log(i18next.t('문서를 불러오는 중 오류가 발생했습니다.'), e.message);
		if (e.name === 'InvalidPDFException' || e.message === 'Invalid PDF structure.') {
			setPdfLoadError(true);
		}
	}

	const loadSuccess = ({ numPages }) => {
		let tempArr = [];
		for (let i = 0; i < numPages; i++) {
			tempArr.push(i + 1);
		}
		console.log(tempArr);
		setPage(1);
		setPages(tempArr);

		if (pdfBoxRef.current)
			setPdfWidth(pdfBoxRef.current?.offsetWidth || 0);
	}

	const checkCompared = (state) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return false;
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
			default:
				return false;
		}
	}

	const handleResize = () => {
		setPdfWidth(pdfBoxRef?.current?.offsetWidth)
	}

	const handleClickRetryOCR = async () => {
		let response = await confirm(i18next.t('OCR을 재실행 하면 창이 꺼지고 수정한 내용이 사라집니다.'), i18next.t('OCR을 재실행 하시겠습니까?'))
		if (!response) return;
		console.log(projectCode, projectSubmission.userCode)
		await projectStore.updateProjectUserState(projectCode, projectSubmissionStore.projectSubmission.userCode, ProjectStateType.SUBMITTED);
		await projectSubmissionStore.requestRetryOCR(projectCode, projectSubmission.userCode);
		// window.close();
	}
	const handleClickEdit = async () => {
		if (editMode) {
			let response = await confirm(i18next.t('편집을 취소 하시겠습니까?'))
			if (!response) return;
			let tempArr = cloneDeep(projectSubmissionAnswerStore.submissionAnswers);
			console.log(tempArr);
			setAnswerList(tempArr);
		}
		setEditMode(!editMode);
	}


	const handleClickComplete = async () => {
		if (editMode) {
			let response = await confirm(i18next.t('편집한 내용을 저장 하시겠습니까?'))
			if (!response) return;
			projectSubmissionAnswerStore.changeCompareAnswer(answerList);
			await projectSubmissionAnswerStore.updateSubmissionAnswers();
			await projectSubmissionAnswerStore.downloadSubmissionAnswersAndStore(submissionCode);
			setEditMode(!editMode);
		} else {
			let response = await confirm(i18next.t('대조 작업을 완료 하시겠습니까?'))
			if (!response) return;
			projectStore.updateProjectUserState(projectCode, projectSubmissionStore.projectSubmission.userCode, ProjectStateType.COMPARED, true);
			props.handleClickClose();
		}
	}

	const handleChangeAnswers = (answer, index) => {
		let temp = answerList.map(item => item);
		temp[index].answer = answer;
		setAnswerList(temp);
	}

	const isPenSubmission = projectSubmission && projectSubmission.submitType === ProjectSubmitType.PEN;
	const [scannedPdfFile, setScannedPdfFile] = React.useState(null);
	const [submittedMaterial, setSubmittedMaterial] = React.useState<"pdf" | "stroke" | "none">("none");

	console.log(`submittedMaterial= ${submittedMaterial}`);

	return (
		<div className={classes.root}>
			<Box
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box className={classes.newTabContent} display='flex' alignItems='center' justifyContent='space-between'>
						<Box className={classes.fileUploadBox} sx={{ marginRight: "10px" }}>
							<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.compareTop}>
								{isPenSubmission ?
									<Typography>{i18next.t('필기원본')}</Typography>
									:
									<Typography>{i18next.t('스캔원본')}</Typography>
								}
								<Box>
									{!isPenSubmission &&
										<Button className={classes.compareTopBtn} disabled={checkCompared(projectSubmission.state)} onClick={handleClickRetryOCR} disableRipple>
											<Typography>{i18next.t('OCR 재실행하기')}</Typography>
										</Button>
									}
								</Box>
							</Box>
							<Box className={classes.compareContent} ref={pdfBoxRef} style={{ alignItems: "center" }}>
								{/*todo 여러 파일 받을때는 files map 으로 변경*/}
								{pdfLoadError && <>
									<Typography>
										<Trans i18nKey="스캔한 PDF는 현재 지원하고 있지 않습니다.">
											<strong>스캔한 PDF는 현재 지원하고 있지 않습니다.</strong><br /><br />
											이 제출물은 이전 버전에서 사용되었던, 답안지 <strong>스캔 제출물</strong>입니다.<br />
											현재 버전의 서비스에서는 <strong>스캔 제출물</strong>은 더 이상 지원하지 않습니다.<br />
											이용에 불편을 드려 죄송합니다.<br />
										</Trans>
									</Typography>
								</>}
								{(!pdfLoadError && scannedPdfFile) &&
									<Document
										file={scannedPdfFile}
										onLoadError={loadErr}
										onLoadSuccess={loadSuccess}
									>
										{/*<Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} scale={1} loading/>*/}
										{pages && pages.map((item) => {
											return (
												<Page
													key={`page${item}`}
													className={classes.page}
													pageNumber={item}
													renderTextLayer={false}
													renderAnnotationLayer={false}
													scale={1}
													width={pdfWidth - 20}
												/>
											)
										})}
									</Document>
								}
							</Box>
						</Box>
						<Box className={classes.fileUploadBox} >
							<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={clsx(classes.compareTop, classes.compareTopColor)}>
								{isPenSubmission ?
									<Typography>{i18next.t('필기 인식 결과')}</Typography>
									:
									<Typography>{i18next.t('OCR 판독 결과')}</Typography>
								}

								<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
									{!checkCompared(projectSubmission.state) &&
										<>
											<Button className={classes.compareTopBtn} style={{ marginRight: "5px" }} onClick={handleClickEdit} disableRipple>
												{editMode ?
													<Typography>{i18next.t('취소')}</Typography>
													:
													<Typography>{i18next.t('편집')}</Typography>
												}
											</Button>
											<Button className={classes.compareTopBtn} onClick={handleClickComplete} disableRipple>
												{editMode ?
													<Typography>{i18next.t('저장')}</Typography>
													:
													<Typography>{i18next.t('대조 작업 완료')}</Typography>
												}
											</Button>
										</>
									}
									<Button onClick={props.handleClickClose}>X</Button>
								</Box>
							</Box>
							<Box className={classes.compareContent}>
								<Box>
									<TableContainer component={Paper} className={classes.tableBox}>

										<WorksheetResultHeader
											classes={classes}
											projectTargetGroupName={project.targetGroupName}
											projectYear={project.year}
											projectSemester={project.semesterType}
											projectSubjectName={project.subjectName}

											userName={projectSubmission.userProfile?.name}
											grade={projectSubmission.userProfile?.grade}
											className={projectSubmission.userProfile?.className}
											number={projectSubmission.userProfile?.number}
										/>
									</TableContainer>
								</Box>
								{/*<Box className={classes.compareContent}>*/}
								<QuestionAndReferenceTab
									answerList={answerList}
									changeAnswer={handleChangeAnswers}
									editMode={editMode}
									projectExamList={projectExamStore.projectExamList}
								// onSetQuestionHTMLWithOrderAndScore={setQuestionHTMLWithOrderAndScore}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</div>
	);
}


export default observer<typeof CompareData>(CompareData);