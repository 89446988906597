import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class FileInfoRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/files";
	}

	public async create(multipartFile: File) {
		const formData = new FormData();
		formData.append('mFile', multipartFile);

		const data = await this.getRequestPromise("post", this.requestPrefix, {}, formData, "multipart/form-data");
		return data;
	}

	public async deleteList(data) {
		const result = await this.getRequestPromise("delete", this.requestPrefix, {}, data);
		return result;
	}
}

