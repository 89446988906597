import { CSSInterpolation } from "@emotion/css/dist/declarations/src/create-instance";

import { Theme } from '@mui/material/styles'
export const ResultReviewStyle : (theme: Theme) => Record<string, CSSInterpolation> = (theme) => ({
	root: {
		width: '100%',
		textAlign: 'left',
	},
	// emptyBox:{
	//     width: '100%',
	//     height: `calc(100vh - ${topHeight}px - 36px - 20px - 24px - 24px)`,
	//     display:'flex',
	//     alignItems: 'center',
	//     justifyContent: 'center',
	//     '& p':{
	//         fontSize: '1.375rem',
	//         color: '#87888d',
	//         fontWeight:400,
	//         textAlign: 'center',
	//         lineHeight: '30.36px'
	//     }
	// },
	// boxWrap: {
	//     backgroundColor: '#fff',
	//     padding: 20,
	//     borderRadius: 8,
	//     height: 'calc(100vh - 500px)'
	// },

	topBarInner: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: "60px",
		borderBottom: '1px solid #e5e5e5',
		padding: '0px 20px',
		background: '#eee',
	},
	topBarTitle: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#6F6E75',
			// marginLeft: "10px",
		}
	},
	viewScale: {
		marginRight: '10px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem !important',
			color: '#6F6E75 !important',
		}
	},
	iconBtnReset: {
		color: '#CBCACF !important',
		'&.MuiIconButton-root': {
			padding: 0,
			'&:hover': {
				background: 'transparent'
			},
		}
	},
	iconButton: {
		'&.MuiIconButton-root': {
			marginLeft: "20px",
		}
	},
	iconToggle: {
		'&.MuiIconButton-root': {
			margin: 10,
		}
	},
	iconDailog: {
		'&.MuiIconButton-root': {
			margin: 10,
			marginLeft: "30px",
		}
	},
	topBarMenu: {
		'& .MuiTypography-root': {
			color: '#2F80ED',
			fontSize: '1rem',
			fontWeight: 600,
			textDecoration: 'underline',
			letterSpacing: '-0.5px',
			marginTop: "3px",
		}
	},


	dialogBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff'
		}
	},


	topBar: {
		'&.MuiToolbar-root': {
			borderBottom: '1px solid #e5e5e5',
			minHeight: 54,
		}
	},
	barTest: {
		'&.MuiDrawer-paper': {
			visibility: 'visible'
		}
	},
	listButton: {
		'&.MuiListItemButton-root': {
			justifyContent: 'center'
		},
		'& .MuiListItemText-root': {
			marginLeft: "20px",
		}
	},
	listIcon: {
		'&.MuiListItemIcon-root': {
			minWidth: 'auto',
		},

	},

	/////////////////////
	sideBarContent: {
		width: '200px',
		borderRight: '1px solid #e5e5e5',
		height: 'calc(100vh - 61px)',
	},
	sideBarOpenStyle: {
		'& .MuiDrawer-paper': {
			width: "210px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},
	sideBarCloseStyle: {
		'& .MuiDrawer-paper': {
			width: "60px",
			height: 'calc(100vh - 55px)',
			marginTop: "55px",
		}
	},





	/////////////////////
	padding: {
		display: 'flex',
		alignItems: 'start',
		justifyContent: 'center',
		padding: '10px 35px',
		background: '#F9FAFB'
	},
	mainContentBox: {
		// border: '1px solid #CBCBCB',
		// padding: 20,
		// height: 'calc(100vh - 90px)',
		marginTop: "20px",
		overflowY: 'auto',
		background: '#fff',
		padding: '0px 10%',
		position: 'relative',
		// margin: '0px 15px',
		display: 'flex'
	},
	mainContentBoxLeft: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 130px)',
		overflowY: 'auto',
		background: '#fff',
		// width: 'calc(100vw - 770px)',
		// width: '40%',
		width: '100%',
		// maxWidth: 919,
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	mainContentBoxRight: {
		border: '1px solid #CBCBCB',
		padding: 20,
		height: 'calc(100vh - 90px)',
		overflowY: 'auto',
		background: '#fff',
		width: '100%',
		// width: '60%',
		// maxWidth: 919,
		boxSizing: 'border-box',
		marginLeft: "10px",
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},


	commonSubTitle: {
		marginBottom: "10px",
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 500,
			'& span': {
				color: '#008C73'
			}
		}
	},
	schoolNameBox: {
		background: '#F3F3F3',
		padding: '30px 35px',
	},
	schoolName: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 'bold',
			color: '#111',
			marginBottom: "5px",
			marginTop: "20px",
		}
	},
	schoolNameSubText: {
		'&.MuiTypography-root': {
			color: '#555',
			fontSize: '1rem',
			letterSpacing: '-0.5px',
			marginBottom: "15px",
			'& span': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	searchSchoolInput: {
		'& .MuiFormControl-root': {
			maxWidth: 830,
			width: '100%',
			height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 50
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
		}
	},




	greenBtn: {
		marginLeft: "15px",
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#008c73',
			'&:hover': {
				background: '#008c73',
			},
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 'bold'
			}
		}
	},
	lineBtn: {
		'&.MuiButtonBase-root': {
			width: "100px",
			height: "36px",
			background: '#fff',
			border: '1px solid #CBCBCB',
			'&:hover': {
				background: '#fff',
			},
			'& .MuiTypography-root': {
				color: '#008c73',
				fontWeight: 'bold'
			}
		}
	},
	selectText: {
		'&.MuiTypography-root': {
			color: '#111',
			fontWeight: 'bold',
			marginRight: "15px",
		}
	},

	formControl: {
		'&.MuiFormControl-root ': {
			margin: 0,
			width: "160px",
			marginRight: "12px",
		},
		'& .MuiSelect-select': {
			width: "160px",
			height: "36px",
			padding: '14px 25px 15px 12px !important',
			fontSize: '1.125rem',
			boxSizing: 'border-box',
			color: '#777',
			letterSpacing: '-0.5px',
			display: 'flex',
			alignItems: 'center',
			background: '#fff',
			border: 'solid 1px #87888d',
			borderRadius: '6px !important',
			'@media all and (max-width: 800px)': {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiSelect-select': {
			border: '0px solid rgba(117, 0, 250, 0.4)',
			padding: '13px 25px 14px 12px !important',
		},
		'& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
			borderRadius: 6,
		},
		'& .MuiInput-underline:after, .MuiInput-underline:before': {
			border: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			border: 0,
		},
		'& .MuiSelect-icon': {
			top: 14,
			right: 11,
		}
	},



	moreListBtn: {
		marginTop: "10px",
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& hover': {
				background: 'transparent'
			},
			'& svg': {
				marginLeft: "5px",
			}
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			marginTop: "3px",
		}
	},
	pageNumber: {
		marginTop: "10px",
		'& .MuiPaginationItem-root': {
			'&.Mui-selected': {
				background: '#008C73',
				color: '#fff',
				border: '1px solid #008C73',
				borderRadius: '100%'
			},
		},
		'& .MuiPaginationItem-root:hover': {
			background: '#fff',
			border: '1px solid #008C73',
			color: '#008C73',
			fontWeight: 'bold',
			borderRadius: '100%'
		}
	},

	checkBoxStyle: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#2F80ED',
			'& .MuiIconButton-colorSecondary:hover': {
				background: 'transparent'
			}
		},
		'& .MuiIconButton-colorSecondary:hover': {
			background: 'transparent'
		}
	},
	tableStudentName: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	tableBox: {
		marginTop: "30px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '1rem',
				color: '#323232',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},

	schoolText: {
		border: '1px solid #CBCBCB',
		background: '#F3F3F3',
		padding: 10,
		textAlign: 'center',
		borderRadius: '5px 0px 0px 5px',
		width: "80px",
		boxSizing: 'border-box'
	},

	schoolNameText: {
		border: '1px solid #CBCBCB',
		borderLeft: '0px',
		background: '#DEF8F3',
		padding: '10px 20px',
		borderRadius: '0px 5px 5px 0px',
		width: "240px",
		boxSizing: 'border-box',
		'&.MuiTypography-root': {
			fontWeight: 'bold'
		}
	},

	dialogInput: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
			height: "440px",
		}
	},
	dialogInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "460px",
			marginBottom: "20px",
		},
	},
	stepInputBox: {
		'& .MuiIconButton-root': {
			margin: '20px 10px 5px 40px'
		}
	},

	inputStep: {
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: 36
		},
		'& .MuiOutlinedInput-input': {
			padding: '9px 0px',
			height: "19px",
			letterSpacing: '-0.5px'
		}
	},
	inputStepMulti: {
		'& .MuiOutlinedInput-root': {
			height: "60px",
		},
		'& .MuiOutlinedInput-input': {
			height: "38px",
		}
	},
	stepText: {
		'&.MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 'bold',
			color: '#2F80ED',
			marginRight: "20px",
		}
	},

	addSentence: {
		'& .MuiButton-label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'&.MuiButtonBase-root': {
			'&:hover': {
				background: 'transparent'
			},
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontSize: '0.875rem',
				marginTop: "2px",
			}
		}
	},

	boxList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FAFAFA',
		borderRadius: 6,
		width: '100%',
		height: "200px",
		margin: '0px 5px',
		padding: 10,
		'& .MuiTypography-root': {
			color: '#6F6F6F',
			fontSize: '0.75rem',
			minHeight: 93,
		},
		'&:hover': {
			outline: '2px solid #56CCF2'
		}
	},
	boxListTitle: {
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			fontWeight: 700,
			marginTop: "5px",
			marginBottom: "5px",
			minHeight: 'auto'
		}
	},
	fileUploadBox: {
		marginTop: "30px",
		background: '#E0E0E0',
		height: 'calc(100vh - 190px)',
		overflowY: 'auto',
		position: 'relative'
	},
	boardSize: {
		position: 'absolute',
		right: 10,
		bottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	fileUploadTitle: {
		marginTop: '-18px',
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 500,
			marginBottom: "20px",
		}
	},
	fileUploadText: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			color: '#555',
			marginBottom: "20px",
		}
	},
	fileUploadBtn: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "170px",
			height: "36px",
			borderRadius: 3,
			border: '1px solid #CBCBCB',
			'& .MuiTypography-root': {
				color: '#2F80ED',
				fontWeight: 'bold'
			}
		}
	},
	fileUploadSubText: {
		margin: '15px 0px'
	},
	urlInputBox: {
		'&.MuiPaper-root': {
			width: "600px",
			height: "35px",
			border: '1px solid #CBCBCB',
			marginBottom: "15px",
		},
		'& .MuiInputBase-root': {
			width: "480px",
		},
		'& .MuiInputBase-input': {
			padding: '8px 10px 7px',
		}
	},
	urlBtn: {
		marginLeft: "10px",
		'&.MuiButtonBase-root': {
			width: "82px",
			height: "27px",
			background: '#CDCDCD',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#888',
				letterSpacing: '-0.5px',
			}
		}
	},
	urlBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 'bold',
				color: '#fff'
			}
		}
	},

	summaryAiBtn: {
		'&.MuiButtonBase-root': {
			background: '#CDCDCD',
			width: '100%',
			marginBottom: "20px",
			'& .MuiTypography-root': {
				color: '#888',
				fontWeight: 'bold',
			}
		}
	},
	summaryAiBtnActive: {
		'&.MuiButtonBase-root': {
			background: '#2F80ED',
			'& .MuiTypography-root': {
				color: '#fff',
			}
		}
	},
	aiSubText: {
		'&.MuiTypography-root': {
			fontSize: '0.75rem',
			color: '#555',
		}
	},
	sideList: {
		position: 'absolute',
		left: 0,
		top: 0,
		background: '#4F4F4F',
		width: "114px",
		height: 'calc(100vh - 190px)',
		// height: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	sideListInner: {
		overflowY: 'auto',
		padding: '10px 7px',
		'&::-webkit-scrollbar': {
			width: '10px',
			height: '10px'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#6f6f6f',
			borderRadius: '10px',
			backgroundClip: 'padding-box',
			border: '3px solid transparent',
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			marginTop: "5px",
		},
	},
	img: {
		position: 'relative',
		'& img': {
			width: '100%',
			borderRadius: '5px',
			'&:hover': {
				outline: '2px solid #03BF69'
			},
		},

	},
	examInfoIcon: {
		marginLeft: "10px",
		marginBottom: "13px",
	},
	pageNum: {
		position: 'absolute',
		bottom: 5,
		background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff)',
		width: '100%',
		borderRadius: '0 0 5px 5px',
		'& .MuiTypography-root': {
			padding: '4px 5px',
			textAlign: 'right'
		}
	},
	newTabHeader: {
		borderBottom: '1px solid #cbcbcb',
		padding: 20,
	},
	newTabContent: {
		padding: '29px 20px 20px 20px',
		background: '#fafafa'
	},
	newTabTitle: {
		'&.MuiTypography-root': {
			color: '#555',
			marginRight: "14px",

		}
	},
	newTabHeaderTitle: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px',
			fontWeight: 700,
			marginLeft: "30px",
		}
	},
	newTabName: {
		'&.MuiTypography-root': {
			color: '#111',
			fontSize: '1.125rem',
			letterSpacing: '-1px'
		}
	},
	newTabTitlePointer: {
		marginLeft: "25px",
		position: 'relative',
		'&:before': {
			content: "''",
			width: "4px",
			height: "4px",
			background: '#cbcbcb',
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			left: '-13px',
			top: 10,
		},
	},
	newTabSubject: {
		'&.MuiTypography-root': {
			fontWeight: 600
		}
	},
	newTabPage: {
		'&.MuiTypography-root': {
			color: '#008C73',
			fontWeight: 600,
		}
	},
	titleBox: {
		background: '#F3F3F3',
		'&.MuiTableCell-root': {
			fontWeight: 700,
		}
	},
	amountScoreTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			marginTop: "20px",
		}
	},
	totalAmountScore: {
		width: '60%',
		padding: '0px',
		borderBottom: '1px solid #cbcbcb',
		marginBottom: '30px',
		'& .MuiTypography-root': {
			fontSize: '3.375rem',
			fontWeight: 500,
			color: '#787878',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			},
		},
	},
	amountScore: {
		width: '60%',
		padding: '0px',
		// borderBottom: '1px solid #cbcbcb',
		'& .MuiTypography-root': {
			fontSize: '3.375rem',
			fontWeight: 500,
			color: '#787878',
			'& span': {
				color: '#008C73',
				fontWeight: 700,
			},
		},
	},
	amountScoreText: {
		fontSize: '1.25rem',
		color: '#787878 !important',
		marginLeft: "5px",
	},
	// compareContent: {
	// 	// padding: 20,
	// 	// height: 'calc(100vh - 224px)',
	// 	// overflowY: 'auto',
	// 	marginTop: props => (props as any).isFirst ? 0 : 30

	// 	// alignItems:"center"
	// },
	compareRound: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: '30px 40px',
		marginBottom: "20px",
		background: 'rgba(233, 243, 252, 0.10)',
		// height: 'calc(100vh - 360px)',
		// overflowY: 'auto'
	},
	textArticleNumber: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleNumberBox: {
		marginLeft: '10px'
	},
	textArticleTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			// marginTop: "10px",
			marginBottom: "10px",
		},
	},
	textArticle: {
		// border: '2px solid #111',
		border: '1px solid #cbcbcb',

		borderRadius: 3,
		// padding: '10px 20px',
		padding: '6px 13px',

		marginBottom: "30px",
		'& .MuiTypography-root': {
			fontSize: '1rem',
			color: '#111',
			letterSpacing: '-0.5px',
			fontWeight: 500,
		}
	},
	textArticleReference: {
		marginTop: "20px",
		textAlign: 'right',
		'& .MuiTypography-root': {
			color: '#787878',
			fontSize: '0.875rem',
		}
	},
	questionList: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			color: '#111',
			fontWeight: 700,
			letterSpacing: '-0.5px'
		}
	},
	answerGuideText: {
		// border: '1px solid #111',
		border: '1px solid #cbcbcb',
		// padding: '14px 18px',
		padding: '6px 13px',
		borderRadius: 3,
		marginTop: "10px",
		'& .MuiTypography-root': {
			fontSize: '1.0rem',
			letterSpacing: '-0.4px'
		}
	},
	bestAnswerList: {
		marginTop: "20px",
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			letterSpacing: '-1px',

		}
	},
	questionViewer: {
		'& .toastui-editor-contents': {
			fontSize: '1.25rem',
			fontWeight: 700,
			'& > div': {
				'& > p': {
					margin: 0,
				}
			},
		},
	},
	bestAnswerBox: {
		border: '2px solid #111',
		borderRadius: 3,
		minHeight: 68,
		marginTop: "34px",
		'& .MuiTypography-root': {
			padding: '20px 30px',
			fontSize: '1.25rem',
			letterSpacing: '-0.4px'
		}
	},
	greenBorderBox: {
		border: '4px solid #008C73',
		borderRadius: 10,
		padding: 20,
	},
	bgColor: {
		height: '140px !important',
		background: '#DFDFDF',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	rubricText: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			fontSize: '0.875rem',
			textAlign: 'center',
			'& span': {
				fontSize: '1.3rem',
				fontWeight: 700,
				marginRight: "3px",
			}
		}
	},
	markText: {
		'&.MuiTypography-root': {
			fontWeight: 400,
			fontSize: '0.875rem',
			textAlign: 'center',
			'& span': {
				fontSize: '2rem',
				fontWeight: 700,
				marginRight: "3px",
			}
		}
	},
	markTextColor: {
		'&.MuiTypography-root': {
			'& span': {
				color: '#008C73'
			}
		}
	},
	tableText: {
		height: "120px",
		'&.MuiTypography-root': {
			fontSize: '0.875rem',
			color: '#323232',
			letterSpacing: '-0.5px',
			padding: '0 5px',
			// overflow:'hidden',
			// textOverflow:'ellipsis',
			whiteSpace: 'break-spaces',
			overflow: 'auto',
			textOverflow: 'ellipsis',
		}
	},
	iconBtn: {
		cursor: 'pointer',
	},
	iconBtnSupport: {
		marginTop: "20px",
	},
	tableBox2: {
		marginTop: "20px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				background: '#F3F3F3',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				// whiteSpace:'nowrap',
				whiteSpace: 'normal',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	greenInnerBox: {
		marginTop: "60px",
		border: '2px solid #008C73',
		background: '#E9F8F5',
		borderRadius: 10,
		padding: '20px 40px 40px 40px',
		position: 'relative',
	},
	noResultMark: {
		background: '#fff',
	},
	noResultBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 490,
		'& .MuiTypography-root': {
			fontSize: '1.5rem',
			fontWeight: 700,
			letterSpacing: '-0.2px',
			color: '#111',
			marginTop: "24px",
		}
	},
	noResultSub: {
		'&.MuiTypography-root': {
			fontSize: '1rem',
			fontWeight: 400,
			letterSpacing: '-0.5px',
			color: '#555',
		}
	},
	redInnerBox: {
		border: '2px solid #EB5757',
		background: '#fff',
	},
	tagNameBox: {
		position: 'absolute',
		top: '-38px',
		left: 10,
		background: '#008C73',
		borderRadius: '10px 10px 0 0',
		padding: '8px 30px',
		'& .MuiTypography-root': {
			color: '#fff',
			fontWeight: 700,
			fontSize: '0.875rem'
		}
	},
	tagNameBoxRed: {
		background: '#EB5757',
	},
	tagBtnView: {
		position: 'absolute',
		top: '-38px',
		right: '-10px',
		'& .MuiButtonBase-root': {
			background: '#fff',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				fontWeight: 500,
				color: '#555',
				marginRight: "5px",

			}
		}
	},
	bgColorGreen: {
		background: '#008C73 !important',
		color: '#fff !important'
	},
	tableTextGreen: {
		'&.MuiTypography-root': {
			color: '#008C73'
		}
	},
	greenBoxTitle: {
		'&.MuiTypography-root': {
			fontSize: '1.25rem',
			fontWeight: 700,
			letterSpacing: '-0.5px',

		}
	},
	slider: {

	},
	progressLeftBox: {
		marginBottom: '-8px',
		width: '190px'
	},
	progressBox: {
		maxWidth: 440,
		marginLeft: "0px",
		width: '100%',
		position: 'relative',
		'& .MuiLinearProgress-root': {
			height: "16px",
			background: '#eee',
			borderRadius: 99,
		},
		'& .MuiLinearProgress-bar': {
			background: '#EB5757',
			borderRadius: 99,
		}
	},
	progressLeftText: {
		position: 'absolute',
		top: '-20px',
		left: 0,
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem'
		}
	},
	progressRightText: {
		position: 'absolute',
		top: '-20px',
		right: 0,
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem'
		}
	},
	progressGuide: {
		position: 'absolute',
		'& .MuiTypography-root': {
			color: '#787878',
			fontSize: '0.813rem',
			position: 'relative',
			width: "60px",
			marginLeft: '-23px',
			// textAlign: 'right',
			'&:before': {
				content: "''",
				// width: "1px",
				height: "45px",
				// background:'red',
				// borderRadius: '50%',
				display: 'inline-block',
				position: 'absolute',
				left: '22px',
				top: 22,

				border: '1px dashed #555'
			},
		}
	},
	viewMoreText: {
		'&.MuiTypography-root': {
			fontSize: '1.125rem',
			fontWeight: 700,
			color: '#111',
			letterSpacing: '-0.5px',
			marginRight: "5px",
		}
	},
	resultBtnToggle: {
		display: 'flex',
		cursor: 'pointer',
		'&.MuiButtonBase-root': {
			background: '#fff',
			marginBottom: "30px",
		},
		'& .MuiTypography-root': {
			color: '#008C73',
			fontSize: '0.875rem',
			letterSpacing: '-0.5px'
		}
	},
	redContainer: {
		background: '#FBE3E3',
		borderRadius: 10,
		padding: '17px 18px',
		'& .MuiTypography-root': {
			color: '#111',
			letterSpacing: '-0.5px'
		}
	},
	TaskContainer: {
		border: '1px solid #cbcbcb',
		borderRadius: 10,
		padding: '0px 30px 30px 30px',
	},
	TaskContainerInner: {
		border: '3px solid #111',
		borderRadius: 3,
		padding: 10,
		minHeight: 200,
	},
	pageUpIcon: {
		position: 'fixed',
		bottom: '10px',
		right: 20,
		'&.MuiIconButton-root': {
			border: '1px solid #fff',
			background: '#fff',
			boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
			width: "40px",
			height: "40px",
			'&:hover': {
				background: '#fff'
			}

		}
	},
	questionBoxMarginBottom: {
		marginBottom: "50px",
	},
	borderGuide: {
		marginBottom: "30px",
		position: 'relative',
		'&:before': {
			content: "''",
			// width: '500px',
			width: '400%',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			right: '55px',
			top: 20,
		},
		'&:after': {
			content: "''",
			// width: '500px',
			width: '400%',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			left: '55px',
			top: 20,
		},
	},
	borderGuideText: {
		marginTop: "30px",
		marginBottom: "30px",
		position: 'relative',
		'&:before': {
			content: "''",
			// width: '500px',
			width: '400%',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			right: '100px',
			top: 10,
		},
		'&:after': {
			content: "''",
			// width: '500px',
			width: '400%',
			height: "1px",
			borderTop: '1px dashed #cbcbcb',
			display: 'inline-block',
			position: 'absolute',
			left: '100px',
			top: 10,
		},
		'& .MuiTypography-root': {
			fontSize: '0.813rem',
			color: '#787878'
		}
	},
	saveLineMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "110px",
			height: "37px",
			background: '#fff',
			border: '1px solid #888',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	saveLineMarkBtnActive: {
		'& .MuiButtonBase-root': {
			border: '1px solid #111',
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 700,
			}
		}
	},
	applyMarkBtn: {
		'& .MuiButtonBase-root': {
			width: "110px",
			height: "37px",
			background: '#cbcbcb',
			'& .MuiTypography-root': {
				fontSize: '0.875rem',
				color: '#888'
			}
		}
	},
	applyMarkBtnActive: {
		'& .MuiButtonBase-root': {
			background: '#008C73',
			'& .MuiTypography-root': {
				color: '#fff',
				fontWeight: 700,
			}
		}
	},
	titleBoxStudent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiTypography-root': {
			color: '#111',
			fontWeight: '500',
			// fontSize: '1.75rem',
			fontSize: '1.5rem'
		}
	},
	nextTaskPage: {
		'&.MuiButtonBase-root': {
			background: '#fff',
			width: "139px",
			height: "45px",
			border: '1px solid #111',
			borderRadius: 6,
			'& .MuiTypography-root': {
				color: '#111',
				fontWeight: 500,
				fontSize: '1.25rem'
			}
		}
	},
	rubricCommonCell: {
		height: '140px !important',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	rubricCommonCell2: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		height: '140px !important',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	rubricCellBgColor: {
		height: '140px !important',
		background: 'rgb(250, 250, 250)',
		// borderLeft: '1px solid #cbcbcb',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	tableBoxMarginTop: {
		marginTop: '30px !important'
	},
	tableBoxMarginTop2: {
		marginTop: '10px !important'
	},
	feedbackBgColor: {
		// height: '140px !important',
		background: '#F3F3F3',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	feedbackBox: {
		padding: '10px 10px !important'
	},
	feedbackBoxPadding: {
		padding: '0px 10px !important'
	},
	scoreMarkInput: {
		whiteSpace: 'normal',
		'& .MuiFormControl-root': {
			width: '100%',
			// height: "46px",
			boxSizing: 'border-box',
			background: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			height: "74px",
			'&.Mui-focused fieldset': {
				borderColor: '#2F80ED',
			},
		},
		'& .MuiOutlinedInput-input': {
			// padding: '9px 5px',
			padding: 0,
			height: '92px !important',
			// textAlign     : 'center',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	scoreMarkInputMulti: {
		'& .MuiOutlinedInput-root': {
			height: "120px",
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#fff',
		}
	},
	tableBox3: {
		marginTop: "5px",
		'&.MuiPaper-root': {
			borderTop: '1px solid rgba(119, 119, 119, 0.7)',
			boxShadow: 'none',
			borderRadius: 0,
			'& table': {
				tableLayout: 'fixed'
			},
			'& .MuiTableCell-head': {
				fontSize: '0.875rem',
				color: '#555',
				background: '#F3F3F3',
				letterSpacing: '-0.5px',
				fontWeight: 'bold',
				padding: '0 5px',
				boxSizing: 'border-box',
				height: "54px",
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				'& span': {
					fontSize: '0.875rem',
					fontWeight: 'normal'
				},
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
					'& span': {
						fontSize: '0.688rem',
					}
				},
			},
			'& .MuiTableCell-body': {
				fontSize: '1rem',
				color: '#323232',
				height: "54px",
				letterSpacing: '-0.5px',
				padding: '0 5px',
				boxSizing: 'border-box',
				borderBottom: '1px solid rgba(119, 119, 119, 0.7)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'@media all and (max-width: 1550px)': {
					fontSize: '0.875rem',
				},
			}
		}
	},
	rubricScoreBgColor: {
		height: '140px !important',
		// background: '#DFDFDF',
		// borderLeft: '1px solid #cbcbcb',
		borderRight: '1px solid #cbcbcb',
		'& > .MuiTypography-root': {
			fontWeight: 700,
			fontSize: '1rem',
		}
	},
	progressTitle: {
		marginBottom: '-7px'
	},
	progressMainBox: {
		height: "80px",
		marginBottom: "33px",
		paddingLeft: 10,
		paddingRight: 10
	},
	scoreCell: {
		borderLeft: '1px solid #cbcbcb',
		borderRight: '1px solid #cbcbcb',
	},
	progressContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		height: "80px",
		marginBottom: "25px"
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	taskNumberBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		// marginTop: "53px",
	},
	taskNumber: {
		border: '1px solid #008C73',
		borderRadius: 3,
		background: '#DEF8F3',
		width: "88px",
		height: "40px",
		boxSizing: 'border-box',
		padding: '4px 15px',
		textAlign: 'center',
		marginRight: "16px",
		'& .MuiTypography-root': {
			fontSize: '1.25rem'
		}
	},
	markBigScore: {
		'&.MuiTypography-root': {
			color: '#787878',
			fontSize: '1.25rem',
			fontWeight: 700,
			marginLeft: "24px",
			'& span': {
				fontSize: '3.375rem',
				color: '#008C73',
				fontWeight: 700,
				marginRight: "5px",
			}
		}
	},
	popoverTooltip: {
		'& .MuiPopover-paper': {
			background: '#2078E8',
			color: '#fff',
			minWidth: 180,
			minHeight: 30,
			maxWidth: 314,
			padding: '10px 10px 10px 15px',
			overflow: 'visible',
			marginTop: "15px",
			boxSizing: 'border-box'
		},
		'& .MuiTypography-root': {
			color: '#fff',
			fontSize: '15px',
			lineHeight: '21px',
			letterSpacing: '-0.5px',
		}
	},
	tooltipTop: {
		position: 'absolute',
		top: -10,
		left: '47%',
		color: '#000'
	},
	tooltipBtn: {
		'& .MuiButtonBase-root': {
			padding: 0,
			minWidth: 'auto',
			'&:hover': {
				background: 'transparent'
			}
		}
	},
	tooltipTitle: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '1.125rem',
			letterSpacing: '-0.5px',
			fontWeight: 700,
			// marginBottom: "15px",
		}
	},
	tooltipText: {
		'&.MuiTypography-root': {
			color: '#fff',
			fontSize: '0.938rem',
			letterSpacing: '-0.5px',
			marginBottom: "10px",
			whiteSpace: 'pre-line',
			textAlign: 'left',
			'& span': {
				position: 'relative',
				marginLeft: "10px",
				width: "250px",
				display: 'block',
				'&:before': {
					content: "''",
					width: "4px",
					height: "4px",
					background: '#fff',
					borderRadius: '50%',
					display: 'inline-block',
					position: 'absolute',
					left: '-10px',
					top: 7,
				},
			}
		}
	},


	// kitty
	borderLine: {},
	compareTop: {},
	compareTopColor: {},
	page: {},

});












