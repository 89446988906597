import { MyScriptMimeType } from "../../../nl-lib3-common/typedef/myscript-types/myscript-types";

export const toMimeTypeString = (mimeType: MyScriptMimeType) => {
	switch (mimeType) {
		case MyScriptMimeType.JIIX:
			return "application/vnd.myscript.jiix";
		// case MyScriptMimeType.JSON;
		// 	return "application/json";
		case MyScriptMimeType.MATHML:
			return "application/mathml+xml";
		case MyScriptMimeType.LATEX:
			return "application/x-latex";
		case MyScriptMimeType.TEXT:
			return "text/plain";
		case MyScriptMimeType.HTML:
			return "text/html";
		case MyScriptMimeType.GRAPHML:
			return "application/vnd.myscript.graphml";
		case MyScriptMimeType.MUSICXML:
			return "application/vnd.recordare.musicxml";
		case MyScriptMimeType.SVG:
			return "image/svg+xml";
		case MyScriptMimeType.JPEG:
			return "image/jpeg";
		case MyScriptMimeType.PNG:
			return "image/png";
		case MyScriptMimeType.GIF:
			return "image/gif";
		case MyScriptMimeType.PDF:
			return "application/pdf";
		case MyScriptMimeType.DOCX:
			return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		case MyScriptMimeType.PPTX:
			return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
		case MyScriptMimeType.OFFICE_CLIPBOARD:
			return "application/vnd.openxmlformats-officedocument.clipboard";
		default:
			throw new Error(`Unknown MyScriptMimeType: ${mimeType}`);
	}
}

export const toMyscriptMimeType = (mimeType: string) => {
	switch (mimeType) {
		case "application/vnd.myscript.jiix":
		case "application/vnd.myscript.jiix,application/json":
			return MyScriptMimeType.JIIX;
		// case "application/json":
		// 	return MyScriptMimeType.JSON;
		case "application/mathml+xml":
			return MyScriptMimeType.MATHML;
		case "application/x-latex":
			return MyScriptMimeType.LATEX;
		case "text/plain":
			return MyScriptMimeType.TEXT;
		case "text/html":
			return MyScriptMimeType.HTML;
		case "application/vnd.myscript.graphml":
			return MyScriptMimeType.GRAPHML;
		case "application/vnd.recordare.musicxml":
			return MyScriptMimeType.MUSICXML;
		case "image/svg+xml":
			return MyScriptMimeType.SVG;
		case "image/jpeg":
			return MyScriptMimeType.JPEG;
		case "image/png":
			return MyScriptMimeType.PNG;
		case "image/gif":
			return MyScriptMimeType.GIF;
		case "application/pdf":
			return MyScriptMimeType.PDF;
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			return MyScriptMimeType.DOCX;
		case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
			return MyScriptMimeType.PPTX;
		case "application/vnd.openxmlformats-officedocument.clipboard":
			return MyScriptMimeType.OFFICE_CLIPBOARD;
		default:
			throw new Error(`Unknown MyScriptMimeType: ${mimeType}`);
	}
}