import axios, { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from "axios";

export const AuthTokenStorageKey = "_BASIC_AUTHENTICATION_TOKEN_";

const LogPrefix = "[Repository]";

export class Repository {
	public async getRequestPromise<ResponseType = any, BodyType = any, RequestConfigType = any>(
		method: string,
		url: string,
		param = undefined as any,
		data = undefined as BodyType,
		contentType = undefined as any,
		responseType = undefined as any,
		uploadProgress = undefined as (progressEvent: AxiosProgressEvent) => void
	) {
		const token = localStorage.getItem(AuthTokenStorageKey);
		const headers = {};
		if (Boolean(token)) {
			headers["X-Auth-Token"] = token;
		}
		if (contentType) {
			headers["Content-Type"] = contentType;
		}

		try {
			const config: AxiosRequestConfig = {
				method: method,
				url: url,
				headers: headers,
				params: param,
				data: data,
				responseType: responseType ? responseType : "json",
				onUploadProgress: uploadProgress,
			};

			// console.log(LogPrefix, 'HTTP requesting :', config);

			const promise = axios
				.request<ResponseType, AxiosResponse<ResponseType>, RequestConfigType>(config);

			const response = await promise;
			// if (response.status === 200) {
			// return response.data;
			// } else {
			// 	throw new Error(`HTTP Error : ${response.status}`);
			// }

			return response.data;

		} catch (error) {
			throw error;
		}
	};




	public getRequestPromiseOld<ResponseType = any, RequestConfigType = any>(
		method: string,
		url: string,
		param = undefined as any,
		data = undefined as any,
		contentType = undefined as any,
		responseType = undefined as any,
		uploadProgress = undefined as (progressEvent: AxiosProgressEvent) => void
	) {
		const token = localStorage.getItem(AuthTokenStorageKey);
		const headers = {};
		if (Boolean(token)) {
			headers["X-Auth-Token"] = token;
		}
		if (contentType) {
			headers["Content-Type"] = contentType;
		}

		return new Promise((resolve, reject) => {
			const config: AxiosRequestConfig = {
				method: method,
				url: url,
				headers: headers,
				params: param,
				data: data,
				responseType: responseType ? responseType : "json",
				onUploadProgress: uploadProgress,
			};

			// console.log(LogPrefix, 'HTTP requesting :', config);
			axios
				.request<ResponseType, AxiosResponse<ResponseType>, RequestConfigType>(config)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};



	public getAuthTokenFromStorage() {
		return localStorage.getItem(AuthTokenStorageKey);
	};

	public setAuthTokenToStorage(token) {
		localStorage.setItem(AuthTokenStorageKey, token);
	};

	public removeAuthTokenFromStorage() {
		localStorage.removeItem(AuthTokenStorageKey);
	};
}
