export enum ProjectSchoolType {
	ELEM = "ELEM",
	MIDD = "MIDD",
	HIGH = "HIGH",

	// kitty
	NOGRADE = "NOGR",

	NONE = "",
}


// export enum SubjectType {
// 	KOREAN = "KOREAN",
// 	MATH = "MATH",
// 	ENGLISH = "ENGLISH",
// 	SCIENCE = "SCIENCE",
// 	SOCIAL_STUDIES = "SOCIAL_STUDIES",
// 	MUSIC = "MUSIC",
// 	ART = "ART",
// 	PHYSICAL_EDUCATION = "PHYSICAL_EDUCATION",
// 	HISTORY = "HISTORY",
// 	ETHICS = "ETHICS",
// 	SOCIAL_SCIENCE_BRANCH = "SOCIAL_SCIENCE_BRANCH",
// 	ENVIRONMENT = "ENVIRONMENT",
// 	TECHNOLOGY_AND_HOME_ECONOMICS = "TECHNOLOGY_AND_HOME_ECONOMICS",
// 	INFORMATION = "INFORMATION",
// 	CHINESE_CHARACTERS = "CHINESE_CHARACTERS",
// 	HEALTH = "HEALTH",
// 	SOCIAL = "SOCIAL",
// }

export enum AssignmentType {
	OFFLINE = "OFFLINE",
	ONLINE = "ONLINE"
}

export enum ProjectUserSortColumn {
	NAME = "name",
	SCHOOL = "school",
	GRADE_CLASSNAME = "grade_classname"
}

export enum ProjectSharedState {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE"
}
