/// <reference types="web-bluetooth" />

import { DEVICE_STATE } from "../bt-protocol/bluetooth-device-status-enum";
import { PEN_PACKET_END, PEN_PACKET_START } from "../bt-protocol/bt-base-protorol-indicators";
import { unescapePacket } from "../bt-protocol/escape-unescape-packet";
import { IMauiPenCommManager } from "./maui-pen-interface";
import { IPenIdType } from "./IDiscoveredDevices";
import { IBluetoothDeviceBase, IPenComm } from "./pencomm-interface";



export abstract class NeoBtDeviceBase implements IBluetoothDeviceBase {
	protected _name = "";

	protected _protocolHandler: IPenComm | null = null;

	protected _bt_buffer = new Uint8Array(0);

	protected _btId: IPenIdType = undefined;

	protected _inConnecting = false;

	protected _inDisconnecting = false;

	protected _connected = false;

	protected _state = DEVICE_STATE.disconnected;

	protected mauiPenCommManager: IMauiPenCommManager;


	// this.modelNameString = null;

	/**
	 *
	 * @param protocolHandler
	 */
	constructor(protocolHandler: IPenComm, mauiPenCommManager: IMauiPenCommManager) {
		this._protocolHandler = protocolHandler;
		this.mauiPenCommManager = mauiPenCommManager;
	}

	public get protocolHandler() {
		return this._protocolHandler;
	}

	public get deviceId() {
		return this._btId;
	}


	async connect(args: {
		mauiDeviceId?: IPenIdType
		btDevice?: BluetoothDevice,
		protocolStartCallback: () => void,
		shouldStopScanTask?: boolean
	}): Promise<{ status: string, success: boolean }> {
		const { btDevice, mauiDeviceId, protocolStartCallback, shouldStopScanTask = true } = args;
		if (btDevice) {
			return this.connectWebBluetooth(btDevice, protocolStartCallback);
		}

		if (mauiDeviceId) {
			return this.connectMauiBluetooth({
				mauiDeviceId,
				protocolStartCallback,
				shouldStopScanTask
			});
		}

		throw new Error(`None of mauiDeviceId nor btDevice was given`);
	}

	// eslint-disable-next-line class-methods-use-this
	async connectWebBluetooth(btDevice: BluetoothDevice, protocolStartCallback: () => void): Promise<{ status: string, success: boolean }> {
		throw new Error(`not implemented`);
	}


	// eslint-disable-next-line class-methods-use-this
	async connectMauiBluetooth(args: {
		mauiDeviceId: IPenIdType,
		protocolStartCallback: () => void,
		shouldStopScanTask?: boolean
	}): Promise<{ status: string, success: boolean }> {
		throw new Error(`not implemented`);
	}

	// eslint-disable-next-line class-methods-use-this
	registerConnectedMauiBluetooth(mauiDeviceId: IPenIdType, infoRequestProtocolStart: () => Promise<void>): { status: string, success: boolean } {
		throw new Error(`not implemented`);
	}



	// eslint-disable-next-line class-methods-use-this
	disconnect(): Promise<void> {
		throw new Error(`not implemented`);
	}

	// eslint-disable-next-line class-methods-use-this
	preDisconnected(): void {
		throw new Error("Not implemented: preDisconnected");
	}

	/**
	 *
	 */
	onDeviceDisconnected() {
		this._bt_buffer = new Uint8Array(0);

		// 상위로 전달
		const handler = this._protocolHandler;
		if (handler) {
			handler.onDisconnected();
		}
		// this.protocolHandler = null;
	}

	/**
	 *
	 * @param unescaped
	 */

	// eslint-disable-next-line class-methods-use-this
	async writeArray(arr: number[]): Promise<boolean> {
		throw new Error(`not implemented`);
	}

	// eslint-disable-next-line class-methods-use-this
	async write(buf: Uint8Array): Promise<boolean> {
		throw new Error(`not implemented`);
	}


	/**
	 * PEN PACKET PROCESSOR
	 *
	 * process raw packets from pen
	 * slice packets in to a unit packet
	 * process the unit packet command code
	 * @param event
	 */
	onPenPacketReceived = (event: { target: { value: DataView } }) => {
		// console.log("    handle Data");
		const { value } = event.target;
		const data_length = event.target.value.byteLength;

		// 이전에 있던 buf에 concatenate
		const prev_buf = this._bt_buffer;
		const prev_len = prev_buf.length;
		let buffer = new Uint8Array(prev_buf.length + data_length);

		// https://stackoverflow.com/questions/14071463/how-can-i-merge-typedarrays-in-javascript

		// buffer.set(prev_buf);
		for (let i = 0; i < prev_len; i++) {
			buffer[i] = prev_buf[i];

			// // delete this
			// const byte = prev_buf[i];
			// console.log(`prev:    [${i}] 0x${byte.toString(16).padStart(2, "0")}`);
		}

		for (let i = 0; i < value.byteLength; i++) {
			buffer[prev_len + i] = value.getUint8(i);

			// // delete this
			// const byte = value.getUint8(i);
			// console.log(`curr:    [${i}] 0x${byte.toString(16).padStart(2, "0")}`);
		}


		let start = buffer.indexOf(PEN_PACKET_START);
		let end = buffer.indexOf(PEN_PACKET_END);

		// let idx = 0;
		// let cnt = 0;

		// console.log("packet length: " + data_length);
		while (start !== -1 && end !== -1) {
			// console.log("   [" + cnt + "]  packet found: (" + (idx + start) + ", " + (idx + end) + ")");

			// buffer 잘라 주기
			const curCmdPacket = buffer.slice(start, end + 1);
			// idx += end;

			// escape code 처리
			const unescapedBuf = unescapePacket(curCmdPacket);

			// packet 처리
			if (unescapedBuf[0] === PEN_PACKET_START) {
				// pen command 처리
				this._protocolHandler?.processUnitPacket(unescapedBuf);
			} else {
				// 위의 slice 처리가 이상하든지 메모리가 깨졌다.
			}

			// buffer 정리
			// cnt++;
			buffer = buffer.slice(end + 1);
			start = buffer.indexOf(PEN_PACKET_START);
			end = buffer.indexOf(PEN_PACKET_END);
		}

		this._bt_buffer = buffer;
	}
}
