import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "@toast-ui/editor/dist/i18n/ko-kr";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import React from "react";
import "tui-color-picker/dist/tui-color-picker.css";
import fontSize from "tui-editor-plugin-font-size";
import "tui-editor-plugin-font-size/dist/tui-editor-plugin-font-size.css";
import { ReactComponent as TextBoxSizeIcon } from '../../common/images/TextBoxSizeIcon.svg';
import { useStores } from "../../hooks/useStores";
import { TextEditorStyle } from "./styles/TextEditorStyle";
import useEmotionStyles from "../../views/main/styles/useEmotionStyles";
import { useNeoConfirm } from "../../hooks/useNeoConfirm";

type Props = {
	onUploadFile,
	currentHTML: string,
	onSetHTML,
	onInitInitialContent,

	onGetTextLen?,
	placeholder?,
	height?: number,
	maxTextLen?: number,
	exceptFontSizePlugin?: boolean,

	isReset?,
	processAfterReset?,

	isNowBulkLoading?: boolean,
	newHtml?: string
	ref?
}


function TextEditor(props: Props) {
	const {
		onUploadFile,
		currentHTML,
		onSetHTML,
		onGetTextLen,
		placeholder = "",
		onInitInitialContent,
		height = 350,
		maxTextLen = 10000,
		exceptFontSizePlugin = false,

		isReset,
		processAfterReset,

	} = props;

	const { navigateStore } = useStores();
	const {alert, confirm} = useNeoConfirm();

	const [currentHeight, setCurrentHeight] = React.useState(0);
	const [html, setHtml] = React.useState("");
	const [textLen, setTextLen] = React.useState(0);
	const [switchByCharacterLimit, setSwitchByCharacterLimit] = React.useState(false);
	const editorRef = React.useRef(null);
	const classes = useEmotionStyles(TextEditorStyle, { boxHeight: `calc(${currentHeight + 1}px)` });

	React.useEffect(() => {
		// return () => {
		// 	editorRef.current = null;
		// }
	}, []);

	React.useEffect(() => {
		if (
			!editorRef.current ||
			!(editorRef.current instanceof Editor) ||
			!isReset
		)
			return;

		setHtml("");
		editorRef.current.getInstance().setHTML('<p></p>');
		processAfterReset && processAfterReset();
	}, [editorRef.current, isReset]);

	React.useEffect(() => {
		if (
			!editorRef.current ||
			!currentHTML ||
			!(editorRef.current instanceof Editor)
		)
			return;

		editorRef.current.getInstance().setHTML(currentHTML);
		setHtml(currentHTML);
	}, [editorRef.current, currentHTML]);

	React.useEffect(() => {
		// if (!switchByCharacterLimit || !editorRef.current) return;
		if (!editorRef.current || !html || html === '<p></p>') return;

		editorRef.current.getInstance().setHTML(html);
		// setSwitchByCharacterLimit(false);
	}, [switchByCharacterLimit]);
	// }, [switchByCharacterLimit, html]);

	React.useEffect(() => {
		onGetTextLen && onGetTextLen(textLen);
	}, [textLen]);

	React.useEffect(() => {
		setCurrentHeight(height);
	}, [height]);

	const handleMouseDown = (e) => {
		e.preventDefault();

		const startY = e.clientY;

		const handleMouseMove = (e) => {
			const newHeight = currentHeight + (e.clientY - startY);
			if (newHeight < height) return;

			setCurrentHeight(newHeight);
		};

		const handleMouseUp = () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};

		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);
	};

	const handleUploadImage = async (file: File, callback) => {
		try {
			if (!file) return;

			if (!file.type.startsWith("image/")) {
				// alert("The file type must be image.");
				alert("The file type must be image.");
				return;
			}

			// const maxSize = 2 * 1024 * 1024;
			// if (file.size > maxSize) {
			//   alert("The file size must be under the 2MB limit.");
			//   return;
			// }

			if (onUploadFile) {
				await onUploadFile(file)
					.then(data => {
						const { downloadUrl, id } = data;
						if (!downloadUrl || !id) return;

						callback(downloadUrl, id);
					})
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleChangeText = (html) => {
		if (!html) return;

		const tempDiv = document.createElement("div");
		tempDiv.insertAdjacentHTML("afterbegin", html);
		const text = tempDiv.textContent || tempDiv.innerText || "";
		return text;
	};

	const isPlaceHolderAlive = (html) => {

		return false;
		/*
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');
		const placeHolderArr = doc.getElementsByClassName("placeholder ProseMirror-widget");
		return !!placeHolderArr ? placeHolderArr.length > 0 : false;
		*/
	};

	const replaceGapWithNbsp = (html) => {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		const elements = tempDiv.querySelectorAll('p, span');

		elements.forEach(element => {
			element.childNodes.forEach(node => {
				if (node.nodeType === Node.TEXT_NODE) {
					const text = node.textContent;
					const textWithNbsp = text.replace(/ /g, '\u00A0');
					const newText = document.createTextNode(textWithNbsp);
					element.replaceChild(newText, node);
				}
			});
		});

		return tempDiv.innerHTML;
	};

	React.useEffect(() => {
		if (!props.newHtml) return;
		if (!editorRef.current || !(editorRef.current instanceof Editor)) {
			return;
		}

		if (!props.isNowBulkLoading) return;

		setHtml(props.newHtml);
		editorRef.current.getInstance().setHTML(props.newHtml);
		// processAfterReset && processAfterReset();
	}, [props.newHtml]);


	const handleChangeContent = () => {
		if (!editorRef.current) return;
		if (props.isNowBulkLoading) {
			return
		}

		if (currentHTML) {
			// currentHTML로 인한 onSetHTML은 발생하지 않도록.
			onInitInitialContent && onInitInitialContent();
			return;
		}

		// const contentHTML = editorRef.current.getInstance().getHTML();
		const preContentHTML = editorRef.current.getInstance().getHTML();
		let contentHTML = isPlaceHolderAlive(preContentHTML) ? "<p></p>" : preContentHTML;
		const currentText = handleChangeText(preContentHTML);
		const currentTextLen = isPlaceHolderAlive(preContentHTML) ? 0 : (currentText?.length || 0);

		contentHTML = replaceGapWithNbsp(contentHTML);

		if (currentTextLen <= maxTextLen) {
			setHtml(contentHTML);
			// setText(currentText || "");
			setTextLen(currentTextLen);

			onSetHTML?.(contentHTML);
		}

		if (currentTextLen > maxTextLen) {
			// setSwitchByCharacterLimit(true);
			setSwitchByCharacterLimit(prev => !prev);
		}
	};

	const pulgins = exceptFontSizePlugin ? [colorSyntax] : [colorSyntax, fontSize];
	return (
		<div className={classes.main}>
			<Editor
				ref={editorRef}
				height={`${currentHeight}px`}
				// minHeight={'150px'}
				minHeight={`${currentHeight + 50}`}
				placeholder={placeholder}
				previewStyle="vertical" // or tab
				initialEditType="wysiwyg"
				// initialEditType="markdown"
				hideModeSwitch={false}
				toolbarItems={[
					// ["heading", "bold", "italic", "strike"],
					["bold", "italic"],
					['hr', 'quote'],
					['ul', 'ol'],
					["image"],
				]}
				hooks={{ addImageBlobHook: handleUploadImage }}
				// useCommandShortcut={false} // 키보드 입력 컨트롤 방지
				usageStatistics={false} // 통계 수집 거부
				plugins={pulgins}
				// plugins={[colorSyntax]}
				language={navigateStore.language}
				onChange={handleChangeContent}
			/>
			<div
				className={classes.resizer}
				onMouseDown={handleMouseDown}
			>
				<TextBoxSizeIcon />
			</div>
		</div>
	);
}
export default TextEditor;
// export default TextEditor;