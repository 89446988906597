import EventClassBase from "../../../../nl-lib3-common/event/EventClassBase";
import { IDiscoveredDevices, IPenIdType } from "../../../../nl-lib3-common/interfaces/IDiscoveredDevices";
import MauiPenCommManager, { setMauiToJsBridge } from "./maui-pencomm-proxy";
import { IMauiToJsBridge } from "./maui-to-js-interface";
import { MauiToJsEventName, MauiToJsEvent } from "./maui-to-js-types";

/**
 * MauiToJsBridge, Maui에서 JS Interop으로 부르는 함수를 정의한다.
 */

export class MauiToJsBridge extends EventClassBase<MauiToJsEventName, MauiToJsEvent> implements IMauiToJsBridge {
  private static _inst = null;

  // private _appUrl: string = null;

  static get instance() {
    if (!MauiToJsBridge._inst) {
      MauiToJsBridge._inst = new MauiToJsBridge();
    }
    return MauiToJsBridge._inst;
  }

  private constructor() {
    super();
    setMauiToJsBridge(this);

    this.initGlobalFunction();
    this.initGlobalFunctionAppRelated();
  }

  // public init(appUrl: string) {
  //   // this._appUrl = appUrl;
  //   console.log(`MauiToJsBridge inited`);
  // }

  private initGlobalFunction() {
    const w = (window as any);
    // initWebSocketCommandProxy();

    /**
     * MauiToJsEvent_onAutoConnectCompleted
     *
     * @param deviceId
     * @returns
     */
    // if (!w.onAutoConnectCompleted)
    w.onAutoConnectCompleted = async (deviceId: string) => {
      const webSocketServerOnly = process.env.REACT_APP_IS_FOR_WEB_SOCKET_SERVER === "true" ? true : false;
      if (webSocketServerOnly) {
        return;
      }

      console.log("자동 연결할 대상 디바이스 ID:", deviceId);
      this.dispatcher.dispatch(MauiToJsEventName.onAutoConnectCompleted, { deviceId });
    }

    // if (!w.onBluetoothTurnOff)
    w.onBluetoothTurnOff = async () => {
      console.log("블루투스가 켜져 있지 않아 스마트펜 연결 기능을 사용 하실 수 없습니다.");
      this.dispatcher.dispatch(MauiToJsEventName.onBluetoothTurnOff, {});
    };


    w.setAssemblyName = (name: string) => {
      console.log(`setAssemblyName, ${name}`);
      w.mauiAssemblyName = name;
    };


    /**
     * MauiToJsEvent_onDeviceDiscoveringUpdate
     *
     * @param jsonStr
     * @returns
     */
    w.onDeviceDiscoveringUpdate = (jsonStr: string) => {
      const webSocketServerOnly = process.env.REACT_APP_IS_FOR_WEB_SOCKET_SERVER;
      if (webSocketServerOnly === "true") {
        return;
      }

      // MauiPenCommManager.instance.onDeviceDiscoveringUpdate(json);
      const devices = JSON.parse(jsonStr) as IDiscoveredDevices[];
      this.dispatcher.dispatch(MauiToJsEventName.onDeviceDiscoveringUpdate, { devices });
    };


    /**
     * MauiToJsEvent_onDeviceDiscoveringUpdate
     *
     * @param json
     * @returns
     */
    w.onReportDeviceScanningFinished = (jsonStr: string) => {
      const webSocketServerOnly = process.env.REACT_APP_IS_FOR_WEB_SOCKET_SERVER === "true" ? true : false;
      if (webSocketServerOnly) {
        return;
      }


      // console.warn(`onReportDeviceScanningFinished, ${json}`);
      // console.error("maui-to-js.ts: onReportDeviceScanningFinished,  Not implemented");
      // MauiPenCommManager.instance.onReportDeviceScanningFinished(json);

      const devices = JSON.parse(jsonStr) as IDiscoveredDevices[];
      this.dispatcher.dispatch(MauiToJsEventName.onReportDeviceScanningFinished, { devices });
    }




    /**
     * MauiToJsEvent_onDeviceNotification
     *
     * @param id
     * @param val
     * @param mac
     * @returns
     */
    w.onDeviceNotification = (id: IPenIdType, val: string | Uint8Array, mac: string) => {
      try {
        const webSocketServerOnly = process.env.REACT_APP_IS_FOR_WEB_SOCKET_SERVER === "true" ? true : false;
        if (webSocketServerOnly) {
          return true;
        }
        let bytes: Uint8Array;
        if (typeof val === "string") {
          const binary_string = window.atob(val);
          const len = binary_string.length;
          bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
        } else {
          bytes = val;
        }
        // console.log(`          Packet read  (${bytes.length.toString().padStart(5, " ")})  <=== ${bytes.reduce((acc, v) => { const c = decimalToHex(v, 2); return acc + c + " "; }, "")}`);

        MauiPenCommManager.instance.onPacketReceived(id, bytes, mac);
        // this.dispatcher.dispatch(MauiToJsEventName.onDeviceNotification, { id, bytes, mac });
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    };


    /**
     * MauiToJsEvent_onDeviceDisconnected
     *
     * @param id
     * @returns
     */
    w.onDeviceDisconnected = (id: IPenIdType) => {
      const webSocketServerOnly = process.env.REACT_APP_IS_FOR_WEB_SOCKET_SERVER === "true" ? true : false;
      if (webSocketServerOnly) {
        return;
      }

      // MauiPenCommManager.instance.onDeviceDisconnected(id);

      this.dispatcher.dispatch(MauiToJsEventName.onDeviceDisconnected, { id });
    };


    /**
     * MauiToJsEvent_onAuthCodeReceived
     * @param code
     */
    w.onAuthCodeReceived = async (code: string) => {
      console.log(`received code = ${code}`);
      // const { auth } = NDP.instance;
      // const type = localStorage.getItem("NdpClientType") as ClientIdEnum;

      // await auth.processStoredLoginCode(code, type);

      this.dispatcher.dispatch(MauiToJsEventName.onAuthCodeReceived, { code });
    }


    /**
     * MauiToJsEvent_onRefreshTokenReceived
     *
     * @param refreshJson
     */
    w.onRefreshTokenReceived = async (refreshJson: string) => {
      console.log(`received refreshJson = ${refreshJson}`);

      // const parsedJson = JSON.parse(refreshJson);
      // const { refresh_token, authorityType } = parsedJson;

      // const { auth } = NDP.instance;
      // await auth.refreshAccessToken(authorityType, refresh_token);

      this.dispatcher.dispatch(MauiToJsEventName.onRefreshTokenReceived, { refreshJson });
    }
  }

  private initGlobalFunctionAppRelated() {

    const w = (window as any);

    w.onAppActivated = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onAppActivated, {});
      // NDPSyncController.instance.requestAllSync_DownUpSync();
    }

    w.onAppDeactivated = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onAppDeactivated, {});
    }

    w.onAppSleep = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onAppSleep, {});
    }

    w.requestSyncNotebooks = () => {
      this.dispatcher.dispatch(MauiToJsEventName.requestSyncNotebooks, {});
      // NDPSyncController.instance.requestAllSync_DownUpSync();
    }

    w.onRefreshTokenVoid = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onRefreshTokenVoid, {});
    };

    w.onRefreshTokenExist = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onRefreshTokenExist, {});
    };

    w.onBackPressed = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onBackPressed, {});
    }

    w.CloseDrawer = () => {
      this.dispatcher.dispatch(MauiToJsEventName.CloseDrawer, {});
    }

    w.onFailedToGetGoogleDriveToken = () => {
      this.dispatcher.dispatch(MauiToJsEventName.onFailedToGetGoogleDriveToken, {});
    }

    w.transferProgress = (progress: number) => {
      this.dispatcher.dispatch(MauiToJsEventName.transferProgress, { progress });
    }

    w.onErrorCodeReceived = (code: string) => {
      this.dispatcher.dispatch(MauiToJsEventName.onErrorCodeReceived, { code });
    }

    w.onKeyboardVisibilityChanged = (onVisible: boolean) => {
      // iOS 는 키보드의 높이를 직접적으로 구할 수 있으나, AOS 는 rootView 의 변화를 기준으로 구한다.
      // 그런데 웹앱 환경에서는 rootView 의 변화가 일어나지 않으므로 AOS 는 구체적인 높이를 구할 수 없다.
      this.dispatcher.dispatch(MauiToJsEventName.onKeyboardVisibilityChanged, { onVisible });
    }
  }

}


